@search-height: 54px;
@search-side-padd : 12px;
@search-inner-padd : 25px;

.b-search-wrapper {
  padding: 11px;
  &__anonymous {
  }
  &__tips {

  }
  &__tips__common {
    padding-top:0;
  }
  &__tips__breadcrumbs {

  }
}

.b-search {
  background: @input-border-focus;
  box-shadow: inset 0 10px 40px rgba(0,0,0,.05);
  position: relative;
  //z-index: ~"+1";
}

.b-search__auth_warn {
  background: rgba(0,0,0,.4);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: @zindex-navbar;

  .b-search-wrapper {
    padding: 20px 24px;
  }

  //.b-search-area {
  //  z-index: @zindex-modal;
  //}

  .b-search-tip {
    color: #fff;
    a {
      color: inherit;
      border-bottom: 1px dashed;
      text-decoration: none !important;
    }
  }

  .b-search-form-submit-btn {
    font-size: 27px;
    line-height: 27px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.b-header-search-wrapper {
  transition: opacity .2s;
  opacity: 0;
  position: absolute;
  left: -9999px;
  right: 9999px;
  z-index: 22;
  top: 0;
  bottom: 0;

  &.pos {
    left: 0;
    right: 0;
  }

  &.in {
    opacity: 1;
  }
}

.b-search--header {
  background: #ffffff;
  box-shadow: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;

  .b-search-area, .b-search-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all linear .2s;
  }

  .b-search-form {
    height: 100%;
  }

  .b-search-form-submit-btn {
    transition: all linear .2s;
  }

  .b-search-form-submit-btn-text {
    font-size: 16px;
    vertical-align: middle;
    margin-top: -4px;
    display: inline-block;
    padding-left: 5px;
  }

  .b-search-form-col--submit {
    padding-left: 30px;
    transition: all linear .2s;
  }

  .b-search-form-col--close {
    padding-left: 40px;
    transition: all linear .2s;
  }

  .b-search-input-wrapper .form-control {
    box-shadow: none;
    padding: 0 45px 0 15px;
    height: 52px;
    line-height: 52px;

    &:focus {
      box-shadow: none;
      border: 1px solid #cdd1d4;
    }
  }
}

.b-search-form-close-btn {
  font-size: 40px;
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-top: 8px;
  transition: all linear .2s;

  &:hover {
    text-decoration: none;
  }
}

.b-header-search-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: -9999px;
  right: 9999px;
  bottom: 0;
  z-index: 1020;
  background: rgba(0,0,0,0.4);
  opacity: 0;
  transition: opacity .2s;

  &.pos {
    left: 0;
    right: 0;
  }

  &.in {
    opacity: 1;
  }
}

.b-panel .b-header-search-overlay {
  bottom: auto;
  height: 50px;
}


.b-search__focused .b-search-wrapper__tips {
  z-index: @zindex-modal-background + 1;

  .b-search-tip {
    color: #fff;
    a {
      color: inherit;
      border-bottom: 1px dashed;
      text-decoration: none !important;
    }
  }
}

.b-search-area {
  position: relative;
}
.b-search-container {

}

.b-bc-search-dropdown-menu {
  .b-search-area {
    background: @lead-color;
  }
}

.b-search-input-wrapper {
  position: relative;

  //@bord-width : 1px;
  //border:@bord-width solid #cdd1d4;
  //padding: 9px (@search-side-padd - @bord-width);

  .form-control {
    @h: @search-height;
    height:@h;
    line-height: @h;

    //padding: 19px @search-inner-padd;
    padding: 0 45px 0 @search-inner-padd; // @see lktoepgu-173
    font-size:17px;
    text-align: left;
    border-radius: 4px;

    ::placeholder {
      color:#ccc;
    }
    
    &.empty-state {
      border-color: #fcd;

      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
  }

}

.b-search-form {
  width:100%;
  display: table;
  position: relative;
  z-index: ~"+4";
}

.b-search-form__focused {
  z-index: @zindex-modal;
}

.b-section__header .b-search {
  //.tt-dropdown-menu {
  //  position: absolute;
  //  bottom: 100%;
  //  margin-bottom: -10px;
  //  left: 0;
  //  right: 0;
  //  max-width: none;
  //
  //  z-index: auto !important;
  //}
  //.tt-dataset {
  //  right: 0;
  //  max-width: none;
  //  padding-top: 70px;
  //}
  .tt-empty {
    display: none;
    //&.tt-open {
    //  display: block;
    //}
  }
}
//.b-search-results {
//  .tt-dataset {
//    // margin top and padding bottom should be the same, or padding should be lower than margin-top,
//    // because of overflow. @see lktoepgu-1275
//    margin-top: -13px;
//    padding-bottom: 13px;
//  }
//}
.b-search-form-col {
  display: table-cell;
  vertical-align: middle;

  padding-left:@search-side-padd;
  &:first-child {
    padding-left:0;
    width:100%;
  }
}
.b-search-form-clear {
  display: block;
  position: absolute;
  top:50%;
  right: @search-inner-padd;
  font-size: 24px;
  line-height: 24px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: ~"+1";

  color: inherit;
  text-decoration: none !important;
  opacity: 0;

  &.vis {
    opacity: .5;
  }

  &:hover {
    opacity: .85;
  }
}

.b-search-form-submit-btn {
  font-size: 27px;
  line-height: 27px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  height:@search-height;

  @padd : @search-inner-padd;
  span {
    padding-left: 10px;
  }

}

.b-search-tip {
  display: block;
  a {
    cursor: pointer;
  }
}

.search-results-empty-container {
  padding-top:45px;
}
.search-results-empty-icon {
  display: block;
  text-align: center;
}
.search-results-empty-icon__found {
  margin-top: 90px;
}
.search-results-empty-icon-params(@icon) {
  height: image-height(@icon);
  max-width: image-width(@icon);
  background: url('@{icon}') 50% 0 no-repeat;
  background-size: 100% auto;
}
.search-results-empty-icon-img {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  z-index: +5;

  .search-results-empty-icon__not_found & {
    .search-results-empty-icon-params('@{path-images}pelmen.png');
  }
  .search-results-empty-icon__found & {
    .search-results-empty-icon-params('@{path-images}pelmen-kindly.png');
  }
}

.search-results-empty-icon-bubble {
  @border-width: 3px;

  position: absolute;

  left:100%;
  bottom: 100%;
  margin-bottom:-75px;
  font-size: @font-size-small;

  display: block;
  width:265px;
  color: #535b63;
  background: #fff;
  border: @border-width solid #BBDDF7;
  border-radius: 18px;
  padding: 18px;
  text-align: center;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &:after {
    @url : '@{path-images}pelmen-bubble-tail.png';
   @image-width : image-width(@url);
   @image-height : image-height(@url);
    width: @image-width;
    height: @image-height;
    content:"";
    display: block;
    position: absolute;
    background: url('@{url}') 0 0 no-repeat;
    background-size: @image-width @image-height;
    right: 100%;
    margin-right: 0;//-@border-width;
    bottom: 0;
    z-index: ~"+1";

    .search-results-empty-icon__not_found & {
      bottom:0;
    }
    .search-results-empty-icon__found & {
      bottom: 37px;
    }
  }
}
.search-results-empty-message {
  padding-top: 50px;
}
.search-results-empty-message-title {
  display: block;
  text-align: center;
  font-weight:normal;
  font-family: @font-family-bolder;
  font-size: @font-size-h1 * 0.95;
}
.search-results-empty-message-text {
  display: block;
  max-width:420px;
  margin: 1em auto 0;
  text-align: center;
  font-size: @font-size-large;
  color: #535b63;
}
.search-results-empty-actions {
  display: block;
  text-align: center;
  padding-top: 40px;

  .btn {
    .fa {
      margin-right: 1em;
    }
  }
  .btn + .btn {
    margin-left: 20px;
  }
}

.search-results-form {
  padding-left:0;
  padding-right:0;
}

.search-results-filter {
  margin-top: 40px;
}

.b-search-input-wrapper,
.search-results-container {
  .sq-result-cell--spacer {
    width: 0 !important;
  }
}

.search-results-container {
  margin-top: 40px;
  background: @lead-color;
  .sq-result-cell {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .sq-result-cell--res {
    border-color: @gray-lighter !important;
  }
  .search-result-group:first-child:last-child {
    .sq-result-cell {
      border-bottom: 0 !important;
    }
  }
}

.b-no-search-results {
  display: block;
  .clearfix();
}

.b-no-search-results-info {
  display: block;
  float: left;
  width: 65%;
}

.b-no-search-results-title {
  display: block;
  font-family: @font-family-bolder;
  font-size: 40px;
  line-height: 44px;
  padding-right: 20px;
}

.b-no-search-results-image-wrapper {
  display: block;
  float: right;
  width: 35%;
}

.b-no-search-results-image {
  display: inline-block;
  width: 334px;
  height: 264px;
  background: url("@{path-images}pelmen.gif") 50% 50% no-repeat transparent;
  margin: -30px 0 0;
}

.b-no-search-results-buttons {
  display: block;
  padding-top: 30px;
  padding-right: 20px;

  .btn + .btn {
    margin-left: 20px;
  }
}

.search__word {
  display: inline-block;
  padding: 0 2px;
  font-weight: bold;
  color: #fff;
  background-color: @brand-primary;
}