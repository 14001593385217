.reglament-link {
  position: relative;
  display: block;
  vertical-align: top;
  line-height: 25px;
  padding-left:54px;
  &:before {
    content:"";
    display: block;
    width:45px;
    height:45px;
    background: url('@{path-images}reglament-icon.png') center center no-repeat;
    background-size: 100% auto;
    position: absolute;
    top:0;
    left:0;
  }
}
.reglament-link-info {
  color: #000;
  text-transform: uppercase;
  display: block;
  text-decoration: none !important;
}