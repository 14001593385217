@fake-selectbox-height:46px;

.modal-body-content {
  ul {
    li {
      //padding: 0 0 0 1.5em; //0 0 0 20px;
      //margin: 1.25em 0 0; // 20px 0 0;
      padding: 0 0 0 20px;
      margin: 1.25em 0 0 -20px;
      list-style:none;
      position: relative;
      &:before {
        content:"";
        border-radius:100%;
        background: url('@{path-images}svg/list-bullet.svg') center center no-repeat;
        //background-color: @text-color; // is bit baggy on small sizes
        background-size: 100% 100%;
        overflow:hidden;
        width: .45em;
        height:.45em;
        position: absolute;
        top: 0;
        left: 0;
        margin-top:.5em;
      }
    }
  }
}

.modal-body {

  form&, // form.modal-body
  & form { // .modal-body form
    @star-color: @brand-critical;

    .required {
      position:relative;
      &:after {
        content:"*";
        display: inline;
        margin-left:.5em;
        color: @star-color;
      }
    }

    .star {
      color: @star-color;
      margin-left:.5em;
    }
  }

  .form-row {
    display: flex;
    justify-content: space-between;
  }

  select,
  textarea,
  input[type=text],
  input[type=date],
  input[type=email],
  input[type=search],
  input[type=password],
  .bootstrap-select.btn-group .btn {

    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;

    font-size:15px;
    font-weight: normal;
    //line-height: 18px;
    line-height: @line-height-computed;
    padding: @padding-base-vertical @padding-base-horizontal;
    border:1px solid @block-border-color;
    background: @element-bg;

    box-sizing: border-box;
    width:100%;
    max-width: 100%;

    //transition: background-color .35s ease;
    outline-color: fade(@brand-info, 50%);

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px #ffffff inset;
    }
  }

  textarea,
  input[type=text],
  input[type=date],
  input[type=email],
  input[type=search],
  input[type=password] {
    &::placeholder {
      font-size: .8em;
    }
  }

  textarea {
    // @see lktoepgu-1259
    //white-space: pre;
    word-break: break-word;
    // @see http://stackoverflow.com/questions/3453959/how-do-i-fix-inconsistent-textarea-bottom-margin-in-firefox-and-chrome
    display: block;
  }

  span.input-upper > textarea,
  span.input-upper > input,
  .input-upper {
    text-transform: uppercase;
  }

  //.input-upper{
  //  .placeholder({text-transform: initial;})
  //}

  .bootstrap-select .btn {
    padding-right:36px;
  }

  .bootstrap-select .btn:active,
  .bootstrap-select .btn:focus,
  .bootstrap-select .btn:hover {
    background: #f2f2f2 !important;
    color: inherit;
    outline: none !important;
  }

  .select2-container .select2-selection .select2-selection__arrow,
  .bootstrap-select.btn-group .btn .caret {
    display: block;
    width:16px;
    height:16px;
    font-size: 24px;
    margin-top:-.5em;
    border: 0 !important;
    background: none !important;

    &:before {
      .fa-icon();
      content: @fa-var-angle-down;
      font-size: inherit;
      font-weight: normal;
      text-align: center;
      border: 0;
      color: @input-color;
    }
  }

  input[type=date].hasDatepicker ,
  input[type=text].hasDatepicker,
  input[type=date].form__inp_date ,
  input[type=text].form__inp_date{
    width: 150px;
    margin-right: 15px;
    &[readonly] {
      margin-right: 11px;
      &~.ui-datepicker-trigger {
        display: none;
      }
      @supports (pointer-events: none) {
        &~.ui-datepicker-trigger {
          display: inline-block;
          pointer-events: none;
        }
      }
    }
  }

  input.data {
    width: 70px;
    margin-right: 15px;
  }

  select.attr-value-el--filled,
  select.attr-value-el--filled + .btn,
  select.attr-value-el--filled + .btn-group > .btn,
  input[type=email].attr-value-el--filled,
  input[type=text].attr-value-el--filled,
  input[type=date].attr-value-el--filled,
  input[type=password].attr-value-el--filled,
  textarea.attr-value-el--filled {
    outline:0;
    background: transparent !important; /* !important because of automask.js field.style.background usage*/

    &[readonly] {
      background: #f5f5f5 !important;
    }
    &[disabled] {
      background: @gray-lighter !important;
      color: @gray-base;
    }
  }

  select:active,
  select:focus,
  textarea:active,
  textarea:focus,
  input[type=date]:active,
  input[type=text]:active,
  input[type=date]:focus,
  input[type=text]:focus,
  input[type=email]:active,
  input[type=email]:focus,
  input[type=password]:active,
  input[type=password]:focus {
    outline:0;
    background: transparent !important; /* !important because of automask.js field.style.background usage*/
    border-color: @input-border-focus;
    box-shadow: 0 0 0 4px @input-border-focus;

    &:-webkit-autofill {
      box-shadow: 0 0 0 4px @input-border-focus;
    }
  }

  select.error,
  select.error + .bootstrap-select > .btn,
  select.error + .jq-selectbox > .jq-selectbox__select,
  textarea.error,
  input[type=checkbox].error + label:before,
  input[type=hidden].error + input[type=checkbox] + label:before,
  input[type=radio].error + label:before,
  input[type=email][error],
  input[type=date][error],
  input[type=text][error],
  input[type=text].error,
  input[type=date].error,
  input[type=email].error,
  input[type=password].error {
    border-width: 1px;
    border-color: @input-border-error !important;
    box-shadow: none;
  }

  select,
  textarea,
  input[type=file],
  input[type=checkbox],
  input[type=radio],
  input[type=email],
  input[type=date],
  input[type=text],
  input[type=password] {
    &[readonly],
    &[disabled],
    &.disabled {
      cursor: default;
      border-color: rgba(0,0,0,.25) !important;
      background-color: rgba(0,0,0,.05) !important;
      box-shadow: none;
      outline: none;
    }
  }

  //.error {
  //  .placeholder( fade(@brand-critical, 50%) );
  //}
}

.formErMes {
   font-size: @font-size-small;
   //text-transform: uppercase;
   color: @brand-critical;
   display: block;
   margin-top:.5em;//5px;
   //letter-spacing: -0.03em;/*compact letters*/
 }

.validateError {
  .jq-file__name {
    border-width: 1px;
    border-color: @input-border-error !important;
    box-shadow: none;
  }
}

.bootstrap-select.btn-group {
  .dropdown-menu {
    width:100%;
    overflow-x: hidden;
    li a,
    li {
      outline:none;
    }
    li.selected,
    li:active,
    li:focus,
    li:hover {
      background: #f2f2f2;
    }
    li a span.text {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      max-width: 100%;

      // via lktoepgu-680
      white-space: normal;
    }
  }
  &.sp--check-attr-value {
    .dropdown-menu.inner {
      max-height: 250px;
      overflow-y: auto;
    }
  }
}

.attr-value-datepicker input[type="text"] {
  width:110px !important;
}

.modal-body--count-subgroups {
  counter-reset: csbinline;

  .subgroup-title {
    &:before {
      counter-increment: csbinline;
      content: counter(csbinline);
      position: absolute;
      left: 0;
      top: .05em;
      width: auto;
      text-align: center;
      font-size: 36px;
      font-family: EtelkaLightPro,Arial,"Helvetica Neue",Helvetica,sans-serif;
      line-height: 44px;
      margin-left: -50px;
    }
  }
}

.jq-selectbox-wrapper {
  width: 100%;
  max-width: 590px;
}
.selectBox-wrapper-readonly {
  pointer-events: none;
  & .jq-selectbox__select {
    cursor: default;
    border-color: rgba(0, 0, 0, .25) !important;
    background-color: rgba(0, 0, 0, .05) !important;
    box-shadow: none;
    outline: none;
  }
}

.jq-selectbox {
  vertical-align: middle;
  cursor: pointer;
  min-width: 200px;
}

.jq-selectbox__select {
  height: @fake-selectbox-height;
  padding: 0 45px 0 24px;
  line-height: 46px;
  color: #000000;
  border: 1px solid #cdd1d4;
  border-radius: 0;
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-size: 15px;

}

.jq-selectbox__select-text {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.jq-selectbox__trigger {
  position: absolute;
  top: 0;
  right: 17px;
  width: 18px;
  height: 49px;
  line-height: 49px;
  font-size: 26px;
  color: #565656;

  &:after {
    .fa-icon();
    content: @fa-var-angle-down;
  }
}

.jq-selectbox__trigger-arrow {
  display: none;
}

.jq-selectbox__dropdown {
  top: @fake-selectbox-height;
  width: 100%;
  max-width: 100%;
  margin: 1px 0 0 0;
  padding: 0;
  border: 1px solid #cdd1d4;
  background: #FFF;
  left: 0;
  right: 0;
  max-height: 350px;
  z-index: 1;
}

.jq-selectbox li {
  font-size: 17px;
  text-overflow: initial;
  white-space: normal;
  line-height: 24px;
  min-height: 0;
  padding: 10px 20px 10px 40px;
  margin: 0;
  font-family: Arial, sans-serif;
  position: relative;
  display: block;

  &:hover {
    color: #ffffff;
    background: #6ba9d2;
  }

  &.selected, &.sel {
    &:after {
      .fa-icon();
      content: @fa-var-check;
      position: absolute;
      left: 15px;
      top: 14px;
      font-size: 15px;
    }
  }
}

.form__item_filter .btn {
  float: right;
  margin-top: -3px;
  width: 200px;
}

.info_tooltip {
  position: relative;

  &:hover {
    .tooltip {
      opacity: 1;
      visibility: visible;
    }
  }

  .tooltip {
    top: 0;
    margin: 0;
    padding: 0;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: opacity .3s ease;
    white-space: nowrap;
    visibility: hidden;

    .tooltip-inner {
      background: #fff;
      color: initial;
      text-align: left;
      cursor: text
    }
    &.top .tooltip-arrow {
      border-top-color: #fff;
    }
    &.right {
      left: 100%;
      .tooltip-arrow {
        border-right-color: #fff;
      }
    }
    &.bottom {
      top: -100%;
      .tooltip-arrow {
        border-bottom-color: #fff;
      }
    }
    &.left .tooltip-arrow {
      border-left-color: #fff;
    }
  }
}

form, .form {
  .autocomplete-holder {
    position: relative;

    .fakeField {
      position: static;
    }

    .myHint {
      top: 0;
      right: 0;
      bottom: auto;
    }
  }


}

.steps__form {
  .my-loader.loader-hider ~ * {display:none}
}

