.service-organ {
  cursor: pointer;
}
.service-organ-icon {
  margin-bottom: 1em;
  display: block;
  background: url('@{path-images}rus-logo.png') 0 50% no-repeat;
  background-size: auto 100% ;
  height:39px;
}
.service-organ-link,
.service-organ-helper {
  display: block;
  margin: 1em 0 0;
  font-weight: bold;
  font-family: @font-family-medium;
}
.service-organ-helper {

}
.service-organ-link {

}