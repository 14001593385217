.receive-doc-layout {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.receive-doc-row {
  display: table-row;
}
.receive-doc-row:first-child .receive-doc-spacer {
  padding-top: 10px;
}
.receive-doc-cell {
  display: table-cell;
  vertical-align: top;
  padding-top: 25px;
}
.receive-doc-row:first-child .receive-doc-cell {
  padding-top: 0;
}
.user-file-cell--icon,
.receive-doc-cell--icon {
  width:20px;
  white-space: nowrap;
}
.user-file-cell--title,
.receive-doc-cell--info {
  padding-left: 15px;
  padding-right: 15px;
}
.user-file-cell--actions,
.receive-doc-cell--actions {
  width:90px;
}
.custom-receive-doc-wrap {

}
.custom-receive-doc-wrap .receive-doc-cell {
  padding-top:0;
  padding-bottom: 25px;
}
.custom-receive-doc-wrap .receive-doc-cell:first-child {
  padding-left:0;
}
.receive-doc-icon {
  display: inline-block;
  background-position: 0 0;
  background-repeat: no-repeat;
  width:19px;
  height:21px;
}
.receive-doc-icon,
.receive-doc-icon--jpg ,
.receive-doc-icon--png ,
.receive-doc-icon--pdf {
/*  background-image: url('../../images/sunshine/ico/doc-template--danger.png');*/
}
.receive-doc-icon--docx,
.receive-doc-icon--doc,
.receive-doc-icon--rtf {
/*  background-image: url('../../images/sunshine/ico/doc-template--primary.png');*/
}
.receive-doc-icon--xls,
.receive-doc-icon--xlsx {
  /*background-image: url('../../images/sunshine/ico/doc-template--success.png');*/
}
.receive-doc-num,
//.receive-doc-action,
.receive-doc-spacer {
  display: block;
  color: @gray-light;
  font-size: 12px;
  text-transform: uppercase;
}
.receive-doc-num {
  font-size: inherit;
  display: inline-block;
}
.receive-doc-scroll {
  max-height: 400px;
}
.receive-doc-wrap {
  display: block;
}
.receive-doc-required {
  color: @brand-danger;
  /*font-family: 'Open Sans Bold';*/
  display: inline-block;
  vertical-align: top;
  padding: 0 5px;
  margin: 0 -5px;
}
.receive-doc-required-message {
  padding-top: 10px;
}
.receive-doc-wrap + .receive-doc-required-message ,
.mCustomScrollbar + .receive-doc-required-message {
  padding-top: 30px;
}
.receive-doc-spacer {
  display: none; /* .receive-doc-spacer is @deprecated via MSMEV-3522 */
  padding: 0 0 10px;
  line-height: 10px;
}
.receive-doc-title {
  display: block;
  line-height: 20px;
  /*text-transform: capitalize;*/
}
.receive-doc-title-with-tip {
  position: relative;
  padding-right: 35px;

  .attr-value-helper--icon {
    top: 0;
  }
}
.receive-doc-title-text {
}
.receive-doc-comment {
  display: block;
  margin-top:5px;
  opacity: .5;
}
.receive-doc-comment:first-letter {
  text-transform: uppercase;
}
//.receive-doc-action {
//  line-height: 20px;
//  padding-left: 20px;
//  background-position: 0 0;
//  background-repeat: no-repeat;
//}
//.receive-doc-action--download {  background-image: url('../../images/sunshine/ico/doc-download.png');  }
//.receive-doc-action--take {  background-image: url('../../images/sunshine/ico/take-arrow.png');  }