.responsive__tablet {

}

// tablets and smaller
.responsive__tablet_sm {

  .t-modal-layout .t-modal-layout-item {
    width:50%;
  }

  //.nav {
  //  > li {
  //    > a {
  //      padding-left: 32px;
  //      padding-right: 32px;
  //    }
  //  }
  //}

  .app-inner {
    padding-bottom: 150px;
  }

  .b-footer-wrapper {
    padding-bottom: 15px;//30px
  }

  .b-footer-phone-list {
    float: none;
    height: 0;
    margin-bottom: 0;//15px;
    width: 100%;
  }

  .b-footer-logo-cont {
    @s : 61px;
    width: @s;
    height: @s;
  }

  .b-footer-contacts,
  .b-footer-logo-caption {
    font-size: 14px;
    line-height: 1.3em;
  }

  .b-footer-contacts {
    text-align: right;
  }

  .b-footer-logo-caption {
    max-width: 280px;
    padding-left: 7px;
  }

  .b-tech-message {
    .b-tech-message-body {

      & > * {
        font-size: 14px !important;
      }
    }
  }

  .b-petition-in-sign-check-wrap {
    margin-left:0;
    position: relative;
    left: 0;
    top: 0;
  }

  .b-petition-in-sign-check {

    &.fixed {
      position: static;
    }
  }

  .attr-field--layout {
    .attr-label--inline {
      width: 35%;

      &+.attr-value {
        width: 65%;
      }
    }
  }

  .g-tile {
    width: 50%;
  }

  .petition-row-info-actions {
    @btn-margin-top : 20px;
    margin-top: -@btn-margin-top;
    margin-bottom: @btn-margin-top;
    .btn {
      float:left !important;
      width: 30%;
      margin-top: @btn-margin-top;
      padding: @padding-large-vertical @padding-large-horizontal;
      font-size: @font-size-middle;
      &:nth-child(1n){
        margin-left: 5%;
      }
      &:nth-child(3n-2) {
        margin-left: 0;
      }
    }
  }

  .b-auth-warn-slide__visible .b-auth-warn-bg {
    opacity: .35;
  }
  .b-auth-warn-inner {
    @padd : 20px;
    //padding-left:@padd;
    max-width: 65%;
    &__actions {
      background: transparent;
      padding-left:@padd;
      padding-right:@padd;
    }
  }
  body {
    min-width: auto;
  }
  .section-fixate__fixed .section-fixate-inner {
    min-width: auto;
  }
  .b-header-profile {
    position: relative;
  }
  .b-section__fixate.section-fixate {
    height: 185px!important;
  }
}