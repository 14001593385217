.filebox {
  display: block;
  position: relative;
  //overflow: hidden;
  width: 100%;
  clear: both;
}
.modal-body .filebox {
  margin:20px 0 0;
}
.filebox-inner {
  display: table;
  table-layout: fixed;
}
.filebox-btn {
  min-width:90px;
  margin-left: 4px;
}
.filebox-col {
  width: 20%;
  display: table-cell;
  vertical-align: top;
}
.filebox-col:first-child {
  width:auto;
}
.filebox-col input[type=text] {
  width:100%;
}
.modal-body .filebox input[type=text],
.filebox input[type=text] {
  //padding: @padding-base-vertical @padding-base-horizontal;
  line-height: @line-height-base;
  font-size: @font-size-base;
  opacity: .5;
}
.filebox input[type=file] {
  position: absolute;
  top: 0;
  left: 0;//1%;
  width: ~"calc(80% - 12px)";
  margin: 0;
  padding: @padding-base-vertical @padding-base-horizontal;
  //font-size: 20px;
  cursor: pointer;
  opacity: 0;
}

//////////

.filebox .g-file-loader {
  margin-top:10px;
}

.g-file-loader {
  height:20px;
  display:block;
  background:#f5f5f5;
  /*border:1px solid rgba(0,0,0,.05);*/
  position:relative;
}
.g-file-loader-inner {
  position:absolute;
  width:0;
  max-width: 100%;
  height:100%;
  top:0;
  left:0;
  background: @brand-primary;
  /*text-align: center;*/
  text-align: right;
  padding-right:10px;
  overflow: hidden;
  color:transparent;
  font-size: 12px;
  line-height: 20px;

  //transition: background .15s,width 1.5s ease,color .35s ease;
  transition: width .45s ease;
}
.g-file-loader--done .g-file-loader-inner,
.g-file-loader--error .g-file-loader-inner,
.g-file-loader--percent-visible .g-file-loader-inner {
  color: #fff;
}
.g-file-loader--error .g-file-loader-inner {
  background: @brand-danger;
}
.g-file-loader--error .g-file-loader-inner ,
.g-file-loader--done .g-file-loader-inner {
  width:100% !important;
  //transition:background .15s,width .25s ease-in,color .35s ease;
  transition: width .15s ease;
}

.docsbord {
  border: 1px solid @blockquote-border-color;
  padding: 20px;
  margin: 10px 0 0;

  .attr-field--error & {
    border-color: @input-border-error;
  }
}

.storage-files-link {
  text-decoration: none !important;
  border-bottom: 1px dashed;
}
.existed-file-box-info {
  font-weight: bold;
  margin-top:15px;
}
.dsr-files-holder .existed-file-box-cancel {
  text-decoration: none !important;
  border-bottom: 1px dashed;
  display: table-cell;
  width: 20%;
  text-align: center;
}
.cloud-files-list-table-caption {
  margin-bottom: 0;
}

.existed-files-box-header {
  /*font-weight:normal; */
  margin-bottom:5px;
}
.existed-files-amount {

}
.existed-files-container {
  overflow: auto;
  max-height: 100px;
}
.existed-files-item {
  font-weight:normal;
  display: table;
  width: 100%;
  background-color:transparent;
  & span {
    display:table-cell;
    padding-right:30px;
    width: 78%;
  }

  &:hover {
    background-color: #ededed;
  }
}

