/*
transparent modals // t-modals (modals without .modal-dialog)
*/
.t-modal-wrapper {


  color: @text-color;
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  height:100%;
}
/*.t-modal-wrapper td {  height: 0;  }*/
.t-modal-wrapper a {  color: inherit; cursor: pointer; }

.t-modal-header {
  padding: 0 0 30px;
  border-bottom: 1px solid @blockquote-border-color;
  margin-bottom: 35px;
}
//.t-modal-header--location {
//  padding-bottom: 15px;
//}

.t-modal-title {
  font-weight: normal;
  font-family: @font-family-bolder;
  font-size: @font-size-h1*0.95;
  margin:0;
}
.t-modal-search-header {
  margin:8px 0 -7px;
}
.t-modal-search-header-title {
  text-transform: uppercase;
  font-size: 12px;
  //color: #808080;
}
//.t-modal-search-container {
//  width:100%;
//  padding: 15px 0 30px;
//  position: relative;
//}

//.t-modal-input-wrapper {
//  position: relative;
//  display: block;
//  margin:0;
//  font-weight: inherit;
//}
//.t-modal-input-placeholder,
//.t-modal-input {
//  font-size: 24px;
//  line-height:1em;
//  font-family: 'Open Sans Light', sans-serif;
//  font-weight: 100;
//  cursor: text;
//}
//.t-modal-input-placeholder {
//  top: 25px;
//  position: absolute;
//  left:24px;
//  z-index: +1;
//  /*pointer-events: none;*/
//}
//.t-modal-input {
//  width:100%;
//  background: none;
//  outline: none;
//  border: 1px solid;
//  padding:6px 20px;
//  margin: 0 3px;
//  line-height: 32px;
//
//  transition: background .35s ease;
//}
//.t-modal-input-clear {
//  position: absolute;
//  top: 0;
//  right:0;
//  height: 42px;
//  width: 42px;
//  background: url("../../images/sunshine/index/icons/navigation/sm/ico-close.png") 50% 50% no-repeat;
//  background-size: contain;
//  display: block;
//  text-indent: -9999px;
//  opacity: .5;
//}
//.t-modal-input-clear:hover {
//  opacity: .75;
//}
//.t-modal-input.loading{background-color: rgba(255,255,255,.15)}
//.t-modal-submit {
//  border:0;
//  outline: none;
//  background: none;
//  position: absolute;
//  right:10px;
//  width:20px;
//  height:20px;
//  background-repeat: no-repeat;
//  background-position: center center;
//  top: 50%;
//  margin-top: -10px;
//}


/*.t-modal-submit--location {background-image: url('../../images/sunshine/ico/geo.png')}*/
//.t-modal-submit--search {background-image: url('../../images/sunshine/ico/search.png')}
//.t-modal-submit--current-location {
//  margin:0;
//  top:0;
//  bottom:0;
//  right: -3px; /* search container has 3px margin from sides */
//  height:auto;
//  width: 140px;
//  text-align: center;
//  background: rgb(255,81,69);
//  background: rgba(255,81,69,.7);
//  border: 1px solid #B83D35;
//
//  -webkit-transition: color .35s ease, background .35s ease;
//  -moz-transition: color .35s ease, background .35s ease;
//  transition: color .35s ease, background .35s ease;
//}
//.t-modal-submit--current-location:hover{
//  background-color: #ff746a;
//}

///* TODO : real search.png from mockup & remove background size */
//.t-modal-submit--search{background-size:100% 100%;}

.t-modal-content {
  padding: 50px 0 60px;
  position: relative;
}
.t-modal-wrapper .t-modal-content,
.t-modal-wrapper .t-modal-content-outer,
.t-modal-wrapper .t-modal-content-inner {
  height:100%;
  width: 100%;
}
.t-modal-wrapper .t-modal-content-inner {
  position: absolute;
  top:0;
  left:0;
}

.t-modal-content-inner {
  overflow: hidden;
  min-height: 4.5em;
}

/*
t modal actions
*/
.t-modal-actions {
  position: relative;
  /*padding: 45px 0 10px;*/
  padding: 0 0 10px;
}
.t-modal-actions .tm-back-link {
  z-index: +2;
  position: absolute;
  /*top: 50px;*/
  top: 5px;
  left:80px;
}
.t-modal-action-links {
  position: relative;
  display: block;
  text-align: center;
  font-size: 0.01px;
}
.t-modal-action-links--bottom{
  font-size:inherit;
  padding: 55px 0 30px;
}
.t-modal-action-links--choosen-location {
  font-size: 22px;
  position: absolute;
  /*top: 45px;*/
  top: 0;
  width:50%;
  text-align: center;
  left: 25%;
  z-index: -1;
  pointer-events: none;
}
.t-modal-action-links--choosen-location.in {
  z-index: +1;
  pointer-events: auto;
}

.t-modal-action-link {
  display: inline-block;
  text-transform: uppercase;
  font-size:18px;/* @todo : set font size from mockup */
}
.t-modal-action-link + .t-modal-action-link {
  margin-left: 15px;
}

.t-modal-search-container .t-modal-search-actions {
  position: absolute;
  bottom:100%;
  right:0;
  margin-bottom: 10px;
  font-size: 16px;
  white-space: nowrap;
}

.link-search-action {
  position: relative;
  white-space: nowrap;
  text-transform: uppercase;
  padding-left:45px;
  text-decoration: none !important;
  margin-left: 1.5em;
}
.link-search-action:hover span {
  border-bottom: 1px solid;
}
.link-search-action:before {
  content: "";
  display: inline-block;
  width:39px;
  height:22px;
  position: absolute;
  left:0;
  top:50%;
  margin-top:-10px;
  background-position: 0 0;
  background-repeat: no-repeat;
}
//.link--location-determine {padding-left: 26px;}
//.link--location-choose-on-map:before {  background-image: url('../../images/sunshine/ico/map.png');  }
//.link--location-determine:before {  background-image: url('../../images/sunshine/ico/geo.png');  }

/*
t modal layouts
*/
.t-modal-layout {
  width: 100%;
  //height: 100%; // temporary @deprecated
  font-size: 0.01px;
  position: relative;
  table-layout: fixed;

  height: 100% !important; // @added
}
.t-modal-layout-error {
  text-align: center;
  font-size: 28px;
  display: block;
  padding: 20px;
}
.t-modal-layout-item {
  color: @text-color;
  text-decoration: none !important;

  .t-modal-layout & {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 70px;
    min-height: 150px;
  }
}
.t-modal-layout .t-modal-layout-tab[data-type="preferentialRecipientType"] .t-modal-layout-item {
  margin-bottom: 1em;/* @see MSMEV-4295 */
}
.t-modal-layout-item {
  font-size: 14px;
  position: relative;
  padding-left:109px;
  padding-right: 45px;

  .t-modal-layout--categories & {
    width:33.33%;
  }
  .t-modal-layout--locations & {
    width:33.33%;//20%;
  }
}

//.t-modal-layout--locations {
//  margin: 0 80px;
//  width: auto;
//  overflow: hidden;
//  .t-modal-layout-item--dep-region {
//    width:50%;
//    float: none;
//    margin-bottom: 3em;
//    //.g-location-items { font-size: 12px;  }
//  }
//}

.t-modal-layout--locations .t-modal-layout-item--dep-region .t-modal-layout-item-link,
.t-modal-layout--locations .t-modal-layout-item--dep-region .t-modal-layout-item-link > span {
  white-space: normal;
}

.t-modal-layout-item-count {
  z-index: ~"+1";
  cursor: default;
  pointer-events: none;
  position: absolute;
  right: 1px;
  top:4px;

  background: @count-circle-color;
  color: #fff;
  font-size: @font-size-base;
  line-height: 1em;
  white-space: nowrap;
  border-radius: 12px;
  padding: 5px 8px;
}
.t-modal-layout-item-count-link {
  display: block;
  margin: 8px 0 0;
  cursor: pointer;
  color: @link-color;
  &:hover {
    color: @link-hover-color;
  }
  & > span,
  &-text {

  }

  .color();
}

.t-modal-layout-item-icon {
  //text-indent: -9999px;
  //background-repeat: no-repeat;
  //background-position: center center;
  //background-size: 100% 100%;
  position: absolute;
  width: 88px;
  height:95px;
  top: 0;
  left:0;

  &-img {
    width: 100%;
  }
}
.t-modal-layout-item-title {
  position: relative;
  font-family: @font-family-bolder;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3em;
  margin: 0 0 8px;
  //padding:20px 0;

  &-inner{
    //min-height:47px;
    min-height:95px;
    vertical-align: middle;
    display: table-cell;
  }
}
.t-modal-layout-item-desc {
  display: block;
  margin: 10px auto 0;
  color: @gray-middle;
}
.t-modal-layout-item-count-link {
  //.color();
}
.t-modal-layout-item-title {
  .color();
}
.t-modal-layout-item-title.focused {
  background: rgba(255,255,255,1);
  color: #000;
}
.t-modal-layout-item-title--letter {
  font-family: @font-family-bolder;
  font-weight: 100;
  font-size: 36px;
  text-transform: uppercase;
}
.t-modal-layout-item__disabled, .t-modal-layout-item-link__disabled {
  color: #6A6A6A !important;
  cursor: not-allowed !important;
}
.t-modal-layout-item__disabled .line {
  background-color: #6A6A6A !important;
}
.t-modal-layout-item__disabled a {
  cursor: inherit !important;
}
.t-modal-layout-item-link__disabled:hover > span {
  background: none !important;
}
//.t-modal-layout-item-title .line {
//  background-color: #fff;
//  position: absolute;
//  bottom: -15px;
//  left:0;
//  transition: background .15s ease-out;
//}
.t-modal-layout-item-link {
  max-width: 110%;
  min-width: 51%;
  position: relative;
  display: inline-block;
  text-decoration: none !important;
  padding: .5em 0;
  color: inherit;

  //.loop(@counter) when (@counter > 0) {
  //  .loop((@counter - 1));    // next iteration
  //  &.type-@{counter}{
  //    &:hover {
  //      @var : "color-@{counter}";
  //      color : @@var;
  //    }
  //  }
  //}
  //.loop(@color-count);

  &:after {
    .fa-icon();
    content: @fa-var-angle-right;
    font-size: @font-size-middle;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
  }

  .t-modal-layout-item-link-text {
    display: inline-block;
    max-width: 95%;
    vertical-align: middle;
    width: auto;
  }
}
.t-modal-layout-item-link-text {
  display: block;
  white-space: nowrap;
  text-decoration: none !important;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.t-modal-layout-item-link.tm-link {
  margin-left: -10px;/* move left for padding-left value */
}

.t-modal-layout--categories .t-modal-layout-item-title {
  cursor: pointer;
  //&:hover {color: @brand-primary; }
}

.t-modal-tags {
  margin-top: 16px;
}

.t-modal-layout-item .tm-tags > .focused {
  border-color: transparent;
  background-color: #fff;
  //color: #333;
}

.t-modal-layout-tab {
  //position: absolute; // temporary @deprecated
  top:0;
  left:  200%;
  width: 100%;
  height:100%;
  transition: all .35s ease;

  font-size: 0.01px;

  &.selected--prev,
  &:first-child { left: -200%; }
  &.selected {  left:0;  }
}

.t-modal-layout-block-location-choose {
  display: block;
  margin: 0 0 35px;

  .g-local-link {
    margin-left: 0.7em;
    margin-right: 2em;
  }
}