.b-header {
  //border-bottom: 5px solid @brand-info;
  //padding-top: @panel-height;
}
.b-header-wrapper {
  .clearfix();
  padding-top:15px;
  padding-bottom:15px;

  transition: padding .35s ease;
}

.b-header-logo {
  @logo-height : 50px;

  float:left;

  width:280px;
  height:@logo-height;
  //transition: height .35s ease;

  font-size: 26.85px;
  line-height: 1em;
  white-space: nowrap;
  margin: 0;
  padding:0;

  &-inner {
    display: inline-block;
  }

  &-glob-outer {
    position: relative;
    overflow: hidden;
  }
  &-glob {
    display: block;
    height:@logo-height;
    transition: height .35s ease;
  }

  &-img-outer {
    @s: 28px;
    width: @s;
    height:@s;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
  }
  &-img {
    position: absolute;
    max-width:100%;
    max-height:100%;
    left:0;
    top:0;
  }

  &-caption {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-family : @font-family-medium;
    font-size:26.84px;
    margin-left:8px;

    //&:after{
    //  content:"";
    //  display: block;
    //  pointer-events: none;
    //  position: absolute;
    //  top:0;
    //  left:0;
    //  right:0;
    //  bottom:0;
    //  background: url('@{path-images}lktoepgu.png') 50% 50% no-repeat;
    //  z-index: ~"+1";
    //}
  }

  //.g-gosuslugi-logo {
  //  float:left;
  //  & + .b-header-title {
  //    border-left: 2px solid #e9f2f8;
  //  }
  //}
}

.section-fixate__scrolled {
  @logo-height : 32px;
  .b-header-logo {
    height: @logo-height;
    &-glob {
      display: block;
      height:@logo-height;
    }
  }
  .b-header {
    border-bottom: 0;
  }
  .b-header-wrapper {
    padding-top:9px;
    padding-bottom:10px;
  }
  .b-header-profile {
    top: 0;
    &__anonymous {
      top:13px;
    }
  }
  .b-header-profile-drop-btn {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .b-header-nav {
    margin: 0;
    padding-top: 4px;
  }

  .b-search-form-col--input .b-search-input-wrapper .form-control {
    height: 40px;
    line-height: 40px;
  }

  .b-search-wrapper {
    padding: 4px 11px;
  }

  .b-search-form-submit-btn {
    height: 40px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 15px;
  }

  .b-search-form-close-btn {
    font-size: 38px;
    line-height: 38px;
    margin-top: 2px;
  }

  .b-search--header .b-search-form-col--close {
    padding-left: 30px;
  }

  .section-fixate-inner {
    border-bottom: 1px solid fade(@gray-middle, 15%);
  }
}

.b-header-profile {
  position: absolute;
  right: 25px;
  //font-weight:bold;
  font-size: 18px;
  color: @brand-primary;

  // cannot use translateY always, because of dropdown relative position,
  // so using fixed pixels for normal state
  top: 21px;
  transition: opacity .65s ease, top .35s ease;

  &__anonymous {
    top: 35px;
  }
  &__hidden {
    opacity: 0;
    pointer-events: none;
    // forced visible for scrolled
    .section-fixate__banner_passed & {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
.b-header-profile-drop {
  margin-right: -@header-drop-padding-right + (@header-drop-arrow-width/2);
}

.b-header-profile-drop-btn {
  display: block;
  position: relative;
  width: 100%;
  text-align: right;
  text-decoration: none !important;
  background: none;

  @t : 15px;
  padding: @t @header-drop-padding-right @t @header-drop-padding-left;
  border-top-left-radius: @border-radius-large;
  border-top-right-radius: @border-radius-large;
  border: 1px solid transparent;
  border-bottom: 0;

  .open & {
    border-color: @header-drop-border-color;
  }

  .g-user-name {
    display: inline-block;
    font-family: @font-family-light-sans-serif;
    font-size: @font-size-large;
    font-weight: normal;
  }

  .dropdown-arrow-after(@header-drop-arrow-width);

  transition: padding .35s ease;
}

.b-header-profile-drop-menu {
  box-shadow: none;
  position: relative;
  width:100%;
  background: @dropdown-bg;
  padding: 5px 0 0;//26px;
  margin: 0;

  border:1px solid @header-drop-border-color;
  border-radius: 0 0 @border-radius-large @border-radius-large;
  border-top: 0;

  &:before {
    content:"";
    display: block;
    position: absolute;
    top:0;
    left: @header-drop-padding-left;
    right: @header-drop-padding-left;
    border-top: 1px solid @header-drop-separator-color;
    height:0;
  }

  li{
    display: block;
    list-style: none;
    a {
      width: 100%;
      height:100%;
      display: block;
      color: inherit;
      font-weight: normal;
      text-decoration: none !important;
      padding: 13px @header-drop-padding-right 13px @header-drop-padding-left;
    }
    //&:first-child>a{padding-top:18px;}
    //&:last-child>a{padding-bottom:26px;}
  }

  li.changeauth span{
    display: block;
    color: #000;
    font-size: 12px;
    font-family: als_ekibastuz_light,Arial,sans-serif;
    font-weight: 400;
  }
  li.changeauth>span{
    padding-left: 17px;
  }
  li.changeauth:hover a span {
    color: #333;
  }
  li.changeauth>a {
    padding: 7px 14px 7px 35px;
    font-size: 12px;
    max-width: 275px;
    white-space: normal;
  }
  .changeauthBlock {
    overflow: auto;
  }
}

.b-header-profile-drop.dropdown.open {
  background: #fff;
}

.b-header-title {
  text-transform: lowercase;
  text-decoration: none !important;
  display: block;
  float: left;

  @h : 49px;
  height: @h;
  line-height: @h;
  padding-left:37px;

  &, &:hover, &:active {
    color: #0065b1;
  }
}
.b-header-title-main {
  color: #ee3f58;
}

.b-header-profile-link {
  font-family: @font-family-light-sans-serif;
  font-size: @font-size-large;
  display: inline-block;
  vertical-align: middle;

  background: url("@{path-images}key.png") left center no-repeat transparent;
  padding-left: 24px;
  font-weight: normal;
  //text-transform: lowercase;
  &__signup {
    //margin: 24px 0;
    margin-left: 24px;
  }
}
.b-header-profile-btn {
  display: inline-block;
  vertical-align: middle;
  padding: 6px 26px;
  font-weight: 700;
  //width:120%;
  //margin-left:-10%;
  //margin-top 8px;
  border:1px solid @brand-primary;
  color: @brand-primary;
  //text-transform: lowercase;
  //margin: 20px 0;
  margin-left: 24px;
}
.b-header-nav {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: @font-size-large;
  margin: 0;
  padding-top: 20px;
  li {
    display: inline-block;
    text-align: center;
    margin-left: 45px;
    &.active a {
      color: @gray-base;
    }
    a {
      text-decoration: none !important;
    }
  }
}
.b-header-catwrapper {
  padding: 0!important;
  position: relative;
  display: none;

  .b-header-catwrapper-block {
    position: absolute;
    top:0;
    border-bottom: 1px solid @header-drop-border-color;
    border-top: 1px solid @header-drop-border-color;
    width: 100%;
    background: @element-bg;
    padding-bottom: 40px;
    .btn-block {
      text-align: center;

      .btn + .btn {
        margin-left: 15px;
      }
    }
    .service__list{
      list-style: none;
      padding: 0;
      margin: 10px 0 30px;
      &:after {
        display: block;
        clear: both;
        content:'';
      }
      li {
        width: 24%;
        padding: 0px 10px;
        margin: 5px 0;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        &:hover {
          background: @state-info-bg;
        }
        img {
          width: 44px;
          height: 44px;
        }
        .service__ext_block {
          display: none;
        }
        a {
          text-decoration: none !important;
          color: @gray-base;
          font-size: @font-size-middle;
          display: table;
          min-height: 70px;
          span {
            display: table-cell;
            vertical-align: middle;
            &.service__name {
              padding-left: 14px;
            }
          }
        }
      }
    }
  }
}

.b-header-catwrapper-arrow {
  display: block;
  position: absolute;
  bottom: 100%;
  background: url("@{path-images}hcc.png") center bottom no-repeat transparent;
  z-index: 100;
  width: 34px;
  height: 16px;
  margin-left: -17px;
  margin-bottom: -1px;
}

.b-header-catalog-toggler {
  position: relative;

  a {
    display: inline-block;
    position: relative;
  }
}

.b-header-catalog-toggler-bridge {
  position: absolute;
  top: 60%;
  left: -30px;
  right: -30px;
  height: 50px;
  display: none;
}

.b-panel-drop__category {
  &:hover {
    background: #2344a4;
  }

  .dropdown-menu {
    border: 0 none;
    margin: 0;
    border-radius: 0;
    padding: 0;

    & > li {
      & > a {
        padding: 0 20px;
        line-height: 50px;
        height: 50px;
        white-space: nowrap;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        &:hover {
          background-color: #2a4fb7;
          border-top: 1px solid #1e3685;
          border-bottom: 1px solid #1e3685;
        }
      }
    }
  }

  .open {
    .b-panel-drop-btn {
      background: #2344a4;
    }

    .dropdown-menu {
      background: #2344a4;
    }
  }
}