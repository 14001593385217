@ecp-verified-color-verified : @brand-success;
@ecp-verified-color-declined : @brand-critical;

.ecp-verify-labels {
  display: block;
  position: relative;
  color: @lead-text-color;

  & + & {
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid @block-border-color;
  }
}
.ecp-verify-sign-algo-val {

}
.ecp-verify-sign-algo-key {

}

.ecp-verify-status-icon {
  position: absolute;
  width:80px;
  height:80px;
  left:0;
  top:-25px;

  &:before,
  &:after {
    .fa-icon();
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    content: @fa-var-circle-thin;
    color: #f2f2f2;
    font-size: 90px;
  }
  &:after {
    font-size: 36px;
  }

  &__verified:after {
    content: @fa-var-check;
    color: @ecp-verified-color-verified;
  }
  &__declined:after {
    content: @fa-var-times;
    color: @ecp-verified-color-declined;
  }
}

.ecp-verify-status {
  font-weight: bold;
  display: block;
  position: relative;
  padding-left:20px;

  &__verified {
    color: @ecp-verified-color-verified;
  }
  &__declined {
    color: @ecp-verified-color-declined;
  }

  &:before {
    .fa-icon();
    position: absolute;
    top:50%;
    left:0;
    transform: translateY(-50%);
  }
  &__verified:before {
    content: @fa-var-check;
  }
  &__declined:before {
    content: @fa-var-times;
  }
}

.ecp-verify-content {
  display: block;
  padding-left:105px;

  &__verified {

  }
  &__declined {

  }
}

/*
 styles for signing files
 look into template: WEB-INF/cms/templates/lktoepgu/actions/pubobjcreate/includes/attrs/link_file_sign.jsp
*/

.upload-box-el-actions__compact {
  left: auto;
  right: 110px;
  top: 5px;
}

.upload-box-progress-text {
  right:150px;
}
.upload-box-el-actions__compact .upload-box-action.upload-box-action__cancel  {
   padding:1px 5px;
   font-size:inherit;
}
.file-attr-signature-box {
  padding: 2px 0 4px;
  display: block;
  float:right;
  text-align: right;
}

.file-attr-signature {
  margin: 15px 0 0 10px;
}
.file-attr-signature-marker {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height:25px;

  cursor: pointer;

  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.file-attr-signature-marker:hover {
  opacity: .85;
}
.file-attr-signature-marker__loading {
  background-image : url('@{path-images}loader-small.gif') !important;
  opacity: 1 !important;
}
.file-attr-signature-marker__signed {
  background-image : url('@{path-images}signature/validity_ok.png');
}
.file-attr-signature-marker__error {
  background-image : url('@{path-images}signature/validity_error.png');
}
.file-attr-signature-marker__warn {
  background-image : url('@{path-images}signature/validity_alert.png');
}
.file-attr-signature-btn {
  display: inline-block;
  vertical-align: middle;
}
/*.ecp-dialog {
  left:0!important;
  top:auto!important;
  transform: none!important;
}  */
