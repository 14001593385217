.b-help {
  background-color: #fff;
}
.btn-fontmiddle {
  font-size: 16px;
}
.b-help-bg {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  top:0;
  bottom:0;
  left: 0;
  right:0;
  position: absolute;
  display: block;
}

.b-help-wrapper {
  padding: 0;
  position: relative;
}
.b-help-inner {
  padding: 60px 60px 80px;
  position: relative;
  color: #fff;
  min-height: 450px;
  margin: 0 auto;
  h2 {
    font-size: 52px;
    margin-bottom: 40px;
    margin-top: 0;
    font-family: @font-family-bolder;
    font-weight: 400;
  }
  &--text {
    font-size: 21px;
    font-weight: 400;
    padding-bottom: 60px;
  }
  .btn-group .btn {
    margin-right: 28px;
  }
  .b-help-secure {
    padding: 40px 50px 60px;
    background: url('@{path-images}help-secure_bg.png') repeat center center transparent;
    position: absolute;
    right: 0;
    top:80px;
    width: 235px;
    &--text {
      background: url('@{path-images}help_secure.png') no-repeat top center transparent;
      padding-top: 100px;
      font-size: 20px;
      font-weight: bold;
      text-decoration: none;
      line-height: 26px;
      text-align: center;
      color: #565656;
      display: block;
      :hover {
        color: #415862;
      }
    }
  }
}
.modal-help {
  padding: 60px 0 40px;
}
.modal-page-content-wrapper-contacts--grey {
  margin: 0 auto;
  padding: 0;
}
.b-help-contacts {
  display: table;
  padding: 20px 0 0;
  margin: 0 auto;
  &-block {
    display: table-cell;
    width: 300px;
    text-align: center;
    &--inner {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      padding-top: 90px;
      a {
        text-decoration: none;
        color: #565656;
        display: block;
        text-align:center;
        :hover {
          color: #415862;
        }
      }
    }
    &--support {
      background: url('@{path-images}help-support.png') no-repeat top center transparent;
    }
    &--phone {
      background: url('@{path-images}help-phone.png') no-repeat top center transparent;
    }
    &--app {
      background: url('@{path-images}help-app.png') no-repeat top center transparent;
    }
    &--btn {
      padding-top: 20px;
      span {
        display: block;
        width: 160px;
        margin: 0 auto;
      }
    }
  }
}
.b-help-faq {
  .btn-block {
    padding:0 0 30px;
    text-align: center;
  }
  &--ul {
    display: table;
    padding: 45px 0 30px;
    margin: 0 auto;
    li {
      display: block;
      width: 33%;
      float: left;
      padding-left: 50px;
      a:before {
        content: "\f105";
        color: #0063b0;
        border: 2px solid #0063b0;
        border-radius: 100%;
        width: 24px;
        height: 24px;
        padding-top: 2px;
        text-align: center;
        font: normal normal normal 18px/16px FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: block;
        position: absolute;
        font-weight: 700;
        padding-left: 2px;
      }
      a {
        display: block;
        font-size: 18px;
        line-height: 24px;
        text-decoration: none;
        span {
          padding: 0 0 30px 40px;
          display: block;
        }

      }
    }
  }
}
.b-help-helpful {

  padding-top: 0;

  .btn-block {
    padding: 0 0 30px;
    text-align: center;
  }

  &--ul {
    display: block;
    padding: 0 0 12px;
    margin: 0 auto;
    width: 1198px;
    overflow: hidden;

    li {
      display: block;
      width: 299px;
      float: left;

      a {
        display: block;
        width: 281px;
        height: 281px;
        line-height: 281px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        text-decoration: none;
        background-position: center center;
        background-repeat: no-repeat;
        padding: 10px;
        margin: 0 9px 18px;

        span {
          line-height: 24px;
          display: inline-block;
          padding: 10px;
        }
      }
    }

    &--centered {
      text-align: center;
      width: auto;

      li {
        display: inline-block;
        float: none;

        & + li {
          margin-left: -3px;
        }
      }
    }
  }
}
.modal-body-headcrumbs--help {
  border-bottom: 1px solid #e5e5e7;
  padding-bottom: 20px;
  margin-bottom: 30px;
  .modal-body-headcrumbs-title {
    line-height: 46px;
  }
}
.faq-inner{
  padding: 0;
  list-style: none;
  &--question {
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
    margin: 23px 0;
    text-decoration: none;
    border-bottom: 1px dashed #0173c1;
    display: inline-block;
    position: relative;
  }
  &--question:hover, &--question:focus {
    text-decoration: none;
  }
  &--question:before{
    content: "\f107";
    color: #565656;
    border: 1px solid #ccc;
    border-radius: 100%;
    width: 22px;
    height: 22px;
    padding-top: 3px;
    text-align: center;
    font: normal normal normal 18px/16px FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    position: absolute;
    right: -32px;
    top: 4px;
    font-weight: bold;
  }
  &--answer {
    display: none;
  }
  & > li {
    list-style: none;
    display: block;
  }
  li.active &--answer{
    display: block;
  }
  li.active &--question:before {
    content: "\f106";
    padding-top: 1px;
  }
}
.help-article {
  font-size: 16px;
  color: #535b63;
  h3 {
    margin: 20px 0 40px 0;
    font: 600 18px 'ekibastuz', 'sans-serif';
  }
}