@sq-result-item-favicon-width : 0;//20px;
@sq-result-item-left: 20px + @sq-result-item-favicon-width;

/*
Typehead
*/
.twitter-typeahead {
  /*display: block !important;*/
  width: 100%;
}

.typeahead,
.tt-query,
.tt-hint {
  width: 100%;
  height: 52px;
  /*padding: 8px 12px;*/
  //font-size: 14px;
  //font-family: 'Open Sans Light', sans-serif;
  //font-weight: 100;
  /*line-height: 30px;*/
  outline: none;
}

.typeahead,
.tt-query,
.tt-hint,
.search-query {
  /*outline: none;*/
  z-index: ~"+1";
}

.tt-query {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-dropdown-menu {
  text-align: left;
}

.tt-dropdown-menu {
  max-width: 100%;
  min-width: 300px;
  width: 100%;
  /*margin-top: -5px;*//* .search-query has margin-bottom:5px style */
  /*padding: 8px 0;*/
  background-color: #fff;
  //box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  white-space: normal;
  z-index: 9999 !important;
}

.bxSearch .tt-dropdown-menu {
  margin-top: -5px;
}

.tt-dropdown-menu.scroll { /* has custom scroll */
  background-color: transparent;
}

.tt-dropdown-menu.scroll.mCS_destroyed,
.tt-dropdown-menu.scroll .mCustomScrollBox {
  background-color: #fff;
}

.tt-suggestion {
  display: table;
  width: 100%;
  /*padding: 3px 20px;*/
  /*font-size: 18px;*/
  /*line-height: 24px;*/
}

.sq-result-cell--res {
  background: lighten(@lead-color, 50%);
  border: 1px solid @input-border-focus;
  border-top: 0;
  padding-top: 15px;
  margin-top: 5px;

  .tt-suggestion:first-child & {
    border-top: 0;
    margin-top: 0;
  }

  .sq-result-row:last-child & {
    border-bottom: 1px solid @input-border-focus;
  }

  &.next-group {
    //border-bottom: 0;
  }
}

.sq-result-cell--spacer {
  display: table-cell;
  width:136px;
}

.sq-result-row {
  &:hover {
    .sq-result-cell--res {
      background: lighten(@lead-color, 3.5%);
    }
  }
}

.tt-dropdown-menu b {
  font-weight: bold;
  color: #000;
}

.tt-suggestion.tt-cursor .sq-result-item,
.tt-suggestion.tt-cursor .sq-result-item:hover {
  /*color: #fff;*/
  /*background-color: #fde6e3;*/
  /*background-color: rgba(0,0,0,.05);*/
  text-decoration: none !important;
}

.tt-suggestion.tt-cursor .sq-result-item .sq-result-item-text,
.tt-suggestion.tt-cursor .sq-result-item .sq-result-item-text,
.tt-suggestion.tt-cursor .sq-result-item .sq-result-item-text b,
.tt-suggestion.tt-cursor .sq-result-item .sq-result-item-text b strong {
  color: @brand-primary;
}

.tt-suggestion p {
  margin: 0;
}

.sq-result-more-wrap {
  text-align: left;
  padding: 0 0 0 @sq-result-item-left;
}

.sq-result-more {
  display: none;
  color: @link-color;

  :hover {
    color: @link-hover-color;
    text-decoration: underline;
  }
}

.sq-result-more-wrap.show-true .sq-result-more,
.tt-suggestion:last-child .sq-result-more {
  display: inline-block;
  margin: 0 0 10px;
}

/**
Res
*/
.tt-dataset {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background: #fff;//@lead-color;
  z-index: ~"+2";

  display: none;
}

.tt-open .tt-dataset {
  display: block;
}

/**
* search result shadow
* @see lktoepgu-1155
*/
.tt-dataset-search {
  box-shadow: 0 10px 10px rgba(0,0,0,.15);
  .tt-empty & {
    box-shadow: none;
  }
}
//.tt-dataset-search:after {
//  content: "";
//  display: block;
//  position: absolute;
//  bottom: 1px;
//  left: 0;
//  right: 0;
//  height: 10px;
//  box-shadow: 0 10px 10px rgba(0,0,0,.15);
//  z-index: ~"-1";
//
//  .tt-empty & {
//    display: none;
//  }
//}

/**
Search query
*/
.tt-dataset-search-results {

}

/*.sq-result-item,*/
.sq-empty-message {
  padding: 25px 20px 10px;
  font-size: @font-size-middle;
  //border-top: 1px solid;
  //border-color: #f3f3f3;
  //border-color: rgba(0, 0, 0, .05);
  position: relative;
  display: block;

  //&:first-child {
  //  border-top: 0;
  //}
}

.tt-suggestions {
  /*padding: 12px 0 3px 0;*/
}

.tt-dropdown-menu {
  height: auto !important;
  max-height: 500px;
  /*min-height:300px;*/
  /*overflow: auto;*/
}

.tt-dropdown-menu > .scroll {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.tt-dropdown-menu div[class^="tt-dataset-"] {
  display: table !important;
  width: 100% !important;
}

.sq-result-group {
  display: table-cell !important;
  padding-right: 25px;
  padding-top: 28px;
  width: 16.5% !important;
  min-width: 150px;
  height: 100px;//min-height
  //border-right: 1px solid @input-border-focus;
  vertical-align: top;
  text-align: right;
}

.sq-result-group-title {
  display: inline-block;
  position: relative;
  line-height: 1.3;
  font-family: @font-family-medium;
  font-size: 17px;
  font-weight: normal;
  text-align: right;
  max-width: 110px;
  color: @brand-primary;

  &:hover {
    cursor: pointer;

    .caption {
      color: @link-hover-color;
      text-decoration: underline;
    }
  }

  .length {
    display: block;
    position: absolute;
    left: 100%;
    bottom:100%;
    margin-left: -.5em;
    margin-bottom: -.5em;

    z-index: ~"+1";
    cursor: default;
    pointer-events: none;

    border-radius: 12px;
    padding: 5px 8px;
    color: #fff;
    font-family: @font-family-base;
    font-size: @font-size-base;
    line-height: 1em;
    white-space: nowrap;
    background: @count-circle-color;
  }
}

.tt-suggestions {
  display: table-cell !important;
  border-bottom: 1px solid #dedede;
}

.sq-result-more,
.sq-result-item {
  font-size: @font-size-base;
}
.sq-result-more-cut {
  text-transform: lowercase;
}

.sq-result-item {
  display: block;
  padding: 0 85px 30px @sq-result-item-left;// additional space for `favorites\bookmarks icon` at left
  text-decoration: none !important;
  margin-top: 0;

  b, strong {
    font-weight: bold;
  }
}

.sq-result-item-text {
  display: block;
  font-weight: normal;
  font-family: @font-family-bold;
  font-size: @font-size-middle;
  color: #000;
}
.sq-result-item-descr {
  display: block;
  font-weight: normal;
  font-size: @font-size-small;
  line-height: 1em;
  margin-top: 1em;
  color: @gray-middle;
}

.sq-result-item-snippet {
  line-height: 20px;
  margin-top: 1em;
  color: #828282;

  b, strong {
    color: @brand-critical;
    font-weight: normal;
  }
}

.sq-result-item-icon {
  display: none;

  //position: absolute;
  //top: 50%;
  //left: 27px;
  //transform: translate(-50%, -50%);
  //background-position: center center;
  //background-repeat: no-repeat;
  //width: 12px;
  //height: 12px;
  //
  ///* use some image by default, so we won't have empty result-item-icon, if class is wrong */
  //background-image: url(../../images/lktoepgu/ico/search/category.png);
  //
  ///* @see MSMEV-2363 for type details */
  //&--category {  background-image: url(../../images/lktoepgu/ico/search/category.png)  }
  //&--district {  background-image: url(../../images/lktoepgu/ico/search/district.png)  }
  //&--file {  background-image: url(../../images/lktoepgu/ico/search/file.png)  }
  //&--office {  background-image: url(../../images/lktoepgu/ico/search/office.png)  }
  //&--org {  background-image: url(../../images/lktoepgu/ico/search/org.png)  }
  //&--service {  background-image: url(../../images/lktoepgu/ico/search/service.png)  }
  //&--situation {  background-image: url(../../images/lktoepgu/ico/search/situation.png)  }
}

.tt-dataset-service .sq-empty-message {
  display: block;
  font-size: 14px;
}

.tt-dataset-situation .sq-empty-message,
.tt-dataset-category .sq-empty-message,
.tt-dataset-org .sq-empty-message,
.tt-dataset-news .sq-empty-message {
  display: none;
}

/*
Simple autocomplete based on typeahead
 */
.behavior-autocomplete {
}

.behavior-autocomplete ~ .tt-dropdown-menu .tt-suggestion {
  padding: 3px 20px;
}

.behavior-autocomplete ~ .tt-dropdown-menu .tt-suggestion:hover {
  cursor: pointer;
  background-color: @input-border-error;
}