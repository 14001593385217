.petition-status-icon {
  width: @status-icon-size;
  height:@status-icon-size;
  display: inline-block;
  vertical-align: top;
  //position: relative;
  border-radius: 100%;
  border: 0 solid;

  position: absolute;
  top: @table-cell-padding;
  left: @table-cell-padding/2;

  &:before,
  &:after {
    .fa-icon();
    width: 1em;
    margin-top:-1px;
    text-align: center;
    //font-size: 16px;
    font-size: 100%;
    line-height: 1em;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }

  &:before {
    content: @fa-var-circle-thin;
    //font-size: 28px;
    font-size: 200%;
  }

  &,
  &--unknown {  color: @brand-secondary; &:after {
    content: @fa-var-info;
  }}
  &--success {color: @brand-success; &:after {
    content: @fa-var-check;
  }}
  &--exceeded,
  &--rejected,
  &--denied {color: @brand-critical; &:after {
    content: @fa-var-times;
  }}
  &--ingroup {color: @brand-warning; &:after {
    content: @fa-var-users;
    font-size: 100%;
  }}
  &--new {color: @brand-primary; &:after {
    content: @fa-var-plus;
  }}
  &--pending,
  &--inprocess {color: @brand-warning; &:after {
    content: @fa-var-clock-o;
  }}
  &--newExecutor {color: @brand-warning; &:after {
    content: @fa-var-male;
  }}
  &--waitingOriginals {color: @brand-warning; &:after{
    content: @fa-var-sticky-note-o;
    font-size: 13px;
  }}
  //&--danger {color: @brand-danger; &:after {
  //  content: @fa-var-times;
  //}}
  //&--warning {color: @brand-warning; &:after {
  //  content: @fa-var-exclamation;
  //}}
}
.petition-status-title {
  padding-left: 30px;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.petition-row-info {
  display: none;
  padding: 0 @table-cell-padding/2;
  cursor: initial;
}

.petition-row--open {
  &:hover {
    background-color: #fff;
  }
  .petition-row-info {
    display: block;
  }
  .grid-col-remove-link {
    display: none;
  }
}

.petition-row {
  position: relative;
}
.action-row {
  cursor: pointer;
  &:hover {
    background: @state-info-bg;
  }
}

.petition-row--exceeded {
  background: lighten(@brand-critical, 45%);
}

.b-complaint-exceeded-info {
  padding: @table-cell-padding @table-cell-padding/2 0;
}
.b-complaint-exceeded-desc {
  display: block;
  padding: @padding-base-vertical 0 0;
  color: @gray-light;
}

.b-complaint-exceeded-ico {
  position: relative;
  vertical-align: top;
  top: 0;
  left: 10px;
}

.petition-status-icon {
  .petition-row--loading & {
    background: url('@{path-images}loader-small.gif') center center no-repeat;
    //box-shadow: none;
    //border: none;
    &:before,
    &:after {
      display: none;
    }
  }
}

.petition-row-inner {
  position: relative;
}

.petition-row-info-arrow {
  overflow: hidden;
  width: 19px;
  position: absolute;
  bottom: 0;
  top: @status-icon-size + @table-cell-padding + 5px;
  left:(@status-icon-size/2);

  &:before {
    top:0;
    bottom:13px;
    left:8px;
    border-left:3px solid @brand-primary;
    position: absolute;
    display: block;
    content:"";
  }

  svg {
    fill: @brand-primary;
    position: absolute;
    bottom:0;
    left:0;
  }

  display: none;
  .petition-row--open.petition-row--ready & {
    display: block;
  }
}

.petition-row-info-container {
  .clearfix();
  padding: 0 0 @table-cell-padding;
  position: relative;
}

.petition-subfold-holder-outer {
  padding-top: 3px;
  padding-left: 20px;

  &:after {
    // cleafix
    content:"";
    display: table;
    clear:both;
  }

  &.subfold-holder-outer__sideline:before {
    left: @table-cell-padding/2;
    .gradient(@gray-lighter, @brand-primary);
  }
}

.petition-info-group-marker {
  width: @status-icon-size;
  height: @status-icon-size;
  box-shadow: inset 0 0 0 2px @brand-primary;
  color: @brand-primary;
}

.petition-info-status {
}
.petition-info-status-title {
  font-weight: bold;
}
.petition-info-status-date {
  color: @lead-text-color;
}
.petition-info-status-org {

}

.petition-info-status-link {
  color: @brand-primary;

  & > &-text {
    display: inline;
    //border-bottom: 1px dashed;
  }

  //&:after {
  //  .fa-icon();
  //  content: @fa-var-angle-up;
  //  position: relative;
  //}
}

.petition-info-status-col  {
  padding-bottom: @table-cell-padding*2;
  .subfold-holder-outer__last & {
    padding-bottom: 0;
  }
}

.petition-row-info-comment {
  font-style: @font-size-base;
  line-height: @line-height-computed;
  color: @lead-text-color;

  display: block;
  padding:15px 0;
}

.petition-info-status-additional {
  clear: both;
}

.petition-row-info-actions {
  .clearfix();

  font-size: 0.01px;

  .btn {
    float: left;
  }

  .btn + .btn {
    margin-left: 20px;
  }
}

.petition-row-info-voted {
  color: @brand-success;
  cursor: default;

  &:hover {
    color: @brand-success;
  }
}

.petition-row-info-voted-ico {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: -2px 5px 0 0;
  vertical-align: middle;
}

#historyList_processing {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 100;
  padding-top: 50%;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,0.6);
  }
}

.historyList_processing_loader {
  background: white;
  display: inline-block;
  padding: 20px;
  border: 1px solid;
  z-index: 100;
  position: relative;

   & img {
      width:24px;
      height:24px;
      margin:0 10px 0 0;
      display: inline-block;
   }
}

@keyframes my_fadeIn {
  from {opacity:0}
  to {opacity:1}
}

@keyframes my_fadeOut {
  from {opacity:1}
  to {opacity:0; }
}

.historyList {
  font-size:15px;
  .grid-col--status {padding-left:32px}
  .grid-row {animation:my_fadeIn 1s; cursor:pointer;
    &.my-loader {display:table-row; background-size: 30px auto; background-position: 50%; min-height: 0; padding-top:0;
      &>td {opacity:0}
    }
    &._to_del {animation:my_fadeOut 1s}
  }
  ._status {
     .ico {margin:0 0 0 -32px; display:block; position:absolute; width:32px; font-size:20px}
      .fa-clock-o {color:#EAA800}
      .fa-check {color:#009C51}
      .fa-times {color:#EE3F58}
   }
  ._extId {color:@text-color;
    &:hover {color:#1a88d7}
  }
  ._title {font-family:@font-family-bold; font-weight:700; line-clamp:2; -webkit-line-clamp:2; -webkit-box-orient: vertical;
    display: -webkit-box; overflow:hidden; max-height:3em}
  ._org {line-clamp:2; -webkit-line-clamp:2; -webkit-box-orient: vertical;
    display: -webkit-box; overflow:hidden; max-height:3em}
  &.togglable {
    .btn-switch {
      &:before {content:'Скрыть историю'; vertical-align:middle}
      &:after {content:"\f106"; font:normal 14px/20px FontAwesome; vertical-align:middle; margin-left:10px}
    }
    &.closed {
      .table>tbody>tr+tr {display:none}
      .btn-switch {&:before {content:'Показать историю'} &:after {content:"\f107"}}
    }
  }

  ._info {margin:1em 0 0 0}

  .btn {vertical-align:middle;
    &+.fav {margin-left:10px; vertical-align:middle}
  }

  .fav {background: #F6F6F6;
    width:32px; height:32px;
    display:inline-block;

    border: 1px solid #E4E4E4;
    border-radius: 4px;
    text-align:center;
    cursor:pointer;
    &.active:before {color:#0173C1}
    &:before {content:'\f005'; font:normal normal normal 20px/32px FontAwesome; color:#A6AFC2}
  }
  ._comment {font-size:16px; font-weight:700; padding:16px 24px; color:#848993; margin:24px 0;
     h5 {font-size:18px; margin:0 0 16px 0; color:#4C4C4C}
  }
}

.table-filter {
  padding:15px 0;
  .col+.col {padding-left:0}
  div.form-control, label.form-control {
    display:block; padding-right:45px; position:relative;
    .ico {width:20px; height:20px; text-align:center; line-height:20px; position:absolute; right:20px; cursor:pointer}
    input[type=text] {border:0; width:100%; margin:0; outline:none !important; line-height:24px; padding:0;
      &[readonly] {background:none !important; margin-right:0 !important}
      &:active,&:focus {box-shadow:none}
    }
  }
}
