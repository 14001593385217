.section__description {
  font-size: 18px;
  line-height: 26px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  padding: 10px 0;
}
.document_signer_check {
  .loader {
    visibility: hidden;
    margin-right: 15px;
    span{
      display:inline-block;
      padding-left:40px;
      background: url("@{path-images}loader-small.gif") no-repeat left center transparent;
      line-height:46px;
      height:46px;
      color: #0063b0;
      font-weight: bold;
      font-size: 18px;
    }
    &.show {
      visibility: visible;
    }
  }
  .form {
    .clearfix();
    .form__item_tr:first-child {
      margin: 24px 0 15px;
    }
    .form__item_tr {
      margin: 30px 0;
      padding: 30px;
      border: 1px solid #e4eef5;
      width: 50%;
      &.uploaded {
        background: #f6f9fb;
        .jq-file {
          width: 65%!important;
        }
      }
      &.static {
        float: left;
        width: 25%;
        border: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        .jq-file {
          width: 100%!important;
        }
        .fileBlock {
          padding-left: 0;
          padding-right: 0;
          .jq-file__name {
            span {
              font-size: 14px;
              color: #a0aeae;
              display: block;
            }
          }
        }
        .form__lbl {
          display: none;
        }
        .upload-box-el-actions {
          display: none;
        }
        .upload-box-progress-wrapper {
          display: none;
        }
        .jq-file {
          padding-right: 0;
          width: auto;
        }
      }
      .fileBlock {
        .clearfix();
        padding: 20px 30px 20px 48px;
      }
      .fileAttr_icon {
        display: table-cell;
        width: 55px;
        span {
          width: 55px;
          display: block;
          margin-right: 30px;
          margin-bottom: 5px;
        }
        .file_icon {
          background: url('@{path-images}signer_check/file_icon.png') no-repeat center center transparent;
          height: 77px;
        }
        .sign_icon {
          background: url('@{path-images}signer_check/sign_icon.png') no-repeat center center transparent;
          height: 42px;
        }
      }
      .upload-box {
        display: table-cell;
        width: 100%;
        .upload-box-action.upload-box-action__cancel {
          background: url('@{path-images}signer_check/remove.png') no-repeat center center transparent;
          width: 11px;
          height: 11px;
          padding: 0;
          line-height: normal;
          border: 0;
          display: block;
          border-radius: unset;
          color: transparent;
        }
        .upload-box-el-container {
          position: static;
          z-index: 900;
        }
        .upload-box-progress-text {
          top: 4px;
          right: 125px;
          display: none;
        }
        .upload-box-progress-wrapper {
          top: 40px;
          z-index: 800;
        }
        .jq-file {
          cursor: pointer;
          padding-right: 0;
          width: 94px;
          input {
            width: 92px;
            left: 0;
            transform: scale(20);
          }
          &__name {
            display: none;
          }
          &__browse {
            color: #333;
            background-color: #fff;
            border-color: #ccc;
            font-size: 14px;
            display: inline-block;
            left: 0;
            right: auto;
            padding: 10px 25px;
          }
          &:hover {
            .jq-file__browse {
              color: #333;
              background-color: #e6e6e6;
              border-color: #adadad;
              cursor: pointer;
            }
          }
        }
        .jq-file.disabled {
          position: absolute!important;
          margin-top: 5px;
          top:0;

          .jq-file__name {
            display: block;
            border: 0;
            font-size: 15px;
            color: #000;
            padding: 0;
            position: relative;
            height: auto;
            background: transparent;
          }
          .jq-file__browse {
            display: none;
          }
        }
      }
      .form__lbl {
        font-size: 21px;
        font-weight: bold;
        .counter {
          border: 2px solid #0667b2;
          border-radius: 100%;
          font-size: 16px;
          font-weight: bold;
          display: inline-block;
          color: #0667b2;
          width: 26px;
          line-height: 22px;
          text-align: center;
          margin-right: 15px;
        }
        .note {
          margin-left: 48px;
        }
      }
    }
  }
  .sign_result_block {
    padding: 30px 30px 30px 138px;
    margin: 30px 0;
    position: relative;
    width: 50%;
    h2 {
      margin-top: 0;
    }
    b {
      font-weight: bold;
      font-family: als_ekibastuz_bold,Arial,sans-serif;
    }
    span {
      color: #999999;
    }
    .certInfo {
      table {
        tr {
          td {
            vertical-align: top;
            padding: 4px 0 4px 20px;
            color: #999999;
            font-weight: bold;
            font-family: als_ekibastuz_bold,Arial,sans-serif;
          }
          td:first-child {
            color: #000000;
          }
        }
      }
    }
    &:before {
      display: block;
      position: absolute;
      width: 80px;
      height: 80px;
      left: 30px;
      top: 30px;

      font:normal normal normal 50px/60px FontAwesome;
      text-align: center;
    }
    &.green {
      background: #f1f7ea;
      &:before {
        content: "\f00c";
        border: 0;
        color: #7fb441;
        border-radius: 100%;
      }
    }
    &.red {
      background: #fbeaec;
      &:before {
        content: "\f00d";
        border: 0;
        color: #dc3f55;
        border-radius: 100%;
      }
    }
    &.blue_marker {
      background: #c3d9ff;
    }
    &.yellow {
      background: #F9F3D9;
    }
    .trafficMessageFull {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 2px solid #000;
      color: #999999;
      font-weight: bold;
      font-family: als_ekibastuz_bold, Arial, sans-serif;
      &.green {
        border-top: 2px solid #7fb441;
      }
      &.red {
        border-top: 2px solid #dc3f55;
      }
      &.blue_marker {
        border-top: 2px solid #c3d9ff;
      }
      &.yellow {
        border-top: 2px solid #F9F3D9;
      }
      .green {
        color: #7fb441;
      }
      .red {
        color: #dc3f55;
      }
      .blue_marker {
        color: #c3d9ff;
      }
      .yellow {
        color: #F9F3D9;
      }
    }
  }
}