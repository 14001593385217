.attr-unilist {
  .g-download;
}
.attr-unilist-file {
  display: block;
  //display: inline-block;
  //vertical-align: top;
  //margin: 0 5px 5px;
  margin: 0 0 1em;
  //border: 1px solid @input-border;
  color: inherit;
  //text-align: center;
  text-align: left;
  //padding: 5px 10px;
  //width: ~"calc(33.33% - 10px)";
  width:100%;
  position: relative;
}
.attr-unilist-file-inner {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.attr-unilist-file-icon {
  .g-download-icon;
}
.attr-unilist-file-link {
  display: inline-block;
  vertical-align: top;
  min-height: @download-icon-min-height;
  padding-left: 45px;
  color: inherit;
}

.attr-unilist-file__removed {
  opacity: .35;
  &:hover {
    opacity: .75;
  }

  .attr-unilist-file-link {
    text-decoration: none !important;
    color: @gray-light !important;
  }
}
.attr-unilist-file-name {
  .g-download-title;
  width:100%;
}
.attr-unilist-file-size {
  .g-download-comment;
}
.attr-unilist-file-link-layout {
  .g-download-link-layout;
}
.attr-unilist-file-link-col {
  .g-download-link-col;

  &__actions {
    text-align: right;

    .btn {
      opacity:1;
    }
  }
}
.attr-unilist-file-actions {

  .btn {
    & > span {
      display: inline-block;
      margin-left: .5em;
    }

    &:before {
      .fa-icon();
      position: relative;
      text-decoration: none !important;
    }

    //&__download,
    &__restore {
      &:before {
        content:@fa-var-undo;
        color: @link-color;
        //top: 2px;
      }
      &:hover:after {
        color: lighten(@link-hover-color, 20%);
      }
    }
    &__download {
      &:before {
        content: @fa-var-download;
        //top: 3px;
      }
    }
    &__remove {
      background: @btn-default-bg;
      border: 1px solid;
      color: @brand-critical;
      &:before {
        top: 3px;
        font-size: 24px;
        font-weight: bold;
        line-height: 18px;
        content: "\00d7";
      }
      &:hover {
        color: @btn-default-bg;
        background: @brand-critical;
      }
    }
  }
}

//.attr-unilist-file:hover {
//  .attr-unilist-file-actions {
//    .btn__download {
//      display: inline-block;
//    }
//  }
//}

.attr-unilist-file-preview {
  .g-download-preview;
}

.edit-attr .attr-unilist-file-actions .btn__download {display: none}
.view-attr .attr-unilist-file-actions .btn__remove {display: none}