.collapser {
  display: none;
}

@space-before-border : 50px;
@space-after-border : 40px;

.group-holder ,
.group-holder-inner,
.subgroup-holder-outer {
  position: relative;
  padding: 0 0 0 @group-holder-num-width;
  .subgroup-holder-outer {
    padding-left: 0;
  }
  &.group-holder--narrow {
    padding: 0 0 0 @group-holder-narrow-num-width;
  }
}

.group-holder__nosub {
  padding-left:0;
  .group-num {
    display: none;
  }
}

.group-holder ,
.attr-list-layout,
.subgroup-holder-outer {
  margin: 0 0 @space-before-border 0;
}

.group-holder:not(.hidden) + .group-holder,
.attr-list-layout + .subgroups-list > .subgroup-holder-outer,
.subgroup-holder-outer + .subgroup-holder-outer {
  padding-top:@space-after-border;
  &:before {
    content:"";
    display: block;
    position: absolute;
    top: 0;
    left:0;
    right:0;
    border-top: 1px solid @modal-block-border-color;
  }
}

.subgroup-holder-outer {
  padding-top: @space-after-border;

  &.subgroup-holder-outer--no-title {
    padding-top: 0;

    .attr-row:first-child {
      .attr-field {
        margin-top: 0;
      }
    }
  }
}

.subgroup-num,
.group-num {
  position: absolute;
  left:0;
  width: @group-holder-num-width;
  top:0;
  text-align: center;
  font-size: @font-size-h1;
  font-family: @font-family-bolder;
  line-height: 44px;
}

.group-num-wrapper {
  display: block;
  padding-bottom: @space-after-border / 2;
}

.group-holder-inner,
.group-holder {
  .subgroup-holder-outer .subgroup-num {
    left: -@group-holder-num-width;
    top: @space-after-border + 1;
  }
}
.group-holder:not(.hidden) + .group-holder {
  .group-num {
    top: @space-after-border;
  }
}

.subgroup-title,
.group-title {
  position: relative;
  font-family: @font-family-bold;
  font-weight: bold;
  font-size: @font-size-large;
  line-height: 1.5em;
  padding-top: 15px;
  .mobile-txt-fix;

  &.group-title--def {
    font-size: @font-size-h2;
    line-height: 1.2em;
    font-family: @font-family-bolder;
    font-weight: normal;
  }
}

.subgroup-actions {
  //float:right;
  float: right;
  position: absolute;
  right: 0;
  top: .5em;

  font-size: @font-size-base;
  font-weight: normal;
}
.subgroup-action-link {
  display: inline-block;
  & + & {
    margin-left: 2em;
  }
}

.fold-holder {
  padding: 0;
  position: relative;
  counter-reset: groups-counter;
}

.subfold-holder {
  .attr-title {
    font-size: @font-size-middle - 1;
    font-family: @font-family-bold;
    line-height: @line-height-large;
  }
}

.subfold-holder-outer {
  padding: (@group-bullet-size*0.2) 0 38px (@group-bullet-size+11px);
  position: relative;

  .subfold-holder-outer {
    padding-left : 0;
    &:before {
      display: none;
    }
    .subfold-title {
      font-size: @font-size-middle - 1;
      font-family: @font-family-bold;
      line-height: @line-height-large;
      font-weight: bold;
    }
  }

  &__sideline{
    &:before {
      content:"";
      display: block;
      position: absolute;

      .gradient(@gray-lighter, @brand-primary);

      @s : 5px;
      @w : 3px;
      width: 3px;
      left: @group-bullet-size/2 - @w/2;
      top: @group-bullet-size + @s;
      bottom: @s;
    }

    &__color {
      &__default:before ,
      &__primary:before {.gradient(@gray-lighter, @brand-primary);}
      &__secondary:before {.gradient(@gray-lighter, @brand-secondary);}
      &__danger:before {.gradient(@gray-lighter, @brand-danger);}
      &__info:before {.gradient(@gray-lighter, @brand-info);}
      &__warning:before {.gradient(@gray-lighter, @brand-warning);}
      &__critical:before {.gradient(@gray-lighter, @brand-critical);}
      &__success:before {.gradient(@gray-lighter, @brand-success);}
      &__disabled:before {.gradient(@gray-lighter, @brand-disabled);}
    }
  }

  &:last-child:before {
    display: none;
  }
}

.subfold-title-text-additional {
  border-bottom: 1px dashed;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.group-marker{
  width: @group-bullet-size;
  height:@group-bullet-size;
  background: #fff;
  border-radius: 100%;
  color: @brand-primary;
  border: 2px solid;
  position: absolute;
  top:0;
  left:-1px;

  &__color {
    .color();
  }

  &:after {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);

    content: counter(groups-counter);
    counter-increment: groups-counter;

    font-size: @font-size-small;
    font-family: @font-family-medium;
    font-weight: normal;
    font-style: normal;

    height: 1em;
    line-height: 1.3em;
    width: 100%;
    text-align: center;
  }

  &__static {
    &:after {
      display: none;
    }
  }

  //&__main {
  //  background: @brand-secondary;
  //  box-shadow : none;
  //}

  //.subfold-holder-outer:last-child & {
  //  box-shadow: inset 0 0 0 2px @brand-secondary;
  //}
}

.subfold-title {
  font-size: @font-size-middle;
  font-family: @font-family-bold;
  line-height: @line-height-large;
  font-weight: bold;
  padding-bottom: 30px;
  display: block;
  text-align: left;
}

.checkFill {
  display: none;
}

.g-check-fill {
  vertical-align:top;
  display: inline-block;
  text-transform: lowercase;
  padding: 3px 6px;
  font-size: @font-size-base;
  color: #fff;
  background-color: @gray-light;

  &--common {
    font-size: inherit;
    background-color: @gray-light;
  }

  &.correct {
    background-color: lighten(@brand-success, 50%) !important;
  }
}

.subgroup-holder-outer {
  .subgroup-title .checkFill {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: 45px;
  }
  &:hover .subgroup-title .checkFill {
    background-color: @brand-danger;
  }
}
.subgroup-holder-outer--loading {
  &:after {
    content:"";
    display: block;
    background: #fff url('@{path-images}loader-small.gif') center center no-repeat;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    cursor: progress;
    z-index: ~"+5";
    opacity: .85;
  }
}
.form__section {
  &.clonable {._del, ._add {display:none}
    &:last-child {._add,._del {display:inline-block}}
  }
}

.form__section_inner {
  .clearfix();
  display: block;
  padding: 40px 0;
  margin: 0;
  border-top: 1px solid #cdd1d4;
  border-bottom: 1px solid #cdd1d4;

  & + .form__section_inner {
    border-top: 0 none;
  }
  & + fieldset.form__section_inner {
    border-top: 1px solid #e9e9e9;
  }

  &:first-child {
    margin-top: 0;
  }
}
.form__section_inner__wb {
  border-bottom: 0;
  border-top: 0;
  margin: 0;
}

.form__section__title {
  font-size: 18px;
  line-height: 22px;
  font-family: "als_ekibastuz_bold", sans-serif;
  color: #000;
  padding: 0 40px 0 0;
  margin: 0;
  position: relative;
  .clearfix();

  & + .wrapper {
    padding: 30px 0 0;
    margin-bottom: -30px;
  }
}

.trigger {
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 50%;
  right: 0;
  margin: -5px 0 0;
  font-size: 17px;
  line-height: 25px;
  border-radius: 100%;
  border: 1px solid #cdd1d4;
  background: #ffffff;
  color: #4c4c4c;
  cursor: pointer;

  .icon_up, .icon_down {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: transparent;
    text-align: center;
    line-height: 28px;
    width: auto;
    height: auto;

    &:after {
      .fa-icon();
      font-size: 20px;
      content: @fa-var-angle-up;
    }
  }

  .icon_down {
    &:after {
      content: @fa-var-angle-down;
    }
  }
}

.b-skip-row {
  & + .form__item, & + .form__section_inner {
    margin-top: 0;
  }
}

.b-upper-group {
  position: relative;
  padding-left: 63px;
  margin-left: -63px;

  & + .b-upper-group {
    //margin-top: -40px !important;
  }

  &[hidden="true"] + .b-upper-group {
    //margin-top: 40px !important;
  }

  & + .form__item {
    margin-top: 30px;
  }

  .form__remark_status {
    position: absolute;
    right: 40px;
    top: 50%;
    margin-top: -12px;
  }

  .form__section__title {
    padding-right: 174px;
  }

  .form__section__title__common {
    padding-right: 40px;
  }
}
.form__section__data {
  .clearfix();
}
.b-upper-group--modal {
  margin-left: 0;
  padding-left: 0;

  .form__section__title+.wrapper {
    margin-bottom: 0;
    .clearfix();
  }
}

.b-inner-group {
  padding: 25px 30px;
  border: 1px solid #e9e9e9;
  box-shadow: #e9e9e9;
  margin: 40px 0;
}

.b-inner-group--modal {
  .form__section__title {
    font-size: 15px;
  }
}
.group-attention {
  margin: 30px 0;
  padding: 15px 50px;
  border:1px solid #c8c7cc;
  border-top:5px solid #f2c617;
  h4 {
    margin: 0 0 15px
  }
}
.form__remark_status {
  float: none;
  display: inline-block;
  margin: 0 0 0 10px;
  padding-left: 30px;
  font-size: 0.8235em;
  font-weight: normal;
  font-family: Arial, sans-serif;
  position: relative;

  .icon {
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    border: 2px solid;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    top: 50%;
    margin-top: -13px;
    background: transparent;
    text-indent: initial;
    color: inherit;
  }
}

.form__remark_status_invalid {
  color: @brand-critical;

  .icon {
    border-color: @brand-critical;

    &:after {
      .fa-icon();
      content: @fa-var-close;
      position: relative;
      top: -1px;
    }
  }
}

.form__remark_status_complete {
  color: @brand-success;

  .icon {
    border-color: @brand-success;

    &:after {
      .fa-icon();
      content: @fa-var-check;
    }
  }
}

.b-group-num {
  display: block;
  position: absolute;
  font-size: 36px;
  line-height: 40px;
  font-family: @font-family-bolder;
  top: 0;
  right: 100%;
  padding: 27px 20px 0 20px;
  margin-right: -63px;
}
.steps__form {
  .form__section_inner {
    padding-right:63px;
    .form__section_inner {
      border:#E9E9E9 1px solid; margin:0 0 1em 0;
      .b-group-num {display:none}
    }
  }
}

._wizard {
  .form__section {display:none; padding:48px; background:#f1f3f6; border:0 !important;
    .form__section {display:block}
    &.active {display:block}

    .form__inp[data-mask-before][style] {width:100% !important }

    input[type=hidden][value='']~.form__area, .form__area~.addNew {display:none}
    input[type=hidden][value='']~.form__area~.addNew {display:block}

    .form__section__title {pointer-events: none; margin-bottom:1em}
  }
  .attr-list-layout {display:block; margin:0 -9px; width:auto !important;
    &>tbody{display:flex; flex-wrap:wrap; width:100%}
    .form__item_tr {display:block; width:50%; position:relative;
      border:transparent 9px solid;
      label.form__lbl {font-size: 18px; font-weight:700;  /*margin: 0; */  margin:0 0 17px 0;  padding:17px 0; position:relative; float:none; &:after {border-bottom:#CCCCCC 1px solid; height:0; left:-17px; right:-17px; bottom:0; position:absolute; content:''}}
      /*&:before {        border: #CCCCCC 1px solid;        position: absolute;  pointer-events: none;display: block;content: ''; top: 0; left: 0;right: 0;bottom: 0;}*/
      .form__area {resize: none; border:0; background:transparent !important}
      &>td {/*padding-top:0 !important; display:block;*/ border-radius: 4px;background-color: rgb(255, 255, 255);box-shadow: 0 4px 9px 1px rgba(0, 0, 0, 0.08);  padding: 6px 17px 17px 17px; display: flex; flex-direction: column; height:100%}
      &.type8, &.type9 {width:100%; border:0; padding:17px 9px; /*&:before {content:none};*/&>td {box-shadow:none; background:none; padding:0} label.form__lbl { font-size: 15px; margin-bottom: 1em; }}
      &.type9{
        &:only-child {
        padding-top:0;
        .form__lbl {display:none}}
      }
    }
  }
}

._tiles_grid {
  .grid_table {display:none}
  ._tilesList {display:flex; flex-wrap:wrap; margin:0 -17px}
  ._tile {position:relative;  border-radius: 4px;background-color: rgb(255, 255, 255);box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.08); padding:0; margin:17px; width:~"calc(50% - 34px)";
    ._list {display:flex; flex-wrap:wrap;
      ._li { font-size:18px; width:50%; padding:27px 28px;
       /* &:nth-child(odd) {font-size:14px; font-weight:normal}*/
        &:first-child {font-size:18px; font-weight:bold; width:100%; padding-right:70px; border-bottom:#e6ebec 1px solid}
        h5 {margin:0 0 4px 0; font-size:14px; color:#a5aeb3; overflow:hidden; white-space:nowrap; text-overflow:ellipsis}
      }
    }

    ._tools {position:absolute; top:17px; right:17px;
      a {color:#086ab3; text-decoration:none; width:22px; line-height:22px; border:#cdd1d4 1px solid; border-radius:50%; text-align:center}
    }
  }

  .addNew {width:~"calc(50% - 34px)";}
}

.addNew {border-width: 1.22px;border-color: rgb(0, 99, 172);border-style: dashed;border-radius: 4px; background-color: rgba(255, 255, 255, 0); padding:26px 28px;   font-size:14px; margin:17px; position:relative; cursor:pointer;
     h2 {margin-top:0; font-size:18px;}
     &:before {font:normal normal normal 14px FontAwesome; content:'\f067';  width:22px; line-height:22px; color:#FFFFFF; background:#0b40b3; border-radius:50%; text-align:center; position:absolute; top:17px; right:17px; display:block; padding:1px 1px 0 0}
   }