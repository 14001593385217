.security-roles-list {
  margin: 20px 0;
}

.user-files-table {
  width:100%;
  .g-download  {
    border-bottom: 1px solid @gray-lighter;
    padding-bottom: 20px;
  }
}

.b-profile-counter {
  display: inline-block;
  margin: 0 0 0 4px;
  color: #ffffff;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
  background: @brand-danger;
  min-width: 20px;
  padding: 0 4px;
  border-radius: 20px;
  font-weight: normal;
}

.b-common-list-item {
  margin-bottom: 20px;
}

.b-plain-text {
  font-size: 15px;
  line-height: 20px;
}

select {
  width: 100%;
}

.page__list,.paging_simple_numbers, .b-pagination-holder {
  .clearfix();
  display: block;
  padding: 0;
  margin: 20px 0 0;
}

.page__item {
  display: block;
  float: left;
  margin-right: 10px;
  font-family: @font-family-ekb-bold;
  font-size: 14px;

  a, .page__link  {
    display: block;
    float: left;
    min-width: 30px;
    height: 30px;
    padding: 0 3px;
    border: 1px solid #cdd1d4;
    border-radius: 2px;
    text-align: center;
    line-height: 30px;
    font-size: 17px;
  }

  .page__link_current {
    color: #fff;
    background: #6ba9d2;
    border-color: #6ba9d2;
  }

  &.page__item__ellipsis {
    .page__link {
      color: #0173c1;
    }
  }
}
.paginate_button {
  display: block;
  float: left;
  margin-right: 10px;
  font-family: @font-family-ekb-bold;
    min-width: 30px;
    height: 30px;
    padding: 0 3px;
    border: 1px solid #cdd1d4;
    border-radius: 2px;
    text-align: center;
    line-height: 30px;
    font-size: 17px;

  &.current {
    color: #fff;
    background: #6ba9d2;
    border-color: #6ba9d2;
  }
}


.b-pagination-holder {
  a {
    display: block;
    float: left;
    margin-right: 10px;
    font-family: @font-family-ekb-bold;
    min-width: 30px;
    height: 30px;
    padding: 0 3px;
    border: 1px solid #cdd1d4;
    border-radius: 2px;
    text-align: center;
    line-height: 30px;
    font-size: 17px;

    &.jp-current {
      color: #fff;
      background: #6ba9d2;
      border-color: #6ba9d2;
    }

    &.jp-disabled {
      opacity: .5;
    }
  }
}

.jp-invisible { visibility: hidden !important; } .jp-hidden { display: none !important; }


.ellipsis {
  display: block;
  float: left;
  margin-right: 10px;
  font-family: @font-family-ekb-bold;
  min-width: 30px;
  height: 30px;
  padding: 0 3px;
  border: 1px solid #cdd1d4;
  border-radius: 2px;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
  color: #0173c1;
}

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter:Alpha(Opacity=0);
}

.ui-front {
  z-index: 100;
}


.ui-datepicker {
  display: none;
  padding: 0;
  background: #ffffff;
  border: 1px solid #cdd1d4;
  border-radius: 0;
  font-family: Arial, sans-serif;
  z-index: 5000 !important;

  table {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .ui-datepicker-prev, .ui-datepicker-next {
    display: block;
    position: absolute;
    left: 15px;
    top: 50%;
    font-size: 18px;
    line-height: 20px;
    margin: -10px 0 0;
    color: #565656;
    background: transparent;
    border: 0 none;
    width: auto;
    height: auto;

    &:hover {
      border: 0 none;
      background: transparent;
    }

    .ui-icon {
      display: none;
    }

    &:after {
      .fa-icon();
      content: @fa-var-chevron-left;
    }
  }

  .ui-datepicker-next {
    left: auto;
    right: 15px;

    &:after {
      .fa-icon();
      content: @fa-var-chevron-right;
    }
  }
}

.ui-datepicker-inline {
  width: 310px;
}

.ui-datepicker-header {
  background: transparent;
  padding: 15px;
  position: relative;
  border: 0 none;
  display: block;
  font-size: 17px;
  font-weight: normal;
  color: #565656;
}

.ui-datepicker-calendar {
  th {
    font-size: 15px;
    font-weight: normal;
    color: #565656;
    text-align: center;
  }

  tr td:first-child {
    border-left: 0 none;
  }

  td {
    font-size: 16px;
    text-align: center;
    padding: 0;
    border-top: 1px solid #cdd1d4;
    border-left: 1px solid #cdd1d4;

    a, .ui-state-default, .ui-datepicker & a {
      border: 0;
      background: #ffffff;
      display: block;
      text-align: center;
      color: @brand-primary;
      padding: 12px;

      &.ui-state-active {
        border: 3px solid #bfe5ff;
        padding: 9px;
        font-weight: bold;
      }
    }
  }

  .ui-datepicker-week-end {
    color: @brand-critical;
  }

  td.ui-datepicker-unselectable, td.ui-state-disabled {
    span {
      background: #f7f9f9;
      color: #aaaeb3;
    }
  }
}

.form__section__data {
  & > :first-child {
    margin-top: 0 !important;
  }
}

.avDates-lables {
  white-space: nowrap;
  display: block;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 8px;
  font-family: Arial, sans-serif;
  padding: 5px 0;
  font-weight: normal;
  float: left;
  margin-right: 1%;
  width: 171px;
}
.avDates-lables.active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.avDates-lables input {display: none;}
.avDates-datesCol{
  //width: 19%;
  //margin: 0 1% 0 0;
  //float: left;
  display: block;
  overflow: hidden;
  max-width: 850px;
}
.avTimesTd {width: 270px;}
.avTimes-lables {
  white-space: nowrap;
  display: block;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  //margin-bottom: 2px; //our
  font-family: Arial, sans-serif;
  //padding: 5px 0; //our
  padding:5px 8px;
  font-weight: normal;
  margin: 5px 8px 0 0; //client
  width: 56px;
  float: left;
}
.avTimes-lables.active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.avTimes-lables input {display: none;}
.avTimes-timesCol{
  //width: 42px; //client remove
  //margin: 4px 10px 10px 0; //client remove
  float: left;
  width: 260px;
}

.form__remark, .alert, .form__section, .form__item {
  font-family: Arial, sans-serif;
}

.time_list {
  overflow:hidden;
  width:100%;
  margin-top:30px;
}

.time_list a {
  text-decoration: none;
  float: left;
  width: 80px;

  white-space: nowrap;
  display: block;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 5px 0;
  font-weight: normal;
  margin: 0 20px 20px 0;

}
.time_list a:active, .time_list a.s {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.form__item_wrap {
  position: relative;

  &.form__item_wrap--dis {
    .temp_before {
      opacity: .5;
    }
  }
}

.my-small-loader {
  color: #000;
  padding: 20px 10px 20px 40px;
  background: url('@{path-images}loader-small.gif') no-repeat scroll 5px 50% transparent;
  font-family: @font-family-ekb-bold;
  font-size: 16px;
  line-height: 17px;

  &.my-small-loader--inline {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 15px;
  }

  &.my-small-loader--thin {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    color: #333;
    display: block;
  }
}

.my-loader {
  background: url('@{path-images}loader-big.gif') no-repeat scroll 50% 10px transparent;
  display: block;
  min-height: 137px;
  padding-top: 120px;
  text-align: center;
  color: #000000;
  font-family: @font-family-ekb-bold;
  font-size: 16px;
  line-height: 17px;

  &.loader-overlay {position:absolute; left:0; top:0; right:0; bottom:0; background-color:#FFFFFF; z-index:+1}
}

.b-button-top-back {
  margin-bottom: 20px;
}

.service-tabs {
  a, li {
    text-align: center;
  }
}

.accordion {
  .toggler {
    cursor: pointer;
  }
}

.ui-dialog .ui-dialog-buttonpane {
  text-align: center;
  background-image: none;
  margin-top: 30px;

  .btn + .btn {
    margin-left: 10px;
  }
}

.b-plain-list {
  li + li {
    margin-top: 5px;
  }
}

.b-title-centered {
  display: block;
  text-align: center;
  margin: 40px 0;
  font-family: "als_ekibastuz_light" ,Arial, sans-serif;
  font-size: 42px;
  line-height: 46px;
  font-weight: 200;

  &:first-child {
    margin-top: 0;
  }
}

.wsn {
  white-space: nowrap;
}

.b-nav-folfer {
  display: block;
  padding: 25px 0;
  border-bottom: 1px solid #e1e1e1;
  background: #f6f6f6;
  position: relative;
  top: 0;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
  transition: all .2s;

  &.fixed {
    position: fixed;
    z-index: 1001;
    top: 52px;
    padding: 10px 0;
  }

  &:before, &:after {
    display: block;
    position: absolute;
    top: 0;
    bottom: -1px;
    background: #f6f6f6;
    left: 100%;
    right: -9999px;
    border-bottom: 1px solid #e1e1e1;
    content: "";
  }

  &:after {
    right: 100%;
    left: -9999px;
  }
}

.b-nav-folfer-wrap {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: -60px 0 60px;
}

.b-nav-folfer-list {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

.b-nav-folfer-list-item {
  display: inline-block;
  vertical-align: middle;

  & + .b-nav-folfer-list-item {
    margin-left: 196px;
  }
}

.b-nav-folfer-list-link {
  font-size: 16px;
  line-height: 18px;
  border-bottom: 1px dotted;

  &:hover, &:active, &:focus {
    text-decoration: none;
  }
}

.b-text-larger {
  font-size: 16px;
}

.p_info {
  color: #000;
}

#avDates {
  overflow: hidden;
}

.info_block {
  position: relative;
  margin: 30px 0;
  border: 1px solid #dbe2e6;
  color: #4c4c4c;
  font: 14px/20px "Helvetica Neue", "Helvetica", Arial, sans-serif;
  padding: 25px 20px;

  & :first-child {
    margin-top: 0 !important;
  }

  p {
    margin: 1em 0 0;
  }

  &.type_warn {
    box-shadow: 0 6px 0 0 #FEC202 inset;
    border-top: none;
  }
}

.b-lk-notify {
  max-width: 740px;
}

.b-lk-notify-setter {
  display: block;
  margin: 0 0 30px 0;

  input, .form__item_wrap {
    width: 270px !important;
    display: inline-block;
    vertical-align: middle;
  }

  input[type="text"][readonly], input[type="text"][disabled] {
    opacity: .5;
    border: 1px solid #cdd1d4 !important;
    background: #fff !important;
  }
}

.b-lk-notify-label {
  display: inline-block;
  vertical-align: middle;
  width: 200px;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.b-lk-notify-switcher {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 20px;
}

.b-lk-notify-table {
  width: 100%;
  margin: 55px 0 0;
  max-width: 700px;

  &:first-child {
    margin-top: 0;
  }

  thead {
    th {
      font-size: 16px;
      font-weight: bold;
      font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
      text-align: center;

      &:first-child {
        text-align: left;
      }
    }
  }

  tbody {
    td {
      font-size: 16px;
      font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
      text-align: center;
      padding: 15px 0;
      border-bottom: 1px solid #c8c7cc;

      &:first-child {
        text-align: left;
      }
    }
  }

  .checkbox {
    display: inline-block;
  }
}

.b-advice {
  margin: 30px 0;
  padding: 58px 32px 40px 187px;
  background: #FFF 87px 40px no-repeat;
  border-top: 1px solid #DBE2E6;
  border-bottom: 1px solid #DBE2E6;
  width: 900px;
  overflow: hidden;
}

.b-advice-ico {
  vertical-align: middle;
  text-align: center;
  height: 80px;
  width: 80px;
  box-sizing: border-box;
  float: left;
  margin-right: 24px;
  margin-top: -18px;
  margin-left: -104px;
  background: url('@{path-images}all_bad.png') center center no-repeat transparent;
}

.b-advice-content {

}

.b-advice-header {
  font-family: "als_ekibastuz_light" ,Arial, sans-serif;
  font-weight: 200;
  margin-bottom: 30px;
  font-size: 37px;
}

.b-advice-text {
  font-size: 15px;
  color: #535B63;
  line-height: 20px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}

._info {color:#A6AFC2}

.ico {
  &:first-child {margin-right:10px}
}

.pad-default {padding:24px; font-weight:700; border: 1px solid #F1F3F6; border-radius:4px; box-shadow: 0 3px 8px 0 rgba(204, 204, 204, 0.15);
  &.with-hover:hover {box-shadow: 0 5px 8px 0 #CCCCCC;}
}

.popnotification {position:fixed; bottom:20vh; left:0; right:0; z-index:10000; text-align:center; animation: _myfadeOut 2s; animation-iteration-count:1;
  span {background:#4C4C4C; color:#E4E4E4; padding:5px 10px; font-size:12px; border-radius:4px; display:inline-block}
}

.addr_block {
  .addr {
    &:after {content:','}
    &:last-child:after {content:none}
  }
}

.bg-success {background: rgba(0, 156, 81, 0.05)}
.bg-danger {background: rgba(238, 63, 88, 0.05)}
.bg-warning {background: rgba(234, 168, 0, 0.1)}
.bg-info {background-color: #d9edf7;}

.text-success {color:#2ACD6B}
.text-danger {color:#EE2F53}
.text-warning {color:#F5831F}

@keyframes _myfadeOut {
  0% {opacity:0}
  10% {opacity:1}
  100% {opacity:0; }
}