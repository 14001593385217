@attr-value-line-height: 24px;

.attr-list-layout {
  width: 100%;
  table-layout: fixed;

  .modal-body > &:last-child {
    //margin-bottom: 0;
  }
}
.attr-row {
  width:100%;
  //& + & {
    & > td,
    & > .attr-col {
      & > .attr-value:first-child,
      & > .attr-field {
        margin-top:30px;
      }
    }
  //}
}
.attr-col {
  display: inline-block;
  //padding: @padding-base-vertical @padding-base-horizontal;
  width: 100%;

  &--grid{width:100%;}
  &--text{}
  &--common{}
}

.attr-container {
  min-height: @padding-base-vertical*2 + @line-height-computed + 2px; // 2px for border
}
.attr-fake-value {
  display: block;
  select, input, textarea {
    &, .modal-body & {
      &, &:focus, &:active, &.attr-value--filled {
        background: @lead-middle !important;
        color: @lead-text-color !important;
        box-shadow: none !important;
        border-color: transparent!important;
      }
    }
  }
  .g-download {
    padding-top: @padding-base-vertical;
  }
  .temp_before {
    color: @lead-text-color !important;
  }
  .radio-inline span {
    color: @lead-text-color !important;
  }
  label, .radio {
    cursor: default;
  }
}

.attr-field {
  vertical-align: top;
  position: relative;
  //display: table;
  display: block;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  & + & {
    margin-top: 30px;
  }
}

.attr-field__scrolled {
  .attr-label {
    opacity: 0;
    pointer-events: none;
  }
}

.attr-label,
.attr-title {
  //color: #4d4d4d;
  text-align: left;
  display: block;
}

.attr-title {
  //font-size: 115%;
  //line-height: 1em;
  text-transform: none;
  //font-family: 'Open Sans Bold';
  font-weight: bold;
  display: block;
  margin-top: 25px;
}
.attr-or {
  display: block;
  position: relative;
  padding: 2em 0;
  text-align:left;
  text-transform: lowercase;
}
.attr-or:after {
  content:"";
  display: block;
  position: absolute;
  left: 2.5em;
  right:0;
  height:0;
  top:50%;
  border-top:1px solid;
  opacity: .5;
}
.attr-label {
  //text-transform: uppercase;
}
.attr-label--inline {
  //display: table-cell;
  /*width:50%;*/

  display: block;

  position: absolute;
  left:0;
  right:0;
  margin:0;
  top:@padding-base-vertical;
  padding: 0 @padding-base-horizontal;
  pointer-events: none;
  cursor: text;
  z-index: ~"+3";

  font-family: inherit;
  font-weight: normal;
  line-height: @line-height-computed;
  font-size: 15px;
  color: #a6aeb3;

  .page.ready &,
  .modal.ready & {
    transition:
        font-size .15s ease,
        top .15s ease,
        opacity .35s ease;
  }

  //vertical-align: top;/* because it jumps over the cell height, on .attr-value error display */
  //padding-right: 5%;
}

.attr-label--view {
  display: block;
  top:@padding-base-vertical;
  padding: 0;
  pointer-events: none;
  cursor: text;
  font-family: inherit;
  font-weight: normal;
  line-height: @line-height-computed;
  font-size: 12px;
  color: #a6aeb3;
  margin: 0;
}

.attr-label--file {
  max-width: ~"calc(100% - 38px)"; // helper-icon width*2 + spacings
}

.attr-field--layout {
  display: table;
  width:100%;
  .attr-label--inline {
    position: relative;
    left: 0 !important;
    top: 0 !important;
    color: inherit !important;
    font-size: inherit !important;
  }
}

.attr-field--layout .attr-label--inline,
.attr-label--pull {
  padding-right: 5%;
  font-weight: normal;
  text-align: left;

  &, & + .attr-value {
    display: table-cell;
    width:50%;
    vertical-align: top;/* because it jumps over the cell height, on .attr-value error display */
  }
}

.attr-label--pull {
  font-weight: bold;
}

.attr-label--inline + .attr-value {
  select,
  textarea,
  input[type=text],
  input[type=search],
  input[type=date],
  input[type=email],
  input[type=password] {
    .placeholder(~"transparent !important");
  }
  .select2-selection__placeholder,
  .bootstrap-select > .btn,
  select {
    color: transparent !important;
  }
  .bs-searchbox input[type=text] {
    .placeholder(~"@{input-color-placeholder} !important");
    color: @input-color !important;
  }
  option {
    color: @input-color !important;
  }
}

.attr-field--focused,
.attr-field--readonly,
.attr-field--filled {
  .attr-label--inline {
    font-size: 10px;
    top:-0.075em;
  }
  .attr-label--inline + .attr-value {
    .select2-selection__placeholder,
    .bootstrap-select > .btn,
    select {
      color: @input-color !important;
    }
  }
}

.attr-label .required:after {
  margin-left:0.5em;
  position: relative;
  top: -0.175em;
}
.attr-label-title {
  word-wrap: break-word;
}
.attr-label-title-wrapper {
  /* wrapper over .attr-label-title */
  /* @see MSMEV-5588 */
  //display: table-cell;
  //height: 26px; /* font-size + 12px padding top */
  //vertical-align: bottom;

  .attr-label--inline & {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.attr-value {
  vertical-align: middle;
  display: block;
  position: relative;
  line-height: @attr-value-line-height;
}
.attr-value--view {
  font-size: 15px;
  line-height: 20px;
}
.attr-value {
  //p {
  //  margin: 0 0 .5em;
  //}
  //p.radio, p.checkbox {
  //  margin-top: 10px;
  //  margin-bottom: 10px;
  //}
  textarea {
    resize: vertical;
    max-height: 18em;
    min-height: 5.5em;
  }
}
.attr-value--inline,
.attr-label--inline + .attr-value {
  //display: table-cell;
  //width:50%;
  //vertical-align: top;
  display: block;
  width:100%;
}
.attr-value--inline {
  padding-top: @padding-base-vertical;
  padding-left:@padding-base-horizontal;
}
.attr-value .attr-value--inline {
  padding-left: 0;
}
.attr-label + .attr-value--helper .attr-value-helper--icon {
  top:-2em;
}
.attr-label--inline + .attr-value--helper .attr-value-helper--icon {
  top: @padding-base-vertical;
}
/*.attr-label--inline + .attr-value .attr-value-helper--icon {*/
/*top: 22px;*//* 10px + 12px */
/*}*/
.attr-label--under + .attr-value {
  margin-top:12px;
}
.attr-label-dou {
  padding: 0 5px 0 0;
  & + .attr-value {
    vertical-align: top;
    color: #4d4d4d;
    table td {
      width: auto;
    }
  }
}
.attr-label-dou--sec + .attr-value {
  color: rgb(128,128,128);
}
.attr-label-dou.attr-label-dou--sec {
  font-weight: normal;
}
.attr-field.checkbox-group {
  //border: 1px solid rgba(0,0,0,.05);
  /*box-shadow: 0 5px 10px rgba(0,0,0,.1);*/
  //padding: 20px;
  margin: 20px 0;
  display: block;
}
.attr-field.checkbox-group > .attr-label + .attr-value {
  margin-top: 20px;
}
.attr-field.checkbox-group {
  .checkbox {
    font-size: @font-size-small;
    line-height: 21px;
    & + & {
      margin-top: 20px;
    }
  }
}
.attr-field {
  &.checkbox {
    //left: 20px;
  }
  &, &.radio, &.checkbox {
    & + .attr-value-helper--icon {
      top:0;
    }
  }

  .radio, .checkbox {
    &:first-of-type {
      padding-left:0;
    }
  }
}

.attr-value-helper {
  display: block;
  position: relative;
  margin: 0;//10px 0;
  font-weight: normal;
  font-size: @font-size-small;
  color: @gray-light;
}
.attr-value-helper--title {
  text-transform: initial;
}
.attr-value-helper--file {
  //
}
/*.attr-value-helper--title:before{content:"("}*/
/*.attr-value-helper--title:after{content:")"}*/
/*.attr-value-helper--title:first-letter {  text-transform: uppercase;  }*/
.attr-value-helper--icon {
  cursor: help;
  position: absolute;
  width: 22px;
  height:22px;
  overflow: hidden;
  background-position: center center;
  top:12px;
  right: 10px;
  margin: 0;
  z-index: +2;

  border-radius: 100%;
  border: 1px solid #d1d1d1;
  background: #ffffff;
  color: transparent;

  &.attr-value-helper--file {
    top: .25em;
  }

  &:after {
    .fa-icon();
    content: @fa-var-question;//"?";
    position: absolute;
    top:50%;
    left:50%;
    //transform: translate(-50%,-50%);
    margin: -.5em 0 0 -.3em; // .fa-var-question is not absolutely centered
    color: #000000;
    font-size: 12px;
    font-weight: normal;
    cursor: inherit;
  }

  &.attr-value-helper--icon--inline {
    display: inline-block;
    margin: 0 0 0 3px;
    vertical-align: middle;
    position: relative;
    top: 0;
    right: 0;
  }
}
//.attr-value-helper--info-left,
//.attr-value-helper--info-right {
//  // deprecated
//}
//.attr-value-helper--info-left {
//  padding-left: 28px;
//  background-position: center left;
//}
//.attr-value-helper--info-right {
//  padding-right: 28px;
//  background-position: center right;
//}
.attr-value-helper {
  &.attention {
    .alert-variant(@alert-warning-bg; @alert-warning-border; @alert-warning-text);
    //font-size:13px;
    color: #fff;
    text-align:center;
    text-indent:0;

    &:after {
      line-height: 19px;
      content:"!";
    }
  }

  & + .popover {
    left:auto !important;
    right: -20px !important;
    font-size: @font-size-small;
    line-height: 1.5em;
    max-width: 65%;
  }

  & + .popover .arrow {
    left: auto !important;
    right: 27px !important;
  }
}
.attr-value--helper {

  select,
  textarea,
  input[type=text],
  input[type=date],
  input[type=email],
  input[type=password] {
    padding-right: 30px;
  }

  textarea {
    overflow-y:scroll;
  }
  .js--search-zags-by-address ~ .attr-value-helper--icon,
  textarea ~ .attr-value-helper--icon {
    right: 24px;
  }

  .selectbox-wrapper ~ .attr-value-helper--icon ,
  select ~ .attr-value-helper--icon {
    //right: 20px;
    /*right: 24px;*/ /* then poppover -44px */
    right: 38px;
  }

  & > .selectbox-wrapper ~ .popover ,
  & > select ~ .popover {
    /*right:-44px !important;*/
    right: 8px !important;
  }
}

.attr-value {
  select,
  .bootstrap-select,
  input[type=text],
  input[type=date],
  input[type=email],
  input[type=password]{
    width:100% !important;
  }

  .linked-obj {
    cursor: pointer;
    resize: none;
  }
}

.attr-readonly {
  display: block;
  padding: @padding-base-vertical @padding-base-horizontal;
  border: 1px solid @btn-default-border;
  background: @input-bg-disabled;
  text-shadow: 1px 1px 0 #fff;
}

.attr-mfc-map {
  margin-top: 5px;
  border: 1px solid @blockquote-border-color;
  width:  100%;
  height: 400px;
}

////////////

.form-attr-section {
  padding-bottom: 35px;
}
.form-attr-section--equeue {
  padding-bottom: 50px;
}
.form-attr-section--additional {
  padding-top: 35px;
  border-top: 1px solid @block-border-color;

  font-size: 12px;
}
.form-attr-section--equeue.form-attr-section--additional {
  padding-top: 15px;
}
.form-attr-section .attr-title {
  margin-top: 0;
  text-transform: inherit;
  font-size: inherit;
}
.form-attr-section--additional .attr-title {
  font-weight: normal;
  font-family: inherit;
}
.form-attr-section .attr-value {
  //font-family: 'Open Sans';
}
.form-attr-section--additional .attr-value {
  font-weight: normal;
  font-family: inherit;
}

.form-attr-section .attr-field{
  table-layout: fixed;
}
.form-attr-section .attr-label--inline{
  //width: 200px;
}
.form-attr-section .attr-label--inline + .attr-value {
  width:100%;
}
.form-attr-section--equeue .attr-label--inline,
.form-attr-section--equeue .attr-label--inline + .attr-value {
  padding-top: 20px;
}

.radio-list,
.checkbox-list {
  .radio, .checkbox {
    margin: 20px 0;
  }
}

.attr-list-attr {
  padding-left: 1em;
}
.attr-list-attr-item {
  & + & {
    margin-top: .5em;
  }
}


.form__item {
  display: block;
  padding: 0;
  margin: 30px 0;
  .clearfix();

  &:first-child {
    margin-top: 0;
  }

  &.form__item_with_check {
    select,
    textarea,
    input[type=text],
    input[type=date],
    input[type=email],
    input[type=search],
    input[type=password] {
      max-width: 90%;
    }
  }
}

.form__item_tr {
  & > td {
    padding-top: 30px;
  }

  &:first-child {
    & > td {
      padding-top: 0;
    }
  }
}

.form__lbl, .tb__title {
  display: block;
  font-size: 15px;
  line-height: 22px;
  //color: #a6aeb3;
  color: #535B63;
  font-family: Arial, sans-serif;
  margin: 0 0 8px 0;
  font-weight: normal;
}

.tb__title {
  font-weight: 700;
  color: #000;
}

.form__item__desc, .note {
  display: block;
  margin-left: 15px;
  margin-top: 2px;
  font-size: 13px;
  color: #a5aeb3;
  white-space:pre-wrap;
  font-family: Arial, sans-serif;
}

.mark, .star {
  margin-left: 3px;
  color: @brand-critical;
}

.ui-datepicker-trigger {
  position: relative;
  margin-left: -45px;
  margin-right: 10px;
  top: -1px;
  z-index: 2;
}

.jq-file {
  width: 100%;
  padding-right: 132px;
  /*height: 36px;*/
  min-height:46px;
  position: relative;
  display: block;

  .dsr-files-holder & {
    margin-top: 15px;
  }

  & + .note {
    margin-right: 100px;
  }
}
.jq-file .my-small-loader {
  display:block;
  margin-top:40px;
}

.jq-file input {
  cursor: pointer;
  height: auto;
  line-height: 1em;
}

.jq-file__name {
  display: block;
  position: absolute;
  left: 0;
  right: 100px;
  height: 46px;
  padding: 12px 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #fff;
  border:1px solid @block-border-color;
  -webkit-appearance: none;
  font-family: Arial, sans-serif;
}

.jq-file__browse {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid #005597;
  white-space: nowrap;
  padding: 12px 24px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  background: #0063b0;
  position: absolute;
  right: 0;
}

.jq-file:not(.disabled):hover .jq-file__browse {
  color: #fff;
  background-color: #00467d;
  border-color: #003259;
}

.jq-file.disabled .jq-file__name {
  color: #888;
}
.jq-file.disabled,
.jq-file.disabled .jq-file__browse,
.__ajax-upload._disabled .jq-file__browse {
    border-color: #CCC;
    box-shadow: none;
    color: #888;
}

.__ajax-upload {
  &._disabled {
    input[type=file] {pointer-events: none}
  }
  .jq-file.disabled:hover, &._disabled .jq-file:hover{
    overflow: visible !important;
  }
  .jq-file.disabled:after, &._disabled .jq-file:after {
    content: 'Файл уже приложен. Для прикрепления другого нажмите "Отменить"';
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    right: 0;
    margin-top: -30px;
    background-color: #fff;
    font-size: 0.9em;
    padding: 3px 9px;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: #333;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease;
  }
  .jq-file.disabled:hover:after, &._disabled .jq-file:hover:after {
    opacity: 1;
    visibility: visible;
  }
}

.b_time_spacer {
  display: inline-block;
  margin-right: 15px;
}

td.b-right-actions {
  padding-left: 20px;
  width: 18px;
}

.with_button_wrap {
  display: block;
  position: relative;

  select {
    max-width: none;
  }
}

select[size="6"] {
  max-width: none !important;
}

.styled_dropp_button {
  position: absolute;
  top: 0;
  right: 17px;
  width: 18px;
  height: 49px;
  line-height: 49px;
  font-size: 26px;
  color: #565656;
  border: 0 none;
  background: transparent;
  padding: 0;
  margin: 0;

  &:after {
    .fa-icon();
    content: @fa-var-angle-down;
  }

  .arrow {
    display: none;
  }
}

.attr-scucces-sign {
  font-size: 18px;
  line-height: 20px;
  color: @brand-success;
  margin-left: 5px
}

.form__inp.static_choosed {
  font-family: Arial, sans-serif;
}

.b-attrs-row {
  & + .b-attrs-row {
    margin-top: 14px;
  }
}