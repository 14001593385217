/*
Modal blocks
*/
.modal-block {
  position: relative;
  padding: 26px 15px 26px 11px;
  width:100%;

  &:first-child {
    padding-top:0;
  }

  .btn {
    text-align: center;
    font-size: 17px;
    .fa {
      margin-left: 14px;
      font-weight: bold;
    }
  }
}

.modal-body-headcrumbs-actions {
  .btn {
    text-align: center;
    font-size: 17px;
    margin-top: 15px;
    .fa {
      margin-left: 14px;
      font-weight: bold;
    }
  }
}

.modal-block-sm {
}
//.modal-block--rating {
//  padding: 20px 15px;
//}
.modal-block-blocked-info {
  display: none;
}
.modal-block-blocked-info-inner {
  display: table;
  width: 100%;
  height: 100%;
}
.modal-block-blocked-info-text {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  font-weight: bold;
  padding: 20px;
  /*cursor: not-allowed;*/
}
.modal-block + .modal-block {
  border-top: 1px solid @modal-block-border-color;
}
.modal-block--loading:after {
  display: block;
  content: "";
  position: absolute;
  left:0;
  right:0;
  bottom:0;
  top:0;
  z-index:+2;
}
.modal-block--card + .modal-block--card {
  margin-top: 0;
}
.modal-block--default {
  color: @text-color;
  background-color: @gray-lighter;
}
.modal-block--danger {
  color: #fff;
  background-color: @brand-danger;
}
.modal-block--disabled {
  opacity: .75;
  /*cursor: not-allowed;*/
  color: @gray-light !important;
}
//.modal-block--disabled .btn:hover {
//  color: inherit !important;
//  background: inherit !important;
//  cursor: inherit !important;
//}
.modal-block--disabled .btn {
  pointer-events: none;
}
.modal-block--blocked {
  position: relative;
}
.modal-block--blocked .modal-block-blocked-info {
  display: block;
  position: absolute;
  z-index: +1;
  left:-1px;
  right:-1px;
  bottom:-1px;
  top:-1px;
  /*background: rgba(255,255,255,.9);*/
  background: #fff;
}

.modal-block-header {
  text-transform: uppercase;
}
.modal-block--card .modal-block-header {
  min-height: 50px;
}
.modal-block-title {
  margin: 0;
  padding: 5px 0;
  position:relative;
  font-family: 'Open Sans Bold', sans-serif;
  font-weight: 100;
  font-size: 18px;
  line-height: 20px;
}
.modal-actions .modal-block-title {
  padding-bottom: 15px;
}
.modal-block-title:after {
  content: "";
  position:absolute;
  width:70px;
  height:0;
  border-bottom: 3px solid;
  top: 100%;
  margin-top:2px;
  left:0;
}
.modal-block--doc_templates .modal-block-title:after {
  display: none !important;
}

/*.modal-block .g-tile-anchor {*/
/*left:0;*/
/*right:0;*/
/*}*/

.modal-block .btn-opaque + .btn-opaque {
  border-top: 0;
}

.modal-block .g-tile-status-title {
  text-shadow: none;
}
.modal-block .g-tile-status {
  top:85px;
  z-index: +1;
  padding:0;
}
.modal-block .g-tile-status-icon {
  width:  50px;
  height: 50px;
  background-size: 50px;
  border-width: 2px;
  margin: 5px auto;
}
.modal-block .modal-block-footer .g-tile-anchor {
  left:0;
  right:0;
  padding:0 30px 0 0;
  font-size: 12px;
}

//.modal-block-title.warning {color:#ff5043}
//.modal-block-title.warning:after {border-color:#ff5043}

.b-modal-right-list {margin-top:25px; max-height:670px}
.b-modal-right-list a {margin:0 0 25px 0; display:block}
.b-modal-right-list.mCustomScrollbar {margin-right:-10px}

.modal-block-wrapper {
  /*min-height: 215px;*/
  //min-height: 195px;
  //margin-bottom: 30px;
  position: relative;
}
.modal-block-sm .modal-block-wrapper {
  min-height: initial;
  margin-bottom: 0;
}
.modal-block-content {
  /*margin-top:33px;*/
  margin-top:23px;
  line-height: 20px;
}
.modal-block--doc_templates .modal-block-content {
  /*margin-top: 15px;*/
  margin-top: 18px;
}
.modal-block-content.scroll {
  max-height: 257px;
}
.modal-header-preamble-text:after,
.modal-block-content.scroll:after {
  content: "";
  position: absolute;
  bottom: 0;
  max-height: 76px;
  height:100%;
  left:0;
  right:0;
  /*background-image: url('../../images/sunshine/bg/service-info-bottom-loop.png');*/
  background-position: center bottom;
  background-repeat: repeat-x;
  background-size: auto 100%;
  z-index: +1;
  pointer-events: none;
  opacity: 1;

  transition: opacity .35s ease;
}

.modal-block-content p {
  margin: 0;
}
.modal-block-content ol {
  display: block;
  padding: 0 10px 0 20px;
  margin-left: 0;
  line-height: 1em;
}
.modal-block--doc_templates .modal-block-content ol {
  list-style: none;
  counter-reset: item;
}
.modal-block--doc_templates .modal-block-content ol li:before {
  content: counter(item) ". ";
  display: inline-block;
  counter-increment: item;
  width: 20px;
  text-align: left;
  position: absolute;
  top: 0;
  left: -20px;
}
.modal-block-content ol li + li {
  margin-top: 1em;
}
.modal-block-content ol strong {
  display: block;
  padding: 10px 0;
}
.modal-block-footer {
  /*font-family: 'Open Sans Bold', sans-serif;*/
  /*font-weight: bold;*/
  margin-top: 30px;
  position: relative;
  min-height: 40px;
}
.modal-block .btn {
  //width:100%;
  //text-transform: uppercase;
}
.modal-block--card .modal-block-footer {
  margin-top:0;
}
.modal-block--card .modal-block-footer .btn {
  padding:12px;
  line-height: 14px;
  border:1px solid;
  font-family: inherit;
}
.modal-block--card .modal-block-footer .btn--load-draft {
  position: absolute;
  top:-44px;
  left:0;
  right:0;
}
.modal-block-footer .btn + .btn {
  margin-top: 10px;
}
.modal-block--card .modal-block-footer .btn--load-draft + .btn {
  margin-top: 0;
}
/* overwrite style for hidden */
.modal-block-footer .hidden.btn + .btn {
  margin-top: 0;
}

.modal-block--card .equeue-date {
  font-family: 'Open Sans Semibold';
  display: block;
  margin: 0 0 .5em;
}
.modal-block--card .equeue-contacts {
  line-height:1.25em;
}

.modal-block--equeue {
  border-top: none !important;
  padding-top:0;
}

.modal-block--doc_templates {
  overflow: hidden;
}
.modal-block--doc_templates--hidden {
  height: 0;
}
.modal-block--doc_templates .modal-block-footer {
  margin-top:10px;
}
.modal-block--what_to_do .modal-block-content,
.modal-block--doc_templates .modal-block-content {
  /*letter-spacing: -0.03em;*/
  /*line-height: 14px;*/
}
.doc_template-actions {
  font-size:12px;
  line-height: 15px;
  display: block;
}
.doc_template-actions,
.doc_template-actions .g-local-link {
  color: #808080;
}

.modal-header-section {
  margin: 35px -33px/*-35px margin + 2px border*/ 0;
  overflow: visible;
}
.modal-header-section:after {
  display: table;
  clear: both;
  content:"";
}
.modal-header-section .modal-block--card {
  margin: 0 5px 5px;
}
.modal-block--card {
  vertical-align: top;
  /*display: inline-block;*/
  border:1px solid;
  width:225px;
  float:left;
  margin: 0;

  box-shadow: 0 2px 10px rgba(0,0,0,.1);
  background-color: #fff;
  color: #4d4d4d;
}
.modal-block--card .modal-block-content {
  margin-top: 21px;
}

.modal-header-progress {
  //position:relative;
  //float:right;
  //text-align: right;

  display: none;
}
.modal-header-progress .g-sector {
  background: #fff;
}
.modal-header-progress .g-sector--active {
  /*background: #c4c4c4;*/
  background: #0cca39;
}

/*
Petition modal
*/
.modal-dialog--petition {

}
.modal-dialog--petition input[type=text],
.modal-dialog--petition input[type=date],
.modal-dialog--petition input[type=password],
.modal-dialog--petition select,
.modal-dialog--petition textarea {
  max-width:100%;
}
.modal-dialog--petition input.hasDatepicker[type="text"] {
  /*background-image:url(../../images/sunshine/ico/calendar.png)!important;*/
  background-repeat:no-repeat!important;
  background-position: 98% 45% !important;
}
.modal-dialog--petition .attr-value--helper input.hasDatepicker[type="text"] {
  background-position: 85% 45% !important;
}

.modal-block-service-actions {
  p {
    margin-top: 0;
  }

  .btn {
    margin-bottom: 15px;
  }
}