.service-payment-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  &__title {
    font-family: @font-family-bold;
    font-size: 18px;
    font-weight: 600;
  }

  &__button-block {
    position: relative;
    width: 100%;

    button {
      width: 100%;
      text-align: left;
      padding: 25px 75px;
      background-color: white;
      border: 1px solid @nav-tabs-border-color;
      border-radius: 5px;
      font-family: @font-family-bold;
      font-size: 16px;
      font-weight: 600;

      &:hover {
        background-color: @nav-link-hover-bg;
      }

      &:after {
        font-family: 'FontAwesome';
        content: "\f054";
        position: absolute;
        right: 20px;
        top: calc(50% - 8px);
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 10px;
        padding-top: 1px;
        padding-left: 2px;
        border: 2px solid #0173c1;
        color: #0173c1;
        border-radius: 100%;
        pointer-events: none;
      }
    }

    img {
      position: absolute;
      display: block;
      width: auto;
      height: 40px;
      left: 20px;
      top: calc(50% - 20px);
      pointer-events: none;
    }
  }
}