.attr-field__upload-photo {
  width: 425px;
  padding: 30px;
  background: #f4f5f5;
  position: relative;
  &.view {
    width: 250px;
    .upload-photo__imgBlock {
      margin-bottom: 0;
    }
  }
  &.uploadPhoto__editBlock {
    width: 100%;
    padding: 20px;
    .image-editor {
      overflow: hidden;
      position: relative;
      -ms-scroll-limit: 0 0 0 0;
      -ms-overflow-style: none;
    }
    .cropit-preview-background {
      opacity: .2;
    }
    .cropit-preview {
      background-size: cover;
      width: 190px!important;
      height: 250px!important;
    }
    .cropit-preview-image-container {
      cursor: move;
    }

    .cropit-preview-background {
      opacity: .2;
      cursor: auto;
    }

    .image-size-label {
      margin-top: 10px;
    }
    .cropit-image-control {
      width: 35px;
      height: 35px;
      position: relative;
      z-index: 10;
      display: inline-block;
      margin-top: 10px;
      border: 0;
    }
    .rotate-ccw {
      background: url("@{path-images}rotate_ccw@1x.png") center center no-repeat transparent;
      margin-right: 5px;
      margin-left: -5px;
    }
    .zoom-out {
      background: url("@{path-images}minus_btn.svg") center center no-repeat transparent;
      margin-right: 5px;
    }
    .zoom-in {
      background: url("@{path-images}plus_btn.svg") center center no-repeat transparent;
      margin-right: -5px;
      margin-left: 5px;
    }
    .cropit-image-input {
      display: none;
    }
    .cropit-image-zoom-input {
      position: relative;
      z-index: 10;
      display: inline-block;
      width: 59px;
      min-height: 35px;
      &::-ms-track {
        width: 59px;
        height: 10px;
        background: transparent;
        border-color: transparent;
        border-width: 6px 0;
        color: transparent;
      }

      &::-ms-fill-upper {
        background: #fff;
        border-radius: 6px;
      }
      &::-ms-fill-lower {
        background: #fff;
        border-radius: 6px;
      }
      &::-ms-thumb {
        color: #565656;
        background: #565656;
        border-radius: 100%;
        width: 10px;
        height: 10px;
      }
      &::-ms-tooltip {
        display: none;
      }
    }
    .uploadPhoto__infoMove {
      width: 182px;
      position: absolute;
      left: 30px;
      top: 20px;
      padding: 8px;
      background: #e3e3e3;
      .uploadPhoto__infoMove-Icon {
        float: left;
        margin: 2px -100% 0 0;
        display: block;
        width: 27px;
        height: 27px;
        background: url('@{path-images}move_arrow.svg') no-repeat;
        -webkit-background-size: 27px;
        background-size: 27px;
      }
      .uploadPhoto__infoMove-Text{
        font-size: 12px;
        margin-left: 32px;
        display: block;
      }
    }
    .upload-photo__imgBlock {
      position: relative;
      left: 35%;
      margin-bottom: 60px;
    }
  }
  .upload-photo__imgBlock {
    padding: 7px 0;
    background: url('@{path-images}man_new.svg') 0 0 no-repeat #e4f6ff;
    background-size: cover;
    height: 250px;
    width: 190px;
    border: 5px solid #bbd1e8;
    margin-bottom: 20px;
    .PGU-ImageEditor-st0 {
      fill: #535B63;
    }
    .PGU-ImageEditor-st1 {
      font-family: 'Helvetica', 'Arial', sans-serif;
    }
    .PGU-ImageEditor-st2 {
      font-size: 12px;
    }

    .PGU-ImageEditor-st3 {
      opacity: 0.3;
    }
    .PGU-ImageEditor-st5 {
      fill: none;
      stroke: #000000;
      stroke-miterlimit: 10;
      stroke-dasharray: 5, 5;
    }
  }
  .btn-default {
    padding: 12px 18px;
  }
}
.uploadPhoto__annotate {
  margin-bottom: 15px;
  ul {
    margin: 0;
    padding: 0;
    &.error {
      li {
        margin-left: 30px;
        list-style: none;
        &:before {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          background: url('@{path-images}error.png') 0 0 no-repeat transparent;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
.uploadPhoto__toggleBtn {
  border-bottom: 1px dashed #0173c1;
  margin-bottom: 20px;
  display: inline-block;
  &:hover {
    text-decoration: none;
    border-bottom: 1px dashed transparent;
  }
  &:after {
    content: "";
    position: relative;
    vertical-align: top;
    line-height: inherit;
    margin-top: .5em;
    margin-left: .25em;
    display: inline-block;
    overflow: hidden;
    height: 0;
    width: 0;
    border: 4px solid;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
  &.open:after {
    border-top-color: transparent;
    border-bottom-color: initial;
    margin-top: 0.2em;
  }
}
.uploadPhoto__description {
  display: none;
  height: 390px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px;
  box-sizing: border-box;
  font-size: 14px;
  &.open {
    display: block;
  }
  h4 {
    margin: 0;
  }
}
.uploadPhoto__hiddenFile {
  visibility: hidden;
  position: absolute;
}
.uploadPhoto__title-error {
  background: url('@{path-images}all_be_warn.svg') 0 0 no-repeat transparent;
  display: block;
  float: left;
  height: 60px;
  width: 60px;
  margin-right: 15px;
}