.g-svg-etc__download-sheet-dims {
	width: 42px;
	height: 54px;
}

.g-svg-etc__group-side-arrow-dims {
	width: 30px;
	height: 80px;
}

.g-svg-icon__alert-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__antenna-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__apostile-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__bigboard-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__bill-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__book-plus-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__bookmark-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__briefcase-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__car-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__cardiogram-lense-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__cell-network-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__certificate-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__collet-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__core-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__coupon-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__cross-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__deer-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__equeue-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__family-1-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__family-2-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__family-3-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__fence-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__folder-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__handcuffs-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__house-man-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__house-ok-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__inn-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__kid-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__libra-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__lifebuoy-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__lights-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__medal-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__medicine-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__megafon-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__message-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__nipple-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__paper-ok-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__paper-ok-2-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__paper-rub-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__pass-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__passport-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__payments-1-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__payments-2-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__payments-3-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__pencil-doc-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__percent-doc-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__point-map-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__pram-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__pram2-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__quarters-1-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__quarters-2-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__quarters-3-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__receipt-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__rings-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__rudder-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__scores-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__shield-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__stamp-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__target-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__tickets-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__trowel-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__valve-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__wallet-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__water-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__wind-dims {
	width: 120px;
	height: 120px;
}

.g-svg-icon__zpassport-dims {
	width: 120px;
	height: 120px;
}

.g-svg-list-bullet-dims {
	width: 17px;
	height: 17px;
}

