@sx-mask-not-valid-anim-name : sx-mask-not-valid-shake;
.sx-mask-not-valid {

}
.sx-mask-not-valid-anim {
  @anim-name : @sx-mask-not-valid-anim-name;
  animation: @anim-name .55s linear;
  animation-fill-mode: both;
  @keyframes @anim-name {
    8%, 41% {  transform: translateX(-6px);  }
    25%, 58% {  transform: translateX(6px);  }
    75% {  transform: translateX(-3px);  }
    92% {  transform: translateX(3px);  }
    0%, 100% {  transform: translateX(0);  }
  }
}