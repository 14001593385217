@service-name-left-padding : 35px;


.service-group-title {
  padding-bottom: 30px;
  border-bottom: 1px solid @block-border-color;
  margin-bottom: 60px;
}
.grid-col {
  .non-e-serv,
  .e-serv {
    width: 30px;
    height: 22px;
    display: block;
    float: left;
    margin-right: 5px;
    /*margin-top: -2px;  */
    background:  url("@{path-images}e.png") left center no-repeat transparent;
  }
  .non-e-serv {
    background-image:  url("@{path-images}non-e.png");
  }
  .service-block {
    display: table;
    width: 100%;
  }
  .service-name {
    display: table-cell;
    vertical-align:top;
    ul,li {
      list-style: none;
      display: block;
      margin: 0;
      padding: 0;
    }
    ul {
      padding-left: 15px;
    }
    li {
      padding: 10px 0;
    }
  }

  .service-action {
    width: 40px;
    cursor: pointer;
    display: table-cell;
    .fa {
      border-radius: 100%;
      border: 1px solid @block-border-color;
      text-align: center;
      width: 24px;
      height: 24px;
      line-height: 20px;
      font-size: 18px;
      margin-top: -3px;
    }
  }
  .service-link {
    cursor: pointer;
    .fa {font-weight: bold;}

    a {
      color: inherit;
    }
  }
  .linker {
    .fa {
      color: #0173c1;
      border: 2px solid #0173c1;
    }
    .fa:before{content:"\f105"}
  }
  .toggler {
    .fa {
      border: 1px solid @block-border-color;
    }
    .fa:before{content:"\f107"}
    &.open {
      .fa:before{content:"\f106"}
    }
  }
}

a.service-name {
    padding-left: @service-name-left-padding;
    & .non-e-serv,
    & .e-serv {
       margin-left: -@service-name-left-padding ;
    }
  .non-e-serv + &,
  .e-serv + & {
    padding-left:0;
  }
}

