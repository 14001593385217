.hunter-resources {
  font-family: @font-family-bold;

  .hunter-info, &__blocks {
    margin-top: 24px;
  }

  &__table-container {

  }

  .hunter-table {
    margin-top: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-title {
    label {
      margin: 0;
      padding: 0;
      line-height: inherit;
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
    }

    input {
      margin: 0;
      padding: 0;
    }
  }

  &__sum {
    padding: 6px 12px;
    border-radius: 4px;
    font-size: @font-size-small;
  }

  &__sum-value {
    font-weight: bold;
  }

  &__block-item {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &__block-item &__table-container {
    padding-left: 24px;
  }
}

.hunter-tabs {
  font-family: @font-family-bold;
  display: flex;

  .hunter-info+& {margin-top:0.5em}

  .hunter-content {width:100%;}

  &__button {
    position: relative;
    flex-shrink: 0;
    padding: 16px;
    background: none;
    border: none;
    color: @gray-light;

    &:first-child {
      &:after {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }
    }

    &:last-child {
      &:after {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
    }

    &:only-child {
      &:after {
        border-radius: 4px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: @gray-lighter;
    }

    &.active {
      color: @brand-primary;

      &:after {
        background-color: @brand-primary;
      }
    }
  }
}

.hunter-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: none;
  font-weight: bold;
  background: none;
  color: @brand-primary;
}

.hunter-info {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding: 16px 24px;
  background-color: #E7F1F8;
  border-radius: 4px;

  &+& {margin-top:2px}

  &__left-side &__item {
    padding: 0;
  }

  &__right-side {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &__item {
    padding: 6px 12px;
    border-radius: 4px;

    &--small {
      font-size: @font-size-small;
    }

    &--gray {
      color: @gray-light
    }

    &--bg-white {
      background-color: white;
    }
  }

  &__value {
    font-weight: bold;

    &.active {
      color: @brand-primary;
    }
  }
}

.hunter-table {
  width: 100%;
  border: 1px solid @gray-lighter;
  border-radius: 4px;
  border-collapse: unset;
  border-spacing: 0;
  background-color: @gray-lighter;
  overflow: hidden;

  thead {
    background-color: @gray-lighter;
  }

  tbody {
    background-color: white;
  }

  th {
    padding: 12px 24px;
  }

  td {
    padding: 8px 24px;
  }
}