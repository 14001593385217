html {
  text-size-adjust: 100%;
}

body {
  overflow-y: scroll;
  margin-top: 0 !important; // gosbar fix
  min-width: 1200px;
  background: #e6ebec;
  border:0;
  margin:0;
}

// don't show the x for text inputs
::-ms-clear {
  display: none;
}

a {
  cursor: pointer;
  &[disabled], &.disabled {opacity:0.5; pointer-events:none; cursor:not-allowed}
}

a, button {
  outline: 0 none !important;
}
a {
  color: #0173c1;
  text-decoration: none;
}

a:hover {
  color: #1a88d7;
}
.dash, a.dash {
  border-bottom:1px dashed;
  cursor:pointer;
  text-decoration: none;
}
svg use {
  pointer-events: none;
}
strong {
  font-family: @font-family-bold;
}
.or {
  display: inline-block;
  padding: @padding-base-vertical @padding-base-horizontal;
}

.opaque {
  opacity: 0 !important;
  filter:alpha(opacity=0) !important;
  pointer-events: none !important;
}

.wrapper {
  position: relative;
  max-width:@wrapper-max-width;
  margin:0 auto;

  @sidePadd : @wrapper-side-padding;
  padding-left: @sidePadd;
  padding-right: @sidePadd;
}
.zero-padding {padding:0!important;}

//.g-svg__t {
//  .loop(@counter) when (@counter > 0) {
//    .loop((@counter - 1));    // next iteration
//    &-@{counter} {
//      &, svg {
//        @var : "color-@{counter}";
//        fill : @@var;
//      }
//    }
//  }
//  .loop(@color-count);
//}
.g-svg-color {
  &__default {fill:@brand-primary}
  &__primary {fill:@brand-primary}
  &__secondary {fill:@brand-secondary}
  &__danger {fill:@brand-danger}
  &__info {fill:@brand-info}
  &__warning {fill:@brand-warning}
  &__critical {fill:@brand-critical}
  &__success {fill:@brand-success}
  &__disabled {fill:@brand-disabled}
}

.color() {
  &__default {color:@brand-primary}
  &__primary {color:@brand-primary}
  &__secondary {color:@brand-secondary}
  &__danger {color:@brand-danger}
  &__info {color:@brand-info}
  &__warning {color:@brand-warning}
  &__critical {color:@brand-critical}
  &__success {color:@brand-success}
  &__disabled {color:@brand-disabled}
}

.g-svg__error {
  fill: transparent;
  stroke: transparent;
  //border: 2px solid @brand-critical;
  //border-radius: 100%;
  background: transparent url('@{path-images}pelmen.png') 50% 50% no-repeat;
  background-size: 100% auto;
  background-size: cover;
}

.g-flag {
  display: inline-block;
  vertical-align: baseline;

  @w : 22px;
  @h : 15px;
  width: @w;
  height:@h;
  background-size: @w @h;
  background-repeat: no-repeat;
  background-position: 0 0;
  //background-color: #fff;
  &__ru  {background-image:url('@{path-images}flag/ru.png')}
  &__uk {background-image:url('@{path-images}flag/uk.png')}
  &__crt {background-image:url('@{path-images}flag/crt.png')}
}

//.g-gosuslugi-logo {
//  @image : '@{path-images}gosuslugi.png';
//  @iw : image-width(@image);
//  @ih : image-height(@image);
//  width: @iw;
//  height: @ih;
//  display: block;
//  overflow: hidden;
//  background: url('@{image}') 0 50% no-repeat;
//  background-size: @iw @ih;
//  text-indent: -99999px;
//}

.g-local-link {
  /*display: inline-block;*/
  border-bottom: 1px dashed;
  text-decoration: none !important;
  &--danger {
    color: @brand-danger;
  }
  &--disabled {
    border-bottom-color: rgba(0,0,0,0);
  }
}

.gradient (@startColor : @brand-primary, @endColor: @gray) {
  background-color: @startColor;
  background: linear-gradient(to bottom, @startColor, @endColor);
}

.error-blink[error] {
  animation-duration: 3s;
  animation-fill-mode: both;
  animation-iteration-count: 3;
  animation-name: error-blink-flash;
}
@keyframes error-blink-flash {
  0%, 50% {  background-color: #ffedec;  }
  25%, 75% {  background-color: #ffffff;  }
  100% {  background-color: #f2f2f2;  }
}

.datepicker__mobile {
  -webkit-appearance: none;
}
.mobile-inline,
.mobile-inline-block,
.mobile-block {
  display: none;
}

.mobile-txt-fix {
  max-height: 1000000px;
}

.dropdown-arrow-after(@bwidth : 4px, @mtop : .5em) {
  &:after {
    content:"";
    position: relative;
    vertical-align: top;
    line-height: inherit;
    margin-top: @mtop;
    //margin-left: 4px;
    margin-left: .25em;
    display: inline-block;
    overflow: hidden;
    height:0;
    width:0;
    border: @bwidth solid;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;

    .open & {
      border-top-color: transparent;
      border-bottom-color: initial;
      margin-top: ~"calc(@{mtop} - @{bwidth})";
    }
  }
}

.g-currency {
  font-weight: 400;
  font-size: inherit;
  .fa {
    font-weight: 100;
    font-size: 90%;
    margin-left: .25em;
  }
}

.g-captcha {
  display: inline-block;
  background: url('@{path-images}loader-small.gif') center center no-repeat;
  border: 1px solid @gray-lighter;

  &, &-img {
    min-width:124px;
  }

  &-img {
    display: inline-block;
    height: 44px;
    width:auto;
  }
}

.section-fixate__fixed  .section-fixate-inner {
  position: fixed;
  left: 0;
  right: 0;
  background: #fff;
  border-bottom: 1px solid hsla(0,0%,66%,.15);
  z-index: @zindex-navbar-fixed;
  min-width: 1200px;

  .app__search_focused & {
    position: absolute;
    top: 0 !important;
    transform: translateY(0) !important;
    z-index: 1;
  }
}

.g-search-query-mask {
  display: block;
  position: fixed;
  left:0;
  right:0;
  bottom:0;
  top:0;
  background: rgba(0,0,0,.4);
  //pointer-events: none; // allow to click through
  z-index: @zindex-modal-background;
}

.text-label-box {
  display: block;
  margin: 0 0 2em;
  line-height: 1.5em;
}
.text-label-box-title,
.text-label-box-value {
  display: inline;
  margin: 0;
  padding: 0;
  word-break: break-word;
}
.text-label-box-title {
  font-weight: bold;
  &:after {
    content:":";
    margin-right: .25em;
  }
}
.text-label-box-value {
  font-weight: normal;
}

.app-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  padding-bottom: 352px;
  z-index: 99;
  background: #ffffff;
}

.text-bar {padding:6px 12px; border-radius:4px}
  .bg-warning {background-color:#FFF8E0}