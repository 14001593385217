.nav-primary {
  //padding: 60px 0 10px; // @deprecated via lktoepgu-1229
  padding: 0 0 10px;
}
.nav-primary-wrapper {
  text-align: center;
}
.nav-primary-title {
  font-size: @font-size-h1;
  font-family: @font-family-bolder;
  font-weight: normal;
  padding:0;
  margin: 0 0 65px;
}
.nav-primary-title--no-margin {
  margin: 0;
}
.nav-primary-list {
  line-height: 3em;
}
.nav-primary-link {
  @m : 4%;

  white-space: nowrap;
  font-size: @font-size-h3;
  text-decoration: none !important;
  border-bottom: 1px dashed;
  margin:0 @m;

  &:first-child{margin-left:@m/2}
  &:last-child{margin-left:@m/2}
}

.nav {
  .nav-vertical-level1 {
    font-size: 16px;
    padding-bottom: 10px;
    a {
      border-bottom: 1px dashed #0173c1;
      &:hover {
        border-bottom: 1px dashed #1a88d7;
        text-decoration: none;
      }
    }
    &.active {
      a {
        border-bottom: 0;
        color: #333;
      }
    }
  }
  .nav-vertical-level2 {
    font-size: 14px;
    padding-bottom: 10px;
    padding-left: 20px;
    position: relative;
    /*&:before {
        content: "";
        position: absolute;
        vertical-align: top;
        line-height: inherit;
        margin-top: 5px;
        left: 0;
        display: block;
        overflow: hidden;
        height: 0;
        width: 0;
        border: 4px solid #0173c1;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
    }*/
    &.active {
      &:before {
        border-left-color: #333;
      }
      a {
        color: #333;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (min-width: 960px) and (max-width: 1340px) {
  .nav-primary-link {
    margin-left: 2%;
    margin-right:2%;
    font-size: 23px;
  }
}
@media only screen and (max-width: 960px) {
  .nav-primary-link {
    margin-left: 1.5%;
    margin-right:1.5%;
    font-size: 22px;
  }
}