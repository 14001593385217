html, body {
  height:100%;
}

.app {
  height:100%;
}
.app-inner {
  position: relative;
  min-height: 100%;
  padding-bottom: 88px; // footer height
  min-width: @wrapper-min-width;

  padding-top: 50px; // @see lktoepgu-1348 // gosbar height fix // TODO : dynamic height from gosbar iframe height ?
}
.app-inner__custom {
  padding-top:0;
}

.app__loading {
  .b-section__content,
  .b-section__header {
    opacity: 0;
  }
}

.browserupgrade {
  display: block;
  padding: 1% 2%;
  text-align: center;
  &-info {
    font-size: 18px;
    text-align: left;
  }
}