.responsive__screen_small {
  body {
    width: 100%;
    min-width: 100%;
  }
  .section-fixate__fixed .section-fixate-inner {
    min-width: 100%;
    max-width: 100%;
  }
  .wrapper {
    min-width: 100%;
    max-width: 100%;
  }
  .b-header-logo {
    width: 220px;
  }
  .g-tile-title {
    font-size: 16px;
  }
  .g-tile-content {
    font-size: 14px;
    line-height: 16px;
  }
}