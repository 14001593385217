@search-filter-category-item-padd-left : 40px;
@search-filter-category-padd-extra: 85px;

.b-search-filters-categories {
  border-top: 1px solid @block-border-color;
  padding: 0;
}
.b-search-filters-categories-list {
  padding: 20px 0 0;
  .clearfix();
}
.b-search-filters-category-item {
  position: relative;
  padding:
      @padding-base-vertical
      0
      @padding-base-vertical
      (@search-filter-category-item-padd-left + @search-filter-category-padd-extra);
  width:50%;
  float: left;
}
.b-search-filters-category-link {
  margin:0;
  padding:0;
  text-decoration: none !important;
  font-size: @font-size-middle;

  &-text {

  }
  &-icon {
    display: block;
    position: absolute;
    width: 25px;
    height:25px;
    left: @search-filter-category-padd-extra;
    top: 11px;
    transform:         rotate(90deg);
    //transform:         rotate(0deg);

    &:before,
    &:after {
      .fa-icon();
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
    }
    &:before {
      content: @fa-var-circle-thin;
      font-size: 200%;
    }
    &:after {
      content: @fa-var-angle-right;
    }
  }

}

.b-search-filters-categories-title {
  font-weight: bold;
  font-family: @font-family-bold;
  margin: 0;
  padding: 20px 0 0;

  & + .b-search-filters-categories-list {
    .b-search-filters-category-item {
      padding-left: @search-filter-category-item-padd-left;
    }
    .b-search-filters-category-link-icon {
      left:0;
    }
  }
}