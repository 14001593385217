.b-section {
  position: relative;
}
.b-section__content {
  @padd : 60px;
  min-height: 250px + @padd*2;
  padding:@padd 0;
}

.b-section-common {
  margin-top: 60px;
}

.b-section-common--mid {
  margin-top: 30px;
}

.b-page-section {
  .clearfix();
  padding: 0 0 45px;
  & + & {
    padding-top: 55px;
    border-top: @blockquote-border-color;
  }
}
.b-page-section-title {
  font-size: @font-size-h3;
  font-family: @font-family-medium;
  margin: 0 0 45px;
  padding: 0;
}
.b-page-section-content {

}
.b-page-section-item {
  margin-top: 25px;
}
.b-page-section-item-title {
  font-weight: bold;
  margin: 0 0 .25em;
}
.b-page-section-item-data {
  margin:0;
  padding:0;
  line-height: @line-height-large;
}