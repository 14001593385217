@auth-warn-search-panel-height : 110px; // TOOD : javascript dynamic ?

.b-auth-warn {
  background-color: #fff;//@lead-color;
  //padding: 40px 0 175px;

  height:400px + @auth-warn-search-panel-height;//525px;
}
.b-auth-warn-bg {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  //background-size: cover;
  top:0;
  bottom:0;
  left: 0;
  right:0;
  position: absolute;
  display: block;
}

.b-auth-warn-slider {
  // do not specify height for slider! IE11 will crash on unload
  // @see TUMENEKTO-5059
  height:100%;
}
//.b-auth-warn-slider__recalc {
//  height: auto !important;
//  .b-auth-warn-slide {
//    display: block !important;
//    visibility: hidden !important;
//  }
//}
.b-auth-warn-wrapper {
  padding: 0 20px;
  position: relative;
}
.b-auth-warn-slide {
  position: absolute;
  left:0;
  top:0;
  right:0;
  bottom:0;
  opacity: 0;
  z-index: 10;
  transition: opacity 1s;
  color: @text-color;
  text-decoration: none;

  &__visible {
    display: block;
    z-index: 11;
    opacity: 1;
  }
}

.b-auth-warn-slide-link {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  text-decoration: none;
  color: @text-color;
  outline: 0 none;

  &:hover {
    text-decoration: none;
  }
}

.b-auth-warn-inner {
  height: ~"calc(100% - @{auth-warn-search-panel-height})";
  max-width: 655px;
  padding: 40px 65px 40px 65px;
  position: relative;
  display: table;
  z-index: 15;

  &__actions {
    background: rgba(255,255,255,.5);
    position: absolute;
    right:25px;
    top:50%;
    height: auto;
    padding:30px;
    transform: translateY(-50%);
    margin-top: -@auth-warn-search-panel-height / 2;
    width: auto;
  }
}
.b-auth-warn-text {
  display: table-cell;
  vertical-align: middle;
  color: #415862;
  text-shadow: 1px 1px 0 #fff;
  font-family: @font-family-bolder;
  font-weight: normal;
  line-height: 1.5em;
  font-size:36px;

  b, strong {
    font-family: @font-family-medium;
    font-weight: bold;
  }
}
.b-auth-warn-actions-text {
  font-family: @font-family-bolder;
  //font-weight: bold;
  color: @gray;
  font-size: @font-size-h3;
  line-height: 24px;
  text-align: center;
  padding-top: 100px;
  background: url("@{path-images}gu.png") center top no-repeat transparent;
}
.b-auth-btn {
  width:275px;
  text-align: center;
  word-break: break-all;
  display: block;
  margin-top: 15px;
}
.b-auth-btn__sign_up {
  color: #565656;
  //background: #fff;
  //border-color: #cdd1d4;
}

.b-auth-warn-slider-control {
  display: block;
  position: absolute;
  top: 20px;
  left: 14px;
  width: 30px;
  height: 39px;
  line-height: 39px;
  text-align: center;
  background: #fbfbfb;
  cursor: pointer;
  z-index: 15;
}

.b-auth-warn-slider-control--right {
  left: 46px;
}