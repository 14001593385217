
// TODO

@radio-checkbox-bg : #6ca9d2;

.radio-checkbox(@border-radius, @size : 20px) {
  margin-left: 0;
  opacity: 0;
  & + label > span:first-child:last-child {
    display: inline-block;
    margin-left: @padding-base-horizontal/2;
  }
  & + label:before {
    border-radius: @border-radius;
    content:"";
    width:  @size;
    height: @size;
    float:left;
    background: transparent center center no-repeat;
    background-size: 100% 100%;
    border: 1px solid @input-border;
    margin: 0 0 0 -@padding-base-horizontal;
    position: relative;
  }
  &:hover + label:before ,
  &:focus + label:before {
    border-color: @input-border-focus;
  }
  &:focus + label:before {
    box-shadow: 0 0 0 4px @input-border-focus;
  }
  &:checked + label:before {
    background: #fff;
    box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 6px @radio-checkbox-bg;
  }
}

.radio input[type="radio"] {
  .radio-checkbox(100%);

  &:focus + label:before {
    border-color: @input-border;
    box-shadow: none;
  }

  &:checked + label:before {
    background: #fff;
    box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 6px @radio-checkbox-bg;
  }
}
.checkbox input[type="checkbox"] {
  .radio-checkbox(@border-radius-large);

  &:checked + label{
    &:before {
      background: @radio-checkbox-bg;
      box-shadow: none;
    }
    &:after {
      .fa-icon();
      content: @fa-var-check;
      position: absolute;
      top: 3px;
      left:3px;
      color: #fff;
      font-size: @fa-font-size-base;
    }
  }
  &[readonly],
  &:disabled {
    pointer-events: none;
    display: none;
  }
  &[readonly] + label,
  &:disabled + label{
    &:before {
      background: @gray-middle;
      box-shadow: none;//inset 0 0 0 1px #fff;
      pointer-events: none;
    }
  }

}

.attr-field--focused {
  input[type="checkbox"]:checked,
  input[type="radio"]:checked {
    & + label:before {
      box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 6px @radio-checkbox-bg, 0 0 0 4px @input-border-focus;
    }
  }
}

.b-elem-top-static-label {
  font-size: 15px;
  line-height: 22px;
  color: #a6aeb3;
  font-family: Arial, sans-serif;
  display: block;
  margin: 0 0 8px 0;
  font-weight: normal;
}