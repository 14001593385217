@modal-footer-btn-margin: 0;
@modal-column-right-width:322px;

.page {
  .clearfix();
  &.process{position: absolute;left:-999999px;} // megapuperhack (:
}

.modal-loader {
  background: url('@{path-images}loader-small.gif') center center no-repeat;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: ~"-1";
}
.fade.in .modal-loader {
  opacity: 1;
}
.ready.in .modal-loader {
  opacity: 0 !important;
}

.modal-dialog,
.modal-columns {
  /*.modal-columns used to separate .modal-dialog from modal side bar (modal actions & etc)*/
  position: relative;
  width: 100%;
  margin: 0 auto;

  .modal & {
    max-width: 1000px;
    margin: 80px auto;
  }
}
.modal-sm .modal-dialog {
  width: auto;
  max-width: 450px;
}
.modal-dialog--linkedobj {
  max-width: 600px;

  .collapser {
    display: none;
  }
  .subgroup-title {
    cursor: initial;
  }

  .attr-label--inline + .attr-value,
  .attr-label--inline {
    display: block;
    width:100%;
    //padding:0;
  }

  .attr-value .docsbord {
    /*margin:0;*/
    margin: 10px 0 20px;
    width:100%;
  }

  .subgroup-holder,
  .subgroups-list {
    padding-left:0;
    padding-right:0;
    margin-left:0;
    margin-right:0;
    padding-bottom: 0;
    box-shadow: none;
    border: none;
  }
  .subgroups-list {
    margin-top:40px;
    border-top:1px solid rgba(0,0,0,.15);
    padding-top: 20px;
  }

  .subgroup-holder-outer{
    padding-top: 0;
    padding-left: 0;
  }

  .subgroups-list:first-of-type {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }

}
//.modal .modal-dialog--linkedobj .close2 {
//  margin-right: -350px;
//}

//.modal--agreement .modal-header,
//.modal--article .modal-header {background-color: transparent}
//.modal--article .modal-dialog {max-width: 945px;}

.modal {

  .modal-dialog {
    max-width: 700px;
    min-width: 550px;
    width: 75%;

    background-color: #fff;
    border: 0;//1px solid rgba(0,0,0,.15);
    border-radius: @border-radius-base;
    padding: 75px 55px 40px;
  }
  &--alert {
    .modal-dialog {
      max-width: 550px;
    }

    &-wide {
      .modal-dialog {
        max-width: 620px;
      }
    }

    .modal-body {
      color: @lead-text-color;
    }

    &-draft-restore {
      .modal-dialog {
        max-width: 600px;

        .modal-title {
          text-align: left;
        }
      }
    }
  }

  .modal-header {
    padding: 0;//60px 50px 0;
    margin: 0 auto;
    text-align: left;//center;
  }

  .modal-title {
    text-align: left;//center;
    font-size: 24px;
    line-height: 1.3em;
    font-family:@font-family-medium;
    font-weight: 900;
  }
  .modal-body {
    padding: 35px 0;
  }
  .modal-footer {
    text-align: left;
    padding: 35px 0 0;
    .btn {
      min-width: 30%;//45%;
      margin: @modal-footer-btn-margin 0;
      & + & {
        margin-left: 5%;
      }
      //&:last-child&:nth-child(2) {
      //  float:right;
      //}
    }
  }
}

.modal--service .modal-body {
  min-height:6em;
}

.modal-body > .alert:first-child:last-child {
  margin-bottom: 0;
}

.modal-columns .modal-dialog {
  float: left;
  width: 100%;
  margin: 0;
  margin-right: -@modal-column-right-width;
  padding-right: @modal-column-right-width;
}
.modal-columns .modal-actions {
  width:@modal-column-right-width;/**/
  padding-left: 42px;
  float:right;
  position:relative;
  z-index:+1;
}

.modal-actions {
  .btn {
    width: 100%;
  }
  .btn + .btn {
    margin-top: 10px;
  }
}

.modal-content {
  position: relative;
  outline: none;
  max-width: 900px;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  color: @text-color;
}
.modal-backdrop.loader + .modal-backdrop.loader,
.modal-backdrop.loader ~ .modal-backdrop.loader {
  display: none; /* only single loader visible at time */
}
.modal-backdrop.loader {
  z-index: 9999;
  background-color: #fff;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.in {
  opacity: .55;
}
.modal-backdrop.loader.in {
  opacity: .85;
}
.modal-header {
  .clearfix();
  display: block;
  position:relative;
  //border-bottom: 1px solid @modal-block-border-color;
  padding: 60px 120px 0 0;
  margin: 0 0 45px;
  font-size: 34px;
  font-family: @font-family-bolder;
  font-weight: normal;

  &--danger {
    //background: #ff5145;
    //color: #fff;
  }
  &--warning {
    //background:#e4c773;
    //color: #fff;
  }
}

.modal-header--no-pad {
  padding-top: 0;
}

.modal-header--no-all-pad {
  padding-top: 0;
  padding-right: 0;
}

.modal-header__bordered {
  margin-bottom: 30px;
  border-bottom: 1px solid @block-border-color !important;
  padding-bottom: 40px;
}
.modal-header__no-icon {
  padding-left : 0;
}

.modal--service {
  .modal-header {
    border:0;
    margin-bottom: 30px;
  }
}
.modal--department {
  .modal-header {
    padding-bottom: 15px;
    margin-bottom: 30px;
  }
}

.modal-header-icon {
  @size : 70px;
  width:  @size;
  height: @size;
  position: absolute;
  top: 0;
  left: 0;
  &-svg {
    width: 100%;
    height: 100%;

    fill: @brand-secondary;
    &--equeue {fill: @brand-primary;}
  }
  &-pelmen {
    @icon : '@{path-images}pelmen-icon.png';
    @icon-width : image-width(@icon);
    @icon-height : image-height(@icon);
    width: @icon-width;
    height: @icon-height;
    position: absolute;
    left: -(@icon-width - @size) / 2;
    top: -24px;//-(@icon-width - @size);
    background: url('@{icon}') 0 0 no-repeat;
    background-size: @icon-width @icon-height;
  }
}

.modal-header-filters {
  display: block;
  margin: 37px 0 -5px;
}
.modal-header-filter {
  display: inline-block;
}
.modal-header-filter-spacer {
  width:27px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.modal-header-filter-label {
  font-weight: inherit;
  font-family: inherit;
  font-size: inherit;
}

.modal-header-actions {
  display: block;
  padding: 40px 0 0;
}

.modal-header-actions .btn--submit {
  float: right;
}

.modal-header-actions .g-action-link--add {
  float: left;
}


.modal-sm .modal-header:after {
  /* used for signin, signup, lostpwd  */
  width:160px;
  height:1px;
  display:block;
  margin:15px auto 0 auto;
  background:#BEBEBE;
  /*background:rgba(0,0,0,.05); */
  content:''
}
.modal-title,
.modal-subtitle {
  display: block;
  text-align:left;
  font-weight: normal;
}
.modal-title {
  margin: 0;
  line-height:46px;
  font-size: 40px;
  font-family: @font-family-ekb-light;
  font-weight: normal;
  .modal-subtitle {
    margin-top: 7px;
  }
  ._placeholder+._placeholder {margin-top:10px}
}
.modal-title-inner {
  min-height: 70px;
  display: table;
  width: 100%;
}
.modal-title-inner-text {
  display: table-cell;
  vertical-align: middle;
  width:100%;
}
.modal-subtitle {
  font-family: @font-family-bolder;
  font-size: @font-size-h3*0.835;
  line-height:@font-size-h1; // same as .modal-title
  margin: 0;
  padding:0;
}
.modal-datetime {
  height:28px;
  line-height:28px;
  margin-top:7px;/*.modal-title > .line margin*/
  white-space: nowrap;
  color: #fff;
  padding-left:38px;
  /*background: url('../../images/sunshine/ico/clock-white.png') 0 50% no-repeat;*/
  //font-family: 'Open Sans Light';
  font-size: 18px;
  text-transform: uppercase;
  display: block;
  float:right;
}
.modal-sm .modal-title {
  line-height: 1.42857143; /* ??? */
  text-align:center;
  max-width:100%;
}

.modal-body {
  position: relative;
  padding-top: 30px;
  //.modal & {  padding: 35px 50px;  }
  .modal-sm & {  padding: 20px 70px;  }
}

.modal-body--not-padded {
  padding: 0;
}

.modal-nav {
  margin-bottom: 55px;
}

.modal-nav__stretch {
  display: table;
  width: 100%;
  table-layout: fixed;

  &:before,
  &:after {
    content: initial !important;
    display: none !important;
    clear:none !important;
  }

  &>li {
    display: table-cell;
    &>a {
      padding-left:16px;
      padding-right:16px;
      text-align: center;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.modal-filter-bar {
  .clearfix();
  margin:0 0 13px;//25px;
  width:100%;
}

.modal-body-headcrumbs {
  margin: 35px 0 0;

  &:first-child {
    margin: 0;
  }
}

.modal-body-headcrumbs-actions {
  .btn {
    text-align: center;
    font-size: 17px;
    margin-top: 15px;
    .fa {
      margin-left: 14px;
      font-weight: bold;
    }
  }
}

.modal-body-headcrumbs-layout {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.modal-body-headcrumbs-col {
  @btn-width : 165px;

  display: table-cell;
  vertical-align: middle;
  &__btn {
    width:@btn-width;
    vertical-align: top;
    padding-top: 20px;
    .btn {
      max-width: @btn-width;
    }
  }
  &__title {
    line-height: 1.3em;
    &:first-child {
      padding-left: 41px;
    }
  }
}
.modal-body-headcrumbs-title {
  font-weight: normal;
  font-family: @font-family-ekb-bold;
  font-size: 24px;
  line-height: 27px;
  margin: 0;
  padding:0;
  width: 100%;
  //overflow: hidden;
  //white-space: nowrap;
  //text-overflow: ellipsis;
}

.modal-body-content {
  & > .group-holder {
    padding-left : 0;
  }
}
.modal-body-content-title ,
.modal-body-content-text {
  color: #4d4d4d;
}
.modal-body-content-title {
  //font-size:18px;
  text-transform: none;
  //line-height: 24px;
  //font-family: 'Open Sans';
  font-weight: bold;
  margin: 30px 0;
}
.modal-body-content-title {
}
.modal-body-content-title:first-letter {
  text-transform: uppercase;/*@see MSMEV-4047*/
}
.modal-body-content-text {
  line-height: 20px;
}
.modal-body-actions {
  overflow: auto;/*clearfix*/
  width: 100%;
  .btn-action--prev { float:left; }
  .btn-action--next { float:right; }
}
.modal-body-actions--layout {
  display: table;
}
.modal-body-actions-cell {
  display:table-cell;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;

  &--middle {
    padding: 0 1em;
    width: 100%;
  }
  &--left {
    text-align: left;
  }
  &--right {
    text-align: right;
  }

  .btn {
    //padding: 9px 27px;
    min-width: 125px;

    & + .btn {
      margin-left: 15px;
    }
  }
}

//.modal-body-actions .btn-inner-text:after,
//.modal-body-actions .btn-inner-text:before {
//  width:9px;
//  height: 13px;
//  display: inline-block;
//  background-repeat: no-repeat;
//  background-position: 0 0;
//  vertical-align: top;
//  margin-top: 3px;
//}

.modal-body-header {
  display: block;
  width: 100%;
}

.modal-body-footer {
  //background: #f2f2f2;
  //border-top: 1px solid #e6e6e6;
  //border-top: 1px solid rgba(0,0,0,.05);
  border-top: 1px solid @modal-content-border-color;
  padding: 45px 0 0;

  z-index: +1;
  position: relative; //absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.modal-body-footer--one-col {
  max-width: 580px;
  padding: 28px 0 0;
}
.modal-body-footer--static {
  position: relative;
}
.modal-body-footer-msg {
  display: inline-block;
  margin: 0 auto;
  color: #ff5145;
  /*font-size: 80%;*/
  text-align:center;
  text-transform: uppercase;
  line-height: 1.25em;
  padding: 7px;
}
.modal-body-columns {
  position: relative;
}

.modal-body-columns--max {
  max-width: 1050px;
}

.modal-body-columns--layout {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.modal-body-column--sidebar,
.modal-body-column--content {
  display: table-cell;
  vertical-align: top;
  position: relative;
  padding-bottom:100px;

  .modal-body {
    opacity: 1;
    transition: opacity .4s;
  }
}

.modal-body-content--loading {

  .modal-body {
    opacity: 0;
  }

  &:after {
    background: url('@{path-images}loader-big.gif') no-repeat scroll 50% 200px transparent;
    display: block;
    min-height: 300px;
    padding-top: 120px;
    text-align: center;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.modal-body-column--content--fixed-max {
  max-width: 1060px;
}

.modal-body-column--content-one-col {
  display: block;
  padding-bottom: 100px;
  max-width: 580px;
}

.modal-body-column--content-one-col-not-max {
  display: block;
  padding-bottom: 100px;
}

.modal-body-column--content {
  /*float:right;*/
  /*margin-left:-260px;*/
  /*padding-left: 260px;*/
  /*position: relative;*/

  width:100%;
  /*z-index: +1;*/
}
.modal-body-column--sidebar {
  //background: #f2f2f2;
  width:215px;
  /*position: absolute;*/
  /*left:0;*/
  /*top:0;*/
  /*bottom:0;*/
  z-index: +2;
  //padding-top: 25px;
}

.modal-body-info {
  font-size:105%;
  margin-top:-40px;
  margin-bottom: 40px;
}
.mbi-date,
.mbi-time,
.mbi-separator,
.mbi-category,
.mbi-author {
  display: inline-block;
}

.mbi-category,
.mbi-author,
.mbi-category:hover,
.mbi-author:hover {
  color: @link-hover-color;
}

.mbi-date {  }
.mbi-time {  margin: 0 .5em;  }
.mbi-separator {  margin: 0 .5em; }
.mbi-category {  }
.mbi-author {  }

.modal-sm .modal-body > form {
  padding-top:10px;
}
.modal-footer {
  //padding: 50px 0 0;
  padding: 19px 50px 20px 50px;
  border-top: 1px solid @modal-block-border-color;
  //border-bottom: 1px solid #BEBEBE;
  //background:#f5f5f5;
  .modal & {

  }
  .or {
    display: inline-block;
    text-transform: lowercase;
    vertical-align: top;
    margin: @modal-footer-btn-margin .5em;
    & + .btn {
      margin-left: -@padding-base-horizontal;
    }
  }
}
.modal-footer__no-border {
  padding-top: 60px;
  border-top:0;
}
.modal-footer__no-side-padding {
  padding-left:0;
  padding-right:0;
}
//.modal-footer:last-child {
//  border-bottom: 0;
//}
.modal-sm .modal-footer {
  padding-bottom: 20px;
  text-align: right;
}
.modal-header + .modal-footer {
  //background: #fff;
}
.modal-footer--no-border,/* select best option for different modal views (with footer border and without) */
article.modal-body + .modal-footer { /**/
  border-top:0;
}
.modal-footer .btn + .btn {
  //margin-bottom: 0;
  margin-left: 5%;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modal-footer a {
  color:#4d4d4d
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-dialog--ts-add {
  max-width: 600px;
  .modal-title {
    text-align: left;
  }
}

//.modal--text .modal-dialog {
//  max-width:780px;
//}
//.modal--text .close2 {
//  margin-right:-450px !important;
//}

.modal-close {
  display: block;
  position: absolute;
  width:40px;
  height:40px;
  top:20px;
  right:20px;
  border-radius: 100%;
  border:1px solid @input-border;
  background: #fff;

  &:after {
    .fa-icon();
    content:@fa-var-times;
    color: @lead-text-color;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display: block;
  }
}

.modal--alert-draft-restore {
  .modal-footer {
    white-space: nowrap;
    .btn {
      white-space: initial;
      word-break: break-word;
    }
  }
}

.ui-dialog {
  position: absolute;
  top: 0;
  left: 0;
  outline: 0;
  padding: 50px 55px 40px;
  border: 0 none;
  background: #ffffff;
  border-radius: 3px;

  .ui-dialog-titlebar {
    border: 0 none;
    background: transparent;
    padding: 0;
    margin: 0 0 20px 0;
  }

  .ui-dialog-title {
    text-align: left;//center;
    font-size: 24px;
    line-height: 1.3em;
    font-family:@font-family-medium;
    font-weight: 900;
    padding-right:50px;
    display:block
  }


  .ui-dialog-titlebar-close {
    display: block;
    position: absolute;
    width:40px;
    height:40px;
    top:12px;
    right:12px;
    border-radius: 100%;
    border:1px solid @input-border;
    background: #fff;
    font-size: 0;

    span {
      display: none;
    }

    &:after {
      .fa-icon();
      content:@fa-var-times;
      color: @lead-text-color;
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      display: block;
      font-size: 16px;
      margin: -1px 0 0 1px;
    }
  }

  .ui-dialog-content {
    border: 0 none;
    padding: 0;
  }

  .modal-body {
    padding: 0;
  }

}

.modal-page-content-wrapper {
  display: block;
  max-width: 900px;
  position: relative;
  margin: 0 auto;
}

.modal-page-content-wrapper--wide {
  max-width: 1160px;
}

.modal-page-content-wrapper--grey {
  background: #f6f6f6;
  padding: 70px 0;
  margin: 70px auto;

  &:before, &:after {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    background: #f6f6f6;
    left: 100%;
    right: -9999px;
    content: "";
  }

  &:after {
    right: 100%;
    left: -9999px;
  }
}

.modal-page-content-wrapper-inner-mid {
  display: block;
  max-width: 900px;
  position: relative;
  margin: 0;
}

.modal-dialog__location_choose {
  .jq-selectbox {
    z-index: auto !important;
  }
}