.eq-office-info,
.equeue-user-input-data {
  //color: @text-color;
}
.equeue-user-input-data {
  line-height:1.75em;
}

.equeue-list-holder {
  //min-height:402px;/* .filter_wrapper height */
}
.equeue-map-wrapper {
  width: 100%;
  height: 402px; /* .equeue-list-holder height */
}
.equeue-map {
  background: #fff;
  position: absolute;
  top: 0;
  left: -1px;
  bottom: 0;
  right: -1px;
}
.equeue-map-empty-msg {
  position: absolute;
  top: 50%;
  left:50%;
  margin-left: -150px;
  width:300px;
  margin-top: -40px;
  background: #fff;
  padding: 20px;
  z-index: +2;

  box-shadow: 0 10px 30px rgba(0,0,0,.125);
}
.equeue-map > .eq-map {
  position: relative;
  width:100%;
  height:100%;
}
.equeue-map .search_block {
  width:auto;
  left: 270px;
  right: 20px;
  top: 20px;
}
.equeue-map .filter_block {
  padding-left:0;
}
.equeue-map .filter_block .filter_icon {
  display: none;
}
.equeue-map .filter_wrapper {
  left:0;
  top: 0;
}

.eq-office-info {
  width: 100%;
  table-layout: fixed;
  font-weight: normal;
}

.eq-office-info td {
  vertical-align: top;
}

.eq-office-info-icon {
  width: 15px;
  display: inline-block;
  vertical-align: top;
  float: left; /* collapse space after icon */
}

.eq-office-title {
  margin-left: @padding-base-horizontal/2;
  display: inline-block;
  vertical-align: top;
  &__label {
    font-weight: normal;
  }
}

.eq-office-info-text {
  display: block;
}

.eq-office-info-icon + .eq-office-info-text {
  display: inline-block;
  margin-left: 8px;
  width: ~"calc(100% - 27px)"; /* icon width + margin */
}

.eq-office-info-label {
  position: relative;
  padding-top: 15px;
}
.eq-office-info-additional {
  font-size:12px;
  line-height: 14px;
}
//.modal--equeue .sr_item .l,
//.eq-office-info td:first-child {
//  width: 120px;
//}

.modal--equeue {
  .sr_item .l {display: inline-block;}

  .eq-sr-usluga,
  .eq-sr-service {
    font-weight: normal;
    font-family: @font-family-medium;
    font-size: @font-size-middle;
  }
}