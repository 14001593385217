.grid {
  width: 100%;
  max-width: 100%;
  margin-bottom: @line-height-computed;

  .grid-header {
    font-weight: bold;
  }
  table {
    width: 100%!important;
  }
  .grid-row {
   // .clearfix();

    border-bottom: 1px solid @table-border-color;
    &:last-child {
      border-bottom: 0;
    }
    &.row-list {
      border-bottom: 0;
    }
  }

  // Cells
  .grid-header,
  .grid-body,
  .grid-footer {
      .grid-col {
        padding: @table-cell-padding @table-cell-padding/2;
        line-height: @line-height-base;
        vertical-align: top;


        &--status {
          line-height: @status-icon-size;
          a {
            word-break: break-all;
          }
        }
      }
  }

  // Bottom align for column headings
  .grid-header .grid-row {
    vertical-align: bottom;
    border-bottom: 4px solid @table-border-color;
  }
  // Remove top border from thead by default
  .grid-header:first-child {
    .grid-row:first-child {
      .grid-col {
        border-top: 0;
      }
    }
  }

  // Account for multiple tbody instances
  .grid-body + .grid-body {
    border-top: 4px solid @table-border-color;
  }

  // Nesting
  .grid {
    background-color: @body-bg;
  }
}

.grid-col-action-link--refresh {
  @c : @brand-primary;
  color: @c;
  text-decoration: none !important;
  &:hover {
    color: lighten(@c, 20%);
  }

  display: inline-block;
  //display: none;
  opacity: 0;
  &__visible,
  .grid-row:hover & {
    //display: inline-block;
    opacity:1;
  }

  &:after {
    .fa-icon();
    content: "@{fa-var-refresh}";
    font-size: 18px;
    line-height: 1em;
  }
}

.grid-col-remove-link {
  @c : @brand-critical;
  color: @c;
  text-decoration: none !important;
  font-size: 24px;
  line-height: 1em;
  &:hover {
    color: lighten(@c, 20%);
  }

  display: inline-block;
  //display: none;
  opacity: 0;
  &__visible,
  .grid-row:hover & {
    //display: inline-block;
    opacity:1;
  }
}

.grid-col {
  &--id,
  &--status {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &--info {
    word-break: break-word;
  }
  &--number,
  &--float {
    text-align: right;
  }
  &--num {
    font-weight: bold;
    white-space: normal;
    word-break: break-word;
  }
  > p:first-child {
    margin-top:0;
  }
}

.grid-header-col {
}

.grid-header-col-inner {
  @padd : @padding-base-vertical;
  padding: @padd @padd;
  margin: -@padd -@padd;
  //margin-left: 0;
  //margin-right: -@padd*2;
  //margin-top:-@padd;
  //margin-bottom:-@padd;

  display: inline-block;
  background: transparent;
  border-radius: @border-radius-base;

  font-weight: normal;
}

.grid-header-col__sort {
  cursor: pointer;

  .grid-header-col-text {
    text-decoration: none;
    color: @brand-primary;
    border-bottom: 1px dashed;
    //display: inline-block;
    vertical-align: top;
    //padding-right: 1.5em;
    font-weight: normal;

    &:after {
      .fa-icon();
      content: @fa-var-angle-down;
      //position: relative;
      display: none;
      vertical-align: middle;
      font-size: 1.25em;
      margin-left: .25em;
      //left: 1.5em;
    }
  }
}
.grid-header-col__sort__asc, .sorting_asc {
  .grid-header-col-text:after {
    content: @fa-var-angle-up;
    display: inline-block;
    border-bottom: 0;
    position: absolute;
    font-size: 18px;
    color: #000;
  }
}
.grid-header-col__sort__desc, .sorting_desc {
  .grid-header-col-text:after {
    content: @fa-var-angle-down;
    display: inline-block;
    border-bottom: 0;
    position: absolute;
    font-size: 18px;
    color: #000;
  }
}
.grid-header-col__sort__active {
  //.grid-header-col-inner {
  //  background-color: @btn-active-bg;
  //  border-color: @btn-active-bg;
  //  color: @btn-active-color;
  //}
  //.grid-header-col-text {
  //  color: inherit;
  //  border-bottom-color: transparent;
  //}
}

.grid-row-inner {
  .clearfix();
}

.grid-row--link {
  &:hover {
    cursor: pointer;
    background-color: @lead-color;
  }
}

.grid-row.loading {
  // TODO
}

.grid-row--remove {
  background-color: fade(@brand-critical, 10%) !important;
}

.table-header {
  .table-action {
    display: none;
  }
}

.grid-holder,
.table-holder {
  width:100%;
  display: block;
  overflow: auto;
}
.grid-container,
.table-container {
  .table {
    //margin-bottom: 0;
  }
}
.table {
}

table._fixed {width:100%; table-layout: fixed}

.grid-actions,
.table-actions {
  padding:20px 0;

  .table-holder + & {
    border-top: 1px solid @table-border-color;
  }
}

.grid-message-empty,
.table-message-empty {
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  color: @gray-light;
  padding: 20px 0;
}

.table-action {
  @size : 20px;
  width: @size;
  height:@size;
  overflow: hidden;
  position: relative;
  color: transparent;
  display: inline-block;
  text-align: center;
  cursor: pointer;

  &:after {
    .fa-icon();
    font-size: @size;
    line-height: 1em;
    display: inline-block;
    text-decoration: none !important;
    position: absolute;
    top:0;
    left:0;
  }

  &--edit {
    &:after {
      content:@fa-var-pencil;
      color: @link-color;
    }
    &:hover:after {
      color: lighten(@link-hover-color, 20%);
    }
  }
  &--remove {
    @c : #CF011A;//@brand-critical;
    &:after {
      content: "\00d7";
      color: @c;
    }
    &:hover:after {
      color: lighten(@c, 20%);
    }
  }
}
.table-filter--right {
  background: @table-bg-hover;
  padding: 20px;
  margin-top: 60px;

  strong {
    display: block;
    padding-bottom: 20px;
  }
  .jq-selectbox-wrapper {
    margin-bottom: 25px;
  }
}
.table-filter--top {
  background: @table-bg-hover;
  padding: 10px 0;
  margin-top: 0;
  margin-bottom: 25px;

  strong {
    display: block;
  }
  .jq-selectbox-wrapper {
    margin-bottom: 15px;
  }
}

.tb__list {
  border: 0 none;
  background: transparent;
  font-family: Arial, sans-serif;

  thead {
    th {
      vertical-align: middle;
      border-bottom: 4px solid #c8c7cc;
      padding: 20px;
      line-height: 1.42857143;
      font-weight: 700;
      outline: 0 none;

      &.sorting, &.sorting_asc, &.sorting_desc, &.sorting_fake {
        color: #0173c1;
        font-weight: 200;
      }

      &.sorting, &.sorting_asc, &.sorting_desc {
        cursor: pointer;
      }

      &.sorting_asc:after, &.sorting_desc:after {
        .fa-icon();
        content: @fa-var-angle-up;
        margin: 0 0 0 3px;
        color: #4c4c4c;
      }

      &.sorting_desc:after {
        content: @fa-var-angle-down;
      }
    }
  }

  tbody {
    td {
      //padding: 20px; //our
      padding:20px 0 20px 20px; //client
      line-height: 1.42857143;
      vertical-align: top;
      border-top: 1px solid #c8c7cc;
      outline: 0 none;
    }

    tr:nth-of-type(even) {
      background-color: #f9f9f9;
    }

    tr.silver {
      background:#d1e5ef;
    }
  }
}

.tb__list_row {
  td {
    transition: background 1.5s ease;
  }
  &.new td {
    background: @alert-success-bg;
  }
}

.tb__list--not-colored {
  tbody {

    tr:nth-of-type(even) {
      background: transparent;
    }
  }
}

.tb__list--condensed {

  thead {
    th {
      padding: 15px;
    }
  }

  tbody {
    td {
      padding: 15px;
      color: #839199;
    }
  }

  tr {
    td:first-child, th:first-child {
      padding-left: 0;
    }
  }
}

.action {
  border-top: 1px solid #c8c7cc;
  padding: 20px 0;
  margin: 20px 0 0;
}

.action__item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 0;
  font-size: 23px;
  line-height: 25px;
  height: 25px;
}

.action__item_create {
  color: @brand-success;
  margin-right: 9px;
}

.action__item_edit {
  color: @brand-primary;
  line-height: 27px;
}

.action__item_del {
  color: @brand-danger;
}

.control-no-margin {
  &.radio, &.checkbox {
    margin-top: 0;
    margin-bottom: 0;
  }
}

tr._placeholder td:before, div._placeholder {display:block; content:' '; background:#C0C0C0; height:1em;animation: placeholder-glow 2s ease-in-out infinite;}

div._placeholder+._placeholder {margin-top:1em}

@keyframes placeholder-glow {
  50% {
    opacity: 0.3;
  }
}