.service-appeal-block {
  display:table;
  width:100%;
  & .service-appeal-block--data {
    display:table-row;
  }
}

.service-appeal-block--data {

  & .service-appeal--result,
  & .service-appeal--request {
    vertical-align:top;
    text-align:right;
    display:table-cell;
  }

  & .service-appeal--title {
    padding-right:30px;
    text-align:left;
  }
}


.service-appeal--title,
.service-appeal--result_success,
.service-appeal--result_fail {
  display: block;
}

.service-appeal--result_success {
   color: @brand-success;
}
.service-appeal--result_fail {
  color: @brand-danger;
}

.service-appeal-block + .note {
  text-align: right;
  padding-top:5px;
}