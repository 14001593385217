.b-footer {
  background-color: @panel-footer-bg;
  color: @panel-footer-color;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  .app-inner > & {
    position: absolute;
    bottom:0;
    left:0;
    right:0;
  }
}
.b-footer-wrapper {
  padding-top:30px;
  padding-bottom: 6px;
}
.b-footer-inner {
  .clearfix();
}
.b-footer-copy {
  border-top: 1px solid @header-drop-border-color;
  padding: 15px 0 15px 85px;
  background: url('@{path-images}logo-grey.png') left center no-repeat transparent;
  a {
    color: @gray;
  }
}
.b-footer-nav {
  ul,li {list-style: none;padding: 0;margin: 0;}
  &>ul {
    display: table;
    width: 100%;
    margin-bottom: 20px;
  }
  &>ul>li {
    display: table-cell;
    width: 25%;
  }
  p {
    padding: 0;margin: 0;
  }
  a {
    color: @gray;
  }
  .phone {
    text-decoration: none;
    font-size: @font-size-large;
    color: @gray-base;
    padding-bottom: 12px;
    font-weight: 500;
  }
  .elink {
    display: inline-block;
  }
  h1 {
    margin: 0 0 12px;
    padding: 0;
    font-weight: 500;
    font-size: @font-size-large;
    color: @gray-base;
  }

}
.b-footer-logo {
  @size : 44px;
  text-decoration: none !important;
  color: inherit !important;
  white-space: nowrap;

  float:left;
  max-width: 50%;

  font-size: @font-size-base;
  line-height: @line-height-computed;

  &-href {
    position: absolute;
    left:-9999px;
  }
  &-cont {
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
    width: @size;
    height:@size;

    margin: 0 13px;
  }
  &-img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left:0;
    top:0;
  }
  &-caption {
    display: inline-block;
    vertical-align: top;
    white-space: normal;

    max-width: 380px;
    padding: 3px 14px;
  }
}

.b-footer-contacts {
  float:right;
  max-width: 50%;
  padding: 6px 0;

  font-size: @font-size-middle;
  line-height: @line-height-computed;
}
.b-footer-sector {
  display: inline-block;
  vertical-align: top;

  & + & {
    margin-left:42px;
  }
}
.b-footer-phone-list {
  font-family: @font-family-bold;
  font-weight: bold;
  text-align: right;

  display: table;
  height: 2.5em;
  float: left;

  .b-footer-sector-inner {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
  }
}
.b-footer-phone {
  display: block;
}
.b-footer-link-list {

}
.b-footer-link {
  display: block;
  color: inherit;
  text-decoration: underline;
}