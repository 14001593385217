.content-body {
  .service__list{
    list-style: none;
    padding: 0;
    margin: 20px 0 30px;
    &:after {
      display: block;
      clear: both;
      content:'';
    }
    .dep_toggler {
      cursor: pointer;
      span {
        display: inline-block;
        height: 30px;
        width: 30px;
        border: 2px solid #ddd;
        border-radius: 100%;
        margin-left: 10px;
        position: relative;
        margin-bottom: -5px;
        &:after {
          display: block;
          content: "";
          border: solid #000;
          border-width: 0 2px 2px 0;
          padding: 5px;
          -ms-transform: rotate(225deg);
          transform: rotate(225deg);
          -webkit-transform: rotate(225deg);
          margin-bottom: -2px;
          position: absolute;
          top: 9px;
          left: 7px;
        }
      }
      &.opened {
        span {
          &:after {
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            top: 4px;
          }
        }
      }
    }
    .dep_toggle {
      display: none;
      &.opened {
        display: block;
      }
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      width: 33%;
      padding: 25px 75px 25px;
      margin: 0;
      display: inline-block;
      vertical-align: top;
      img {
        width: 80px;
        height: 80px;
      }
      .service__desc {
        padding: 0 0 25px;
        display: block;
        text-decoration: none !important;
        color: @gray-base;
        &:hover {
          text-decoration: none !important;
          color: @gray-base;
        }
      }
      &.service__list-dep {
        cursor: pointer;
        padding: 25px;
        &:hover {
          background: @state-info-bg;
        }
        img {
          width: 50px;
          height: 50px;
        }
        a.service__list_link {
          .service__name {
            font-size: 16px;
            color: @brand-primary;
            line-height: 20px;
          }
        }
      }
      a.service__list_link {
        text-decoration: none !important;
        .service__list_block {
          display: table;
          min-height: 70px;
        }
        .service__name {
          text-decoration: none !important;
          color: @gray-base;
          font-size: 22px;
          line-height: 24px;
          padding-left: 14px;
          &:hover {
            color: @link-hover-color;
            text-decoration: none !important;
          }
        }
        .service__name, .service__ico {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }

    .service__ext_block {
      padding: 25px 0 0;
      display: block;
    }
  }
}