//.service-procedure-container {
//  position: relative;
//
//  &.loading {
//    .service-procedure-row {
//      display: none;
//    }
//  }
//}
//.service-procedure-title {
//  margin:0;
//  padding:0;
//  text-decoration: none !important;
//  font-size: @font-size-middle;
//}
//.service-procedure-title:first-letter {
//  text-transform: uppercase;
//}
//.service-procedure-row {
//  //padding: 0 50px;
//  position: relative;
//  /*cursor: pointer;*/
//
//  &.loading {
//    .service-procedure-row-icon {
//      background: url('@{path-images}loader-small.gif') center center no-repeat;
//      //box-shadow: none;
//      &:before,
//      &:after {
//        display: none;
//      }
//    }
//  }
//}
//.service-procedure-row:first-child:hover .service-procedure-row-border,
//.service-procedure-row .service-procedure-row-border {
//  border-top: 1px solid rgba(154,154,154,.25);
//}
//.service-procedure-row:first-child .service-procedure-row-border {
//  border-top-color: transparent;
//}
//.service-procedure-row:first-child:last-child .service-procedure-row-border {
//  display: none;
//}
//.service-procedure-row-content {
//  padding: 12px 0 12px 40px;
//  position: relative;
//}
//.service-procedure-target-link {
//  /*border-bottom: 1px solid transparent;*/
//}
//.service-procedure-title.sp_target_link:hover .service-procedure-target-link {
//  /*border-bottom: 1px solid;*/
//  text-decoration: underline;
//}
//.service-procedure-row-content.collapsed .service-procedure-targets-list {
//  display: none;
//}
//
//.service-procedure-row-border {
//  display: none;
//  //display: block;
//  //position: absolute;
//  //left: 35px;
//  //right:35px;
//  //top:0;
//  //transition: all .35s ease;
//}
///*.service-procedure-row:hover {*/
///*background: #f2f2f2;*/
///*}*/
//.service-procedure-row:hover .service-procedure-row-border,
//.service-procedure-row:hover + .service-procedure-row .service-procedure-row-border {
//  left:0;
//  right:0;
//}
//.service-procedure-row-icon {
//  display: block;
//  position: absolute;
//  width: 25px;
//  height:25px;
//  left: 0;
//  top: 11px;
//  transform:         rotate(90deg);
//  //transform:         rotate(0deg);
//
//  &:before,
//  &:after {
//    .fa-icon();
//    position: absolute;
//    top:50%;
//    left:50%;
//    transform: translate(-50%,-50%);
//  }
//  &:before {
//    content: @fa-var-circle-thin;
//    font-size: 200%;
//  }
//  &:after {
//    content: @fa-var-angle-right;
//  }
//}
//.service-procedure-row-content.collapsed .service-procedure-row-icon {
//  transform:         rotate(0);
//  //transform:         rotate(90deg);
//}
///*.service-procedure-row:hover .service-procedure-row-icon {*/
///*left: 10px;*/
///*}*/
//
//.service-procedure-target-info-container {
//  left:0;
//  opacity: 1;
//  position: relative;
//  padding: 0;
//
//  .fold-holder + .fold-holder {
//    padding-top: 45px;
//  }
//
//  //.new {
//  //  left: 20px;
//  //  opacity: 0;
//  //}
//
//  .modal-header-preamble + .service-procedure-container + & {
//    padding-top: 45px;
//  }
//
//  .subfold-holder {
//    :first-child {
//      margin-top: 0;
//    }
//
//    ul {
//      margin-bottom: 0;
//    }
//  }
//}
//.subfold-holder--additional {
//  .subfold-holder-outer {
//    padding: 0 0 30px;
//  }
//
//  .subfold-title--toggler {
//    padding-bottom: 0;
//
//    & + .subfold-holder {
//      margin-top: 30px;
//    }
//  }
//}
//.service-procedure-targets-list {
//  margin: 5px 0 0 25px;
//  padding: 0;
//}
//.service-procedure-target {
//  line-height: 16px;
//  margin: 15px 0 0;
//}
//.service-procedure-target:hover,
//.service-procedure-target:hover .service-procedure-target-link{
//  //color: #FF5145;
//  text-decoration: underline;
//}

.b-service-info-group-title {
  font-family: "als_ekibastuz_light" ,Arial, sans-serif;
  font-weight: 200;
  font-size: 30px;
  line-height: 32px;
  margin: 60px 0 50px;

  &:first-child {
    margin-top: 0;
  }
}

.b-service-info-subgroup {
  .b-service-info-subgroup-inner {
    padding-left: 40px;
    padding-right: 50px;
    .b-service-info-subgroup-title {
      /*margin: 49px 0 33px;*/
      padding: 35px 30px 10px 0;
      font: 600 18px/20px 'Helvetica Neue,Helvetica,Arial,sans-serif', sans-serif;
      border-bottom: 1px solid #e5e5e7;
      font-weight: bold;
    }
    .b-service-info-subgroup-items {
      font-size: 16px;
      line-height: 24px;
      font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
      padding: 20px 0 0;

      h4 {
        margin: 20px 0 10px;
        padding: 0;
        line-height: 26px;
        font-size: 18px;
      }
    }
  }

}

.b-service-info-subgroup-title {
  padding: 35px 35px 16px 0;
  font: 600 24px/26px 'als_ekibastuz_bold', sans-serif;
  border-bottom: 1px solid #e5e5e7;
  position: relative;
  cursor: pointer;
}

.b-service-info-subgroup-items {
  font-size: 18px;
  line-height: 26px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  padding: 20px 0 0;

  h4 {
    margin: 20px 0 10px;
    padding: 0;
    line-height: 26px;
    font-size: 18px;
  }
}

.b-service-info-nav {
  font-size: 18px;
  line-height: 20px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0 30px 0 0;
}

.b-service-info-nav-title {

}

.b-service-nav-block {
  display: block;
  border-top: 4px solid #f3f3f4;
  margin-top: 18px;
}

.b-service-nav-block-title {
  margin-top: 19px;
  margin-bottom: 18px;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
}

.b-service-nav-block-anchors {
  display: block;
  padding: 0;
  margin: 0;

  li {
    display: block;
    padding: 0;
    margin: 0 0 10px;
  }
}

.b-service-nav-block-anchor {
  font-size: 14px;
  line-height: 21px;
}

.b-service-nav-hidden-block {
  margin: 10px 0 0;
  padding-left: 15px;
}

