.timelisting {
  //background-image: url("../../images/sunshine/ico/ico-er-time.png");
  //background-repeat: no-repeat;
  //background-attachment: scroll;
  //background-position: 12px 50%;
  /*color: #595652;*/
  cursor: pointer;
  display: inline-block;
  line-height: 24px;
  font-size: @font-size-middle;
  font-weight: bold;
  padding: 6px 15px;//@padding-base-vertical @padding-base-horizontal;
  margin: 5px 15px;
  min-width: 120px;

  //color: @lead-text-color-dark;
  color: @brand-primary;
  border: 1px solid @datepicker-border-color;
  box-shadow: none;
  border-radius: @border-radius-large;

  //&.active,
  //&:hover {
  //  background: #f2f2f2;
  //  color: @link-hover-color;
  //}
  // {
  //  border-color: @link-hover-color;
  //  background: @brand-primary;
  //  color: #fff;
  //}

  &:hover {
    //color: @link-hover-color;
    background: @datepicker-disabled-bg;
    border-color: @datepicker-border-color;
  }
  &:focus,
  &:active {
    color: @brand-primary;
    border-color: @brand-primary;
  }

  &.active {
    //box-shadow: 0 0 0 4px @input-border-focus;
    border-color: @brand-primary;
    background: @brand-primary;
    color: #fff;
  }
}
.timelisting input {
  display: none;
}
.timelisting-backlink {
}
.timelisting-container {
  margin: 0 -15px;
}
.timelisting-label {
  font-size: @font-size-base;
  display: block;
  margin: 35px 0 20px;
  color: #737b81;
  border-bottom: 1px solid @datepicker-border-color;
  padding: 0 0 10px;
}
.timelisting--period {
  font-size: @font-size-base;
  //letter-spacing: -0.03em;
  //width: 150px;
}