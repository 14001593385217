.isNew:after {
  content: 'Новое';
  display: block;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 35px;
  height: 35px;
  background: #ff0000;
  border-radius: 100%;
  color: #fff;
  font-size: 11px;
  text-align: center;
  padding: 10px 0;
  line-height: 15px;
}