
/*.my-loader {color: #fff !important; font-family: 'Open Sans Light', sans-serif; }*/

/*.m1 .w {margin:0 50px}*/

.table-cell {display:table-cell!important;float:none;}

.list2 {margin:0 0 0 0; padding:0; font-family: Arial, sans-serif;}
.list2 li {margin:0; padding:0 0 20px 0; display:block; list-style:none}
.list2 h3 {font:normal 14px 'Open Sans Bold'; color:#000000; margin:0 0 7px 0}
.list2 ul {margin:0; padding:0}
.list2 ul li {margin:0; padding:0 0 5px 0; display:block; list-style:none}

.legend{
    display: block;
    /*font: 28px 'Open Sans Light';*/
    font-family: EtelkaLightPro,Arial,"Helvetica Neue",Helvetica,sans-serif;
    width: 100%;
    min-height:32px;
    padding: 0 0 16px;
    border-bottom: 1px solid #eee;
    overflow:hidden;
		margin: 0 0 .5em;
}
	form > table + fieldset > .legend {
		margin:40px 0 20px;
	}

.wogroup,
.linkedobj-actions-footer {
	width:100%;
}

.linkedobj-actions-footer {
    margin-top: 50px;
}

.linkedobj-actions-footer td {
    text-align: center;
}

.linkedobj-actions-footer .btn + .btn {
	margin-left: 24px;
}

.subgroup-holder-outer.field_dou fieldset {width: 789px;}
.subgroup-holder-outer fieldset .megagriddiv {width: 596px !important;margin-left: 6px;}
.subgroup-holder-outer fieldset .input input[type=text], .input input[type=password] {color:#5B5B5B; font:normal 13px/28px 'Open Sans Regular'; /*width: 589px;*/}
.subgroup-holder-outer fieldset textarea {width: 602px;}
.subgroup-holder-outer fieldset fieldset {width: 573px;}
.subgroup-holder-outer.field_dou fieldset fieldset {width: 753px;}
.subgroup-holder-outer fieldset fieldset textarea {width: 566px;}
.subgroup-holder-outer fieldset fieldset .megagriddiv {width: 560px !important;margin-left: 6px;}
.subgroup-holder-outer fieldset fieldset .input input[type=text], .input input[type=password] {color:#5B5B5B; font:normal 13px/28px 'Open Sans Regular'; /*width: 553px;*/}

.attrs {
	display:table; width:100%; table-layout:fixed;
	max-width: 858px;
	/* fallback for table cell */
	*display:block;  *width:auto;
	/* fallback for box-sizing, border + padding */
	*padding: 0 4px;
}

/*.sameRow:first-child {margin-left:0; padding-left:0; border-left:0;}*/
/*.sameRow input[type=text],*/
/*.sameRow input[type=password] {width:100%;*margin-left:-2px;}*/

.subgroup-holder > table .attrs > label {  margin-top:25px;  }

/*.messages h5 {width:10em; float:left; font-size:12px; text-transform:none; font-weight:normal; margin:0 20px 0 0; padding-left:50px; background:url(../../images/lkmo/ico/message.png) 20px no-repeat} .messages .new h5 {background-image:url(../../images/lkmo/ico/message2.png);}*/
/*.messages .ul2 li {font-size:11px; text-transform:uppercase; max-width:750px}*/
/*.messages .ul2 a {color:#757575; text-decoration:none}*/
/*.messages .del {padding-left:20px; background:url(../../images/lkmo/ico/del.png) left no-repeat; float:right; display:block}*/

.checklist {
    //line-height:2.3em
}
.checkitem {
    font-size:14px;
    text-transform:none;
    font-weight:normal;
    margin:0 20px 20px 0;
    padding-left:25px;
    //background:url(../../images/lkmo/ico/checkitem-ic.png) 20px no-repeat
}
.checkitemfailed, .checkitempass  {
    font-size:14px;
    text-transform:none;
    font-weight:normal;
    margin:0 20px 20px 0;
    padding-left:25px;
    position: relative;

    &:before {
        position: absolute;
        left: 0;
        top: 0;
        color: @brand-critical;
        .fa-icon();
        content: @fa-var-close;
        font-size: 16px;
    }
    //background:url(../../images/lkmo/ico/checkitemfailed-ic.png) 20px no-repeat
}
.checkitemok  {
    font-size:14px;
    text-transform:none;
    font-weight:normal;
    margin:0 20px 20px 0;
    padding-left:25px;
    position: relative;

    &:before {
        position: absolute;
        left: 0;
        top: 0;
        color: @brand-success;
        .fa-icon();
        content: @fa-var-check;
        font-size: 16px;
    }
    //background:url(../../images/lkmo/ico/checkitemok-ic.png) 20px no-repeat
}

.newgridtable {
    /*width: 645px;*/
    position: relative;
    clear: both;
}
.grid-tools {
    float: none !important;
    line-height: 10px !important;
    margin: 10px 0 10px;
    padding: 0 20px 0 0;
    white-space: nowrap;
}
.newgridtable .grid_holder {
    clear: both;
}
.grid_holder > table {
	table-layout: fixed;
}

.cls {clear: both;}

table.tabdata.statuses{width:82%}
td.attrTitle{font-family:"Open Sans Italic", 'Open Sans Light'!important;font-size:14px;color:#000;}
tr.status td{background:#fdf7e4;padding:15px 10px 15px 5px;border-top:1px solid #e67800;border-bottom:1px solid #e67800;}
tr.status{border:1px solid #000;}
tr.status td {text-transform:uppercase;font-family:"Open Sans Italic", 'Open Sans Light';font-size:13px;color:#830e0e;}
tr.status td:first-child{text-transform:none;font-size:14px;color:#000;}

/*/////*/

[required] .star,
[required] + .star ,
.required .star ,
.required + .star {
  display: none;
}

/* grid-table styles  */
.grid-block {
  position: relative;
  overflow:auto;
  width:100%;
  padding-bottom: 1px; /* visible border bottom */
}
.grid-block >.grid-wrapper  {
  min-width:100%;
  width:100px!important;
}
.grid-block table td {
  word-break: break-word;
}
/*table.grid tbody tr.silver td, table.grid tbody tr.silver th {*/
  /*background-color:#d1e5ef;*/
/*}*/
/*table.grid tbody tr.gridRow td, table.grid tbody tr.gridRow th {*/
  /*background-color:transparent;*/
/*}*/

.grid-tools {
  white-space:nowrap;
  float:right;
  padding: 0 20px 0 0;
  line-height:10px!important;
}
.grid-tools a {
  display:inline-block;
  margin: 0 0 0 2px;
  cursor: pointer;
}

/*.my-Modal table td,*/
/*fieldset table td {*/
  /*padding: 10px 10px 10px 0;*/
/*}*/
/*.my-Modal table td:first-child,*/
/*fieldset table td:first-child {*/
  /*vertical-align: top;*/
  /*padding-top: 20px;*/
/*}*/
.note2 {
  margin: 5px 0 10px;
  opacity: .5;
  .mobile-txt-fix;
  /*font-style: italic;*/
}

.attr-value table {
  width: 100%;
}
.attr-value table td {
  width: 50%;
}

.attr-value table.view_grid {
	table-layout: fixed;
}
.attr-value table.view_grid th {
	text-align: left;
	/*font-family: 'Open Sans Light';*/
  font-weight: normal;
	word-break: break-all;
	word-break: break-word;
}
.attr-value table.view_grid th,
.attr-value table.view_grid td {
	border: 1px solid rgba(0,0,0,.05);
	padding: 0 4px;
	font-size: 12px;
	letter-spacing: -0.03em;
}
.attr-value table.view_grid td {
	width: auto;
}
.attr-value table.view_grid td.Rborder {
	/*width:1px;*/
	display: none;
}

/* TODO : all selects size > 1 */
.modal-body select[size="8"],
.modal-body select[size="6"] {
  left:0;
  top: 0;
  position: absolute;
  /*width:280px !important;*/
  border: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  background: @element-bg !important;
  z-index: ~"+4";
}

/*#documNoteDiv {*/
  /*width:400px;*/
  /*border: 1px solid #b4b4b4;*/
  /*color: #4C4C4C;*/
  /*padding: 15px;*/
  /*background: #f2f2f2;*/
/*}*/

select.sp-target {
	display: none !important;
}

.search-div {
  position:relative;
}

.temp_before {
  position: absolute;
  right: 100%;
  /*margin-right: calc(-24px + .25em);*/
  margin-right: -23px;
  line-height: 20px;
    font-size: 15px;
    width: 24px;
  text-align: right;

  display: none;

    font-family: Arial, sans-serif;

    color: rgb(51, 51, 51) !important;

    padding: 13px 0;

    .attr-field--readonly &,
  .attr-field--focused &,
  .attr-field--filled & {
    display: block;
  }
}

/* pubsoap/handlers/createorderdourequest/privilegecategory.jsp */
.g-privilege-legend {
	padding: 5px 0;
	letter-spacing: -0.03em;
}
.g-privilege-legend-link {
	font-size: 11px;
	/*padding: 2px 0;   */
	text-decoration:none;
	border-bottom:1px dashed #bfbfbf;
	white-space: nowrap;
}
.g-privilege-legend-link--active {
	border:0 none;
	cursor:default;
}
.g-privilege-legend-sep {
	display:inline-block;
	height:10px;
	margin:0 2px;
	border-right:2px solid #bfbfbf;
}

.attractions-msg-caption {
  display: block;
  font-weight: normal;
  margin: 0 0 1em;
}

.ecp-dialog {
  z-index: 99999 !important;
  //left: 50% !important;
  left:0!important;
  right:0;
  margin:0 auto;
  top: 50%;
  /*transform: translate(-50%,-50%);*/
  transform: translate(0,-50%);
}
dialog.ecp-dialog {
  left:0!important;
  top:auto!important;
  transform: none!important;
}
