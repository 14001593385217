@equeue-ticket-header-icon-size : 40px;

.equeue-results {
  padding:0;
}

.equeue-ticket {
  max-width: 800px;
  margin: 0 auto;
}

.equeue-ticket-header {

}
.equeue-ticket-header-inner {
  position: relative;
  padding-left: @equeue-ticket-header-icon-size + 55px;
  padding-bottom: 45px;
}
.equeue-ticket-header-layout {
  display: table;
  height: @equeue-ticket-header-icon-size; // min-height
  margin-right: 250px;
}
.equeue-ticket-header-layout-inner {
  display: table-cell;
  vertical-align: middle;
}
.equeue-ticket-header-icon {
  @border-width : 0;
  @s : @equeue-ticket-header-icon-size - @border-width;
  width: @s;
  height: @s;
  border: @border-width solid;
  border-radius: 100%;

  position: absolute;
  top: 10px;
  left:10px;

  &:before,
  &:after {
    .fa-icon();
    text-align: center;
    font-size: @s;
    line-height: @s;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }

  &:before {
    content: @fa-var-circle-thin;
    font-size: @s*2;
  }

  &--success {  color: @brand-success; &:after {
    content: @fa-var-check;
  }}
  &--error {  color: @brand-critical; &:after {
    content: @fa-var-times;
  }}
}
.equeue-ticket-header-status {
  display: block;
  font-size: @font-size-large;
  font-family: @font-family-bold;
  font-weight: normal;
}
.equeue-ticket-header-info {
  display: block;
  font-size: @font-size-base;
  font-family: @font-family-base;
}
.equeue-ticket-header-actions {
  position: absolute;
  right:0;
  top:0;

  .btn {
    padding-top:9px;
    padding-bottom:9px;
  }
}

//
// SIGN RESULTS
//

.sign-result {
  //color: #4d4d4d;
  //padding-bottom: 125px;

  .modal-body--custom-attrs{
    padding:35px 20px 0;
  }
}

.sign-result-map {
  position: relative;
  /*margin: -35px -50px 40px;*/
  margin-bottom: 40px;
}

.sign-result-map-img {
  width: 100%;
}

.sign-result-layout {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.sign-result-row {
  display: table-row;
}

.sign-result-col {
  @side-padd : 73px;
  vertical-align: top;
  display: table-cell;
  padding: 0 @side-padd;
  width: 50%;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}

.sign-result-date-item + .sign-result-date-item,
.sign-result-row--bordered .sign-result-col + .sign-result-col {
  border-left-width: 2px;
  border-left-style: solid;
}

.sign-result-block {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0);

  & + & {
    margin-top: 25px;
  }
}

.sign-result-block-email {
  word-break: break-word;
}

.sign-result-date-item,
.sign-result-block--date,
.sign-result-row--bordered .sign-result-col + .sign-result-col {
  border-color: #e9f2f8;//rgba(0, 0, 0, .15);
}

.sign-result-block--pin,
.sign-result-block--date,
.sign-result-block--greetings {
  font-family: @font-family-medium;
  font-size: @font-size-large;
  //font-family: 'Open Sans Bold';
  //font-size: 125%;
}

.sign-result-block--pin-helper,
.sign-result-block--greetings {
  //max-width: 90%;
}

.sign-result-block--office {
}

.sign-result-block--pin,
.sign-result-block--date {
  padding: 15px;
  text-align: center;
}

.sign-result-block--date {
  padding-left: 0;
  padding-right: 0;

  background: #eef8fd;
  border: 1px solid #e4eef5;
  color: @lead-text-color-dark;
}

.sign-result-date {
  display: table;
  width: 100%;
}

.sign-result-date-item {
  display: table-cell;
  text-align: center;

  &--time {
    width: 35%;
  }
}

.sign-result-block--pin {
  text-transform: uppercase;
  border: 1px solid;
  //color: @brand-critical;
  color: @brand-danger;
}

.sign-result-actions {
  .clearfix();
  text-align: right;
  width: 100%;
  display: block;

  .btn-action--remove-ticket {
    float: left;
  }

  .btn {
    margin-bottom: 20px;
  }

  .btn + .btn {
    margin-left: @padding-small-horizontal/2;
  }
  .btn-link + .btn-link {
    margin-left: -@padding-small-horizontal;
  }

  .sign-result-col {
    vertical-align: middle;
    text-align: center;
  }

  .btn-action--accept-ticket {
    width: 100%;
  }
}

.sign-result-actions--additional {
  .sign-result-action-link,
  .btn {
    min-width: 35%;
    max-width: 47.5%;
    color: @text-color;
  }
}

.sign-result-action-link {
  background-position: 10px 50%;
  background-repeat: no-repeat;
  border: 0;

  &:hover {
    background-color: transparent;

    .sign-result-action-link-text {
      border-bottom: 1px solid;
    }
  }

  &--print {
    /*background-image: url('../../images/sunshine/ico/ticket-print.png');*/
    padding-left: 44px;
  }
  &--download {
    /*background-image: url('../../images/sunshine/ico/ticket-download.png');*/
    padding-left: 0;
  }
}