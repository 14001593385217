.b-profile-nav {
  .clearfix();
}
.b-profile {
  .edit-attr,
  .edit-btn--save,
  .edit-btn--cancel,
  .edit-mode .view-attr,
  .edit-mode .edit-btn--edit {
    display:none;
  }
  .edit-btn--edit,
  .edit-mode .edit-btn--cancel,
  .edit-mode .edit-btn--save {
    display: inline-block;
  }
  .edit-mode .edit-attr {
    display: block;
  }

  .subgroup-actions--not-required .edit-btn--edit,
  .edit-mode .subgroup-actions--not-required .edit-btn--save,
  .edit-mode .subgroup-actions--not-required .edit-btn--cancel {
    display: none;
  }
  .save-mode .subgroup-actions--not-required .edit-btn--save {
    display: inline-block;
  }

}

.filter-period-container {
  margin-right: 18px;
  margin-bottom: 12px;
}
.filter-period-container,
.filter-period-sub {
  float:left;
}
.filter-period-link {
  margin-left: 6px;
  display: inline-block;
  padding: @padding-base-vertical 0;
  & + & {
    margin-left: 18px;
  }
  .checkbox {
    margin: 0;
    color: #839199;
  }
}
.elkSubscribe-error {
  color:@brand-warning;
  position: absolute;
  width: 300px;
  margin-top: 5px;
}
.elkSubscribe__process:after {
  background: transparent url('@{path-images}loader-simple.gif') center center no-repeat;
  content: "";
  width: 30px;
  height:25px;
  position: absolute;
  right: -45px;
  top: 0;
}


.pagination-wrap {
  display: block;
  text-align: center;
  padding: 70px 0 0;
}
.form-group {
  .attr ul {
    padding: 0;
    margin: 0 0 0 15px;
  }
}

.modal--profile-settings  {

  .attr-row--snils,
  .attr-row--inn,
  .attr-row--ogrnip,

  .attr-row--lastName,
  .attr-row--firstName,
  .attr-row--birthday,
  .attr-row--secondName {
    display: inline-block;
    padding-left: 20px;
    width: 33.33%;
  }
  // TODO do not hardcode that padding
  .attr-row--snils,
  .attr-row--birthday,
  .attr-row--lastName {
    padding-left: 0;
  }

  .attr-col--sex {
    .radio label {
      padding-left: @padding-base-horizontal;
      & > span:first-child:last-child {
        margin-left: 0;
      }
    }
    .attr-container {
      min-height: 0;
    }
    .attr-label--inline, .attr-value {
      width: auto;
      display: inline-block;
      vertical-align: middle;
      line-height: 20px;
    }
  }
}