.modal--alert-ecp .modal-dialog {
  max-width: 600px;
}
.g-ecp-cert-list {
  margin:0;
  padding:0;
}
.g-ecp-cert-link {
  display: block;
  width: 100%;
  white-space: normal;
  word-break: break-all;
  font-weight: 100;

  & + & {
    margin-top: 20px;
  }
}