.g-svg-etc__download-sheet-dims {
  width: 42px;
  height: 54px;
}
.g-svg-etc__group-side-arrow-dims {
  width: 30px;
  height: 80px;
}
.g-svg-icon__alert-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__antenna-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__apostile-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__bigboard-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__bill-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__book-plus-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__bookmark-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__briefcase-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__car-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__cardiogram-lense-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__cell-network-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__certificate-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__collet-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__core-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__coupon-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__cross-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__deer-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__equeue-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__family-1-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__family-2-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__family-3-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__fence-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__folder-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__handcuffs-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__house-man-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__house-ok-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__inn-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__kid-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__libra-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__lifebuoy-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__lights-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__medal-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__medicine-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__megafon-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__message-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__nipple-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__paper-ok-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__paper-ok-2-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__paper-rub-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__pass-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__passport-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__payments-1-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__payments-2-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__payments-3-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__pencil-doc-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__percent-doc-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__point-map-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__pram-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__pram2-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__quarters-1-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__quarters-2-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__quarters-3-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__receipt-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__rings-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__rudder-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__scores-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__shield-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__stamp-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__target-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__tickets-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__trowel-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__valve-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__wallet-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__water-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__wind-dims {
  width: 120px;
  height: 120px;
}
.g-svg-icon__zpassport-dims {
  width: 120px;
  height: 120px;
}
.g-svg-list-bullet-dims {
  width: 17px;
  height: 17px;
}
@font-face {
  font-family: 'EtelkaLightPro';
  src: url('../../../fonts/EtelkaLightPro/etelka-light-pro_16165-webfont.eot');
  src: url('../../../fonts/EtelkaLightPro/etelka-light-pro_16165-webfont.eot?#iefix') format('embedded-opentype'), url('../../../fonts/EtelkaLightPro/etelka-light-pro_16165-webfont.woff2') format('woff2'), url('../../../fonts/EtelkaLightPro/etelka-light-pro_16165-webfont.woff') format('woff'), url('../../../fonts/EtelkaLightPro/etelka-light-pro_16165-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'EtelkaTextPro';
  src: url('../../../fonts/EtelkaTextPro/web-etelka-text-pro-webfont.eot');
  src: url('../../../fonts/EtelkaTextPro/web-etelka-text-pro-webfont.eot?#iefix') format('embedded-opentype'), url('../../../fonts/EtelkaTextPro/web-etelka-text-pro-webfont.woff2') format('woff2'), url('../../../fonts/EtelkaTextPro/web-etelka-text-pro-webfont.woff') format('woff'), url('../../../fonts/EtelkaTextPro/web-etelka-text-pro-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'EtelkaTextPro';
  src: url('../../../fonts/EtelkaTextPro/etelka-text-pro-bold-webfont.eot');
  src: url('../../../fonts/EtelkaTextPro/etelka-text-pro-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../../../fonts/EtelkaTextPro/etelka-text-pro-bold-webfont.woff2') format('woff2'), url('../../../fonts/EtelkaTextPro/etelka-text-pro-bold-webfont.woff') format('woff'), url('../../../fonts/EtelkaTextPro/etelka-text-pro-bold-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'EtelkaMediumPro';
  src: url('../../../fonts/EtelkaMediumPro/EtelkaMediumProRegular.eot');
  src: url('../../../fonts/EtelkaMediumPro/EtelkaMediumProRegular.eot?#iefix') format('embedded-opentype'), url('../../../fonts/EtelkaMediumPro/EtelkaMediumProRegular.woff') format('woff'), url('../../../fonts/EtelkaMediumPro/EtelkaMediumProRegular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'als_ekibastuz_bold';
  src: url('../../../fonts/als_ekibastuz_bold.eot');
  src: url('../../../fonts/als_ekibastuz_bold.eot?#iefix') format('embedded-opentype'), url('../../../fonts/als_ekibastuz_bold.woff2') format('woff2'), url('../../../fonts/als_ekibastuz_bold.woff') format('woff'), url('../../../fonts/als_ekibastuz_bold.ttf') format('truetype'), url('../../../fonts/als_ekibastuz_bold.svg#als_ekibastuz_bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'als_ekibastuz_light';
  src: url('../../../fonts/als_ekibastuz_light.eot');
  src: url('../../../fonts/als_ekibastuz_light.eot?#iefix') format('embedded-opentype'), url('../../../fonts/als_ekibastuz_light.woff2') format('woff2'), url('../../../fonts/als_ekibastuz_light.woff') format('woff'), url('../../../fonts/als_ekibastuz_light.ttf') format('truetype'), url('../../../fonts/als_ekibastuz_light.svg#als_ekibastuz_light') format('svg');
  font-weight: normal;
  font-style: normal;
}
html {
  text-size-adjust: 100%;
}
body {
  overflow-y: scroll;
  margin-top: 0 !important;
  min-width: 1200px;
  background: #e6ebec;
  border: 0;
  margin: 0;
}
::-ms-clear {
  display: none;
}
a {
  cursor: pointer;
}
a[disabled],
a.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
a,
button {
  outline: 0 none !important;
}
a {
  color: #0173c1;
  text-decoration: none;
}
a:hover {
  color: #1a88d7;
}
.dash,
a.dash {
  border-bottom: 1px dashed;
  cursor: pointer;
  text-decoration: none;
}
svg use {
  pointer-events: none;
}
strong {
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
}
.or {
  display: inline-block;
  padding: 12px 24px;
}
.opaque {
  opacity: 0 !important;
  filter: alpha(opacity=0) !important;
  pointer-events: none !important;
}
.wrapper {
  position: relative;
  max-width: 1304px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.zero-padding {
  padding: 0!important;
}
.g-svg-color__default {
  fill: #0063b0;
}
.g-svg-color__primary {
  fill: #0063b0;
}
.g-svg-color__secondary {
  fill: #56c5cc;
}
.g-svg-color__danger {
  fill: #b2234b;
}
.g-svg-color__info {
  fill: #2e729e;
}
.g-svg-color__warning {
  fill: #f4821f;
}
.g-svg-color__critical {
  fill: #dd191d;
}
.g-svg-color__success {
  fill: #6ec256;
}
.g-svg-color__disabled {
  fill: #777777;
}
.g-svg__error {
  fill: transparent;
  stroke: transparent;
  background: transparent url('../../../images/lktoepgu/pelmen.png') 50% 50% no-repeat;
  background-size: 100% auto;
  background-size: cover;
}
.g-flag {
  display: inline-block;
  vertical-align: baseline;
  width: 22px;
  height: 15px;
  background-size: 22px 15px;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.g-flag__ru {
  background-image: url('../../../images/lktoepgu/flag/ru.png');
}
.g-flag__uk {
  background-image: url('../../../images/lktoepgu/flag/uk.png');
}
.g-flag__crt {
  background-image: url('../../../images/lktoepgu/flag/crt.png');
}
.g-local-link {
  /*display: inline-block;*/
  border-bottom: 1px dashed;
  text-decoration: none !important;
}
.g-local-link--danger {
  color: #b2234b;
}
.g-local-link--disabled {
  border-bottom-color: rgba(0, 0, 0, 0);
}
.error-blink[error] {
  animation-duration: 3s;
  animation-fill-mode: both;
  animation-iteration-count: 3;
  animation-name: error-blink-flash;
}
@keyframes error-blink-flash {
  0%,
  50% {
    background-color: #ffedec;
  }
  25%,
  75% {
    background-color: #ffffff;
  }
  100% {
    background-color: #f2f2f2;
  }
}
.datepicker__mobile {
  -webkit-appearance: none;
}
.mobile-inline,
.mobile-inline-block,
.mobile-block {
  display: none;
}
.mobile-txt-fix {
  max-height: 1000000px;
}
.g-currency {
  font-weight: 400;
  font-size: inherit;
}
.g-currency .fa {
  font-weight: 100;
  font-size: 90%;
  margin-left: 0.25em;
}
.g-captcha {
  display: inline-block;
  background: url('../../../images/lktoepgu/loader-small.gif') center center no-repeat;
  border: 1px solid #eeeeee;
}
.g-captcha,
.g-captcha-img {
  min-width: 124px;
}
.g-captcha-img {
  display: inline-block;
  height: 44px;
  width: auto;
}
.section-fixate__fixed .section-fixate-inner {
  position: fixed;
  left: 0;
  right: 0;
  background: #fff;
  border-bottom: 1px solid hsla(0, 0%, 66%, 0.15);
  z-index: 1030;
  min-width: 1200px;
}
.app__search_focused .section-fixate__fixed .section-fixate-inner {
  position: absolute;
  top: 0 !important;
  transform: translateY(0) !important;
  z-index: 1;
}
.g-search-query-mask {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1040;
}
.text-label-box {
  display: block;
  margin: 0 0 2em;
  line-height: 1.5em;
}
.text-label-box-title,
.text-label-box-value {
  display: inline;
  margin: 0;
  padding: 0;
  word-break: break-word;
}
.text-label-box-title {
  font-weight: bold;
}
.text-label-box-title:after {
  content: ":";
  margin-right: 0.25em;
}
.text-label-box-value {
  font-weight: normal;
}
.app-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  padding-bottom: 352px;
  z-index: 99;
  background: #ffffff;
}
.text-bar {
  padding: 6px 12px;
  border-radius: 4px;
}
.bg-warning {
  background-color: #FFF8E0;
}
html,
body {
  height: 100%;
}
.app {
  height: 100%;
}
.app-inner {
  position: relative;
  min-height: 100%;
  padding-bottom: 88px;
  min-width: 740px;
  padding-top: 50px;
}
.app-inner__custom {
  padding-top: 0;
}
.app__loading .b-section__content,
.app__loading .b-section__header {
  opacity: 0;
}
.browserupgrade {
  display: block;
  padding: 1% 2%;
  text-align: center;
}
.browserupgrade-info {
  font-size: 18px;
  text-align: left;
}
.modal-body-content ul li {
  padding: 0 0 0 20px;
  margin: 1.25em 0 0 -20px;
  list-style: none;
  position: relative;
}
.modal-body-content ul li:before {
  content: "";
  border-radius: 100%;
  background: url('../../../images/lktoepgu/svg/list-bullet.svg') center center no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  width: 0.45em;
  height: 0.45em;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0.5em;
}
form.modal-body .required,
.modal-body form .required {
  position: relative;
}
form.modal-body .required:after,
.modal-body form .required:after {
  content: "*";
  display: inline;
  margin-left: 0.5em;
  color: #dd191d;
}
form.modal-body .star,
.modal-body form .star {
  color: #dd191d;
  margin-left: 0.5em;
}
.modal-body .form-row {
  display: flex;
  justify-content: space-between;
}
.modal-body select,
.modal-body textarea,
.modal-body input[type=text],
.modal-body input[type=date],
.modal-body input[type=email],
.modal-body input[type=search],
.modal-body input[type=password],
.modal-body .bootstrap-select.btn-group .btn {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  padding: 12px 24px;
  border: 1px solid #cdd1d4;
  background: #fff;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  outline-color: rgba(46, 114, 158, 0.5);
}
.modal-body select:-webkit-autofill,
.modal-body textarea:-webkit-autofill,
.modal-body input[type=text]:-webkit-autofill,
.modal-body input[type=date]:-webkit-autofill,
.modal-body input[type=email]:-webkit-autofill,
.modal-body input[type=search]:-webkit-autofill,
.modal-body input[type=password]:-webkit-autofill,
.modal-body .bootstrap-select.btn-group .btn:-webkit-autofill {
  box-shadow: 0 0 0 1000px #ffffff inset;
}
.modal-body textarea::placeholder,
.modal-body input[type=text]::placeholder,
.modal-body input[type=date]::placeholder,
.modal-body input[type=email]::placeholder,
.modal-body input[type=search]::placeholder,
.modal-body input[type=password]::placeholder {
  font-size: 0.8em;
}
.modal-body textarea {
  word-break: break-word;
  display: block;
}
.modal-body span.input-upper > textarea,
.modal-body span.input-upper > input,
.modal-body .input-upper {
  text-transform: uppercase;
}
.modal-body .bootstrap-select .btn {
  padding-right: 36px;
}
.modal-body .bootstrap-select .btn:active,
.modal-body .bootstrap-select .btn:focus,
.modal-body .bootstrap-select .btn:hover {
  background: #f2f2f2 !important;
  color: inherit;
  outline: none !important;
}
.modal-body .select2-container .select2-selection .select2-selection__arrow,
.modal-body .bootstrap-select.btn-group .btn .caret {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 24px;
  margin-top: -0.5em;
  border: 0 !important;
  background: none !important;
}
.modal-body .select2-container .select2-selection .select2-selection__arrow:before,
.modal-body .bootstrap-select.btn-group .btn .caret:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f107";
  font-size: inherit;
  font-weight: normal;
  text-align: center;
  border: 0;
  color: #555555;
}
.modal-body input[type=date].hasDatepicker,
.modal-body input[type=text].hasDatepicker,
.modal-body input[type=date].form__inp_date,
.modal-body input[type=text].form__inp_date {
  width: 150px;
  margin-right: 15px;
}
.modal-body input[type=date].hasDatepicker[readonly],
.modal-body input[type=text].hasDatepicker[readonly],
.modal-body input[type=date].form__inp_date[readonly],
.modal-body input[type=text].form__inp_date[readonly] {
  margin-right: 11px;
}
.modal-body input[type=date].hasDatepicker[readonly] ~ .ui-datepicker-trigger,
.modal-body input[type=text].hasDatepicker[readonly] ~ .ui-datepicker-trigger,
.modal-body input[type=date].form__inp_date[readonly] ~ .ui-datepicker-trigger,
.modal-body input[type=text].form__inp_date[readonly] ~ .ui-datepicker-trigger {
  display: none;
}
@supports (pointer-events: none) {
  .modal-body input[type=date].hasDatepicker[readonly] ~ .ui-datepicker-trigger,
  .modal-body input[type=text].hasDatepicker[readonly] ~ .ui-datepicker-trigger,
  .modal-body input[type=date].form__inp_date[readonly] ~ .ui-datepicker-trigger,
  .modal-body input[type=text].form__inp_date[readonly] ~ .ui-datepicker-trigger {
    display: inline-block;
    pointer-events: none;
  }
}
.modal-body input.data {
  width: 70px;
  margin-right: 15px;
}
.modal-body select.attr-value-el--filled,
.modal-body select.attr-value-el--filled + .btn,
.modal-body select.attr-value-el--filled + .btn-group > .btn,
.modal-body input[type=email].attr-value-el--filled,
.modal-body input[type=text].attr-value-el--filled,
.modal-body input[type=date].attr-value-el--filled,
.modal-body input[type=password].attr-value-el--filled,
.modal-body textarea.attr-value-el--filled {
  outline: 0;
  background: transparent !important;
  /* !important because of automask.js field.style.background usage*/
}
.modal-body select.attr-value-el--filled[readonly],
.modal-body select.attr-value-el--filled + .btn[readonly],
.modal-body select.attr-value-el--filled + .btn-group > .btn[readonly],
.modal-body input[type=email].attr-value-el--filled[readonly],
.modal-body input[type=text].attr-value-el--filled[readonly],
.modal-body input[type=date].attr-value-el--filled[readonly],
.modal-body input[type=password].attr-value-el--filled[readonly],
.modal-body textarea.attr-value-el--filled[readonly] {
  background: #f5f5f5 !important;
}
.modal-body select.attr-value-el--filled[disabled],
.modal-body select.attr-value-el--filled + .btn[disabled],
.modal-body select.attr-value-el--filled + .btn-group > .btn[disabled],
.modal-body input[type=email].attr-value-el--filled[disabled],
.modal-body input[type=text].attr-value-el--filled[disabled],
.modal-body input[type=date].attr-value-el--filled[disabled],
.modal-body input[type=password].attr-value-el--filled[disabled],
.modal-body textarea.attr-value-el--filled[disabled] {
  background: #eeeeee !important;
  color: #000;
}
.modal-body select:active,
.modal-body select:focus,
.modal-body textarea:active,
.modal-body textarea:focus,
.modal-body input[type=date]:active,
.modal-body input[type=text]:active,
.modal-body input[type=date]:focus,
.modal-body input[type=text]:focus,
.modal-body input[type=email]:active,
.modal-body input[type=email]:focus,
.modal-body input[type=password]:active,
.modal-body input[type=password]:focus {
  outline: 0;
  background: transparent !important;
  /* !important because of automask.js field.style.background usage*/
  border-color: #bfe5ff;
  box-shadow: 0 0 0 4px #bfe5ff;
}
.modal-body select:active:-webkit-autofill,
.modal-body select:focus:-webkit-autofill,
.modal-body textarea:active:-webkit-autofill,
.modal-body textarea:focus:-webkit-autofill,
.modal-body input[type=date]:active:-webkit-autofill,
.modal-body input[type=text]:active:-webkit-autofill,
.modal-body input[type=date]:focus:-webkit-autofill,
.modal-body input[type=text]:focus:-webkit-autofill,
.modal-body input[type=email]:active:-webkit-autofill,
.modal-body input[type=email]:focus:-webkit-autofill,
.modal-body input[type=password]:active:-webkit-autofill,
.modal-body input[type=password]:focus:-webkit-autofill {
  box-shadow: 0 0 0 4px #bfe5ff;
}
.modal-body select.error,
.modal-body select.error + .bootstrap-select > .btn,
.modal-body select.error + .jq-selectbox > .jq-selectbox__select,
.modal-body textarea.error,
.modal-body input[type=checkbox].error + label:before,
.modal-body input[type=hidden].error + input[type=checkbox] + label:before,
.modal-body input[type=radio].error + label:before,
.modal-body input[type=email][error],
.modal-body input[type=date][error],
.modal-body input[type=text][error],
.modal-body input[type=text].error,
.modal-body input[type=date].error,
.modal-body input[type=email].error,
.modal-body input[type=password].error {
  border-width: 1px;
  border-color: #dd191d !important;
  box-shadow: none;
}
.modal-body select[readonly],
.modal-body textarea[readonly],
.modal-body input[type=file][readonly],
.modal-body input[type=checkbox][readonly],
.modal-body input[type=radio][readonly],
.modal-body input[type=email][readonly],
.modal-body input[type=date][readonly],
.modal-body input[type=text][readonly],
.modal-body input[type=password][readonly],
.modal-body select[disabled],
.modal-body textarea[disabled],
.modal-body input[type=file][disabled],
.modal-body input[type=checkbox][disabled],
.modal-body input[type=radio][disabled],
.modal-body input[type=email][disabled],
.modal-body input[type=date][disabled],
.modal-body input[type=text][disabled],
.modal-body input[type=password][disabled],
.modal-body select.disabled,
.modal-body textarea.disabled,
.modal-body input[type=file].disabled,
.modal-body input[type=checkbox].disabled,
.modal-body input[type=radio].disabled,
.modal-body input[type=email].disabled,
.modal-body input[type=date].disabled,
.modal-body input[type=text].disabled,
.modal-body input[type=password].disabled {
  cursor: default;
  border-color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  box-shadow: none;
  outline: none;
}
.formErMes {
  font-size: 12px;
  color: #dd191d;
  display: block;
  margin-top: 0.5em;
}
.validateError .jq-file__name {
  border-width: 1px;
  border-color: #dd191d !important;
  box-shadow: none;
}
.bootstrap-select.btn-group .dropdown-menu {
  width: 100%;
  overflow-x: hidden;
}
.bootstrap-select.btn-group .dropdown-menu li a,
.bootstrap-select.btn-group .dropdown-menu li {
  outline: none;
}
.bootstrap-select.btn-group .dropdown-menu li.selected,
.bootstrap-select.btn-group .dropdown-menu li:active,
.bootstrap-select.btn-group .dropdown-menu li:focus,
.bootstrap-select.btn-group .dropdown-menu li:hover {
  background: #f2f2f2;
}
.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  max-width: 100%;
  white-space: normal;
}
.bootstrap-select.btn-group.sp--check-attr-value .dropdown-menu.inner {
  max-height: 250px;
  overflow-y: auto;
}
.attr-value-datepicker input[type="text"] {
  width: 110px !important;
}
.modal-body--count-subgroups {
  counter-reset: csbinline;
}
.modal-body--count-subgroups .subgroup-title:before {
  counter-increment: csbinline;
  content: counter(csbinline);
  position: absolute;
  left: 0;
  top: 0.05em;
  width: auto;
  text-align: center;
  font-size: 36px;
  font-family: EtelkaLightPro, Arial, "Helvetica Neue", Helvetica, sans-serif;
  line-height: 44px;
  margin-left: -50px;
}
.jq-selectbox-wrapper {
  width: 100%;
  max-width: 590px;
}
.selectBox-wrapper-readonly {
  pointer-events: none;
}
.selectBox-wrapper-readonly .jq-selectbox__select {
  cursor: default;
  border-color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  box-shadow: none;
  outline: none;
}
.jq-selectbox {
  vertical-align: middle;
  cursor: pointer;
  min-width: 200px;
}
.jq-selectbox__select {
  height: 46px;
  padding: 0 45px 0 24px;
  line-height: 46px;
  color: #000000;
  border: 1px solid #cdd1d4;
  border-radius: 0;
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-size: 15px;
}
.jq-selectbox__select-text {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.jq-selectbox__trigger {
  position: absolute;
  top: 0;
  right: 17px;
  width: 18px;
  height: 49px;
  line-height: 49px;
  font-size: 26px;
  color: #565656;
}
.jq-selectbox__trigger:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f107";
}
.jq-selectbox__trigger-arrow {
  display: none;
}
.jq-selectbox__dropdown {
  top: 46px;
  width: 100%;
  max-width: 100%;
  margin: 1px 0 0 0;
  padding: 0;
  border: 1px solid #cdd1d4;
  background: #FFF;
  left: 0;
  right: 0;
  max-height: 350px;
  z-index: 1;
}
.jq-selectbox li {
  font-size: 17px;
  text-overflow: initial;
  white-space: normal;
  line-height: 24px;
  min-height: 0;
  padding: 10px 20px 10px 40px;
  margin: 0;
  font-family: Arial, sans-serif;
  position: relative;
  display: block;
}
.jq-selectbox li:hover {
  color: #ffffff;
  background: #6ba9d2;
}
.jq-selectbox li.selected:after,
.jq-selectbox li.sel:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00c";
  position: absolute;
  left: 15px;
  top: 14px;
  font-size: 15px;
}
.form__item_filter .btn {
  float: right;
  margin-top: -3px;
  width: 200px;
}
.info_tooltip {
  position: relative;
}
.info_tooltip:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
.info_tooltip .tooltip {
  top: 0;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
  white-space: nowrap;
  visibility: hidden;
}
.info_tooltip .tooltip .tooltip-inner {
  background: #fff;
  color: initial;
  text-align: left;
  cursor: text;
}
.info_tooltip .tooltip.top .tooltip-arrow {
  border-top-color: #fff;
}
.info_tooltip .tooltip.right {
  left: 100%;
}
.info_tooltip .tooltip.right .tooltip-arrow {
  border-right-color: #fff;
}
.info_tooltip .tooltip.bottom {
  top: -100%;
}
.info_tooltip .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #fff;
}
.info_tooltip .tooltip.left .tooltip-arrow {
  border-left-color: #fff;
}
form .autocomplete-holder,
.form .autocomplete-holder {
  position: relative;
}
form .autocomplete-holder .fakeField,
.form .autocomplete-holder .fakeField {
  position: static;
}
form .autocomplete-holder .myHint,
.form .autocomplete-holder .myHint {
  top: 0;
  right: 0;
  bottom: auto;
}
.steps__form .my-loader.loader-hider ~ * {
  display: none;
}
.sx-mask-not-valid-anim {
  animation: sx-mask-not-valid-shake 0.55s linear;
  animation-fill-mode: both;
}
@keyframes sx-mask-not-valid-shake {
  8%,
  41% {
    transform: translateX(-6px);
  }
  25%,
  58% {
    transform: translateX(6px);
  }
  75% {
    transform: translateX(-3px);
  }
  92% {
    transform: translateX(3px);
  }
  0%,
  100% {
    transform: translateX(0);
  }
}
.linked-list {
  display: block;
  margin: 0;
  padding: 0;
}
.linked-list-outer .linked-list {
  padding-bottom: 30px;
}
.linked-list-item {
  display: block;
  padding: 0;
  margin: 0;
}
.linked-list-item + .linked-list-item {
  margin-top: 10px;
}
.linked-list-item-inner {
  position: relative;
  display: inline-block;
  width: auto;
}
.linked-list-actions {
  display: block;
  padding: 0;
  margin: 0;
}
.linked-list-link {
  display: block;
  font-size: 16px;
}
.linked-list-item-actions {
  left: 100%;
  top: 50%;
  font-size: 0.01px;
  margin-top: -9px;
  padding: 0 0 0 10px;
  position: absolute;
  white-space: nowrap;
  display: none;
}
.linked-list-item:hover .linked-list-item-actions {
  display: block;
}
.linked-list-item-action {
  margin-left: 10px;
  width: 18px;
  height: 18px;
  overflow: hidden;
  position: relative;
  color: transparent;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.linked-list-item-action:after {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: 1em;
  display: inline-block;
  text-decoration: none !important;
  position: absolute;
  top: 0;
  left: 0;
}
.linked-list-item-action--edit:after {
  content: "\f040";
  color: #0063b0;
}
.linked-list-item-action--edit:hover:after {
  color: #0071ca;
}
.linked-list-item-action--remove:after {
  content: "\00d7";
  color: #dd191d;
}
.linked-list-item-action--remove:hover:after {
  color: #ef6d70;
}
.grid {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.grid .grid-header {
  font-weight: bold;
}
.grid table {
  width: 100%!important;
}
.grid .grid-row {
  border-bottom: 1px solid #c8c7cc;
}
.grid .grid-row:last-child {
  border-bottom: 0;
}
.grid .grid-row.row-list {
  border-bottom: 0;
}
.grid .grid-header .grid-col,
.grid .grid-body .grid-col,
.grid .grid-footer .grid-col {
  padding: 20px 10px;
  line-height: 1.42857143;
  vertical-align: top;
}
.grid .grid-header .grid-col--status,
.grid .grid-body .grid-col--status,
.grid .grid-footer .grid-col--status {
  line-height: 23px;
}
.grid .grid-header .grid-col--status a,
.grid .grid-body .grid-col--status a,
.grid .grid-footer .grid-col--status a {
  word-break: break-all;
}
.grid .grid-header .grid-row {
  vertical-align: bottom;
  border-bottom: 4px solid #c8c7cc;
}
.grid .grid-header:first-child .grid-row:first-child .grid-col {
  border-top: 0;
}
.grid .grid-body + .grid-body {
  border-top: 4px solid #c8c7cc;
}
.grid .grid {
  background-color: #fff;
}
.grid-col-action-link--refresh {
  color: #0063b0;
  text-decoration: none !important;
  display: inline-block;
  opacity: 0;
}
.grid-col-action-link--refresh:hover {
  color: #179aff;
}
.grid-col-action-link--refresh__visible,
.grid-row:hover .grid-col-action-link--refresh {
  opacity: 1;
}
.grid-col-action-link--refresh:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f021";
  font-size: 18px;
  line-height: 1em;
}
.grid-col-remove-link {
  color: #dd191d;
  text-decoration: none !important;
  font-size: 24px;
  line-height: 1em;
  display: inline-block;
  opacity: 0;
}
.grid-col-remove-link:hover {
  color: #ef6d70;
}
.grid-col-remove-link__visible,
.grid-row:hover .grid-col-remove-link {
  opacity: 1;
}
.grid-col--id,
.grid-col--status {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}
.grid-col--info {
  word-break: break-word;
}
.grid-col--number,
.grid-col--float {
  text-align: right;
}
.grid-col--num {
  font-weight: bold;
  white-space: normal;
  word-break: break-word;
}
.grid-col > p:first-child {
  margin-top: 0;
}
.grid-header-col-inner {
  padding: 12px 12px;
  margin: -12px -12px;
  display: inline-block;
  background: transparent;
  border-radius: 3px;
  font-weight: normal;
}
.grid-header-col__sort {
  cursor: pointer;
}
.grid-header-col__sort .grid-header-col-text {
  text-decoration: none;
  color: #0063b0;
  border-bottom: 1px dashed;
  vertical-align: top;
  font-weight: normal;
}
.grid-header-col__sort .grid-header-col-text:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f107";
  display: none;
  vertical-align: middle;
  font-size: 1.25em;
  margin-left: 0.25em;
}
.grid-header-col__sort__asc .grid-header-col-text:after,
.sorting_asc .grid-header-col-text:after {
  content: "\f106";
  display: inline-block;
  border-bottom: 0;
  position: absolute;
  font-size: 18px;
  color: #000;
}
.grid-header-col__sort__desc .grid-header-col-text:after,
.sorting_desc .grid-header-col-text:after {
  content: "\f107";
  display: inline-block;
  border-bottom: 0;
  position: absolute;
  font-size: 18px;
  color: #000;
}
.grid-row-inner:before,
.grid-row-inner:after {
  content: " ";
  display: table;
}
.grid-row-inner:after {
  clear: both;
}
.grid-row--link:hover {
  cursor: pointer;
  background-color: #e9f2f8;
}
.grid-row--remove {
  background-color: rgba(221, 25, 29, 0.1) !important;
}
.table-header .table-action {
  display: none;
}
.grid-holder,
.table-holder {
  width: 100%;
  display: block;
  overflow: auto;
}
table._fixed {
  width: 100%;
  table-layout: fixed;
}
.grid-actions,
.table-actions {
  padding: 20px 0;
}
.table-holder + .grid-actions,
.table-holder + .table-actions {
  border-top: 1px solid #c8c7cc;
}
.grid-message-empty,
.table-message-empty {
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  color: #777777;
  padding: 20px 0;
}
.table-action {
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: relative;
  color: transparent;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
.table-action:after {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  line-height: 1em;
  display: inline-block;
  text-decoration: none !important;
  position: absolute;
  top: 0;
  left: 0;
}
.table-action--edit:after {
  content: "\f040";
  color: #0063b0;
}
.table-action--edit:hover:after {
  color: #0071ca;
}
.table-action--remove:after {
  content: "\00d7";
  color: #CF011A;
}
.table-action--remove:hover:after {
  color: #fe3850;
}
.table-filter--right {
  background: #f5f5f5;
  padding: 20px;
  margin-top: 60px;
}
.table-filter--right strong {
  display: block;
  padding-bottom: 20px;
}
.table-filter--right .jq-selectbox-wrapper {
  margin-bottom: 25px;
}
.table-filter--top {
  background: #f5f5f5;
  padding: 10px 0;
  margin-top: 0;
  margin-bottom: 25px;
}
.table-filter--top strong {
  display: block;
}
.table-filter--top .jq-selectbox-wrapper {
  margin-bottom: 15px;
}
.tb__list {
  border: 0 none;
  background: transparent;
  font-family: Arial, sans-serif;
}
.tb__list thead th {
  vertical-align: middle;
  border-bottom: 4px solid #c8c7cc;
  padding: 20px;
  line-height: 1.42857143;
  font-weight: 700;
  outline: 0 none;
}
.tb__list thead th.sorting,
.tb__list thead th.sorting_asc,
.tb__list thead th.sorting_desc,
.tb__list thead th.sorting_fake {
  color: #0173c1;
  font-weight: 200;
}
.tb__list thead th.sorting,
.tb__list thead th.sorting_asc,
.tb__list thead th.sorting_desc {
  cursor: pointer;
}
.tb__list thead th.sorting_asc:after,
.tb__list thead th.sorting_desc:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f106";
  margin: 0 0 0 3px;
  color: #4c4c4c;
}
.tb__list thead th.sorting_desc:after {
  content: "\f107";
}
.tb__list tbody td {
  padding: 20px 0 20px 20px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #c8c7cc;
  outline: 0 none;
}
.tb__list tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}
.tb__list tbody tr.silver {
  background: #d1e5ef;
}
.tb__list_row td {
  transition: background 1.5s ease;
}
.tb__list_row.new td {
  background: #dff0d8;
}
.tb__list--not-colored tbody tr:nth-of-type(even) {
  background: transparent;
}
.tb__list--condensed thead th {
  padding: 15px;
}
.tb__list--condensed tbody td {
  padding: 15px;
  color: #839199;
}
.tb__list--condensed tr td:first-child,
.tb__list--condensed tr th:first-child {
  padding-left: 0;
}
.action {
  border-top: 1px solid #c8c7cc;
  padding: 20px 0;
  margin: 20px 0 0;
}
.action__item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 0;
  font-size: 23px;
  line-height: 25px;
  height: 25px;
}
.action__item_create {
  color: #6ec256;
  margin-right: 9px;
}
.action__item_edit {
  color: #0063b0;
  line-height: 27px;
}
.action__item_del {
  color: #b2234b;
}
.control-no-margin.radio,
.control-no-margin.checkbox {
  margin-top: 0;
  margin-bottom: 0;
}
tr._placeholder td:before,
div._placeholder {
  display: block;
  content: ' ';
  background: #C0C0C0;
  height: 1em;
  animation: placeholder-glow 2s ease-in-out infinite;
}
div._placeholder + ._placeholder {
  margin-top: 1em;
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.3;
  }
}
.radio input[type="radio"] {
  margin-left: 0;
  opacity: 0;
}
.radio input[type="radio"] + label > span:first-child:last-child {
  display: inline-block;
  margin-left: 12px;
}
.radio input[type="radio"] + label:before {
  border-radius: 100%;
  content: "";
  width: 20px;
  height: 20px;
  float: left;
  background: transparent center center no-repeat;
  background-size: 100% 100%;
  border: 1px solid #CDD1D4;
  margin: 0 0 0 -24px;
  position: relative;
}
.radio input[type="radio"]:hover + label:before,
.radio input[type="radio"]:focus + label:before {
  border-color: #bfe5ff;
}
.radio input[type="radio"]:focus + label:before {
  box-shadow: 0 0 0 4px #bfe5ff;
}
.radio input[type="radio"]:checked + label:before {
  background: #fff;
  box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 6px #6ca9d2;
}
.radio input[type="radio"]:focus + label:before {
  border-color: #CDD1D4;
  box-shadow: none;
}
.radio input[type="radio"]:checked + label:before {
  background: #fff;
  box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 6px #6ca9d2;
}
.checkbox input[type="checkbox"] {
  margin-left: 0;
  opacity: 0;
}
.checkbox input[type="checkbox"] + label > span:first-child:last-child {
  display: inline-block;
  margin-left: 12px;
}
.checkbox input[type="checkbox"] + label:before {
  border-radius: 6px;
  content: "";
  width: 20px;
  height: 20px;
  float: left;
  background: transparent center center no-repeat;
  background-size: 100% 100%;
  border: 1px solid #CDD1D4;
  margin: 0 0 0 -24px;
  position: relative;
}
.checkbox input[type="checkbox"]:hover + label:before,
.checkbox input[type="checkbox"]:focus + label:before {
  border-color: #bfe5ff;
}
.checkbox input[type="checkbox"]:focus + label:before {
  box-shadow: 0 0 0 4px #bfe5ff;
}
.checkbox input[type="checkbox"]:checked + label:before {
  background: #fff;
  box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 6px #6ca9d2;
}
.checkbox input[type="checkbox"]:checked + label:before {
  background: #6ca9d2;
  box-shadow: none;
}
.checkbox input[type="checkbox"]:checked + label:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00c";
  position: absolute;
  top: 3px;
  left: 3px;
  color: #fff;
  font-size: 14px;
}
.checkbox input[type="checkbox"][readonly],
.checkbox input[type="checkbox"]:disabled {
  pointer-events: none;
  display: none;
}
.checkbox input[type="checkbox"][readonly] + label:before,
.checkbox input[type="checkbox"]:disabled + label:before {
  background: #a8a8a8;
  box-shadow: none;
  pointer-events: none;
}
.attr-field--focused input[type="checkbox"]:checked + label:before,
.attr-field--focused input[type="radio"]:checked + label:before {
  box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 6px #6ca9d2, 0 0 0 4px #bfe5ff;
}
.b-elem-top-static-label {
  font-size: 15px;
  line-height: 22px;
  color: #a6aeb3;
  font-family: Arial, sans-serif;
  display: block;
  margin: 0 0 8px 0;
  font-weight: normal;
}
.g-download {
  display: block;
  position: relative;
  margin: 20px 0 0;
}
.g-download + .g-download {
  margin-left: 25px;
}
.g-download.g-download--inline {
  display: inline-block;
}
.g-download.g-download--inline .g-download-inner {
  table-layout: auto;
}
.g-download-link {
  display: table-cell;
  vertical-align: middle;
  height: 44px;
  padding-left: 45px;
  font-size: 15px;
  text-decoration: none !important;
}
.g-download-link:hover .g-download-link-col__title {
  text-decoration: underline;
}
.g-download-inner {
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.g-download-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  display: block;
  width: 95%;
}
.g-download-comment {
  font-size: 12px;
}
.g-download-icon {
  left: 0;
  top: 0;
  position: absolute;
  background: url('../../../images/lktoepgu/svg/etc/download-sheet.svg') right bottom no-repeat;
  width: 32px;
  height: 44px;
}
.g-download-icon:after {
  content: attr(data-extension);
  position: absolute;
  bottom: 7px;
  right: 1px;
  left: 1px;
  text-align: center;
  font-style: normal;
  font-size: 9px;
  font-family: "als_ekibastuz_bold", Arial, sans-serif;
  text-transform: uppercase;
  color: #80888A;
}
.g-download-attach-btn .fa {
  margin-right: 0.5em;
  font-size: 120%;
}
.g-download-preview {
  display: block;
  cursor: zoom-in;
  overflow: hidden;
  left: 0;
  top: 0;
  position: absolute;
  width: 32px;
  height: 44px;
  border: 1px solid #cdd1d4;
}
.g-download-preview-img,
.g-download-preview img {
  min-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.g-download-link-layout {
  display: table;
  table-layout: fixed;
  width: 100%;
  text-decoration: none !important;
}
.g-download-link-col {
  display: table-cell;
  vertical-align: top;
}
.g-download-link-col__actions {
  white-space: nowrap;
}
.g-download-link-col__actions .btn {
  opacity: 0.25;
  transition: opacity 0.35s ease;
}
.g-download-link:hover .g-download-link-col__actions .btn {
  opacity: 1;
}
.g-download-link-col__actions .btn + .btn {
  margin-left: 12px;
}
.linkobj-header {
  display: block;
  padding: 0 0 40px;
}
.linkobj-title {
  position: relative;
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.5em;
}
.linked-obj-area {
  cursor: pointer;
}
.linked-obj-area:hover {
  border-color: #bfe5ff;
}
.attr-list-layout {
  width: 100%;
  table-layout: fixed;
}
.attr-row {
  width: 100%;
}
.attr-row > td > .attr-value:first-child,
.attr-row > .attr-col > .attr-value:first-child,
.attr-row > td > .attr-field,
.attr-row > .attr-col > .attr-field {
  margin-top: 30px;
}
.attr-col {
  display: inline-block;
  width: 100%;
}
.attr-col--grid {
  width: 100%;
}
.attr-container {
  min-height: 46px;
}
.attr-fake-value {
  display: block;
}
.attr-fake-value select,
.attr-fake-value input,
.attr-fake-value textarea,
.modal-body .attr-fake-value select,
.modal-body .attr-fake-value input,
.modal-body .attr-fake-value textarea,
.attr-fake-value select:focus,
.attr-fake-value input:focus,
.attr-fake-value textarea:focus,
.modal-body .attr-fake-value select:focus,
.modal-body .attr-fake-value input:focus,
.modal-body .attr-fake-value textarea:focus,
.attr-fake-value select:active,
.attr-fake-value input:active,
.attr-fake-value textarea:active,
.modal-body .attr-fake-value select:active,
.modal-body .attr-fake-value input:active,
.modal-body .attr-fake-value textarea:active,
.attr-fake-value select.attr-value--filled,
.attr-fake-value input.attr-value--filled,
.attr-fake-value textarea.attr-value--filled,
.modal-body .attr-fake-value select.attr-value--filled,
.modal-body .attr-fake-value input.attr-value--filled,
.modal-body .attr-fake-value textarea.attr-value--filled {
  background: #DCE2E6 !important;
  color: #839199 !important;
  box-shadow: none !important;
  border-color: transparent!important;
}
.attr-fake-value .g-download {
  padding-top: 12px;
}
.attr-fake-value .temp_before {
  color: #839199 !important;
}
.attr-fake-value .radio-inline span {
  color: #839199 !important;
}
.attr-fake-value label,
.attr-fake-value .radio {
  cursor: default;
}
.attr-field {
  vertical-align: top;
  position: relative;
  display: block;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.attr-field + .attr-field {
  margin-top: 30px;
}
.attr-field__scrolled .attr-label {
  opacity: 0;
  pointer-events: none;
}
.attr-label,
.attr-title {
  text-align: left;
  display: block;
}
.attr-title {
  text-transform: none;
  font-weight: bold;
  display: block;
  margin-top: 25px;
}
.attr-or {
  display: block;
  position: relative;
  padding: 2em 0;
  text-align: left;
  text-transform: lowercase;
}
.attr-or:after {
  content: "";
  display: block;
  position: absolute;
  left: 2.5em;
  right: 0;
  height: 0;
  top: 50%;
  border-top: 1px solid;
  opacity: 0.5;
}
.attr-label--inline {
  /*width:50%;*/
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  top: 12px;
  padding: 0 24px;
  pointer-events: none;
  cursor: text;
  z-index: +3;
  font-family: inherit;
  font-weight: normal;
  line-height: 20px;
  font-size: 15px;
  color: #a6aeb3;
}
.page.ready .attr-label--inline,
.modal.ready .attr-label--inline {
  transition: font-size 0.15s ease, top 0.15s ease, opacity 0.35s ease;
}
.attr-label--view {
  display: block;
  top: 12px;
  padding: 0;
  pointer-events: none;
  cursor: text;
  font-family: inherit;
  font-weight: normal;
  line-height: 20px;
  font-size: 12px;
  color: #a6aeb3;
  margin: 0;
}
.attr-label--file {
  max-width: calc(100% - 38px);
}
.attr-field--layout {
  display: table;
  width: 100%;
}
.attr-field--layout .attr-label--inline {
  position: relative;
  left: 0 !important;
  top: 0 !important;
  color: inherit !important;
  font-size: inherit !important;
}
.attr-field--layout .attr-label--inline,
.attr-label--pull {
  padding-right: 5%;
  font-weight: normal;
  text-align: left;
}
.attr-field--layout .attr-label--inline,
.attr-label--pull,
.attr-field--layout .attr-label--inline + .attr-value,
.attr-label--pull + .attr-value {
  display: table-cell;
  width: 50%;
  vertical-align: top;
  /* because it jumps over the cell height, on .attr-value error display */
}
.attr-label--pull {
  font-weight: bold;
}
.attr-label--inline + .attr-value select::-moz-placeholder,
.attr-label--inline + .attr-value textarea::-moz-placeholder,
.attr-label--inline + .attr-value input[type=text]::-moz-placeholder,
.attr-label--inline + .attr-value input[type=search]::-moz-placeholder,
.attr-label--inline + .attr-value input[type=date]::-moz-placeholder,
.attr-label--inline + .attr-value input[type=email]::-moz-placeholder,
.attr-label--inline + .attr-value input[type=password]::-moz-placeholder {
  color: transparent !important;
  opacity: 1;
}
.attr-label--inline + .attr-value select:-ms-input-placeholder,
.attr-label--inline + .attr-value textarea:-ms-input-placeholder,
.attr-label--inline + .attr-value input[type=text]:-ms-input-placeholder,
.attr-label--inline + .attr-value input[type=search]:-ms-input-placeholder,
.attr-label--inline + .attr-value input[type=date]:-ms-input-placeholder,
.attr-label--inline + .attr-value input[type=email]:-ms-input-placeholder,
.attr-label--inline + .attr-value input[type=password]:-ms-input-placeholder {
  color: transparent !important;
}
.attr-label--inline + .attr-value select::-webkit-input-placeholder,
.attr-label--inline + .attr-value textarea::-webkit-input-placeholder,
.attr-label--inline + .attr-value input[type=text]::-webkit-input-placeholder,
.attr-label--inline + .attr-value input[type=search]::-webkit-input-placeholder,
.attr-label--inline + .attr-value input[type=date]::-webkit-input-placeholder,
.attr-label--inline + .attr-value input[type=email]::-webkit-input-placeholder,
.attr-label--inline + .attr-value input[type=password]::-webkit-input-placeholder {
  color: transparent !important;
}
.attr-label--inline + .attr-value .select2-selection__placeholder,
.attr-label--inline + .attr-value .bootstrap-select > .btn,
.attr-label--inline + .attr-value select {
  color: transparent !important;
}
.attr-label--inline + .attr-value .bs-searchbox input[type=text] {
  color: #555555 !important;
}
.attr-label--inline + .attr-value .bs-searchbox input[type=text]::-moz-placeholder {
  color: #999 !important;
  opacity: 1;
}
.attr-label--inline + .attr-value .bs-searchbox input[type=text]:-ms-input-placeholder {
  color: #999 !important;
}
.attr-label--inline + .attr-value .bs-searchbox input[type=text]::-webkit-input-placeholder {
  color: #999 !important;
}
.attr-label--inline + .attr-value option {
  color: #555555 !important;
}
.attr-field--focused .attr-label--inline,
.attr-field--readonly .attr-label--inline,
.attr-field--filled .attr-label--inline {
  font-size: 10px;
  top: -0.075em;
}
.attr-field--focused .attr-label--inline + .attr-value .select2-selection__placeholder,
.attr-field--readonly .attr-label--inline + .attr-value .select2-selection__placeholder,
.attr-field--filled .attr-label--inline + .attr-value .select2-selection__placeholder,
.attr-field--focused .attr-label--inline + .attr-value .bootstrap-select > .btn,
.attr-field--readonly .attr-label--inline + .attr-value .bootstrap-select > .btn,
.attr-field--filled .attr-label--inline + .attr-value .bootstrap-select > .btn,
.attr-field--focused .attr-label--inline + .attr-value select,
.attr-field--readonly .attr-label--inline + .attr-value select,
.attr-field--filled .attr-label--inline + .attr-value select {
  color: #555555 !important;
}
.attr-label .required:after {
  margin-left: 0.5em;
  position: relative;
  top: -0.175em;
}
.attr-label-title {
  word-wrap: break-word;
}
.attr-label-title-wrapper {
  /* wrapper over .attr-label-title */
  /* @see MSMEV-5588 */
}
.attr-label--inline .attr-label-title-wrapper {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.attr-value {
  vertical-align: middle;
  display: block;
  position: relative;
  line-height: 24px;
}
.attr-value--view {
  font-size: 15px;
  line-height: 20px;
}
.attr-value textarea {
  resize: vertical;
  max-height: 18em;
  min-height: 5.5em;
}
.attr-value--inline,
.attr-label--inline + .attr-value {
  display: block;
  width: 100%;
}
.attr-value--inline {
  padding-top: 12px;
  padding-left: 24px;
}
.attr-value .attr-value--inline {
  padding-left: 0;
}
.attr-label + .attr-value--helper .attr-value-helper--icon {
  top: -2em;
}
.attr-label--inline + .attr-value--helper .attr-value-helper--icon {
  top: 12px;
}
/*.attr-label--inline + .attr-value .attr-value-helper--icon {*/
/*top: 22px;*/
/* 10px + 12px */
/*}*/
.attr-label--under + .attr-value {
  margin-top: 12px;
}
.attr-label-dou {
  padding: 0 5px 0 0;
}
.attr-label-dou + .attr-value {
  vertical-align: top;
  color: #4d4d4d;
}
.attr-label-dou + .attr-value table td {
  width: auto;
}
.attr-label-dou--sec + .attr-value {
  color: #808080;
}
.attr-label-dou.attr-label-dou--sec {
  font-weight: normal;
}
.attr-field.checkbox-group {
  /*box-shadow: 0 5px 10px rgba(0,0,0,.1);*/
  margin: 20px 0;
  display: block;
}
.attr-field.checkbox-group > .attr-label + .attr-value {
  margin-top: 20px;
}
.attr-field.checkbox-group .checkbox {
  font-size: 12px;
  line-height: 21px;
}
.attr-field.checkbox-group .checkbox + .attr-field.checkbox-group .checkbox {
  margin-top: 20px;
}
.attr-field + .attr-value-helper--icon,
.attr-field.radio + .attr-value-helper--icon,
.attr-field.checkbox + .attr-value-helper--icon {
  top: 0;
}
.attr-field .radio:first-of-type,
.attr-field .checkbox:first-of-type {
  padding-left: 0;
}
.attr-value-helper {
  display: block;
  position: relative;
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  color: #777777;
}
.attr-value-helper--title {
  text-transform: initial;
}
/*.attr-value-helper--title:before{content:"("}*/
/*.attr-value-helper--title:after{content:")"}*/
/*.attr-value-helper--title:first-letter {  text-transform: uppercase;  }*/
.attr-value-helper--icon {
  cursor: help;
  position: absolute;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background-position: center center;
  top: 12px;
  right: 10px;
  margin: 0;
  z-index: 2;
  border-radius: 100%;
  border: 1px solid #d1d1d1;
  background: #ffffff;
  color: transparent;
}
.attr-value-helper--icon.attr-value-helper--file {
  top: 0.25em;
}
.attr-value-helper--icon:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f128";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5em 0 0 -0.3em;
  color: #000000;
  font-size: 12px;
  font-weight: normal;
  cursor: inherit;
}
.attr-value-helper--icon.attr-value-helper--icon--inline {
  display: inline-block;
  margin: 0 0 0 3px;
  vertical-align: middle;
  position: relative;
  top: 0;
  right: 0;
}
.attr-value-helper.attention {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
  color: #fff;
  text-align: center;
  text-indent: 0;
}
.attr-value-helper.attention hr {
  border-top-color: #f7e1b5;
}
.attr-value-helper.attention .alert-link {
  color: #66512c;
}
.attr-value-helper.attention:after {
  line-height: 19px;
  content: "!";
}
.attr-value-helper + .popover {
  left: auto !important;
  right: -20px !important;
  font-size: 12px;
  line-height: 1.5em;
  max-width: 65%;
}
.attr-value-helper + .popover .arrow {
  left: auto !important;
  right: 27px !important;
}
.attr-value--helper select,
.attr-value--helper textarea,
.attr-value--helper input[type=text],
.attr-value--helper input[type=date],
.attr-value--helper input[type=email],
.attr-value--helper input[type=password] {
  padding-right: 30px;
}
.attr-value--helper textarea {
  overflow-y: scroll;
}
.attr-value--helper .js--search-zags-by-address ~ .attr-value-helper--icon,
.attr-value--helper textarea ~ .attr-value-helper--icon {
  right: 24px;
}
.attr-value--helper .selectbox-wrapper ~ .attr-value-helper--icon,
.attr-value--helper select ~ .attr-value-helper--icon {
  /*right: 24px;*/
  /* then poppover -44px */
  right: 38px;
}
.attr-value--helper > .selectbox-wrapper ~ .popover,
.attr-value--helper > select ~ .popover {
  /*right:-44px !important;*/
  right: 8px !important;
}
.attr-value select,
.attr-value .bootstrap-select,
.attr-value input[type=text],
.attr-value input[type=date],
.attr-value input[type=email],
.attr-value input[type=password] {
  width: 100% !important;
}
.attr-value .linked-obj {
  cursor: pointer;
  resize: none;
}
.attr-readonly {
  display: block;
  padding: 12px 24px;
  border: 1px solid #ccc;
  background: #eeeeee;
  text-shadow: 1px 1px 0 #fff;
}
.attr-mfc-map {
  margin-top: 5px;
  border: 1px solid #eeeeee;
  width: 100%;
  height: 400px;
}
.form-attr-section {
  padding-bottom: 35px;
}
.form-attr-section--equeue {
  padding-bottom: 50px;
}
.form-attr-section--additional {
  padding-top: 35px;
  border-top: 1px solid #cdd1d4;
  font-size: 12px;
}
.form-attr-section--equeue.form-attr-section--additional {
  padding-top: 15px;
}
.form-attr-section .attr-title {
  margin-top: 0;
  text-transform: inherit;
  font-size: inherit;
}
.form-attr-section--additional .attr-title {
  font-weight: normal;
  font-family: inherit;
}
.form-attr-section--additional .attr-value {
  font-weight: normal;
  font-family: inherit;
}
.form-attr-section .attr-field {
  table-layout: fixed;
}
.form-attr-section .attr-label--inline + .attr-value {
  width: 100%;
}
.form-attr-section--equeue .attr-label--inline,
.form-attr-section--equeue .attr-label--inline + .attr-value {
  padding-top: 20px;
}
.radio-list .radio,
.checkbox-list .radio,
.radio-list .checkbox,
.checkbox-list .checkbox {
  margin: 20px 0;
}
.attr-list-attr {
  padding-left: 1em;
}
.attr-list-attr-item + .attr-list-attr-item {
  margin-top: 0.5em;
}
.form__item {
  display: block;
  padding: 0;
  margin: 30px 0;
}
.form__item:before,
.form__item:after {
  content: " ";
  display: table;
}
.form__item:after {
  clear: both;
}
.form__item:first-child {
  margin-top: 0;
}
.form__item.form__item_with_check select,
.form__item.form__item_with_check textarea,
.form__item.form__item_with_check input[type=text],
.form__item.form__item_with_check input[type=date],
.form__item.form__item_with_check input[type=email],
.form__item.form__item_with_check input[type=search],
.form__item.form__item_with_check input[type=password] {
  max-width: 90%;
}
.form__item_tr > td {
  padding-top: 30px;
}
.form__item_tr:first-child > td {
  padding-top: 0;
}
.form__lbl,
.tb__title {
  display: block;
  font-size: 15px;
  line-height: 22px;
  color: #535B63;
  font-family: Arial, sans-serif;
  margin: 0 0 8px 0;
  font-weight: normal;
}
.tb__title {
  font-weight: 700;
  color: #000;
}
.form__item__desc,
.note {
  display: block;
  margin-left: 15px;
  margin-top: 2px;
  font-size: 13px;
  color: #a5aeb3;
  white-space: pre-wrap;
  font-family: Arial, sans-serif;
}
.mark,
.star {
  margin-left: 3px;
  color: #dd191d;
}
.ui-datepicker-trigger {
  position: relative;
  margin-left: -45px;
  margin-right: 10px;
  top: -1px;
  z-index: 2;
}
.jq-file {
  width: 100%;
  padding-right: 132px;
  /*height: 36px;*/
  min-height: 46px;
  position: relative;
  display: block;
}
.dsr-files-holder .jq-file {
  margin-top: 15px;
}
.jq-file + .note {
  margin-right: 100px;
}
.jq-file .my-small-loader {
  display: block;
  margin-top: 40px;
}
.jq-file input {
  cursor: pointer;
  height: auto;
  line-height: 1em;
}
.jq-file__name {
  display: block;
  position: absolute;
  left: 0;
  right: 100px;
  height: 46px;
  padding: 12px 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #cdd1d4;
  -webkit-appearance: none;
  font-family: Arial, sans-serif;
}
.jq-file__browse {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid #005597;
  white-space: nowrap;
  padding: 12px 24px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  background: #0063b0;
  position: absolute;
  right: 0;
}
.jq-file:not(.disabled):hover .jq-file__browse {
  color: #fff;
  background-color: #00467d;
  border-color: #003259;
}
.jq-file.disabled .jq-file__name {
  color: #888;
}
.jq-file.disabled,
.jq-file.disabled .jq-file__browse,
.__ajax-upload._disabled .jq-file__browse {
  border-color: #CCC;
  box-shadow: none;
  color: #888;
}
.__ajax-upload._disabled input[type=file] {
  pointer-events: none;
}
.__ajax-upload .jq-file.disabled:hover,
.__ajax-upload._disabled .jq-file:hover {
  overflow: visible !important;
}
.__ajax-upload .jq-file.disabled:after,
.__ajax-upload._disabled .jq-file:after {
  content: 'Файл уже приложен. Для прикрепления другого нажмите "Отменить"';
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  right: 0;
  margin-top: -30px;
  background-color: #fff;
  font-size: 0.9em;
  padding: 3px 9px;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #333;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.__ajax-upload .jq-file.disabled:hover:after,
.__ajax-upload._disabled .jq-file:hover:after {
  opacity: 1;
  visibility: visible;
}
.b_time_spacer {
  display: inline-block;
  margin-right: 15px;
}
td.b-right-actions {
  padding-left: 20px;
  width: 18px;
}
.with_button_wrap {
  display: block;
  position: relative;
}
.with_button_wrap select {
  max-width: none;
}
select[size="6"] {
  max-width: none !important;
}
.styled_dropp_button {
  position: absolute;
  top: 0;
  right: 17px;
  width: 18px;
  height: 49px;
  line-height: 49px;
  font-size: 26px;
  color: #565656;
  border: 0 none;
  background: transparent;
  padding: 0;
  margin: 0;
}
.styled_dropp_button:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f107";
}
.styled_dropp_button .arrow {
  display: none;
}
.attr-scucces-sign {
  font-size: 18px;
  line-height: 20px;
  color: #6ec256;
  margin-left: 5px;
}
.form__inp.static_choosed {
  font-family: Arial, sans-serif;
}
.b-attrs-row + .b-attrs-row {
  margin-top: 14px;
}
.attr-unilist {
  display: block;
  position: relative;
  margin: 20px 0 0;
}
.attr-unilist + .g-download {
  margin-left: 25px;
}
.attr-unilist.g-download--inline {
  display: inline-block;
}
.attr-unilist.g-download--inline .g-download-inner {
  table-layout: auto;
}
.attr-unilist-file {
  display: block;
  margin: 0 0 1em;
  color: inherit;
  text-align: left;
  width: 100%;
  position: relative;
}
.attr-unilist-file-inner {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.attr-unilist-file-icon {
  left: 0;
  top: 0;
  position: absolute;
  background: url('../../../images/lktoepgu/svg/etc/download-sheet.svg') right bottom no-repeat;
  width: 32px;
  height: 44px;
}
.attr-unilist-file-icon:after {
  content: attr(data-extension);
  position: absolute;
  bottom: 7px;
  right: 1px;
  left: 1px;
  text-align: center;
  font-style: normal;
  font-size: 9px;
  font-family: "als_ekibastuz_bold", Arial, sans-serif;
  text-transform: uppercase;
  color: #80888A;
}
.attr-unilist-file-link {
  display: inline-block;
  vertical-align: top;
  min-height: 44px;
  padding-left: 45px;
  color: inherit;
}
.attr-unilist-file__removed {
  opacity: 0.35;
}
.attr-unilist-file__removed:hover {
  opacity: 0.75;
}
.attr-unilist-file__removed .attr-unilist-file-link {
  text-decoration: none !important;
  color: #777777 !important;
}
.attr-unilist-file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  display: block;
  width: 95%;
  width: 100%;
}
.attr-unilist-file-size {
  font-size: 12px;
}
.attr-unilist-file-link-layout {
  display: table;
  table-layout: fixed;
  width: 100%;
  text-decoration: none !important;
}
.attr-unilist-file-link-col {
  display: table-cell;
  vertical-align: top;
}
.attr-unilist-file-link-col__actions {
  white-space: nowrap;
}
.attr-unilist-file-link-col__actions .btn {
  opacity: 0.25;
  transition: opacity 0.35s ease;
}
.g-download-link:hover .attr-unilist-file-link-col__actions .btn {
  opacity: 1;
}
.attr-unilist-file-link-col__actions .btn + .btn {
  margin-left: 12px;
}
.attr-unilist-file-link-col__actions {
  text-align: right;
}
.attr-unilist-file-link-col__actions .btn {
  opacity: 1;
}
.attr-unilist-file-actions .btn > span {
  display: inline-block;
  margin-left: 0.5em;
}
.attr-unilist-file-actions .btn:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  text-decoration: none !important;
}
.attr-unilist-file-actions .btn__restore:before {
  content: "\f0e2";
  color: #0063b0;
}
.attr-unilist-file-actions .btn__restore:hover:after {
  color: #0071ca;
}
.attr-unilist-file-actions .btn__download:before {
  content: "\f019";
}
.attr-unilist-file-actions .btn__remove {
  background: #fff;
  border: 1px solid;
  color: #dd191d;
}
.attr-unilist-file-actions .btn__remove:before {
  top: 3px;
  font-size: 24px;
  font-weight: bold;
  line-height: 18px;
  content: "\00d7";
}
.attr-unilist-file-actions .btn__remove:hover {
  color: #fff;
  background: #dd191d;
}
.attr-unilist-file-preview {
  display: block;
  cursor: zoom-in;
  overflow: hidden;
  left: 0;
  top: 0;
  position: absolute;
  width: 32px;
  height: 44px;
  border: 1px solid #cdd1d4;
}
.attr-unilist-file-preview-img,
.attr-unilist-file-preview img {
  min-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.edit-attr .attr-unilist-file-actions .btn__download {
  display: none;
}
.view-attr .attr-unilist-file-actions .btn__remove {
  display: none;
}
.upload-box {
  display: block;
  vertical-align: baseline;
  position: relative;
}
.upload-box .jq-file__name {
  padding-right: 64px;
}
.upload-box-button {
  margin: 1px;
}
.upload-box-el-container {
  position: relative;
}
.upload-box-el-actions {
  position: absolute;
  left: 100%;
  margin-left: 5px;
  top: 0;
}
.upload-box-el-actions__compact {
  left: auto;
  right: 100px;
  margin-left: 5px;
  top: 100%;
  margin-top: 5px;
}
.upload-box-el-actions__compact .upload-box-action {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 85%;
  line-height: 1.33em;
}
.upload-box-progress-wrapper {
  display: block;
  width: calc(100% - 100px);
  margin-top: 0;
  position: absolute;
  left: 0;
  top: 100%;
}
.upload-box-progress-el {
  position: absolute;
  left: 0;
  bottom: 100%;
  background: #0063b0;
  display: block;
  width: 0;
  height: 7px;
  border: 0;
  overflow: hidden;
  -webkit-transition: width 0.35s ease;
  -moz-transition: width 0.35s ease;
  transition: width 0.35s ease;
  opacity: 0.5;
}
.upload-box-progress-el__static {
  -webkit-transition: initial;
  -moz-transition: initial;
  transition: initial;
}
.upload-box-progress-el.error {
  border-top-color: #f33;
  border-bottom-color: #a33;
}
.upload-box-progress-text {
  /*font-size: 12px;*/
  font-size: 80%;
  line-height: 1em;
  text-align: left;
  padding: 0.75em 0 0;
  position: absolute;
  right: 120px;
  top: 10px;
}
.upload-box-progress-status {
  font-size: inherit;
  text-align: right;
  padding: 5px 0 0;
}
.upload-box-progress-status__success {
  color: #00aa00;
}
.upload-box-progress-status__error {
  color: #ee0000;
}
/*.upload-box-progress-text + .upload-box-progress-status {*/
/*float: right;*/
/*}*/
.filebox {
  display: block;
  position: relative;
  width: 100%;
  clear: both;
}
.modal-body .filebox {
  margin: 20px 0 0;
}
.filebox-inner {
  display: table;
  table-layout: fixed;
}
.filebox-btn {
  min-width: 90px;
  margin-left: 4px;
}
.filebox-col {
  width: 20%;
  display: table-cell;
  vertical-align: top;
}
.filebox-col:first-child {
  width: auto;
}
.filebox-col input[type=text] {
  width: 100%;
}
.modal-body .filebox input[type=text],
.filebox input[type=text] {
  line-height: 1.42857143;
  font-size: 14px;
  opacity: 0.5;
}
.filebox input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(80% - 12px);
  margin: 0;
  padding: 12px 24px;
  cursor: pointer;
  opacity: 0;
}
.filebox .g-file-loader {
  margin-top: 10px;
}
.g-file-loader {
  height: 20px;
  display: block;
  background: #f5f5f5;
  /*border:1px solid rgba(0,0,0,.05);*/
  position: relative;
}
.g-file-loader-inner {
  position: absolute;
  width: 0;
  max-width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0063b0;
  /*text-align: center;*/
  text-align: right;
  padding-right: 10px;
  overflow: hidden;
  color: transparent;
  font-size: 12px;
  line-height: 20px;
  transition: width 0.45s ease;
}
.g-file-loader--done .g-file-loader-inner,
.g-file-loader--error .g-file-loader-inner,
.g-file-loader--percent-visible .g-file-loader-inner {
  color: #fff;
}
.g-file-loader--error .g-file-loader-inner {
  background: #b2234b;
}
.g-file-loader--error .g-file-loader-inner,
.g-file-loader--done .g-file-loader-inner {
  width: 100% !important;
  transition: width 0.15s ease;
}
.docsbord {
  border: 1px solid #eeeeee;
  padding: 20px;
  margin: 10px 0 0;
}
.attr-field--error .docsbord {
  border-color: #dd191d;
}
.storage-files-link {
  text-decoration: none !important;
  border-bottom: 1px dashed;
}
.existed-file-box-info {
  font-weight: bold;
  margin-top: 15px;
}
.dsr-files-holder .existed-file-box-cancel {
  text-decoration: none !important;
  border-bottom: 1px dashed;
  display: table-cell;
  width: 20%;
  text-align: center;
}
.cloud-files-list-table-caption {
  margin-bottom: 0;
}
.existed-files-box-header {
  /*font-weight:normal; */
  margin-bottom: 5px;
}
.existed-files-container {
  overflow: auto;
  max-height: 100px;
}
.existed-files-item {
  font-weight: normal;
  display: table;
  width: 100%;
  background-color: transparent;
}
.existed-files-item span {
  display: table-cell;
  padding-right: 30px;
  width: 78%;
}
.existed-files-item:hover {
  background-color: #ededed;
}
.sidebar-steps {
  margin: 0;
  padding: 0;
  width: 100%;
  counter-reset: sidebar-step-counter;
}
.sidebar-step {
  padding: 6px 0 95px 41px;
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  word-break: break-word;
  position: relative;
  display: block;
}
.sidebar-step,
.sidebar-step a {
  cursor: default;
  /*cursor: not-allowed;*/
}
.sidebar-step--prev,
.sidebar-step--active,
.sidebar-step--prev a,
.sidebar-step--active a {
  cursor: pointer;
}
.sidebar-step--active {
  font-weight: bold;
}
.sidebar-step a,
.sidebar-step a:hover {
  color: inherit;
  text-decoration: none !important;
}
.sidebar-step:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #eeeeee;
  background: linear-gradient(to bottom, #eeeeee, #0063b0);
  width: 3px;
  left: 13.5px;
  top: 35px;
  bottom: 5px;
}
.sidebar-step[data-target="__services"].sidebar-step--active:before,
.sidebar-step:last-child:before {
  display: none;
}
.sidebar-step-arrow {
  fill: #0063b0;
  width: 19px;
  height: 80px;
  position: relative;
}
.sidebar-steps .sidebar-step-arrow {
  left: 6px;
}
.sidebar-step-marker {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 100%;
  box-shadow: inset 0 0 0 2px #0063b0;
  position: absolute;
  top: 0;
  left: -1px;
}
.sidebar-step-marker:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: counter(sidebar-step-counter);
  counter-increment: sidebar-step-counter;
  color: #0063b0;
  font-size: 14px;
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  height: 1em;
  line-height: 1.25em;
  width: 100%;
  text-align: center;
}
.sidebar-step--active .sidebar-step-marker {
  box-shadow: inset 0 0 0 8px #0063b0;
}
.sidebar-step--active .sidebar-step-marker:after {
  content: "";
}
.sidebar-step--prev.sidebar-step--valid:not(.sidebar-step--active) .sidebar-step-marker {
  background-color: #0063b0;
  box-shadow: none;
}
.sidebar-step--prev.sidebar-step--valid:not(.sidebar-step--active) .sidebar-step-marker:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00c";
  font-size: 18px;
  color: #fff;
}
.sidebar-step--prev.sidebar-step--valid {
  color: #333333;
}
.sidebar-step--disabled {
  /*opacity: .5;*/
  display: none;
}
.modal--equeue .sidebar-step {
  padding: 6px 0 95px 41px;
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  word-break: break-word;
  position: relative;
  display: block;
}
.modal--equeue .sidebar-step,
.modal--equeue .sidebar-step a {
  cursor: default;
  /*cursor: not-allowed;*/
}
.modal--equeue .sidebar-step--prev,
.modal--equeue .sidebar-step--active,
.modal--equeue .sidebar-step--prev a,
.modal--equeue .sidebar-step--active a {
  cursor: pointer;
}
.modal--equeue .sidebar-step--active {
  font-weight: bold;
}
.modal--equeue .sidebar-step a,
.modal--equeue .sidebar-step a:hover {
  color: inherit;
  text-decoration: none !important;
}
.modal--equeue .sidebar-step:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #eeeeee;
  background: linear-gradient(to bottom, #eeeeee, #0063b0);
  width: 3px;
  left: 13.5px;
  top: 35px;
  bottom: 5px;
}
.modal--equeue .sidebar-step[data-target="__services"].sidebar-step--active:before,
.modal--equeue .sidebar-step:last-child:before {
  display: none;
}
.modal--equeue .sidebar-step-arrow {
  fill: #0063b0;
  width: 19px;
  height: 80px;
  position: relative;
}
.sidebar-steps .modal--equeue .sidebar-step-arrow {
  left: 6px;
}
.modal--equeue .sidebar-step-marker {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 100%;
  box-shadow: inset 0 0 0 2px #0063b0;
  position: absolute;
  top: 0;
  left: -1px;
}
.modal--equeue .sidebar-step-marker:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: counter(sidebar-step-counter);
  counter-increment: sidebar-step-counter;
  color: #0063b0;
  font-size: 14px;
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  height: 1em;
  line-height: 1.25em;
  width: 100%;
  text-align: center;
}
.sidebar-step--active .modal--equeue .sidebar-step-marker {
  box-shadow: inset 0 0 0 8px #0063b0;
}
.sidebar-step--active .modal--equeue .sidebar-step-marker:after {
  content: "";
}
.modal--equeue .sidebar-step--prev.sidebar-step--valid:not(.sidebar-step--active) .sidebar-step-marker {
  background-color: #0063b0;
  box-shadow: none;
}
.modal--equeue .sidebar-step--prev.sidebar-step--valid:not(.sidebar-step--active) .sidebar-step-marker:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00c";
  font-size: 18px;
  color: #fff;
}
.modal--equeue .sidebar-step--prev.sidebar-step--valid {
  color: #333333;
}
.modal--equeue .sidebar-step--disabled {
  /*opacity: .5;*/
  display: none;
}
.collapser {
  display: none;
}
.group-holder,
.group-holder-inner,
.subgroup-holder-outer {
  position: relative;
  padding: 0 0 0 62px;
}
.group-holder .subgroup-holder-outer,
.group-holder-inner .subgroup-holder-outer,
.subgroup-holder-outer .subgroup-holder-outer {
  padding-left: 0;
}
.group-holder.group-holder--narrow,
.group-holder-inner.group-holder--narrow,
.subgroup-holder-outer.group-holder--narrow {
  padding: 0 0 0 52px;
}
.group-holder__nosub {
  padding-left: 0;
}
.group-holder__nosub .group-num {
  display: none;
}
.group-holder,
.attr-list-layout,
.subgroup-holder-outer {
  margin: 0 0 50px 0;
}
.group-holder:not(.hidden) + .group-holder,
.attr-list-layout + .subgroups-list > .subgroup-holder-outer,
.subgroup-holder-outer + .subgroup-holder-outer {
  padding-top: 40px;
}
.group-holder:not(.hidden) + .group-holder:before,
.attr-list-layout + .subgroups-list > .subgroup-holder-outer:before,
.subgroup-holder-outer + .subgroup-holder-outer:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #cdd1d4;
}
.subgroup-holder-outer {
  padding-top: 40px;
}
.subgroup-holder-outer.subgroup-holder-outer--no-title {
  padding-top: 0;
}
.subgroup-holder-outer.subgroup-holder-outer--no-title .attr-row:first-child .attr-field {
  margin-top: 0;
}
.subgroup-num,
.group-num {
  position: absolute;
  left: 0;
  width: 62px;
  top: 0;
  text-align: center;
  font-size: 36px;
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  line-height: 44px;
}
.group-num-wrapper {
  display: block;
  padding-bottom: 20px;
}
.group-holder-inner .subgroup-holder-outer .subgroup-num,
.group-holder .subgroup-holder-outer .subgroup-num {
  left: -62px;
  top: 41px;
}
.group-holder:not(.hidden) + .group-holder .group-num {
  top: 40px;
}
.subgroup-title,
.group-title {
  position: relative;
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.5em;
  padding-top: 15px;
  max-height: 1000000px;
}
.subgroup-title.group-title--def,
.group-title.group-title--def {
  font-size: 30px;
  line-height: 1.2em;
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: normal;
}
.subgroup-actions {
  float: right;
  position: absolute;
  right: 0;
  top: 0.5em;
  font-size: 14px;
  font-weight: normal;
}
.subgroup-action-link {
  display: inline-block;
}
.subgroup-action-link + .subgroup-action-link {
  margin-left: 2em;
}
.fold-holder {
  padding: 0;
  position: relative;
  counter-reset: groups-counter;
}
.subfold-holder .attr-title {
  font-size: 15px;
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  line-height: 1.3333333;
}
.subfold-holder-outer {
  padding: 6px 0 38px 41px;
  position: relative;
}
.subfold-holder-outer .subfold-holder-outer {
  padding-left: 0;
}
.subfold-holder-outer .subfold-holder-outer:before {
  display: none;
}
.subfold-holder-outer .subfold-holder-outer .subfold-title {
  font-size: 15px;
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  line-height: 1.3333333;
  font-weight: bold;
}
.subfold-holder-outer__sideline:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #eeeeee;
  background: linear-gradient(to bottom, #eeeeee, #0063b0);
  width: 3px;
  left: 13.5px;
  top: 35px;
  bottom: 5px;
}
.subfold-holder-outer__sideline__color__default:before,
.subfold-holder-outer__sideline__color__primary:before {
  background-color: #eeeeee;
  background: linear-gradient(to bottom, #eeeeee, #0063b0);
}
.subfold-holder-outer__sideline__color__secondary:before {
  background-color: #eeeeee;
  background: linear-gradient(to bottom, #eeeeee, #56c5cc);
}
.subfold-holder-outer__sideline__color__danger:before {
  background-color: #eeeeee;
  background: linear-gradient(to bottom, #eeeeee, #b2234b);
}
.subfold-holder-outer__sideline__color__info:before {
  background-color: #eeeeee;
  background: linear-gradient(to bottom, #eeeeee, #2e729e);
}
.subfold-holder-outer__sideline__color__warning:before {
  background-color: #eeeeee;
  background: linear-gradient(to bottom, #eeeeee, #f4821f);
}
.subfold-holder-outer__sideline__color__critical:before {
  background-color: #eeeeee;
  background: linear-gradient(to bottom, #eeeeee, #dd191d);
}
.subfold-holder-outer__sideline__color__success:before {
  background-color: #eeeeee;
  background: linear-gradient(to bottom, #eeeeee, #6ec256);
}
.subfold-holder-outer__sideline__color__disabled:before {
  background-color: #eeeeee;
  background: linear-gradient(to bottom, #eeeeee, #777777);
}
.subfold-holder-outer:last-child:before {
  display: none;
}
.subfold-title-text-additional {
  border-bottom: 1px dashed;
  cursor: pointer;
}
.subfold-title-text-additional:hover {
  text-decoration: none;
}
.group-marker {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 100%;
  color: #0063b0;
  border: 2px solid;
  position: absolute;
  top: 0;
  left: -1px;
}
.group-marker__color__default {
  color: #0063b0;
}
.group-marker__color__primary {
  color: #0063b0;
}
.group-marker__color__secondary {
  color: #56c5cc;
}
.group-marker__color__danger {
  color: #b2234b;
}
.group-marker__color__info {
  color: #2e729e;
}
.group-marker__color__warning {
  color: #f4821f;
}
.group-marker__color__critical {
  color: #dd191d;
}
.group-marker__color__success {
  color: #6ec256;
}
.group-marker__color__disabled {
  color: #777777;
}
.group-marker:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: counter(groups-counter);
  counter-increment: groups-counter;
  font-size: 12px;
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  height: 1em;
  line-height: 1.3em;
  width: 100%;
  text-align: center;
}
.group-marker__static:after {
  display: none;
}
.subfold-title {
  font-size: 16px;
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  line-height: 1.3333333;
  font-weight: bold;
  padding-bottom: 30px;
  display: block;
  text-align: left;
}
.checkFill {
  display: none;
}
.g-check-fill {
  vertical-align: top;
  display: inline-block;
  text-transform: lowercase;
  padding: 3px 6px;
  font-size: 14px;
  color: #fff;
  background-color: #777777;
}
.g-check-fill--common {
  font-size: inherit;
  background-color: #777777;
}
.g-check-fill.correct {
  background-color: #ffffff !important;
}
.subgroup-holder-outer .subgroup-title .checkFill {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 45px;
}
.subgroup-holder-outer:hover .subgroup-title .checkFill {
  background-color: #b2234b;
}
.subgroup-holder-outer--loading:after {
  content: "";
  display: block;
  background: #fff url('../../../images/lktoepgu/loader-small.gif') center center no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: progress;
  z-index: +5;
  opacity: 0.85;
}
.form__section.clonable ._del,
.form__section.clonable ._add {
  display: none;
}
.form__section.clonable:last-child ._add,
.form__section.clonable:last-child ._del {
  display: inline-block;
}
.form__section_inner {
  display: block;
  padding: 40px 0;
  margin: 0;
  border-top: 1px solid #cdd1d4;
  border-bottom: 1px solid #cdd1d4;
}
.form__section_inner:before,
.form__section_inner:after {
  content: " ";
  display: table;
}
.form__section_inner:after {
  clear: both;
}
.form__section_inner + .form__section_inner {
  border-top: 0 none;
}
.form__section_inner + fieldset.form__section_inner {
  border-top: 1px solid #e9e9e9;
}
.form__section_inner:first-child {
  margin-top: 0;
}
.form__section_inner__wb {
  border-bottom: 0;
  border-top: 0;
  margin: 0;
}
.form__section__title {
  font-size: 18px;
  line-height: 22px;
  font-family: "als_ekibastuz_bold", sans-serif;
  color: #000;
  padding: 0 40px 0 0;
  margin: 0;
  position: relative;
}
.form__section__title:before,
.form__section__title:after {
  content: " ";
  display: table;
}
.form__section__title:after {
  clear: both;
}
.form__section__title + .wrapper {
  padding: 30px 0 0;
  margin-bottom: -30px;
}
.trigger {
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 50%;
  right: 0;
  margin: -5px 0 0;
  font-size: 17px;
  line-height: 25px;
  border-radius: 100%;
  border: 1px solid #cdd1d4;
  background: #ffffff;
  color: #4c4c4c;
  cursor: pointer;
}
.trigger .icon_up,
.trigger .icon_down {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: transparent;
  text-align: center;
  line-height: 28px;
  width: auto;
  height: auto;
}
.trigger .icon_up:after,
.trigger .icon_down:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  content: "\f106";
}
.trigger .icon_down:after {
  content: "\f107";
}
.b-skip-row + .form__item,
.b-skip-row + .form__section_inner {
  margin-top: 0;
}
.b-upper-group {
  position: relative;
  padding-left: 63px;
  margin-left: -63px;
}
.b-upper-group + .form__item {
  margin-top: 30px;
}
.b-upper-group .form__remark_status {
  position: absolute;
  right: 40px;
  top: 50%;
  margin-top: -12px;
}
.b-upper-group .form__section__title {
  padding-right: 174px;
}
.b-upper-group .form__section__title__common {
  padding-right: 40px;
}
.form__section__data:before,
.form__section__data:after {
  content: " ";
  display: table;
}
.form__section__data:after {
  clear: both;
}
.b-upper-group--modal {
  margin-left: 0;
  padding-left: 0;
}
.b-upper-group--modal .form__section__title + .wrapper {
  margin-bottom: 0;
}
.b-upper-group--modal .form__section__title + .wrapper:before,
.b-upper-group--modal .form__section__title + .wrapper:after {
  content: " ";
  display: table;
}
.b-upper-group--modal .form__section__title + .wrapper:after {
  clear: both;
}
.b-inner-group {
  padding: 25px 30px;
  border: 1px solid #e9e9e9;
  box-shadow: #e9e9e9;
  margin: 40px 0;
}
.b-inner-group--modal .form__section__title {
  font-size: 15px;
}
.group-attention {
  margin: 30px 0;
  padding: 15px 50px;
  border: 1px solid #c8c7cc;
  border-top: 5px solid #f2c617;
}
.group-attention h4 {
  margin: 0 0 15px;
}
.form__remark_status {
  float: none;
  display: inline-block;
  margin: 0 0 0 10px;
  padding-left: 30px;
  font-size: 0.8235em;
  font-weight: normal;
  font-family: Arial, sans-serif;
  position: relative;
}
.form__remark_status .icon {
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border: 2px solid;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  top: 50%;
  margin-top: -13px;
  background: transparent;
  text-indent: initial;
  color: inherit;
}
.form__remark_status_invalid {
  color: #dd191d;
}
.form__remark_status_invalid .icon {
  border-color: #dd191d;
}
.form__remark_status_invalid .icon:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00d";
  position: relative;
  top: -1px;
}
.form__remark_status_complete {
  color: #6ec256;
}
.form__remark_status_complete .icon {
  border-color: #6ec256;
}
.form__remark_status_complete .icon:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00c";
}
.b-group-num {
  display: block;
  position: absolute;
  font-size: 36px;
  line-height: 40px;
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  top: 0;
  right: 100%;
  padding: 27px 20px 0 20px;
  margin-right: -63px;
}
.steps__form .form__section_inner {
  padding-right: 63px;
}
.steps__form .form__section_inner .form__section_inner {
  border: #E9E9E9 1px solid;
  margin: 0 0 1em 0;
}
.steps__form .form__section_inner .form__section_inner .b-group-num {
  display: none;
}
._wizard .form__section {
  display: none;
  padding: 48px;
  background: #f1f3f6;
  border: 0 !important;
}
._wizard .form__section .form__section {
  display: block;
}
._wizard .form__section.active {
  display: block;
}
._wizard .form__section .form__inp[data-mask-before][style] {
  width: 100% !important;
}
._wizard .form__section input[type=hidden][value=''] ~ .form__area,
._wizard .form__section .form__area ~ .addNew {
  display: none;
}
._wizard .form__section input[type=hidden][value=''] ~ .form__area ~ .addNew {
  display: block;
}
._wizard .form__section .form__section__title {
  pointer-events: none;
  margin-bottom: 1em;
}
._wizard .attr-list-layout {
  display: block;
  margin: 0 -9px;
  width: auto !important;
}
._wizard .attr-list-layout > tbody {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
._wizard .attr-list-layout .form__item_tr {
  display: block;
  width: 50%;
  position: relative;
  border: transparent 9px solid;
  /*&:before {        border: #CCCCCC 1px solid;        position: absolute;  pointer-events: none;display: block;content: ''; top: 0; left: 0;right: 0;bottom: 0;}*/
}
._wizard .attr-list-layout .form__item_tr label.form__lbl {
  font-size: 18px;
  font-weight: 700;
  /*margin: 0; */
  margin: 0 0 17px 0;
  padding: 17px 0;
  position: relative;
  float: none;
}
._wizard .attr-list-layout .form__item_tr label.form__lbl:after {
  border-bottom: #CCCCCC 1px solid;
  height: 0;
  left: -17px;
  right: -17px;
  bottom: 0;
  position: absolute;
  content: '';
}
._wizard .attr-list-layout .form__item_tr .form__area {
  resize: none;
  border: 0;
  background: transparent !important;
}
._wizard .attr-list-layout .form__item_tr > td {
  /*padding-top:0 !important; display:block;*/
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 4px 9px 1px rgba(0, 0, 0, 0.08);
  padding: 6px 17px 17px 17px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
._wizard .attr-list-layout .form__item_tr.type8,
._wizard .attr-list-layout .form__item_tr.type9 {
  width: 100%;
  border: 0;
  padding: 17px 9px;
  /*&:before {content:none};*/
}
._wizard .attr-list-layout .form__item_tr.type8 > td,
._wizard .attr-list-layout .form__item_tr.type9 > td {
  box-shadow: none;
  background: none;
  padding: 0;
}
._wizard .attr-list-layout .form__item_tr.type8 label.form__lbl,
._wizard .attr-list-layout .form__item_tr.type9 label.form__lbl {
  font-size: 15px;
  margin-bottom: 1em;
}
._wizard .attr-list-layout .form__item_tr.type9:only-child {
  padding-top: 0;
}
._wizard .attr-list-layout .form__item_tr.type9:only-child .form__lbl {
  display: none;
}
._tiles_grid .grid_table {
  display: none;
}
._tiles_grid ._tilesList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -17px;
}
._tiles_grid ._tile {
  position: relative;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.08);
  padding: 0;
  margin: 17px;
  width: calc(50% - 34px);
}
._tiles_grid ._tile ._list {
  display: flex;
  flex-wrap: wrap;
}
._tiles_grid ._tile ._list ._li {
  font-size: 18px;
  width: 50%;
  padding: 27px 28px;
  /* &:nth-child(odd) {font-size:14px; font-weight:normal}*/
}
._tiles_grid ._tile ._list ._li:first-child {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  padding-right: 70px;
  border-bottom: #e6ebec 1px solid;
}
._tiles_grid ._tile ._list ._li h5 {
  margin: 0 0 4px 0;
  font-size: 14px;
  color: #a5aeb3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._tiles_grid ._tile ._tools {
  position: absolute;
  top: 17px;
  right: 17px;
}
._tiles_grid ._tile ._tools a {
  color: #086ab3;
  text-decoration: none;
  width: 22px;
  line-height: 22px;
  border: #cdd1d4 1px solid;
  border-radius: 50%;
  text-align: center;
}
._tiles_grid .addNew {
  width: calc(50% - 34px);
}
.addNew {
  border-width: 1.22px;
  border-color: #0063ac;
  border-style: dashed;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
  padding: 26px 28px;
  font-size: 14px;
  margin: 17px;
  position: relative;
  cursor: pointer;
}
.addNew h2 {
  margin-top: 0;
  font-size: 18px;
}
.addNew:before {
  font: normal normal normal 14px FontAwesome;
  content: '\f067';
  width: 22px;
  line-height: 22px;
  color: #FFFFFF;
  background: #0b40b3;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 17px;
  right: 17px;
  display: block;
  padding: 1px 1px 0 0;
}
.btn.loading {
  color: transparent !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  border-color: #fff;
  background-image: url('../../../images/lktoepgu/loader-small.gif');
  pointer-events: none;
  transition: background-color 0.15s ease;
}
.btn-back i {
  margin-right: 10px;
  font-weight: bold;
}
.btn-group-wrapper {
  display: block;
  margin: 25px 0;
}
.btn-group-label {
  display: inline-block;
  vertical-align: middle;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: #000;
  margin: 0 20px 0 0;
}
.scroll-top-button {
  width: 65px;
  height: 65px;
  border-radius: 5%;
  border: 1px solid #cdd1d4;
  background: #fff;
  color: #4c4c4c;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 25px;
  z-index: 100;
  text-align: center;
}
.scroll-top-button:after {
  display: inline-block;
  content: " ";
  border: 1px solid #cdd1d4;
  width: 25px;
  height: 25px;
  border-width: 2px 0 0 2px;
  transform: rotate(45deg);
  margin-top: 25px;
}
.scroll-top-button:hover {
  background-color: #efefef !important;
}
.btn-fgisdo {
  color: #A6AFC2;
}
.btn-more .my-small-loader {
  position: absolute;
  margin-top: -10px !important;
}
.throbber {
  display: block;
}
.throbber-title {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 11px;
  font-weight: 400;
  font-size: 25px;
}
.content-body.hidden + .throbber .throbber-title {
  padding-top: 0;
}
.throbber-sub-title {
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
  opacity: 0.75;
}
.throbber-img {
  display: block;
  margin: 20px auto;
}
.throbber-img__big {
  width: 100px;
  height: 100px;
  background: url('../../../images/lktoepgu/loader-big.gif') center center no-repeat;
}
.throbber-img__small {
  width: 24px;
  height: 24px;
  background: url('../../../images/lktoepgu/loader-small.gif') center center no-repeat;
}
.holder-loader {
  position: relative;
  cursor: not-allowed;
}
.holder-loader:after {
  content: "" !important;
  display: block !important;
  background: #fff url('../../../images/lktoepgu/loader-small.gif') 50% no-repeat !important;
  position: absolute !important;
  top: -1px !important;
  left: -1px !important;
  width: calc(100% + 2px) !important;
  height: calc(100% + 2px) !important;
  right: 0 !important;
  bottom: 0 !important;
  cursor: progress !important;
  z-index: 5 !important;
  opacity: 0.85 !important;
}
.holder-loader:before {
  content: none !important;
}
.b-breadcrumbs {
  background: #f6f6f6;
  border-top: 5px solid #2e729e;
  border-bottom: 1px solid #e1e1e1;
  font-size: 13px;
  line-height: 19px;
  color: #a5aeb3;
}
.b-breadcrumbs:before,
.b-breadcrumbs:after {
  content: " ";
  display: table;
}
.b-breadcrumbs:after {
  clear: both;
}
.b-breadcrumbs-list {
  overflow: hidden;
}
.b-breadcrumbs-item {
  display: block;
  position: relative;
  padding: 12px 20px;
  color: inherit;
  float: left;
  max-width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.b-breadcrumbs-item-text {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.b-breadcrumbs-item__link {
  color: #4c4c4c;
  text-decoration: none !important;
}
.b-breadcrumbs-item:last-child {
  max-width: 75%;
}
.b-breadcrumbs-item + .b-breadcrumbs-item + .b-breadcrumbs-item:last-child {
  max-width: 50%;
}
.b-breadcrumbs-item:first-child {
  padding-left: 0;
}
.b-breadcrumbs-item .fa {
  margin-right: 10px;
  margin-top: -0.5em;
  position: relative;
  top: -0.05em;
}
.b-breadcrumbs-item + .b-breadcrumbs-item {
  padding-left: 33px;
}
.b-breadcrumbs-item + .b-breadcrumbs-item:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: "&raquo;";
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  width: 13px;
  background: url('../../../images/lktoepgu/breadcrumbs-splitter.png') 0 0 no-repeat;
  background-size: 100% 100%;
}
.b-bc-search-drop {
  float: right;
  position: relative;
  height: 43px;
  margin-left: -42px;
  top: 0;
}
.b-bc-search-dropdown-btn {
  color: #555555;
  padding: 0 0.5em;
  margin-right: -0.5em;
  height: 100%;
  border: 0;
  background: none;
}
.b-bc-search-dropdown-btn:after {
  content: "";
  position: relative;
  vertical-align: top;
  line-height: inherit;
  margin-top: 0.5em;
  margin-left: 0.25em;
  display: inline-block;
  overflow: hidden;
  height: 0;
  width: 0;
  border: 4px solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.open .b-bc-search-dropdown-btn:after {
  border-top-color: transparent;
  border-bottom-color: initial;
  margin-top: calc(0.5em - 4px);
}
.b-bc-search-dropdown-btn .fa {
  font-size: 22px;
  line-height: inherit;
}
.b-bc-search-dropdown-menu {
  box-shadow: none;
  position: absolute;
  background: #fff;
  border: 0;
  border-bottom: 1px solid #eeeeee;
  padding: 13px 0 20px;
  margin: 0;
  right: 0;
  left: 0;
  top: 100%;
  z-index: 100;
  display: none;
}
.b-bc-search-dropdown-menu.open {
  display: block;
}
.b-cat-breadcrumbs {
  padding: 0 0 30px;
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: 100;
  border-bottom: 1px solid #eeeeee;
}
.b-cat-breadcrumbs-navigator {
  padding: 0;
  font-size: 0.01px;
}
.b-cat-breadcrumbs-actions {
  padding: 20px 0 0;
}
.b-cat-breadcrumbs-item {
  display: inline-block;
  vertical-align: bottom;
  font-size: 36px;
}
.b-cat-breadcrumbs-item,
.b-cat-breadcrumbs-item:hover {
  color: inherit;
  text-decoration: none;
}
.b-cat-breadcrumbs-item + .b-cat-breadcrumbs-item {
  margin-left: 0.25em;
}
.b-cat-breadcrumbs-item + .b-cat-breadcrumbs-item:before {
  content: "/";
  color: inherit;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 0 0 0.25em;
}
.b-cat-breadcrumbs-item__parent {
  font-size: 31.5px;
}
.b-cat-breadcrumbs-item__current {
  font-size: 27px;
}
.b-cat-breadcrumbs-item-text {
  border-bottom: 1px solid transparent;
}
.b-cat-breadcrumbs-item__link:hover .b-cat-breadcrumbs-item-text {
  border-bottom: 1px solid;
}
.b-cat-breadcrumbs-item-text__color__default {
  color: #0063b0;
}
.b-cat-breadcrumbs-item-text__color__primary {
  color: #0063b0;
}
.b-cat-breadcrumbs-item-text__color__secondary {
  color: #56c5cc;
}
.b-cat-breadcrumbs-item-text__color__danger {
  color: #b2234b;
}
.b-cat-breadcrumbs-item-text__color__info {
  color: #2e729e;
}
.b-cat-breadcrumbs-item-text__color__warning {
  color: #f4821f;
}
.b-cat-breadcrumbs-item-text__color__critical {
  color: #dd191d;
}
.b-cat-breadcrumbs-item-text__color__success {
  color: #6ec256;
}
.b-cat-breadcrumbs-item-text__color__disabled {
  color: #777777;
}
.b-cat-breadcrumbs-actions-btn__back .fa {
  margin-right: 0.5em;
  font-weight: bold;
}
.page:before,
.page:after {
  content: " ";
  display: table;
}
.page:after {
  clear: both;
}
.page.process {
  position: absolute;
  left: -999999px;
}
.modal-loader {
  background: url('../../../images/lktoepgu/loader-small.gif') center center no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.fade.in .modal-loader {
  opacity: 1;
}
.ready.in .modal-loader {
  opacity: 0 !important;
}
.modal-dialog,
.modal-columns {
  /*.modal-columns used to separate .modal-dialog from modal side bar (modal actions & etc)*/
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.modal .modal-dialog,
.modal .modal-columns {
  max-width: 1000px;
  margin: 80px auto;
}
.modal-sm .modal-dialog {
  width: auto;
  max-width: 450px;
}
.modal-dialog--linkedobj {
  max-width: 600px;
}
.modal-dialog--linkedobj .collapser {
  display: none;
}
.modal-dialog--linkedobj .subgroup-title {
  cursor: initial;
}
.modal-dialog--linkedobj .attr-label--inline + .attr-value,
.modal-dialog--linkedobj .attr-label--inline {
  display: block;
  width: 100%;
}
.modal-dialog--linkedobj .attr-value .docsbord {
  /*margin:0;*/
  margin: 10px 0 20px;
  width: 100%;
}
.modal-dialog--linkedobj .subgroup-holder,
.modal-dialog--linkedobj .subgroups-list {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0;
  box-shadow: none;
  border: none;
}
.modal-dialog--linkedobj .subgroups-list {
  margin-top: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 20px;
}
.modal-dialog--linkedobj .subgroup-holder-outer {
  padding-top: 0;
  padding-left: 0;
}
.modal-dialog--linkedobj .subgroups-list:first-of-type {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}
.modal .modal-dialog {
  max-width: 700px;
  min-width: 550px;
  width: 75%;
  background-color: #fff;
  border: 0;
  border-radius: 3px;
  padding: 75px 55px 40px;
}
.modal--alert .modal-dialog {
  max-width: 550px;
}
.modal--alert-wide .modal-dialog {
  max-width: 620px;
}
.modal--alert .modal-body {
  color: #839199;
}
.modal--alert-draft-restore .modal-dialog {
  max-width: 600px;
}
.modal--alert-draft-restore .modal-dialog .modal-title {
  text-align: left;
}
.modal .modal-header {
  padding: 0;
  margin: 0 auto;
  text-align: left;
}
.modal .modal-title {
  text-align: left;
  font-size: 24px;
  line-height: 1.3em;
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: 900;
}
.modal .modal-body {
  padding: 35px 0;
}
.modal .modal-footer {
  text-align: left;
  padding: 35px 0 0;
}
.modal .modal-footer .btn {
  min-width: 30%;
  margin: 0 0;
}
.modal .modal-footer .btn + .modal .modal-footer .btn {
  margin-left: 5%;
}
.modal--service .modal-body {
  min-height: 6em;
}
.modal-body > .alert:first-child:last-child {
  margin-bottom: 0;
}
.modal-columns .modal-dialog {
  float: left;
  width: 100%;
  margin: 0;
  margin-right: -322px;
  padding-right: 322px;
}
.modal-columns .modal-actions {
  width: 322px;
  /**/
  padding-left: 42px;
  float: right;
  position: relative;
  z-index: 1;
}
.modal-actions .btn {
  width: 100%;
}
.modal-actions .btn + .btn {
  margin-top: 10px;
}
.modal-content {
  position: relative;
  outline: none;
  max-width: 900px;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  color: #333333;
}
.modal-backdrop.loader + .modal-backdrop.loader,
.modal-backdrop.loader ~ .modal-backdrop.loader {
  display: none;
  /* only single loader visible at time */
}
.modal-backdrop.loader {
  z-index: 9999;
  background-color: #fff;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.in {
  opacity: 0.55;
}
.modal-backdrop.loader.in {
  opacity: 0.85;
}
.modal-header {
  display: block;
  position: relative;
  padding: 60px 120px 0 0;
  margin: 0 0 45px;
  font-size: 34px;
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: normal;
}
.modal-header:before,
.modal-header:after {
  content: " ";
  display: table;
}
.modal-header:after {
  clear: both;
}
.modal-header--no-pad {
  padding-top: 0;
}
.modal-header--no-all-pad {
  padding-top: 0;
  padding-right: 0;
}
.modal-header__bordered {
  margin-bottom: 30px;
  border-bottom: 1px solid #cdd1d4 !important;
  padding-bottom: 40px;
}
.modal-header__no-icon {
  padding-left: 0;
}
.modal--service .modal-header {
  border: 0;
  margin-bottom: 30px;
}
.modal--department .modal-header {
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.modal-header-icon {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
}
.modal-header-icon-svg {
  width: 100%;
  height: 100%;
  fill: #56c5cc;
}
.modal-header-icon-svg--equeue {
  fill: #0063b0;
}
.modal-header-icon-pelmen {
  width: 71px;
  height: 79px;
  position: absolute;
  left: -0.5px;
  top: -24px;
  background: url('../../../images/lktoepgu/pelmen-icon.png') 0 0 no-repeat;
  background-size: 71px 79px;
}
.modal-header-filters {
  display: block;
  margin: 37px 0 -5px;
}
.modal-header-filter {
  display: inline-block;
}
.modal-header-filter-spacer {
  width: 27px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.modal-header-filter-label {
  font-weight: inherit;
  font-family: inherit;
  font-size: inherit;
}
.modal-header-actions {
  display: block;
  padding: 40px 0 0;
}
.modal-header-actions .btn--submit {
  float: right;
}
.modal-header-actions .g-action-link--add {
  float: left;
}
.modal-sm .modal-header:after {
  /* used for signin, signup, lostpwd  */
  width: 160px;
  height: 1px;
  display: block;
  margin: 15px auto 0 auto;
  background: #BEBEBE;
  /*background:rgba(0,0,0,.05); */
  content: '';
}
.modal-title,
.modal-subtitle {
  display: block;
  text-align: left;
  font-weight: normal;
}
.modal-title {
  margin: 0;
  line-height: 46px;
  font-size: 40px;
  font-family: "als_ekibastuz_light", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: normal;
}
.modal-title .modal-subtitle {
  margin-top: 7px;
}
.modal-title ._placeholder + ._placeholder {
  margin-top: 10px;
}
.modal-title-inner {
  min-height: 70px;
  display: table;
  width: 100%;
}
.modal-title-inner-text {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}
.modal-subtitle {
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 20.875px;
  line-height: 36px;
  margin: 0;
  padding: 0;
}
.modal-datetime {
  height: 28px;
  line-height: 28px;
  margin-top: 7px;
  /*.modal-title > .line margin*/
  white-space: nowrap;
  color: #fff;
  padding-left: 38px;
  /*background: url('../../images/sunshine/ico/clock-white.png') 0 50% no-repeat;*/
  font-size: 18px;
  text-transform: uppercase;
  display: block;
  float: right;
}
.modal-sm .modal-title {
  line-height: 1.42857143;
  /* ??? */
  text-align: center;
  max-width: 100%;
}
.modal-body {
  position: relative;
  padding-top: 30px;
}
.modal-sm .modal-body {
  padding: 20px 70px;
}
.modal-body--not-padded {
  padding: 0;
}
.modal-nav {
  margin-bottom: 55px;
}
.modal-nav__stretch {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.modal-nav__stretch:before,
.modal-nav__stretch:after {
  content: initial !important;
  display: none !important;
  clear: none !important;
}
.modal-nav__stretch > li {
  display: table-cell;
}
.modal-nav__stretch > li > a {
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-filter-bar {
  margin: 0 0 13px;
  width: 100%;
}
.modal-filter-bar:before,
.modal-filter-bar:after {
  content: " ";
  display: table;
}
.modal-filter-bar:after {
  clear: both;
}
.modal-body-headcrumbs {
  margin: 35px 0 0;
}
.modal-body-headcrumbs:first-child {
  margin: 0;
}
.modal-body-headcrumbs-actions .btn {
  text-align: center;
  font-size: 17px;
  margin-top: 15px;
}
.modal-body-headcrumbs-actions .btn .fa {
  margin-left: 14px;
  font-weight: bold;
}
.modal-body-headcrumbs-layout {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.modal-body-headcrumbs-col {
  display: table-cell;
  vertical-align: middle;
}
.modal-body-headcrumbs-col__btn {
  width: 165px;
  vertical-align: top;
  padding-top: 20px;
}
.modal-body-headcrumbs-col__btn .btn {
  max-width: 165px;
}
.modal-body-headcrumbs-col__title {
  line-height: 1.3em;
}
.modal-body-headcrumbs-col__title:first-child {
  padding-left: 41px;
}
.modal-body-headcrumbs-title {
  font-weight: normal;
  font-family: "als_ekibastuz_bold", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 24px;
  line-height: 27px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.modal-body-content > .group-holder {
  padding-left: 0;
}
.modal-body-content-title,
.modal-body-content-text {
  color: #4d4d4d;
}
.modal-body-content-title {
  text-transform: none;
  font-weight: bold;
  margin: 30px 0;
}
.modal-body-content-title:first-letter {
  text-transform: uppercase;
  /*@see MSMEV-4047*/
}
.modal-body-content-text {
  line-height: 20px;
}
.modal-body-actions {
  overflow: auto;
  /*clearfix*/
  width: 100%;
}
.modal-body-actions .btn-action--prev {
  float: left;
}
.modal-body-actions .btn-action--next {
  float: right;
}
.modal-body-actions--layout {
  display: table;
}
.modal-body-actions-cell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}
.modal-body-actions-cell--middle {
  padding: 0 1em;
  width: 100%;
}
.modal-body-actions-cell--left {
  text-align: left;
}
.modal-body-actions-cell--right {
  text-align: right;
}
.modal-body-actions-cell .btn {
  min-width: 125px;
}
.modal-body-actions-cell .btn + .btn {
  margin-left: 15px;
}
.modal-body-header {
  display: block;
  width: 100%;
}
.modal-body-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 45px 0 0;
  z-index: 1;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}
.modal-body-footer--one-col {
  max-width: 580px;
  padding: 28px 0 0;
}
.modal-body-footer--static {
  position: relative;
}
.modal-body-footer-msg {
  display: inline-block;
  margin: 0 auto;
  color: #ff5145;
  /*font-size: 80%;*/
  text-align: center;
  text-transform: uppercase;
  line-height: 1.25em;
  padding: 7px;
}
.modal-body-columns {
  position: relative;
}
.modal-body-columns--max {
  max-width: 1050px;
}
.modal-body-columns--layout {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.modal-body-column--sidebar,
.modal-body-column--content {
  display: table-cell;
  vertical-align: top;
  position: relative;
  padding-bottom: 100px;
}
.modal-body-column--sidebar .modal-body,
.modal-body-column--content .modal-body {
  opacity: 1;
  transition: opacity 0.4s;
}
.modal-body-content--loading .modal-body {
  opacity: 0;
}
.modal-body-content--loading:after {
  background: url('../../../images/lktoepgu/loader-big.gif') no-repeat scroll 50% 200px transparent;
  display: block;
  min-height: 300px;
  padding-top: 120px;
  text-align: center;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.modal-body-column--content--fixed-max {
  max-width: 1060px;
}
.modal-body-column--content-one-col {
  display: block;
  padding-bottom: 100px;
  max-width: 580px;
}
.modal-body-column--content-one-col-not-max {
  display: block;
  padding-bottom: 100px;
}
.modal-body-column--content {
  /*float:right;*/
  /*margin-left:-260px;*/
  /*padding-left: 260px;*/
  /*position: relative;*/
  width: 100%;
  /*z-index: +1;*/
}
.modal-body-column--sidebar {
  width: 215px;
  /*position: absolute;*/
  /*left:0;*/
  /*top:0;*/
  /*bottom:0;*/
  z-index: 2;
}
.modal-body-info {
  font-size: 105%;
  margin-top: -40px;
  margin-bottom: 40px;
}
.mbi-date,
.mbi-time,
.mbi-separator,
.mbi-category,
.mbi-author {
  display: inline-block;
}
.mbi-category,
.mbi-author,
.mbi-category:hover,
.mbi-author:hover {
  color: #003864;
}
.mbi-time {
  margin: 0 0.5em;
}
.mbi-separator {
  margin: 0 0.5em;
}
.modal-sm .modal-body > form {
  padding-top: 10px;
}
.modal-footer {
  padding: 19px 50px 20px 50px;
  border-top: 1px solid #cdd1d4;
}
.modal-footer .or {
  display: inline-block;
  text-transform: lowercase;
  vertical-align: top;
  margin: 0 0.5em;
}
.modal-footer .or + .btn {
  margin-left: -24px;
}
.modal-footer__no-border {
  padding-top: 60px;
  border-top: 0;
}
.modal-footer__no-side-padding {
  padding-left: 0;
  padding-right: 0;
}
.modal-sm .modal-footer {
  padding-bottom: 20px;
  text-align: right;
}
.modal-footer--no-border,
article.modal-body + .modal-footer {
  /**/
  border-top: 0;
}
.modal-footer .btn + .btn {
  margin-left: 5%;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modal-footer a {
  color: #4d4d4d;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.modal-dialog--ts-add {
  max-width: 600px;
}
.modal-dialog--ts-add .modal-title {
  text-align: left;
}
.modal-close {
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 20px;
  right: 20px;
  border-radius: 100%;
  border: 1px solid #CDD1D4;
  background: #fff;
}
.modal-close:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00d";
  color: #839199;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.modal--alert-draft-restore .modal-footer {
  white-space: nowrap;
}
.modal--alert-draft-restore .modal-footer .btn {
  white-space: initial;
  word-break: break-word;
}
.ui-dialog {
  position: absolute;
  top: 0;
  left: 0;
  outline: 0;
  padding: 50px 55px 40px;
  border: 0 none;
  background: #ffffff;
  border-radius: 3px;
}
.ui-dialog .ui-dialog-titlebar {
  border: 0 none;
  background: transparent;
  padding: 0;
  margin: 0 0 20px 0;
}
.ui-dialog .ui-dialog-title {
  text-align: left;
  font-size: 24px;
  line-height: 1.3em;
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: 900;
  padding-right: 50px;
  display: block;
}
.ui-dialog .ui-dialog-titlebar-close {
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 12px;
  right: 12px;
  border-radius: 100%;
  border: 1px solid #CDD1D4;
  background: #fff;
  font-size: 0;
}
.ui-dialog .ui-dialog-titlebar-close span {
  display: none;
}
.ui-dialog .ui-dialog-titlebar-close:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00d";
  color: #839199;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  font-size: 16px;
  margin: -1px 0 0 1px;
}
.ui-dialog .ui-dialog-content {
  border: 0 none;
  padding: 0;
}
.ui-dialog .modal-body {
  padding: 0;
}
.modal-page-content-wrapper {
  display: block;
  max-width: 900px;
  position: relative;
  margin: 0 auto;
}
.modal-page-content-wrapper--wide {
  max-width: 1160px;
}
.modal-page-content-wrapper--grey {
  background: #f6f6f6;
  padding: 70px 0;
  margin: 70px auto;
}
.modal-page-content-wrapper--grey:before,
.modal-page-content-wrapper--grey:after {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  background: #f6f6f6;
  left: 100%;
  right: -9999px;
  content: "";
}
.modal-page-content-wrapper--grey:after {
  right: 100%;
  left: -9999px;
}
.modal-page-content-wrapper-inner-mid {
  display: block;
  max-width: 900px;
  position: relative;
  margin: 0;
}
.modal-dialog__location_choose .jq-selectbox {
  z-index: auto !important;
}
/*
transparent modals // t-modals (modals without .modal-dialog)
*/
.t-modal-wrapper {
  color: #333333;
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  height: 100%;
}
/*.t-modal-wrapper td {  height: 0;  }*/
.t-modal-wrapper a {
  color: inherit;
  cursor: pointer;
}
.t-modal-header {
  padding: 0 0 30px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 35px;
}
.t-modal-title {
  font-weight: normal;
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 34.2px;
  margin: 0;
}
.t-modal-search-header {
  margin: 8px 0 -7px;
}
.t-modal-search-header-title {
  text-transform: uppercase;
  font-size: 12px;
}
/*.t-modal-submit--location {background-image: url('../../images/sunshine/ico/geo.png')}*/
.t-modal-content {
  padding: 50px 0 60px;
  position: relative;
}
.t-modal-wrapper .t-modal-content,
.t-modal-wrapper .t-modal-content-outer,
.t-modal-wrapper .t-modal-content-inner {
  height: 100%;
  width: 100%;
}
.t-modal-wrapper .t-modal-content-inner {
  position: absolute;
  top: 0;
  left: 0;
}
.t-modal-content-inner {
  overflow: hidden;
  min-height: 4.5em;
}
/*
t modal actions
*/
.t-modal-actions {
  position: relative;
  /*padding: 45px 0 10px;*/
  padding: 0 0 10px;
}
.t-modal-actions .tm-back-link {
  z-index: 2;
  position: absolute;
  /*top: 50px;*/
  top: 5px;
  left: 80px;
}
.t-modal-action-links {
  position: relative;
  display: block;
  text-align: center;
  font-size: 0.01px;
}
.t-modal-action-links--bottom {
  font-size: inherit;
  padding: 55px 0 30px;
}
.t-modal-action-links--choosen-location {
  font-size: 22px;
  position: absolute;
  /*top: 45px;*/
  top: 0;
  width: 50%;
  text-align: center;
  left: 25%;
  z-index: -1;
  pointer-events: none;
}
.t-modal-action-links--choosen-location.in {
  z-index: 1;
  pointer-events: auto;
}
.t-modal-action-link {
  display: inline-block;
  text-transform: uppercase;
  font-size: 18px;
  /* @todo : set font size from mockup */
}
.t-modal-action-link + .t-modal-action-link {
  margin-left: 15px;
}
.t-modal-search-container .t-modal-search-actions {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 10px;
  font-size: 16px;
  white-space: nowrap;
}
.link-search-action {
  position: relative;
  white-space: nowrap;
  text-transform: uppercase;
  padding-left: 45px;
  text-decoration: none !important;
  margin-left: 1.5em;
}
.link-search-action:hover span {
  border-bottom: 1px solid;
}
.link-search-action:before {
  content: "";
  display: inline-block;
  width: 39px;
  height: 22px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px;
  background-position: 0 0;
  background-repeat: no-repeat;
}
/*
t modal layouts
*/
.t-modal-layout {
  width: 100%;
  font-size: 0.01px;
  position: relative;
  table-layout: fixed;
  height: 100% !important;
}
.t-modal-layout-error {
  text-align: center;
  font-size: 28px;
  display: block;
  padding: 20px;
}
.t-modal-layout-item {
  color: #333333;
  text-decoration: none !important;
}
.t-modal-layout .t-modal-layout-item {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 70px;
  min-height: 150px;
}
.t-modal-layout .t-modal-layout-tab[data-type="preferentialRecipientType"] .t-modal-layout-item {
  margin-bottom: 1em;
  /* @see MSMEV-4295 */
}
.t-modal-layout-item {
  font-size: 14px;
  position: relative;
  padding-left: 109px;
  padding-right: 45px;
}
.t-modal-layout--categories .t-modal-layout-item {
  width: 33.33%;
}
.t-modal-layout--locations .t-modal-layout-item {
  width: 33.33%;
}
.t-modal-layout--locations .t-modal-layout-item--dep-region .t-modal-layout-item-link,
.t-modal-layout--locations .t-modal-layout-item--dep-region .t-modal-layout-item-link > span {
  white-space: normal;
}
.t-modal-layout-item-count {
  z-index: +1;
  cursor: default;
  pointer-events: none;
  position: absolute;
  right: 1px;
  top: 4px;
  background: #0063b0;
  color: #fff;
  font-size: 14px;
  line-height: 1em;
  white-space: nowrap;
  border-radius: 12px;
  padding: 5px 8px;
}
.t-modal-layout-item-count-link {
  display: block;
  margin: 8px 0 0;
  cursor: pointer;
  color: #0063b0;
}
.t-modal-layout-item-count-link:hover {
  color: #003864;
}
.t-modal-layout-item-count-link__default {
  color: #0063b0;
}
.t-modal-layout-item-count-link__primary {
  color: #0063b0;
}
.t-modal-layout-item-count-link__secondary {
  color: #56c5cc;
}
.t-modal-layout-item-count-link__danger {
  color: #b2234b;
}
.t-modal-layout-item-count-link__info {
  color: #2e729e;
}
.t-modal-layout-item-count-link__warning {
  color: #f4821f;
}
.t-modal-layout-item-count-link__critical {
  color: #dd191d;
}
.t-modal-layout-item-count-link__success {
  color: #6ec256;
}
.t-modal-layout-item-count-link__disabled {
  color: #777777;
}
.t-modal-layout-item-icon {
  position: absolute;
  width: 88px;
  height: 95px;
  top: 0;
  left: 0;
}
.t-modal-layout-item-icon-img {
  width: 100%;
}
.t-modal-layout-item-title {
  position: relative;
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3em;
  margin: 0 0 8px;
}
.t-modal-layout-item-title-inner {
  min-height: 95px;
  vertical-align: middle;
  display: table-cell;
}
.t-modal-layout-item-desc {
  display: block;
  margin: 10px auto 0;
  color: #a8a8a8;
}
.t-modal-layout-item-title__default {
  color: #0063b0;
}
.t-modal-layout-item-title__primary {
  color: #0063b0;
}
.t-modal-layout-item-title__secondary {
  color: #56c5cc;
}
.t-modal-layout-item-title__danger {
  color: #b2234b;
}
.t-modal-layout-item-title__info {
  color: #2e729e;
}
.t-modal-layout-item-title__warning {
  color: #f4821f;
}
.t-modal-layout-item-title__critical {
  color: #dd191d;
}
.t-modal-layout-item-title__success {
  color: #6ec256;
}
.t-modal-layout-item-title__disabled {
  color: #777777;
}
.t-modal-layout-item-title.focused {
  background: #ffffff;
  color: #000;
}
.t-modal-layout-item-title--letter {
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: 100;
  font-size: 36px;
  text-transform: uppercase;
}
.t-modal-layout-item__disabled,
.t-modal-layout-item-link__disabled {
  color: #6A6A6A !important;
  cursor: not-allowed !important;
}
.t-modal-layout-item__disabled .line {
  background-color: #6A6A6A !important;
}
.t-modal-layout-item__disabled a {
  cursor: inherit !important;
}
.t-modal-layout-item-link__disabled:hover > span {
  background: none !important;
}
.t-modal-layout-item-link {
  max-width: 110%;
  min-width: 51%;
  position: relative;
  display: inline-block;
  text-decoration: none !important;
  padding: 0.5em 0;
  color: inherit;
}
.t-modal-layout-item-link:after {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}
.t-modal-layout-item-link .t-modal-layout-item-link-text {
  display: inline-block;
  max-width: 95%;
  vertical-align: middle;
  width: auto;
}
.t-modal-layout-item-link-text {
  display: block;
  white-space: nowrap;
  text-decoration: none !important;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.t-modal-layout-item-link.tm-link {
  margin-left: -10px;
  /* move left for padding-left value */
}
.t-modal-layout--categories .t-modal-layout-item-title {
  cursor: pointer;
}
.t-modal-tags {
  margin-top: 16px;
}
.t-modal-layout-item .tm-tags > .focused {
  border-color: transparent;
  background-color: #fff;
}
.t-modal-layout-tab {
  top: 0;
  left: 200%;
  width: 100%;
  height: 100%;
  transition: all 0.35s ease;
  font-size: 0.01px;
}
.t-modal-layout-tab.selected--prev,
.t-modal-layout-tab:first-child {
  left: -200%;
}
.t-modal-layout-tab.selected {
  left: 0;
}
.t-modal-layout-block-location-choose {
  display: block;
  margin: 0 0 35px;
}
.t-modal-layout-block-location-choose .g-local-link {
  margin-left: 0.7em;
  margin-right: 2em;
}
/*
Modal blocks
*/
.modal-block {
  position: relative;
  padding: 26px 15px 26px 11px;
  width: 100%;
}
.modal-block:first-child {
  padding-top: 0;
}
.modal-block .btn {
  text-align: center;
  font-size: 17px;
}
.modal-block .btn .fa {
  margin-left: 14px;
  font-weight: bold;
}
.modal-body-headcrumbs-actions .btn {
  text-align: center;
  font-size: 17px;
  margin-top: 15px;
}
.modal-body-headcrumbs-actions .btn .fa {
  margin-left: 14px;
  font-weight: bold;
}
.modal-block-blocked-info {
  display: none;
}
.modal-block-blocked-info-inner {
  display: table;
  width: 100%;
  height: 100%;
}
.modal-block-blocked-info-text {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  font-weight: bold;
  padding: 20px;
  /*cursor: not-allowed;*/
}
.modal-block + .modal-block {
  border-top: 1px solid #cdd1d4;
}
.modal-block--loading:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
}
.modal-block--card + .modal-block--card {
  margin-top: 0;
}
.modal-block--default {
  color: #333333;
  background-color: #eeeeee;
}
.modal-block--danger {
  color: #fff;
  background-color: #b2234b;
}
.modal-block--disabled {
  opacity: 0.75;
  /*cursor: not-allowed;*/
  color: #777777 !important;
}
.modal-block--disabled .btn {
  pointer-events: none;
}
.modal-block--blocked {
  position: relative;
}
.modal-block--blocked .modal-block-blocked-info {
  display: block;
  position: absolute;
  z-index: 1;
  left: -1px;
  right: -1px;
  bottom: -1px;
  top: -1px;
  /*background: rgba(255,255,255,.9);*/
  background: #fff;
}
.modal-block-header {
  text-transform: uppercase;
}
.modal-block--card .modal-block-header {
  min-height: 50px;
}
.modal-block-title {
  margin: 0;
  padding: 5px 0;
  position: relative;
  font-family: 'Open Sans Bold', sans-serif;
  font-weight: 100;
  font-size: 18px;
  line-height: 20px;
}
.modal-actions .modal-block-title {
  padding-bottom: 15px;
}
.modal-block-title:after {
  content: "";
  position: absolute;
  width: 70px;
  height: 0;
  border-bottom: 3px solid;
  top: 100%;
  margin-top: 2px;
  left: 0;
}
.modal-block--doc_templates .modal-block-title:after {
  display: none !important;
}
/*.modal-block .g-tile-anchor {*/
/*left:0;*/
/*right:0;*/
/*}*/
.modal-block .btn-opaque + .btn-opaque {
  border-top: 0;
}
.modal-block .g-tile-status-title {
  text-shadow: none;
}
.modal-block .g-tile-status {
  top: 85px;
  z-index: 1;
  padding: 0;
}
.modal-block .g-tile-status-icon {
  width: 50px;
  height: 50px;
  background-size: 50px;
  border-width: 2px;
  margin: 5px auto;
}
.modal-block .modal-block-footer .g-tile-anchor {
  left: 0;
  right: 0;
  padding: 0 30px 0 0;
  font-size: 12px;
}
.b-modal-right-list {
  margin-top: 25px;
  max-height: 670px;
}
.b-modal-right-list a {
  margin: 0 0 25px 0;
  display: block;
}
.b-modal-right-list.mCustomScrollbar {
  margin-right: -10px;
}
.modal-block-wrapper {
  /*min-height: 215px;*/
  position: relative;
}
.modal-block-sm .modal-block-wrapper {
  min-height: initial;
  margin-bottom: 0;
}
.modal-block-content {
  /*margin-top:33px;*/
  margin-top: 23px;
  line-height: 20px;
}
.modal-block--doc_templates .modal-block-content {
  /*margin-top: 15px;*/
  margin-top: 18px;
}
.modal-block-content.scroll {
  max-height: 257px;
}
.modal-header-preamble-text:after,
.modal-block-content.scroll:after {
  content: "";
  position: absolute;
  bottom: 0;
  max-height: 76px;
  height: 100%;
  left: 0;
  right: 0;
  /*background-image: url('../../images/sunshine/bg/service-info-bottom-loop.png');*/
  background-position: center bottom;
  background-repeat: repeat-x;
  background-size: auto 100%;
  z-index: 1;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.35s ease;
}
.modal-block-content p {
  margin: 0;
}
.modal-block-content ol {
  display: block;
  padding: 0 10px 0 20px;
  margin-left: 0;
  line-height: 1em;
}
.modal-block--doc_templates .modal-block-content ol {
  list-style: none;
  counter-reset: item;
}
.modal-block--doc_templates .modal-block-content ol li:before {
  content: counter(item) ". ";
  display: inline-block;
  counter-increment: item;
  width: 20px;
  text-align: left;
  position: absolute;
  top: 0;
  left: -20px;
}
.modal-block-content ol li + li {
  margin-top: 1em;
}
.modal-block-content ol strong {
  display: block;
  padding: 10px 0;
}
.modal-block-footer {
  /*font-family: 'Open Sans Bold', sans-serif;*/
  /*font-weight: bold;*/
  margin-top: 30px;
  position: relative;
  min-height: 40px;
}
.modal-block--card .modal-block-footer {
  margin-top: 0;
}
.modal-block--card .modal-block-footer .btn {
  padding: 12px;
  line-height: 14px;
  border: 1px solid;
  font-family: inherit;
}
.modal-block--card .modal-block-footer .btn--load-draft {
  position: absolute;
  top: -44px;
  left: 0;
  right: 0;
}
.modal-block-footer .btn + .btn {
  margin-top: 10px;
}
.modal-block--card .modal-block-footer .btn--load-draft + .btn {
  margin-top: 0;
}
/* overwrite style for hidden */
.modal-block-footer .hidden.btn + .btn {
  margin-top: 0;
}
.modal-block--card .equeue-date {
  font-family: 'Open Sans Semibold';
  display: block;
  margin: 0 0 0.5em;
}
.modal-block--card .equeue-contacts {
  line-height: 1.25em;
}
.modal-block--equeue {
  border-top: none !important;
  padding-top: 0;
}
.modal-block--doc_templates {
  overflow: hidden;
}
.modal-block--doc_templates--hidden {
  height: 0;
}
.modal-block--doc_templates .modal-block-footer {
  margin-top: 10px;
}
.modal-block--what_to_do .modal-block-content,
.modal-block--doc_templates .modal-block-content {
  /*letter-spacing: -0.03em;*/
  /*line-height: 14px;*/
}
.doc_template-actions {
  font-size: 12px;
  line-height: 15px;
  display: block;
}
.doc_template-actions,
.doc_template-actions .g-local-link {
  color: #808080;
}
.modal-header-section {
  margin: 35px -33px /*-35px margin + 2px border*/ 0;
  overflow: visible;
}
.modal-header-section:after {
  display: table;
  clear: both;
  content: "";
}
.modal-header-section .modal-block--card {
  margin: 0 5px 5px;
}
.modal-block--card {
  vertical-align: top;
  /*display: inline-block;*/
  border: 1px solid;
  width: 225px;
  float: left;
  margin: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #4d4d4d;
}
.modal-block--card .modal-block-content {
  margin-top: 21px;
}
.modal-header-progress {
  display: none;
}
.modal-header-progress .g-sector {
  background: #fff;
}
.modal-header-progress .g-sector--active {
  /*background: #c4c4c4;*/
  background: #0cca39;
}
/*
Petition modal
*/
.modal-dialog--petition input[type=text],
.modal-dialog--petition input[type=date],
.modal-dialog--petition input[type=password],
.modal-dialog--petition select,
.modal-dialog--petition textarea {
  max-width: 100%;
}
.modal-dialog--petition input.hasDatepicker[type="text"] {
  /*background-image:url(../../images/sunshine/ico/calendar.png)!important;*/
  background-repeat: no-repeat !important;
  background-position: 98% 45% !important;
}
.modal-dialog--petition .attr-value--helper input.hasDatepicker[type="text"] {
  background-position: 85% 45% !important;
}
.modal-block-service-actions p {
  margin-top: 0;
}
.modal-block-service-actions .btn {
  margin-bottom: 15px;
}
.modal--alert-ecp .modal-dialog {
  max-width: 600px;
}
.g-ecp-cert-list {
  margin: 0;
  padding: 0;
}
.g-ecp-cert-link {
  display: block;
  width: 100%;
  white-space: normal;
  word-break: break-all;
  font-weight: 100;
}
.g-ecp-cert-link + .g-ecp-cert-link {
  margin-top: 20px;
}
.timelisting {
  /*color: #595652;*/
  cursor: pointer;
  display: inline-block;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  padding: 6px 15px;
  margin: 5px 15px;
  min-width: 120px;
  color: #0063b0;
  border: 1px solid #edf0f2;
  box-shadow: none;
  border-radius: 6px;
}
.timelisting:hover {
  background: #f7f9f9;
  border-color: #edf0f2;
}
.timelisting:focus,
.timelisting:active {
  color: #0063b0;
  border-color: #0063b0;
}
.timelisting.active {
  border-color: #0063b0;
  background: #0063b0;
  color: #fff;
}
.timelisting input {
  display: none;
}
.timelisting-container {
  margin: 0 -15px;
}
.timelisting-label {
  font-size: 14px;
  display: block;
  margin: 35px 0 20px;
  color: #737b81;
  border-bottom: 1px solid #edf0f2;
  padding: 0 0 10px;
}
.timelisting--period {
  font-size: 14px;
}
.featherlight-loading .featherlight-content {
  background-color: transparent;
}
.featherlight-loading .featherlight-previous,
.featherlight-loading .featherlight-next {
  display: none;
}
.featherlight:last-of-type {
  background: rgba(255, 255, 255, 0.65);
}
.security-roles-list {
  margin: 20px 0;
}
.user-files-table {
  width: 100%;
}
.user-files-table .g-download {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 20px;
}
.b-profile-counter {
  display: inline-block;
  margin: 0 0 0 4px;
  color: #ffffff;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
  text-align: center;
  background: #b2234b;
  min-width: 20px;
  padding: 0 4px;
  border-radius: 20px;
  font-weight: normal;
}
.b-common-list-item {
  margin-bottom: 20px;
}
.b-plain-text {
  font-size: 15px;
  line-height: 20px;
}
select {
  width: 100%;
}
.page__list,
.paging_simple_numbers,
.b-pagination-holder {
  display: block;
  padding: 0;
  margin: 20px 0 0;
}
.page__list:before,
.paging_simple_numbers:before,
.b-pagination-holder:before,
.page__list:after,
.paging_simple_numbers:after,
.b-pagination-holder:after {
  content: " ";
  display: table;
}
.page__list:after,
.paging_simple_numbers:after,
.b-pagination-holder:after {
  clear: both;
}
.page__item {
  display: block;
  float: left;
  margin-right: 10px;
  font-family: "als_ekibastuz_bold", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 14px;
}
.page__item a,
.page__item .page__link {
  display: block;
  float: left;
  min-width: 30px;
  height: 30px;
  padding: 0 3px;
  border: 1px solid #cdd1d4;
  border-radius: 2px;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
}
.page__item .page__link_current {
  color: #fff;
  background: #6ba9d2;
  border-color: #6ba9d2;
}
.page__item.page__item__ellipsis .page__link {
  color: #0173c1;
}
.paginate_button {
  display: block;
  float: left;
  margin-right: 10px;
  font-family: "als_ekibastuz_bold", "als_ekibastuz_bold", Arial, sans-serif;
  min-width: 30px;
  height: 30px;
  padding: 0 3px;
  border: 1px solid #cdd1d4;
  border-radius: 2px;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
}
.paginate_button.current {
  color: #fff;
  background: #6ba9d2;
  border-color: #6ba9d2;
}
.b-pagination-holder a {
  display: block;
  float: left;
  margin-right: 10px;
  font-family: "als_ekibastuz_bold", "als_ekibastuz_bold", Arial, sans-serif;
  min-width: 30px;
  height: 30px;
  padding: 0 3px;
  border: 1px solid #cdd1d4;
  border-radius: 2px;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
}
.b-pagination-holder a.jp-current {
  color: #fff;
  background: #6ba9d2;
  border-color: #6ba9d2;
}
.b-pagination-holder a.jp-disabled {
  opacity: 0.5;
}
.jp-invisible {
  visibility: hidden !important;
}
.jp-hidden {
  display: none !important;
}
.ellipsis {
  display: block;
  float: left;
  margin-right: 10px;
  font-family: "als_ekibastuz_bold", "als_ekibastuz_bold", Arial, sans-serif;
  min-width: 30px;
  height: 30px;
  padding: 0 3px;
  border: 1px solid #cdd1d4;
  border-radius: 2px;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
  color: #0173c1;
}
.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}
.ui-front {
  z-index: 100;
}
.ui-datepicker {
  display: none;
  padding: 0;
  background: #ffffff;
  border: 1px solid #cdd1d4;
  border-radius: 0;
  font-family: Arial, sans-serif;
  z-index: 5000 !important;
}
.ui-datepicker table {
  padding: 0;
  margin: 0;
  width: 100%;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  display: block;
  position: absolute;
  left: 15px;
  top: 50%;
  font-size: 18px;
  line-height: 20px;
  margin: -10px 0 0;
  color: #565656;
  background: transparent;
  border: 0 none;
  width: auto;
  height: auto;
}
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  border: 0 none;
  background: transparent;
}
.ui-datepicker .ui-datepicker-prev .ui-icon,
.ui-datepicker .ui-datepicker-next .ui-icon {
  display: none;
}
.ui-datepicker .ui-datepicker-prev:after,
.ui-datepicker .ui-datepicker-next:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f053";
}
.ui-datepicker .ui-datepicker-next {
  left: auto;
  right: 15px;
}
.ui-datepicker .ui-datepicker-next:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f054";
}
.ui-datepicker-inline {
  width: 310px;
}
.ui-datepicker-header {
  background: transparent;
  padding: 15px;
  position: relative;
  border: 0 none;
  display: block;
  font-size: 17px;
  font-weight: normal;
  color: #565656;
}
.ui-datepicker-calendar th {
  font-size: 15px;
  font-weight: normal;
  color: #565656;
  text-align: center;
}
.ui-datepicker-calendar tr td:first-child {
  border-left: 0 none;
}
.ui-datepicker-calendar td {
  font-size: 16px;
  text-align: center;
  padding: 0;
  border-top: 1px solid #cdd1d4;
  border-left: 1px solid #cdd1d4;
}
.ui-datepicker-calendar td a,
.ui-datepicker-calendar td .ui-state-default,
.ui-datepicker .ui-datepicker-calendar td a {
  border: 0;
  background: #ffffff;
  display: block;
  text-align: center;
  color: #0063b0;
  padding: 12px;
}
.ui-datepicker-calendar td a.ui-state-active,
.ui-datepicker-calendar td .ui-state-default.ui-state-active,
.ui-datepicker .ui-datepicker-calendar td a.ui-state-active {
  border: 3px solid #bfe5ff;
  padding: 9px;
  font-weight: bold;
}
.ui-datepicker-calendar .ui-datepicker-week-end {
  color: #dd191d;
}
.ui-datepicker-calendar td.ui-datepicker-unselectable span,
.ui-datepicker-calendar td.ui-state-disabled span {
  background: #f7f9f9;
  color: #aaaeb3;
}
.form__section__data > :first-child {
  margin-top: 0 !important;
}
.avDates-lables {
  white-space: nowrap;
  display: block;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 8px;
  font-family: Arial, sans-serif;
  padding: 5px 0;
  font-weight: normal;
  float: left;
  margin-right: 1%;
  width: 171px;
}
.avDates-lables.active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.avDates-lables input {
  display: none;
}
.avDates-datesCol {
  display: block;
  overflow: hidden;
  max-width: 850px;
}
.avTimesTd {
  width: 270px;
}
.avTimes-lables {
  white-space: nowrap;
  display: block;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 5px 8px;
  font-weight: normal;
  margin: 5px 8px 0 0;
  width: 56px;
  float: left;
}
.avTimes-lables.active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.avTimes-lables input {
  display: none;
}
.avTimes-timesCol {
  float: left;
  width: 260px;
}
.form__remark,
.alert,
.form__section,
.form__item {
  font-family: Arial, sans-serif;
}
.time_list {
  overflow: hidden;
  width: 100%;
  margin-top: 30px;
}
.time_list a {
  text-decoration: none;
  float: left;
  width: 80px;
  white-space: nowrap;
  display: block;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 5px 0;
  font-weight: normal;
  margin: 0 20px 20px 0;
}
.time_list a:active,
.time_list a.s {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.form__item_wrap {
  position: relative;
}
.form__item_wrap.form__item_wrap--dis .temp_before {
  opacity: 0.5;
}
.my-small-loader {
  color: #000;
  padding: 20px 10px 20px 40px;
  background: url('../../../images/lktoepgu/loader-small.gif') no-repeat scroll 5px 50% transparent;
  font-family: "als_ekibastuz_bold", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 16px;
  line-height: 17px;
}
.my-small-loader.my-small-loader--inline {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 15px;
}
.my-small-loader.my-small-loader--thin {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #333;
  display: block;
}
.my-loader {
  background: url('../../../images/lktoepgu/loader-big.gif') no-repeat scroll 50% 10px transparent;
  display: block;
  min-height: 137px;
  padding-top: 120px;
  text-align: center;
  color: #000000;
  font-family: "als_ekibastuz_bold", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 16px;
  line-height: 17px;
}
.my-loader.loader-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  z-index: 1;
}
.b-button-top-back {
  margin-bottom: 20px;
}
.service-tabs a,
.service-tabs li {
  text-align: center;
}
.accordion .toggler {
  cursor: pointer;
}
.ui-dialog .ui-dialog-buttonpane {
  text-align: center;
  background-image: none;
  margin-top: 30px;
}
.ui-dialog .ui-dialog-buttonpane .btn + .btn {
  margin-left: 10px;
}
.b-plain-list li + li {
  margin-top: 5px;
}
.b-title-centered {
  display: block;
  text-align: center;
  margin: 40px 0;
  font-family: "als_ekibastuz_light", Arial, sans-serif;
  font-size: 42px;
  line-height: 46px;
  font-weight: 200;
}
.b-title-centered:first-child {
  margin-top: 0;
}
.wsn {
  white-space: nowrap;
}
.b-nav-folfer {
  display: block;
  padding: 25px 0;
  border-bottom: 1px solid #e1e1e1;
  background: #f6f6f6;
  position: relative;
  top: 0;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
  transition: all 0.2s;
}
.b-nav-folfer.fixed {
  position: fixed;
  z-index: 1001;
  top: 52px;
  padding: 10px 0;
}
.b-nav-folfer:before,
.b-nav-folfer:after {
  display: block;
  position: absolute;
  top: 0;
  bottom: -1px;
  background: #f6f6f6;
  left: 100%;
  right: -9999px;
  border-bottom: 1px solid #e1e1e1;
  content: "";
}
.b-nav-folfer:after {
  right: 100%;
  left: -9999px;
}
.b-nav-folfer-wrap {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: -60px 0 60px;
}
.b-nav-folfer-list {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}
.b-nav-folfer-list-item {
  display: inline-block;
  vertical-align: middle;
}
.b-nav-folfer-list-item + .b-nav-folfer-list-item {
  margin-left: 196px;
}
.b-nav-folfer-list-link {
  font-size: 16px;
  line-height: 18px;
  border-bottom: 1px dotted;
}
.b-nav-folfer-list-link:hover,
.b-nav-folfer-list-link:active,
.b-nav-folfer-list-link:focus {
  text-decoration: none;
}
.b-text-larger {
  font-size: 16px;
}
.p_info {
  color: #000;
}
#avDates {
  overflow: hidden;
}
.info_block {
  position: relative;
  margin: 30px 0;
  border: 1px solid #dbe2e6;
  color: #4c4c4c;
  font: 14px/20px "Helvetica Neue", "Helvetica", Arial, sans-serif;
  padding: 25px 20px;
}
.info_block :first-child {
  margin-top: 0 !important;
}
.info_block p {
  margin: 1em 0 0;
}
.info_block.type_warn {
  box-shadow: 0 6px 0 0 #FEC202 inset;
  border-top: none;
}
.b-lk-notify {
  max-width: 740px;
}
.b-lk-notify-setter {
  display: block;
  margin: 0 0 30px 0;
}
.b-lk-notify-setter input,
.b-lk-notify-setter .form__item_wrap {
  width: 270px !important;
  display: inline-block;
  vertical-align: middle;
}
.b-lk-notify-setter input[type="text"][readonly],
.b-lk-notify-setter input[type="text"][disabled] {
  opacity: 0.5;
  border: 1px solid #cdd1d4 !important;
  background: #fff !important;
}
.b-lk-notify-label {
  display: inline-block;
  vertical-align: middle;
  width: 200px;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.b-lk-notify-switcher {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 20px;
}
.b-lk-notify-table {
  width: 100%;
  margin: 55px 0 0;
  max-width: 700px;
}
.b-lk-notify-table:first-child {
  margin-top: 0;
}
.b-lk-notify-table thead th {
  font-size: 16px;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
}
.b-lk-notify-table thead th:first-child {
  text-align: left;
}
.b-lk-notify-table tbody td {
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #c8c7cc;
}
.b-lk-notify-table tbody td:first-child {
  text-align: left;
}
.b-lk-notify-table .checkbox {
  display: inline-block;
}
.b-advice {
  margin: 30px 0;
  padding: 58px 32px 40px 187px;
  background: #FFF 87px 40px no-repeat;
  border-top: 1px solid #DBE2E6;
  border-bottom: 1px solid #DBE2E6;
  width: 900px;
  overflow: hidden;
}
.b-advice-ico {
  vertical-align: middle;
  text-align: center;
  height: 80px;
  width: 80px;
  box-sizing: border-box;
  float: left;
  margin-right: 24px;
  margin-top: -18px;
  margin-left: -104px;
  background: url('../../../images/lktoepgu/all_bad.png') center center no-repeat transparent;
}
.b-advice-header {
  font-family: "als_ekibastuz_light", Arial, sans-serif;
  font-weight: 200;
  margin-bottom: 30px;
  font-size: 37px;
}
.b-advice-text {
  font-size: 15px;
  color: #535B63;
  line-height: 20px;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
}
._info {
  color: #A6AFC2;
}
.ico:first-child {
  margin-right: 10px;
}
.pad-default {
  padding: 24px;
  font-weight: 700;
  border: 1px solid #F1F3F6;
  border-radius: 4px;
  box-shadow: 0 3px 8px 0 rgba(204, 204, 204, 0.15);
}
.pad-default.with-hover:hover {
  box-shadow: 0 5px 8px 0 #CCCCCC;
}
.popnotification {
  position: fixed;
  bottom: 20vh;
  left: 0;
  right: 0;
  z-index: 10000;
  text-align: center;
  animation: _myfadeOut 2s;
  animation-iteration-count: 1;
}
.popnotification span {
  background: #4C4C4C;
  color: #E4E4E4;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
  display: inline-block;
}
.addr_block .addr:after {
  content: ',';
}
.addr_block .addr:last-child:after {
  content: none;
}
.bg-success {
  background: rgba(0, 156, 81, 0.05);
}
.bg-danger {
  background: rgba(238, 63, 88, 0.05);
}
.bg-warning {
  background: rgba(234, 168, 0, 0.1);
}
.bg-info {
  background-color: #d9edf7;
}
.text-success {
  color: #2ACD6B;
}
.text-danger {
  color: #EE2F53;
}
.text-warning {
  color: #F5831F;
}
@keyframes _myfadeOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.attr-field__upload-photo {
  width: 425px;
  padding: 30px;
  background: #f4f5f5;
  position: relative;
}
.attr-field__upload-photo.view {
  width: 250px;
}
.attr-field__upload-photo.view .upload-photo__imgBlock {
  margin-bottom: 0;
}
.attr-field__upload-photo.uploadPhoto__editBlock {
  width: 100%;
  padding: 20px;
}
.attr-field__upload-photo.uploadPhoto__editBlock .image-editor {
  overflow: hidden;
  position: relative;
  -ms-scroll-limit: 0 0 0 0;
  -ms-overflow-style: none;
}
.attr-field__upload-photo.uploadPhoto__editBlock .cropit-preview-background {
  opacity: 0.2;
}
.attr-field__upload-photo.uploadPhoto__editBlock .cropit-preview {
  background-size: cover;
  width: 190px!important;
  height: 250px!important;
}
.attr-field__upload-photo.uploadPhoto__editBlock .cropit-preview-image-container {
  cursor: move;
}
.attr-field__upload-photo.uploadPhoto__editBlock .cropit-preview-background {
  opacity: 0.2;
  cursor: auto;
}
.attr-field__upload-photo.uploadPhoto__editBlock .image-size-label {
  margin-top: 10px;
}
.attr-field__upload-photo.uploadPhoto__editBlock .cropit-image-control {
  width: 35px;
  height: 35px;
  position: relative;
  z-index: 10;
  display: inline-block;
  margin-top: 10px;
  border: 0;
}
.attr-field__upload-photo.uploadPhoto__editBlock .rotate-ccw {
  background: url("../../../images/lktoepgu/rotate_ccw@1x.png") center center no-repeat transparent;
  margin-right: 5px;
  margin-left: -5px;
}
.attr-field__upload-photo.uploadPhoto__editBlock .zoom-out {
  background: url("../../../images/lktoepgu/minus_btn.svg") center center no-repeat transparent;
  margin-right: 5px;
}
.attr-field__upload-photo.uploadPhoto__editBlock .zoom-in {
  background: url("../../../images/lktoepgu/plus_btn.svg") center center no-repeat transparent;
  margin-right: -5px;
  margin-left: 5px;
}
.attr-field__upload-photo.uploadPhoto__editBlock .cropit-image-input {
  display: none;
}
.attr-field__upload-photo.uploadPhoto__editBlock .cropit-image-zoom-input {
  position: relative;
  z-index: 10;
  display: inline-block;
  width: 59px;
  min-height: 35px;
}
.attr-field__upload-photo.uploadPhoto__editBlock .cropit-image-zoom-input::-ms-track {
  width: 59px;
  height: 10px;
  background: transparent;
  border-color: transparent;
  border-width: 6px 0;
  color: transparent;
}
.attr-field__upload-photo.uploadPhoto__editBlock .cropit-image-zoom-input::-ms-fill-upper {
  background: #fff;
  border-radius: 6px;
}
.attr-field__upload-photo.uploadPhoto__editBlock .cropit-image-zoom-input::-ms-fill-lower {
  background: #fff;
  border-radius: 6px;
}
.attr-field__upload-photo.uploadPhoto__editBlock .cropit-image-zoom-input::-ms-thumb {
  color: #565656;
  background: #565656;
  border-radius: 100%;
  width: 10px;
  height: 10px;
}
.attr-field__upload-photo.uploadPhoto__editBlock .cropit-image-zoom-input::-ms-tooltip {
  display: none;
}
.attr-field__upload-photo.uploadPhoto__editBlock .uploadPhoto__infoMove {
  width: 182px;
  position: absolute;
  left: 30px;
  top: 20px;
  padding: 8px;
  background: #e3e3e3;
}
.attr-field__upload-photo.uploadPhoto__editBlock .uploadPhoto__infoMove .uploadPhoto__infoMove-Icon {
  float: left;
  margin: 2px -100% 0 0;
  display: block;
  width: 27px;
  height: 27px;
  background: url('../../../images/lktoepgu/move_arrow.svg') no-repeat;
  -webkit-background-size: 27px;
  background-size: 27px;
}
.attr-field__upload-photo.uploadPhoto__editBlock .uploadPhoto__infoMove .uploadPhoto__infoMove-Text {
  font-size: 12px;
  margin-left: 32px;
  display: block;
}
.attr-field__upload-photo.uploadPhoto__editBlock .upload-photo__imgBlock {
  position: relative;
  left: 35%;
  margin-bottom: 60px;
}
.attr-field__upload-photo .upload-photo__imgBlock {
  padding: 7px 0;
  background: url('../../../images/lktoepgu/man_new.svg') 0 0 no-repeat #e4f6ff;
  background-size: cover;
  height: 250px;
  width: 190px;
  border: 5px solid #bbd1e8;
  margin-bottom: 20px;
}
.attr-field__upload-photo .upload-photo__imgBlock .PGU-ImageEditor-st0 {
  fill: #535B63;
}
.attr-field__upload-photo .upload-photo__imgBlock .PGU-ImageEditor-st1 {
  font-family: 'Helvetica', 'Arial', sans-serif;
}
.attr-field__upload-photo .upload-photo__imgBlock .PGU-ImageEditor-st2 {
  font-size: 12px;
}
.attr-field__upload-photo .upload-photo__imgBlock .PGU-ImageEditor-st3 {
  opacity: 0.3;
}
.attr-field__upload-photo .upload-photo__imgBlock .PGU-ImageEditor-st5 {
  fill: none;
  stroke: #000000;
  stroke-miterlimit: 10;
  stroke-dasharray: 5, 5;
}
.attr-field__upload-photo .btn-default {
  padding: 12px 18px;
}
.uploadPhoto__annotate {
  margin-bottom: 15px;
}
.uploadPhoto__annotate ul {
  margin: 0;
  padding: 0;
}
.uploadPhoto__annotate ul.error li {
  margin-left: 30px;
  list-style: none;
}
.uploadPhoto__annotate ul.error li:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  background: url('../../../images/lktoepgu/error.png') 0 0 no-repeat transparent;
  width: 20px;
  height: 20px;
}
.uploadPhoto__toggleBtn {
  border-bottom: 1px dashed #0173c1;
  margin-bottom: 20px;
  display: inline-block;
}
.uploadPhoto__toggleBtn:hover {
  text-decoration: none;
  border-bottom: 1px dashed transparent;
}
.uploadPhoto__toggleBtn:after {
  content: "";
  position: relative;
  vertical-align: top;
  line-height: inherit;
  margin-top: 0.5em;
  margin-left: 0.25em;
  display: inline-block;
  overflow: hidden;
  height: 0;
  width: 0;
  border: 4px solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.uploadPhoto__toggleBtn.open:after {
  border-top-color: transparent;
  border-bottom-color: initial;
  margin-top: 0.2em;
}
.uploadPhoto__description {
  display: none;
  height: 390px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px;
  box-sizing: border-box;
  font-size: 14px;
}
.uploadPhoto__description.open {
  display: block;
}
.uploadPhoto__description h4 {
  margin: 0;
}
.uploadPhoto__hiddenFile {
  visibility: hidden;
  position: absolute;
}
.uploadPhoto__title-error {
  background: url('../../../images/lktoepgu/all_be_warn.svg') 0 0 no-repeat transparent;
  display: block;
  float: left;
  height: 60px;
  width: 60px;
  margin-right: 15px;
}
.cookie-accept-message {
  position: fixed;
  background: #FFFFFF;
  z-index: 10000;
  box-shadow: 0 0 3px #ccc;
  padding: 0 0 0 20px;
  border-top: 2px solid #2e729e;
}
.cookie-accept-message.at-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}
.cookie-accept-message p {
  margin: 1em;
  padding-right: 100px;
}
.cookie-accept-message .cookieBar-button {
  position: absolute;
  bottom: 50%;
  margin-bottom: -18px;
  right: 20px;
  text-decoration: none;
  padding: 5px 10px;
}
/*.my-loader {color: #fff !important; font-family: 'Open Sans Light', sans-serif; }*/
/*.m1 .w {margin:0 50px}*/
.table-cell {
  display: table-cell !important;
  float: none;
}
.list2 {
  margin: 0 0 0 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
.list2 li {
  margin: 0;
  padding: 0 0 20px 0;
  display: block;
  list-style: none;
}
.list2 h3 {
  font: normal 14px 'Open Sans Bold';
  color: #000000;
  margin: 0 0 7px 0;
}
.list2 ul {
  margin: 0;
  padding: 0;
}
.list2 ul li {
  margin: 0;
  padding: 0 0 5px 0;
  display: block;
  list-style: none;
}
.legend {
  display: block;
  /*font: 28px 'Open Sans Light';*/
  font-family: EtelkaLightPro, Arial, "Helvetica Neue", Helvetica, sans-serif;
  width: 100%;
  min-height: 32px;
  padding: 0 0 16px;
  border-bottom: 1px solid #eee;
  overflow: hidden;
  margin: 0 0 0.5em;
}
form > table + fieldset > .legend {
  margin: 40px 0 20px;
}
.wogroup,
.linkedobj-actions-footer {
  width: 100%;
}
.linkedobj-actions-footer {
  margin-top: 50px;
}
.linkedobj-actions-footer td {
  text-align: center;
}
.linkedobj-actions-footer .btn + .btn {
  margin-left: 24px;
}
.subgroup-holder-outer.field_dou fieldset {
  width: 789px;
}
.subgroup-holder-outer fieldset .megagriddiv {
  width: 596px !important;
  margin-left: 6px;
}
.subgroup-holder-outer fieldset .input input[type=text],
.input input[type=password] {
  color: #5B5B5B;
  font: normal 13px/28px 'Open Sans Regular';
  /*width: 589px;*/
}
.subgroup-holder-outer fieldset textarea {
  width: 602px;
}
.subgroup-holder-outer fieldset fieldset {
  width: 573px;
}
.subgroup-holder-outer.field_dou fieldset fieldset {
  width: 753px;
}
.subgroup-holder-outer fieldset fieldset textarea {
  width: 566px;
}
.subgroup-holder-outer fieldset fieldset .megagriddiv {
  width: 560px !important;
  margin-left: 6px;
}
.subgroup-holder-outer fieldset fieldset .input input[type=text],
.input input[type=password] {
  color: #5B5B5B;
  font: normal 13px/28px 'Open Sans Regular';
  /*width: 553px;*/
}
.attrs {
  display: table;
  width: 100%;
  table-layout: fixed;
  max-width: 858px;
  /* fallback for table cell */
  *display: block;
  *width: auto;
  /* fallback for box-sizing, border + padding */
  *padding: 0 4px;
}
/*.sameRow:first-child {margin-left:0; padding-left:0; border-left:0;}*/
/*.sameRow input[type=text],*/
/*.sameRow input[type=password] {width:100%;*margin-left:-2px;}*/
.subgroup-holder > table .attrs > label {
  margin-top: 25px;
}
/*.messages h5 {width:10em; float:left; font-size:12px; text-transform:none; font-weight:normal; margin:0 20px 0 0; padding-left:50px; background:url(../../images/lkmo/ico/message.png) 20px no-repeat} .messages .new h5 {background-image:url(../../images/lkmo/ico/message2.png);}*/
/*.messages .ul2 li {font-size:11px; text-transform:uppercase; max-width:750px}*/
/*.messages .ul2 a {color:#757575; text-decoration:none}*/
/*.messages .del {padding-left:20px; background:url(../../images/lkmo/ico/del.png) left no-repeat; float:right; display:block}*/
.checkitem {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
  margin: 0 20px 20px 0;
  padding-left: 25px;
}
.checkitemfailed,
.checkitempass {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
  margin: 0 20px 20px 0;
  padding-left: 25px;
  position: relative;
}
.checkitemfailed:before,
.checkitempass:before {
  position: absolute;
  left: 0;
  top: 0;
  color: #dd191d;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00d";
  font-size: 16px;
}
.checkitemok {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
  margin: 0 20px 20px 0;
  padding-left: 25px;
  position: relative;
}
.checkitemok:before {
  position: absolute;
  left: 0;
  top: 0;
  color: #6ec256;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f00c";
  font-size: 16px;
}
.newgridtable {
  /*width: 645px;*/
  position: relative;
  clear: both;
}
.grid-tools {
  float: none !important;
  line-height: 10px !important;
  margin: 10px 0 10px;
  padding: 0 20px 0 0;
  white-space: nowrap;
}
.newgridtable .grid_holder {
  clear: both;
}
.grid_holder > table {
  table-layout: fixed;
}
.cls {
  clear: both;
}
table.tabdata.statuses {
  width: 82%;
}
td.attrTitle {
  font-family: "Open Sans Italic", 'Open Sans Light' !important;
  font-size: 14px;
  color: #000;
}
tr.status td {
  background: #fdf7e4;
  padding: 15px 10px 15px 5px;
  border-top: 1px solid #e67800;
  border-bottom: 1px solid #e67800;
}
tr.status {
  border: 1px solid #000;
}
tr.status td {
  text-transform: uppercase;
  font-family: "Open Sans Italic", 'Open Sans Light';
  font-size: 13px;
  color: #830e0e;
}
tr.status td:first-child {
  text-transform: none;
  font-size: 14px;
  color: #000;
}
/*/////*/
[required] .star,
[required] + .star,
.required .star,
.required + .star {
  display: none;
}
/* grid-table styles  */
.grid-block {
  position: relative;
  overflow: auto;
  width: 100%;
  padding-bottom: 1px;
  /* visible border bottom */
}
.grid-block > .grid-wrapper {
  min-width: 100%;
  width: 100px!important;
}
.grid-block table td {
  word-break: break-word;
}
/*table.grid tbody tr.silver td, table.grid tbody tr.silver th {*/
/*background-color:#d1e5ef;*/
/*}*/
/*table.grid tbody tr.gridRow td, table.grid tbody tr.gridRow th {*/
/*background-color:transparent;*/
/*}*/
.grid-tools {
  white-space: nowrap;
  float: right;
  padding: 0 20px 0 0;
  line-height: 10px!important;
}
.grid-tools a {
  display: inline-block;
  margin: 0 0 0 2px;
  cursor: pointer;
}
/*.my-Modal table td,*/
/*fieldset table td {*/
/*padding: 10px 10px 10px 0;*/
/*}*/
/*.my-Modal table td:first-child,*/
/*fieldset table td:first-child {*/
/*vertical-align: top;*/
/*padding-top: 20px;*/
/*}*/
.note2 {
  margin: 5px 0 10px;
  opacity: 0.5;
  max-height: 1000000px;
  /*font-style: italic;*/
}
.attr-value table {
  width: 100%;
}
.attr-value table td {
  width: 50%;
}
.attr-value table.view_grid {
  table-layout: fixed;
}
.attr-value table.view_grid th {
  text-align: left;
  /*font-family: 'Open Sans Light';*/
  font-weight: normal;
  word-break: break-all;
  word-break: break-word;
}
.attr-value table.view_grid th,
.attr-value table.view_grid td {
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0 4px;
  font-size: 12px;
  letter-spacing: -0.03em;
}
.attr-value table.view_grid td {
  width: auto;
}
.attr-value table.view_grid td.Rborder {
  /*width:1px;*/
  display: none;
}
/* TODO : all selects size > 1 */
.modal-body select[size="8"],
.modal-body select[size="6"] {
  left: 0;
  top: 0;
  position: absolute;
  /*width:280px !important;*/
  border: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background: #fff !important;
  z-index: +4;
}
/*#documNoteDiv {*/
/*width:400px;*/
/*border: 1px solid #b4b4b4;*/
/*color: #4C4C4C;*/
/*padding: 15px;*/
/*background: #f2f2f2;*/
/*}*/
select.sp-target {
  display: none !important;
}
.search-div {
  position: relative;
}
.temp_before {
  position: absolute;
  right: 100%;
  /*margin-right: calc(-24px + .25em);*/
  margin-right: -23px;
  line-height: 20px;
  font-size: 15px;
  width: 24px;
  text-align: right;
  display: none;
  font-family: Arial, sans-serif;
  color: #333333 !important;
  padding: 13px 0;
}
.attr-field--readonly .temp_before,
.attr-field--focused .temp_before,
.attr-field--filled .temp_before {
  display: block;
}
/* pubsoap/handlers/createorderdourequest/privilegecategory.jsp */
.g-privilege-legend {
  padding: 5px 0;
  letter-spacing: -0.03em;
}
.g-privilege-legend-link {
  font-size: 11px;
  /*padding: 2px 0;   */
  text-decoration: none;
  border-bottom: 1px dashed #bfbfbf;
  white-space: nowrap;
}
.g-privilege-legend-link--active {
  border: 0 none;
  cursor: default;
}
.g-privilege-legend-sep {
  display: inline-block;
  height: 10px;
  margin: 0 2px;
  border-right: 2px solid #bfbfbf;
}
.attractions-msg-caption {
  display: block;
  font-weight: normal;
  margin: 0 0 1em;
}
.ecp-dialog {
  z-index: 99999 !important;
  left: 0!important;
  right: 0;
  margin: 0 auto;
  top: 50%;
  /*transform: translate(-50%,-50%);*/
  transform: translate(0, -50%);
}
dialog.ecp-dialog {
  left: 0!important;
  top: auto!important;
  transform: none!important;
}
.b-service-baseinfo {
  font-size: 14px;
  line-height: 24px;
}
.b-service-baseinfo > p:first-child {
  margin-top: 0;
}
.service-back-link {
  white-space: normal;
  padding-left: 20px;
  padding-right: 20px;
}
.service-back-link .fa {
  font-size: 120%;
  margin-right: 0.5em;
  font-weight: bold;
  vertical-align: top;
  margin-top: 0.05em;
}
.service-target-recipient-cat-comment {
  display: block;
  margin-top: 0.25em;
}
.service-target-recipient-cat-comment:first-letter {
  text-transform: lowercase;
}
.service-agreement-list {
  margin: 2em 0;
}
.service-agreement-list .sal-item {
  margin: 1em 0 0;
}
.b-service-info-group-title {
  font-family: "als_ekibastuz_light", Arial, sans-serif;
  font-weight: 200;
  font-size: 30px;
  line-height: 32px;
  margin: 60px 0 50px;
}
.b-service-info-group-title:first-child {
  margin-top: 0;
}
.b-service-info-subgroup .b-service-info-subgroup-inner {
  padding-left: 40px;
  padding-right: 50px;
}
.b-service-info-subgroup .b-service-info-subgroup-inner .b-service-info-subgroup-title {
  /*margin: 49px 0 33px;*/
  padding: 35px 30px 10px 0;
  font: 600 18px/20px 'Helvetica Neue,Helvetica,Arial,sans-serif', sans-serif;
  border-bottom: 1px solid #e5e5e7;
  font-weight: bold;
}
.b-service-info-subgroup .b-service-info-subgroup-inner .b-service-info-subgroup-items {
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 20px 0 0;
}
.b-service-info-subgroup .b-service-info-subgroup-inner .b-service-info-subgroup-items h4 {
  margin: 20px 0 10px;
  padding: 0;
  line-height: 26px;
  font-size: 18px;
}
.b-service-info-subgroup-title {
  padding: 35px 35px 16px 0;
  font: 600 24px/26px 'als_ekibastuz_bold', sans-serif;
  border-bottom: 1px solid #e5e5e7;
  position: relative;
  cursor: pointer;
}
.b-service-info-subgroup-items {
  font-size: 18px;
  line-height: 26px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 20px 0 0;
}
.b-service-info-subgroup-items h4 {
  margin: 20px 0 10px;
  padding: 0;
  line-height: 26px;
  font-size: 18px;
}
.b-service-info-nav {
  font-size: 18px;
  line-height: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0 30px 0 0;
}
.b-service-nav-block {
  display: block;
  border-top: 4px solid #f3f3f4;
  margin-top: 18px;
}
.b-service-nav-block-title {
  margin-top: 19px;
  margin-bottom: 18px;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
}
.b-service-nav-block-anchors {
  display: block;
  padding: 0;
  margin: 0;
}
.b-service-nav-block-anchors li {
  display: block;
  padding: 0;
  margin: 0 0 10px;
}
.b-service-nav-block-anchor {
  font-size: 14px;
  line-height: 21px;
}
.b-service-nav-hidden-block {
  margin: 10px 0 0;
  padding-left: 15px;
}
.service-organ {
  cursor: pointer;
}
.service-organ-icon {
  margin-bottom: 1em;
  display: block;
  background: url('../../../images/lktoepgu/rus-logo.png') 0 50% no-repeat;
  background-size: auto 100% ;
  height: 39px;
}
.service-organ-link,
.service-organ-helper {
  display: block;
  margin: 1em 0 0;
  font-weight: bold;
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
}
.reglament-link {
  position: relative;
  display: block;
  vertical-align: top;
  line-height: 25px;
  padding-left: 54px;
}
.reglament-link:before {
  content: "";
  display: block;
  width: 45px;
  height: 45px;
  background: url('../../../images/lktoepgu/reglament-icon.png') center center no-repeat;
  background-size: 100% auto;
  position: absolute;
  top: 0;
  left: 0;
}
.reglament-link-info {
  color: #000;
  text-transform: uppercase;
  display: block;
  text-decoration: none !important;
}
.receive-doc-layout {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.receive-doc-row {
  display: table-row;
}
.receive-doc-row:first-child .receive-doc-spacer {
  padding-top: 10px;
}
.receive-doc-cell {
  display: table-cell;
  vertical-align: top;
  padding-top: 25px;
}
.receive-doc-row:first-child .receive-doc-cell {
  padding-top: 0;
}
.user-file-cell--icon,
.receive-doc-cell--icon {
  width: 20px;
  white-space: nowrap;
}
.user-file-cell--title,
.receive-doc-cell--info {
  padding-left: 15px;
  padding-right: 15px;
}
.user-file-cell--actions,
.receive-doc-cell--actions {
  width: 90px;
}
.custom-receive-doc-wrap .receive-doc-cell {
  padding-top: 0;
  padding-bottom: 25px;
}
.custom-receive-doc-wrap .receive-doc-cell:first-child {
  padding-left: 0;
}
.receive-doc-icon {
  display: inline-block;
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 19px;
  height: 21px;
}
.receive-doc-icon,
.receive-doc-icon--jpg,
.receive-doc-icon--png,
.receive-doc-icon--pdf {
  /*  background-image: url('../../images/sunshine/ico/doc-template--danger.png');*/
}
.receive-doc-icon--docx,
.receive-doc-icon--doc,
.receive-doc-icon--rtf {
  /*  background-image: url('../../images/sunshine/ico/doc-template--primary.png');*/
}
.receive-doc-icon--xls,
.receive-doc-icon--xlsx {
  /*background-image: url('../../images/sunshine/ico/doc-template--success.png');*/
}
.receive-doc-num,
.receive-doc-spacer {
  display: block;
  color: #777777;
  font-size: 12px;
  text-transform: uppercase;
}
.receive-doc-num {
  font-size: inherit;
  display: inline-block;
}
.receive-doc-scroll {
  max-height: 400px;
}
.receive-doc-wrap {
  display: block;
}
.receive-doc-required {
  color: #b2234b;
  /*font-family: 'Open Sans Bold';*/
  display: inline-block;
  vertical-align: top;
  padding: 0 5px;
  margin: 0 -5px;
}
.receive-doc-required-message {
  padding-top: 10px;
}
.receive-doc-wrap + .receive-doc-required-message,
.mCustomScrollbar + .receive-doc-required-message {
  padding-top: 30px;
}
.receive-doc-spacer {
  display: none;
  /* .receive-doc-spacer is @deprecated via MSMEV-3522 */
  padding: 0 0 10px;
  line-height: 10px;
}
.receive-doc-title {
  display: block;
  line-height: 20px;
  /*text-transform: capitalize;*/
}
.receive-doc-title-with-tip {
  position: relative;
  padding-right: 35px;
}
.receive-doc-title-with-tip .attr-value-helper--icon {
  top: 0;
}
.receive-doc-comment {
  display: block;
  margin-top: 5px;
  opacity: 0.5;
}
.receive-doc-comment:first-letter {
  text-transform: uppercase;
}
.content-body .service__list {
  list-style: none;
  padding: 0;
  margin: 20px 0 30px;
}
.content-body .service__list:after {
  display: block;
  clear: both;
  content: '';
}
.content-body .service__list .dep_toggler {
  cursor: pointer;
}
.content-body .service__list .dep_toggler span {
  display: inline-block;
  height: 30px;
  width: 30px;
  border: 2px solid #ddd;
  border-radius: 100%;
  margin-left: 10px;
  position: relative;
  margin-bottom: -5px;
}
.content-body .service__list .dep_toggler span:after {
  display: block;
  content: "";
  border: solid #000;
  border-width: 0 2px 2px 0;
  padding: 5px;
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  margin-bottom: -2px;
  position: absolute;
  top: 9px;
  left: 7px;
}
.content-body .service__list .dep_toggler.opened span:after {
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: 4px;
}
.content-body .service__list .dep_toggle {
  display: none;
}
.content-body .service__list .dep_toggle.opened {
  display: block;
}
.content-body .service__list ul {
  margin: 0;
  padding: 0;
}
.content-body .service__list li {
  width: 33%;
  padding: 25px 75px 25px;
  margin: 0;
  display: inline-block;
  vertical-align: top;
}
.content-body .service__list li img {
  width: 80px;
  height: 80px;
}
.content-body .service__list li .service__desc {
  padding: 0 0 25px;
  display: block;
  text-decoration: none !important;
  color: #000;
}
.content-body .service__list li .service__desc:hover {
  text-decoration: none !important;
  color: #000;
}
.content-body .service__list li.service__list-dep {
  cursor: pointer;
  padding: 25px;
}
.content-body .service__list li.service__list-dep:hover {
  background: #d9edf7;
}
.content-body .service__list li.service__list-dep img {
  width: 50px;
  height: 50px;
}
.content-body .service__list li.service__list-dep a.service__list_link .service__name {
  font-size: 16px;
  color: #0063b0;
  line-height: 20px;
}
.content-body .service__list li a.service__list_link {
  text-decoration: none !important;
}
.content-body .service__list li a.service__list_link .service__list_block {
  display: table;
  min-height: 70px;
}
.content-body .service__list li a.service__list_link .service__name {
  text-decoration: none !important;
  color: #000;
  font-size: 22px;
  line-height: 24px;
  padding-left: 14px;
}
.content-body .service__list li a.service__list_link .service__name:hover {
  color: #003864;
  text-decoration: none !important;
}
.content-body .service__list li a.service__list_link .service__name,
.content-body .service__list li a.service__list_link .service__ico {
  display: table-cell;
  vertical-align: middle;
}
.content-body .service__list .service__ext_block {
  padding: 25px 0 0;
  display: block;
}
.service-dep .modal-header {
  padding: 0 0 0 100px;
}
.service-dep #leftnav.sticky .dep-leftnav-container {
  position: fixed;
}
.service-dep .dep-leftnav-title {
  font-size: 18px;
  font-weight: bold;
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
  margin-bottom: 15px;
}
.service-dep .dep-leftnav-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.service-dep .dep-leftnav-container li {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 30px;
}
.service-dep .dep-leftnav-container li a {
  font-size: 14px;
  color: #0173c1;
  border-bottom: 1px dashed #0173c1;
  display: inline-block;
  line-height: 14px;
  text-decoration: none;
}
.service-dep .dep-leftnav-container li a:hover {
  text-decoration: none;
  border-bottom: 1px transparent;
}
.service-group-title {
  padding-bottom: 30px;
  border-bottom: 1px solid #cdd1d4;
  margin-bottom: 60px;
}
.grid-col .non-e-serv,
.grid-col .e-serv {
  width: 30px;
  height: 22px;
  display: block;
  float: left;
  margin-right: 5px;
  /*margin-top: -2px;  */
  background: url("../../../images/lktoepgu/e.png") left center no-repeat transparent;
}
.grid-col .non-e-serv {
  background-image: url("../../../images/lktoepgu/non-e.png");
}
.grid-col .service-block {
  display: table;
  width: 100%;
}
.grid-col .service-name {
  display: table-cell;
  vertical-align: top;
}
.grid-col .service-name ul,
.grid-col .service-name li {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}
.grid-col .service-name ul {
  padding-left: 15px;
}
.grid-col .service-name li {
  padding: 10px 0;
}
.grid-col .service-action {
  width: 40px;
  cursor: pointer;
  display: table-cell;
}
.grid-col .service-action .fa {
  border-radius: 100%;
  border: 1px solid #cdd1d4;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 20px;
  font-size: 18px;
  margin-top: -3px;
}
.grid-col .service-link {
  cursor: pointer;
}
.grid-col .service-link .fa {
  font-weight: bold;
}
.grid-col .service-link a {
  color: inherit;
}
.grid-col .linker .fa {
  color: #0173c1;
  border: 2px solid #0173c1;
}
.grid-col .linker .fa:before {
  content: "\f105";
}
.grid-col .toggler .fa {
  border: 1px solid #cdd1d4;
}
.grid-col .toggler .fa:before {
  content: "\f107";
}
.grid-col .toggler.open .fa:before {
  content: "\f106";
}
a.service-name {
  padding-left: 35px;
}
a.service-name .non-e-serv,
a.service-name .e-serv {
  margin-left: -35px;
}
.non-e-serv + a.service-name,
.e-serv + a.service-name {
  padding-left: 0;
}
.service-appeal-block {
  display: table;
  width: 100%;
}
.service-appeal-block .service-appeal-block--data {
  display: table-row;
}
.service-appeal-block--data .service-appeal--result,
.service-appeal-block--data .service-appeal--request {
  vertical-align: top;
  text-align: right;
  display: table-cell;
}
.service-appeal-block--data .service-appeal--title {
  padding-right: 30px;
  text-align: left;
}
.service-appeal--title,
.service-appeal--result_success,
.service-appeal--result_fail {
  display: block;
}
.service-appeal--result_success {
  color: #6ec256;
}
.service-appeal--result_fail {
  color: #b2234b;
}
.service-appeal-block + .note {
  text-align: right;
  padding-top: 5px;
}
.service-payment-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}
.service-payment-buttons__title {
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.service-payment-buttons__button-block {
  position: relative;
  width: 100%;
}
.service-payment-buttons__button-block button {
  width: 100%;
  text-align: left;
  padding: 25px 75px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.service-payment-buttons__button-block button:hover {
  background-color: #eeeeee;
}
.service-payment-buttons__button-block button:after {
  font-family: 'FontAwesome';
  content: "\f054";
  position: absolute;
  right: 20px;
  top: calc(50% - 8px);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 10px;
  padding-top: 1px;
  padding-left: 2px;
  border: 2px solid #0173c1;
  color: #0173c1;
  border-radius: 100%;
  pointer-events: none;
}
.service-payment-buttons__button-block img {
  position: absolute;
  display: block;
  width: auto;
  height: 40px;
  left: 20px;
  top: calc(50% - 20px);
  pointer-events: none;
}
.equeue-user-input-data {
  line-height: 1.75em;
}
.equeue-map-wrapper {
  width: 100%;
  height: 402px;
  /* .equeue-list-holder height */
}
.equeue-map {
  background: #fff;
  position: absolute;
  top: 0;
  left: -1px;
  bottom: 0;
  right: -1px;
}
.equeue-map-empty-msg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  width: 300px;
  margin-top: -40px;
  background: #fff;
  padding: 20px;
  z-index: 2;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.125);
}
.equeue-map > .eq-map {
  position: relative;
  width: 100%;
  height: 100%;
}
.equeue-map .search_block {
  width: auto;
  left: 270px;
  right: 20px;
  top: 20px;
}
.equeue-map .filter_block {
  padding-left: 0;
}
.equeue-map .filter_block .filter_icon {
  display: none;
}
.equeue-map .filter_wrapper {
  left: 0;
  top: 0;
}
.eq-office-info {
  width: 100%;
  table-layout: fixed;
  font-weight: normal;
}
.eq-office-info td {
  vertical-align: top;
}
.eq-office-info-icon {
  width: 15px;
  display: inline-block;
  vertical-align: top;
  float: left;
  /* collapse space after icon */
}
.eq-office-title {
  margin-left: 12px;
  display: inline-block;
  vertical-align: top;
}
.eq-office-title__label {
  font-weight: normal;
}
.eq-office-info-text {
  display: block;
}
.eq-office-info-icon + .eq-office-info-text {
  display: inline-block;
  margin-left: 8px;
  width: calc(100% - 27px);
  /* icon width + margin */
}
.eq-office-info-label {
  position: relative;
  padding-top: 15px;
}
.eq-office-info-additional {
  font-size: 12px;
  line-height: 14px;
}
.modal--equeue .sr_item .l {
  display: inline-block;
}
.modal--equeue .eq-sr-usluga,
.modal--equeue .eq-sr-service {
  font-weight: normal;
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 16px;
}
.equeue-calendar {
  color: #aaaeb3;
}
.equeue-calendar-wrapper {
  display: table;
}
.equeue-calendar-legend,
.equeue-calendar-placeholder {
  display: table-cell;
  vertical-align: top;
}
.equeue-calendar-legend {
  padding: 0 15px;
}
.equeue-calendar-legend-footer,
.equeue-calendar-legend-info,
.equeue-calendar-legend-header {
  padding: 15px 25px;
}
.equeue-calendar-legend-header {
  background: #f7f9f9;
  font-weight: bold;
}
.equeue-calendar-legend-footer {
  border-top: 1px solid #e3e3e5;
}
.equeue-calendar-legend-timezone-notify {
  display: block;
  position: relative;
  padding-left: 45px;
  color: #576472;
  line-height: 1.3em;
}
.equeue-calendar-legend-timezone-notify-icon {
  width: 29px;
  height: 29px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 100%;
  border: 2px solid;
}
.equeue-calendar-legend-timezone-notify-icon:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  margin-top: -9px;
  margin-left: -1px;
  border: 1px solid;
  border-right: 0;
  border-top: 0;
}
.equeue-calendar-legend-helper {
  white-space: nowrap;
  margin-top: 15px;
  display: block;
}
.equeue-calendar-cell {
  display: inline-block;
  border: 1px solid #edf0f2;
  padding: 5px;
  font-size: inherit;
  width: 2em;
  height: 2em;
  line-height: 1em;
  text-align: center;
  background: #fff;
  color: #0063b0;
}
.equeue-calendar-cell.disabled {
  background: #f7f9f9;
  color: inherit;
}
.equeue-calendar-legend-helper-label,
.equeue-calendar-legend-helper-cell {
  display: inline-block;
  vertical-align: top;
}
.equeue-calendar-legend-helper-label {
  white-space: normal;
  word-break: break-word;
  padding: 5px;
  margin-left: 10px;
}
.b-profile-nav:before,
.b-profile-nav:after {
  content: " ";
  display: table;
}
.b-profile-nav:after {
  clear: both;
}
.b-profile .edit-attr,
.b-profile .edit-btn--save,
.b-profile .edit-btn--cancel,
.b-profile .edit-mode .view-attr,
.b-profile .edit-mode .edit-btn--edit {
  display: none;
}
.b-profile .edit-btn--edit,
.b-profile .edit-mode .edit-btn--cancel,
.b-profile .edit-mode .edit-btn--save {
  display: inline-block;
}
.b-profile .edit-mode .edit-attr {
  display: block;
}
.b-profile .subgroup-actions--not-required .edit-btn--edit,
.b-profile .edit-mode .subgroup-actions--not-required .edit-btn--save,
.b-profile .edit-mode .subgroup-actions--not-required .edit-btn--cancel {
  display: none;
}
.b-profile .save-mode .subgroup-actions--not-required .edit-btn--save {
  display: inline-block;
}
.filter-period-container {
  margin-right: 18px;
  margin-bottom: 12px;
}
.filter-period-container,
.filter-period-sub {
  float: left;
}
.filter-period-link {
  margin-left: 6px;
  display: inline-block;
  padding: 12px 0;
}
.filter-period-link + .filter-period-link {
  margin-left: 18px;
}
.filter-period-link .checkbox {
  margin: 0;
  color: #839199;
}
.elkSubscribe-error {
  color: #f4821f;
  position: absolute;
  width: 300px;
  margin-top: 5px;
}
.elkSubscribe__process:after {
  background: transparent url('../../../images/lktoepgu/loader-simple.gif') center center no-repeat;
  content: "";
  width: 30px;
  height: 25px;
  position: absolute;
  right: -45px;
  top: 0;
}
.pagination-wrap {
  display: block;
  text-align: center;
  padding: 70px 0 0;
}
.form-group .attr ul {
  padding: 0;
  margin: 0 0 0 15px;
}
.modal--profile-settings .attr-row--snils,
.modal--profile-settings .attr-row--inn,
.modal--profile-settings .attr-row--ogrnip,
.modal--profile-settings .attr-row--lastName,
.modal--profile-settings .attr-row--firstName,
.modal--profile-settings .attr-row--birthday,
.modal--profile-settings .attr-row--secondName {
  display: inline-block;
  padding-left: 20px;
  width: 33.33%;
}
.modal--profile-settings .attr-row--snils,
.modal--profile-settings .attr-row--birthday,
.modal--profile-settings .attr-row--lastName {
  padding-left: 0;
}
.modal--profile-settings .attr-col--sex .radio label {
  padding-left: 24px;
}
.modal--profile-settings .attr-col--sex .radio label > span:first-child:last-child {
  margin-left: 0;
}
.modal--profile-settings .attr-col--sex .attr-container {
  min-height: 0;
}
.modal--profile-settings .attr-col--sex .attr-label--inline,
.modal--profile-settings .attr-col--sex .attr-value {
  width: auto;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
}
.equeue-results {
  padding: 0;
}
.equeue-ticket {
  max-width: 800px;
  margin: 0 auto;
}
.equeue-ticket-header-inner {
  position: relative;
  padding-left: 95px;
  padding-bottom: 45px;
}
.equeue-ticket-header-layout {
  display: table;
  height: 40px;
  margin-right: 250px;
}
.equeue-ticket-header-layout-inner {
  display: table-cell;
  vertical-align: middle;
}
.equeue-ticket-header-icon {
  width: 40px;
  height: 40px;
  border: 0 solid;
  border-radius: 100%;
  position: absolute;
  top: 10px;
  left: 10px;
}
.equeue-ticket-header-icon:before,
.equeue-ticket-header-icon:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 40px;
  line-height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.equeue-ticket-header-icon:before {
  content: "\f1db";
  font-size: 80px;
}
.equeue-ticket-header-icon--success {
  color: #6ec256;
}
.equeue-ticket-header-icon--success:after {
  content: "\f00c";
}
.equeue-ticket-header-icon--error {
  color: #dd191d;
}
.equeue-ticket-header-icon--error:after {
  content: "\f00d";
}
.equeue-ticket-header-status {
  display: block;
  font-size: 18px;
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: normal;
}
.equeue-ticket-header-info {
  display: block;
  font-size: 14px;
  font-family: "als_ekibastuz_bold", Arial, sans-serif;
}
.equeue-ticket-header-actions {
  position: absolute;
  right: 0;
  top: 0;
}
.equeue-ticket-header-actions .btn {
  padding-top: 9px;
  padding-bottom: 9px;
}
.sign-result .modal-body--custom-attrs {
  padding: 35px 20px 0;
}
.sign-result-map {
  position: relative;
  /*margin: -35px -50px 40px;*/
  margin-bottom: 40px;
}
.sign-result-map-img {
  width: 100%;
}
.sign-result-layout {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.sign-result-row {
  display: table-row;
}
.sign-result-col {
  vertical-align: top;
  display: table-cell;
  padding: 0 73px;
  width: 50%;
}
.sign-result-col:first-child {
  padding-left: 0;
}
.sign-result-col:last-child {
  padding-right: 0;
}
.sign-result-date-item + .sign-result-date-item,
.sign-result-row--bordered .sign-result-col + .sign-result-col {
  border-left-width: 2px;
  border-left-style: solid;
}
.sign-result-block {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0);
}
.sign-result-block + .sign-result-block {
  margin-top: 25px;
}
.sign-result-block-email {
  word-break: break-word;
}
.sign-result-date-item,
.sign-result-block--date,
.sign-result-row--bordered .sign-result-col + .sign-result-col {
  border-color: #e9f2f8;
}
.sign-result-block--pin,
.sign-result-block--date,
.sign-result-block--greetings {
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 18px;
}
.sign-result-block--pin,
.sign-result-block--date {
  padding: 15px;
  text-align: center;
}
.sign-result-block--date {
  padding-left: 0;
  padding-right: 0;
  background: #eef8fd;
  border: 1px solid #e4eef5;
  color: #576472;
}
.sign-result-date {
  display: table;
  width: 100%;
}
.sign-result-date-item {
  display: table-cell;
  text-align: center;
}
.sign-result-date-item--time {
  width: 35%;
}
.sign-result-block--pin {
  text-transform: uppercase;
  border: 1px solid;
  color: #b2234b;
}
.sign-result-actions {
  text-align: right;
  width: 100%;
  display: block;
}
.sign-result-actions:before,
.sign-result-actions:after {
  content: " ";
  display: table;
}
.sign-result-actions:after {
  clear: both;
}
.sign-result-actions .btn-action--remove-ticket {
  float: left;
}
.sign-result-actions .btn {
  margin-bottom: 20px;
}
.sign-result-actions .btn + .btn {
  margin-left: 6px;
}
.sign-result-actions .btn-link + .btn-link {
  margin-left: -12px;
}
.sign-result-actions .sign-result-col {
  vertical-align: middle;
  text-align: center;
}
.sign-result-actions .btn-action--accept-ticket {
  width: 100%;
}
.sign-result-actions--additional .sign-result-action-link,
.sign-result-actions--additional .btn {
  min-width: 35%;
  max-width: 47.5%;
  color: #333333;
}
.sign-result-action-link {
  background-position: 10px 50%;
  background-repeat: no-repeat;
  border: 0;
}
.sign-result-action-link:hover {
  background-color: transparent;
}
.sign-result-action-link:hover .sign-result-action-link-text {
  border-bottom: 1px solid;
}
.sign-result-action-link--print {
  /*background-image: url('../../images/sunshine/ico/ticket-print.png');*/
  padding-left: 44px;
}
.sign-result-action-link--download {
  /*background-image: url('../../images/sunshine/ico/ticket-download.png');*/
  padding-left: 0;
}
.user-ntf-second-applicant-open {
  width: 99%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  position: relative;
}
.user-ntf-second-applicant-open:after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  content: '';
  width: 50px;
  background: #fffadf;
  /* Old browsers */
  background: -moz-linear-gradient(left, transparent, #fffadf 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, transparent, #fffadf 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, transparent, #fffadf 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='transparent', endColorstr='#fffadf', GradientType=1);
  /* IE6-9 */
}
.vis-results {
  padding-left: 0;
  padding-right: 0;
}
.vis-ticket {
  max-width: 800px;
  margin: 0 auto;
}
.vis-ticket-header-inner {
  position: relative;
  padding-bottom: 45px;
}
.vis-ticket-header-layout {
  display: table;
  height: 40px;
  margin-right: 250px;
}
.vis-ticket-header-layout-inner {
  display: table-cell;
  vertical-align: middle;
}
.vis-ticket-header-status {
  display: block;
  font-size: 18px;
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: normal;
}
.vis-ticket-header-info {
  display: block;
  font-size: 14px;
  font-family: "als_ekibastuz_bold", Arial, sans-serif;
}
.vis-ticket-header-actions {
  position: absolute;
  right: 0;
  top: 0;
}
.vis-ticket-header-actions .btn {
  padding-top: 9px;
  padding-bottom: 9px;
}
.vis-result .modal-body--custom-attrs {
  padding: 35px 20px 0;
}
.vis-result-map {
  position: relative;
  /*margin: -35px -50px 40px;*/
  margin-bottom: 40px;
}
.vis-result-map-img {
  width: 100%;
}
.vis-result-layout {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.vis-result-row {
  display: table-row;
}
.vis-result-col {
  vertical-align: top;
  display: table-cell;
  padding: 0 50px;
}
.vis-result-col:first-child {
  padding-left: 0;
  width: 30%;
}
.vis-result-col:last-child {
  padding-right: 0;
  width: 70%;
}
.vis-result-date-item + .vis-result-date-item,
.vis-result-row--bordered .vis-result-col + .vis-result-col {
  border-left-width: 2px;
  border-left-style: solid;
}
.vis-result-block {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0);
}
.vis-result-block + .vis-result-block {
  margin-top: 25px;
}
.vis-result-block-email {
  word-break: break-word;
}
.vis-result-date-item,
.vis-result-block--date,
.vis-result-row--bordered .vis-result-col + .vis-result-col {
  border-color: #e9f2f8;
}
.vis-result-block--pin,
.vis-result-block--date,
.vis-result-block--greetings {
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 18px;
}
.vis-result-block--pin,
.vis-result-block--date {
  padding: 15px;
  text-align: center;
}
.vis-result-block--date {
  padding-left: 0;
  padding-right: 0;
  background: #eef8fd;
  border: 1px solid #e4eef5;
  color: #576472;
}
.vis-result-date {
  display: table;
  width: 100%;
}
.vis-result-date-item {
  display: table-cell;
  text-align: center;
}
.vis-result-date-item--time {
  width: 35%;
}
.vis-result-block--pin {
  text-transform: uppercase;
  border: 1px solid;
  color: #b2234b;
}
.vis-result-actions {
  text-align: right;
  width: 100%;
  display: block;
}
.vis-result-actions:before,
.vis-result-actions:after {
  content: " ";
  display: table;
}
.vis-result-actions:after {
  clear: both;
}
.vis-result-actions .btn-action--remove-ticket {
  float: left;
}
.vis-result-actions .btn {
  margin-bottom: 20px;
}
.vis-result-actions .btn + .btn {
  margin-left: 6px;
}
.vis-result-actions .btn-link + .btn-link {
  margin-left: -12px;
}
.vis-result-actions .vis-result-col {
  vertical-align: middle;
  text-align: center;
}
.vis-result-actions .btn-action--accept-ticket {
  width: 100%;
}
.vis-result-actions--additional .vis-result-action-link,
.vis-result-actions--additional .btn {
  min-width: 35%;
  max-width: 47.5%;
  color: #333333;
}
.vis-result-action-link {
  background-position: 10px 50%;
  background-repeat: no-repeat;
  border: 0;
}
.vis-result-action-link:hover {
  background-color: transparent;
}
.vis-result-action-link:hover .vis-result-action-link-text {
  border-bottom: 1px solid;
}
.vis-result-action-link--print {
  /*background-image: url('../../images/sunshine/ico/ticket-print.png');*/
  padding-left: 44px;
}
.vis-result-action-link--download {
  /*background-image: url('../../images/sunshine/ico/ticket-download.png');*/
  padding-left: 0;
}
.petition-status-icon {
  width: 23px;
  height: 23px;
  display: inline-block;
  vertical-align: top;
  border-radius: 100%;
  border: 0 solid;
  position: absolute;
  top: 20px;
  left: 10px;
}
.petition-status-icon:before,
.petition-status-icon:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  margin-top: -1px;
  text-align: center;
  font-size: 100%;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.petition-status-icon:before {
  content: "\f1db";
  font-size: 200%;
}
.petition-status-icon,
.petition-status-icon--unknown {
  color: #56c5cc;
}
.petition-status-icon:after,
.petition-status-icon--unknown:after {
  content: "\f129";
}
.petition-status-icon--success {
  color: #6ec256;
}
.petition-status-icon--success:after {
  content: "\f00c";
}
.petition-status-icon--exceeded,
.petition-status-icon--rejected,
.petition-status-icon--denied {
  color: #dd191d;
}
.petition-status-icon--exceeded:after,
.petition-status-icon--rejected:after,
.petition-status-icon--denied:after {
  content: "\f00d";
}
.petition-status-icon--ingroup {
  color: #f4821f;
}
.petition-status-icon--ingroup:after {
  content: "\f0c0";
  font-size: 100%;
}
.petition-status-icon--new {
  color: #0063b0;
}
.petition-status-icon--new:after {
  content: "\f067";
}
.petition-status-icon--pending,
.petition-status-icon--inprocess {
  color: #f4821f;
}
.petition-status-icon--pending:after,
.petition-status-icon--inprocess:after {
  content: "\f017";
}
.petition-status-icon--newExecutor {
  color: #f4821f;
}
.petition-status-icon--newExecutor:after {
  content: "\f183";
}
.petition-status-icon--waitingOriginals {
  color: #f4821f;
}
.petition-status-icon--waitingOriginals:after {
  content: "\f24a";
  font-size: 13px;
}
.petition-status-title {
  padding-left: 30px;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.petition-row-info {
  display: none;
  padding: 0 10px;
  cursor: initial;
}
.petition-row--open:hover {
  background-color: #fff;
}
.petition-row--open .petition-row-info {
  display: block;
}
.petition-row--open .grid-col-remove-link {
  display: none;
}
.petition-row {
  position: relative;
}
.action-row {
  cursor: pointer;
}
.action-row:hover {
  background: #d9edf7;
}
.petition-row--exceeded {
  background: #fbe0e1;
}
.b-complaint-exceeded-info {
  padding: 20px 10px 0;
}
.b-complaint-exceeded-desc {
  display: block;
  padding: 12px 0 0;
  color: #777777;
}
.b-complaint-exceeded-ico {
  position: relative;
  vertical-align: top;
  top: 0;
  left: 10px;
}
.petition-row--loading .petition-status-icon {
  background: url('../../../images/lktoepgu/loader-small.gif') center center no-repeat;
}
.petition-row--loading .petition-status-icon:before,
.petition-row--loading .petition-status-icon:after {
  display: none;
}
.petition-row-inner {
  position: relative;
}
.petition-row-info-arrow {
  overflow: hidden;
  width: 19px;
  position: absolute;
  bottom: 0;
  top: 48px;
  left: 11.5px;
  display: none;
}
.petition-row-info-arrow:before {
  top: 0;
  bottom: 13px;
  left: 8px;
  border-left: 3px solid #0063b0;
  position: absolute;
  display: block;
  content: "";
}
.petition-row-info-arrow svg {
  fill: #0063b0;
  position: absolute;
  bottom: 0;
  left: 0;
}
.petition-row--open.petition-row--ready .petition-row-info-arrow {
  display: block;
}
.petition-row-info-container {
  padding: 0 0 20px;
  position: relative;
}
.petition-row-info-container:before,
.petition-row-info-container:after {
  content: " ";
  display: table;
}
.petition-row-info-container:after {
  clear: both;
}
.petition-subfold-holder-outer {
  padding-top: 3px;
  padding-left: 20px;
}
.petition-subfold-holder-outer:after {
  content: "";
  display: table;
  clear: both;
}
.petition-subfold-holder-outer.subfold-holder-outer__sideline:before {
  left: 10px;
  background-color: #eeeeee;
  background: linear-gradient(to bottom, #eeeeee, #0063b0);
}
.petition-info-group-marker {
  width: 23px;
  height: 23px;
  box-shadow: inset 0 0 0 2px #0063b0;
  color: #0063b0;
}
.petition-info-status-title {
  font-weight: bold;
}
.petition-info-status-date {
  color: #839199;
}
.petition-info-status-link {
  color: #0063b0;
}
.petition-info-status-link > .petition-info-status-link-text {
  display: inline;
}
.petition-info-status-col {
  padding-bottom: 40px;
}
.subfold-holder-outer__last .petition-info-status-col {
  padding-bottom: 0;
}
.petition-row-info-comment {
  font-style: 14px;
  line-height: 20px;
  color: #839199;
  display: block;
  padding: 15px 0;
}
.petition-info-status-additional {
  clear: both;
}
.petition-row-info-actions {
  font-size: 0.01px;
}
.petition-row-info-actions:before,
.petition-row-info-actions:after {
  content: " ";
  display: table;
}
.petition-row-info-actions:after {
  clear: both;
}
.petition-row-info-actions .btn {
  float: left;
}
.petition-row-info-actions .btn + .btn {
  margin-left: 20px;
}
.petition-row-info-voted {
  color: #6ec256;
  cursor: default;
}
.petition-row-info-voted:hover {
  color: #6ec256;
}
.petition-row-info-voted-ico {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: -2px 5px 0 0;
  vertical-align: middle;
}
#historyList_processing {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 100;
  padding-top: 50%;
}
#historyList_processing:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
}
.historyList_processing_loader {
  background: white;
  display: inline-block;
  padding: 20px;
  border: 1px solid;
  z-index: 100;
  position: relative;
}
.historyList_processing_loader img {
  width: 24px;
  height: 24px;
  margin: 0 10px 0 0;
  display: inline-block;
}
@keyframes my_fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes my_fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.historyList {
  font-size: 15px;
}
.historyList .grid-col--status {
  padding-left: 32px;
}
.historyList .grid-row {
  animation: my_fadeIn 1s;
  cursor: pointer;
}
.historyList .grid-row.my-loader {
  display: table-row;
  background-size: 30px auto;
  background-position: 50%;
  min-height: 0;
  padding-top: 0;
}
.historyList .grid-row.my-loader > td {
  opacity: 0;
}
.historyList .grid-row._to_del {
  animation: my_fadeOut 1s;
}
.historyList ._status .ico {
  margin: 0 0 0 -32px;
  display: block;
  position: absolute;
  width: 32px;
  font-size: 20px;
}
.historyList ._status .fa-clock-o {
  color: #EAA800;
}
.historyList ._status .fa-check {
  color: #009C51;
}
.historyList ._status .fa-times {
  color: #EE3F58;
}
.historyList ._extId {
  color: #333333;
}
.historyList ._extId:hover {
  color: #1a88d7;
}
.historyList ._title {
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: 700;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  max-height: 3em;
}
.historyList ._org {
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  max-height: 3em;
}
.historyList.togglable .btn-switch:before {
  content: 'Скрыть историю';
  vertical-align: middle;
}
.historyList.togglable .btn-switch:after {
  content: "\f106";
  font: normal 14px/20px FontAwesome;
  vertical-align: middle;
  margin-left: 10px;
}
.historyList.togglable.closed .table > tbody > tr + tr {
  display: none;
}
.historyList.togglable.closed .btn-switch:before {
  content: 'Показать историю';
}
.historyList.togglable.closed .btn-switch:after {
  content: "\f107";
}
.historyList ._info {
  margin: 1em 0 0 0;
}
.historyList .btn {
  vertical-align: middle;
}
.historyList .btn + .fav {
  margin-left: 10px;
  vertical-align: middle;
}
.historyList .fav {
  background: #F6F6F6;
  width: 32px;
  height: 32px;
  display: inline-block;
  border: 1px solid #E4E4E4;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}
.historyList .fav.active:before {
  color: #0173C1;
}
.historyList .fav:before {
  content: '\f005';
  font: normal normal normal 20px/32px FontAwesome;
  color: #A6AFC2;
}
.historyList ._comment {
  font-size: 16px;
  font-weight: 700;
  padding: 16px 24px;
  color: #848993;
  margin: 24px 0;
}
.historyList ._comment h5 {
  font-size: 18px;
  margin: 0 0 16px 0;
  color: #4C4C4C;
}
.table-filter {
  padding: 15px 0;
}
.table-filter .col + .col {
  padding-left: 0;
}
.table-filter div.form-control,
.table-filter label.form-control {
  display: block;
  padding-right: 45px;
  position: relative;
}
.table-filter div.form-control .ico,
.table-filter label.form-control .ico {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}
.table-filter div.form-control input[type=text],
.table-filter label.form-control input[type=text] {
  border: 0;
  width: 100%;
  margin: 0;
  outline: none !important;
  line-height: 24px;
  padding: 0;
}
.table-filter div.form-control input[type=text][readonly],
.table-filter label.form-control input[type=text][readonly] {
  background: none !important;
  margin-right: 0 !important;
}
.table-filter div.form-control input[type=text]:active,
.table-filter label.form-control input[type=text]:active,
.table-filter div.form-control input[type=text]:focus,
.table-filter label.form-control input[type=text]:focus {
  box-shadow: none;
}
.b-petition-in-sign {
  padding: 54px 0;
  border-bottom: 1px solid #c8c7cc;
  position: relative;
  color: #839199;
  display: block;
}
.b-petition-in-sign b {
  color: #000;
}
.b-petition-in-sign p:first-child {
  margin-top: 0;
}
.b-petition-in-sign:hover {
  color: inherit;
  background: #e9f2f8;
}
.b-petition-in-sign-ico {
  display: block;
  position: absolute;
  left: 5px;
  width: 102px;
  top: 50px;
  height: 102px;
}
.b-petition-in-sign-error {
  margin-bottom: 20px;
}
.b-petition-in-sign-error h3 {
  font-size: 32px;
}
.b-petition-in-sign-error h3 .modal-subtitle {
  color: #dd191d;
}
.b-petition-in-sign-error .modal-header {
  margin-bottom: 0;
}
.b-petition-in-sign-content {
  display: block;
  padding-left: 130px;
}
.b-petition-in-sign-link {
  font-size: 18px;
  line-height: 22px;
  color: #0063b0;
}
.b-petition-in-sign-check-wrap {
  display: block;
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 40px;
  width: 280px;
  padding: 0;
}
.b-petition-in-sign-check {
  display: block;
  position: relative;
  width: 280px;
  padding: 40px 0 0;
  top: 0;
}
.b-petition-in-sign-check:first-child {
  padding-top: 0;
}
.b-petition-in-sign-check.fixed {
  position: fixed;
}
.b-petition-in-sign-check .btn {
  width: 182px;
}
.b-petition-states {
  padding-bottom: 30px;
  border-bottom: 1px solid #cdd1d4;
  color: #839199;
  position: relative;
}
.b-petition-states b {
  color: #000;
}
.b-petition-states .petition-states-ok {
  color: #6ec256;
}
.b-petition-states .petition-states-wrong {
  color: #dd191d;
}
.b-petition-states .petition-states-wait {
  color: #f4821f;
}
.b-petition-state-item {
  display: block;
  margin: 1em 0;
}
.b-sign-type-ico {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 23px;
  height: 23px;
  margin: 0 10px;
  border-radius: 100%;
  border: 0 solid;
}
.b-sign-type-ico[data-signature] {
  cursor: pointer;
}
.b-sign-type-ico:before,
.b-sign-type-ico:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  font-size: 100%;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.b-sign-type-ico:before {
  content: "\f1db";
  font-size: 200%;
}
.b-sign-type-ico,
.b-sign-type-ico--unknown {
  color: #777777;
}
.b-sign-type-ico:after,
.b-sign-type-ico--unknown:after {
  content: "\f128";
}
.b-sign-type-ico--valid {
  color: #6ec256;
}
.b-sign-type-ico--valid:after {
  content: "\f00c";
}
.b-sign-type-ico--disabled {
  color: #777777;
}
.b-sign-type-ico--disabled:after {
  content: "\f013";
}
.b-sign-type-ico--wrong {
  color: #dd191d;
}
.b-sign-type-ico--wrong:after {
  margin-top: -1px;
  content: "\f00d";
}
.b-sign-type-ico--wait {
  color: #f4821f;
}
.b-sign-type-ico--wait:after {
  content: "\f141";
  font-size: 100%;
}
.p-modal--petition-in-sign-view {
  min-height: 350px;
}
.ecp-verify-labels {
  display: block;
  position: relative;
  color: #839199;
}
.ecp-verify-labels + .ecp-verify-labels {
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid #cdd1d4;
}
.ecp-verify-status-icon {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 0;
  top: -25px;
}
.ecp-verify-status-icon:before,
.ecp-verify-status-icon:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ecp-verify-status-icon:before {
  content: "\f1db";
  color: #f2f2f2;
  font-size: 90px;
}
.ecp-verify-status-icon:after {
  font-size: 36px;
}
.ecp-verify-status-icon__verified:after {
  content: "\f00c";
  color: #6ec256;
}
.ecp-verify-status-icon__declined:after {
  content: "\f00d";
  color: #dd191d;
}
.ecp-verify-status {
  font-weight: bold;
  display: block;
  position: relative;
  padding-left: 20px;
}
.ecp-verify-status__verified {
  color: #6ec256;
}
.ecp-verify-status__declined {
  color: #dd191d;
}
.ecp-verify-status:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.ecp-verify-status__verified:before {
  content: "\f00c";
}
.ecp-verify-status__declined:before {
  content: "\f00d";
}
.ecp-verify-content {
  display: block;
  padding-left: 105px;
}
/*
 styles for signing files
 look into template: WEB-INF/cms/templates/lktoepgu/actions/pubobjcreate/includes/attrs/link_file_sign.jsp
*/
.upload-box-el-actions__compact {
  left: auto;
  right: 110px;
  top: 5px;
}
.upload-box-progress-text {
  right: 150px;
}
.upload-box-el-actions__compact .upload-box-action.upload-box-action__cancel {
  padding: 1px 5px;
  font-size: inherit;
}
.file-attr-signature-box {
  padding: 2px 0 4px;
  display: block;
  float: right;
  text-align: right;
}
.file-attr-signature {
  margin: 15px 0 0 10px;
}
.file-attr-signature-marker {
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.file-attr-signature-marker:hover {
  opacity: 0.85;
}
.file-attr-signature-marker__loading {
  background-image: url('../../../images/lktoepgu/loader-small.gif') !important;
  opacity: 1 !important;
}
.file-attr-signature-marker__signed {
  background-image: url('../../../images/lktoepgu/signature/validity_ok.png');
}
.file-attr-signature-marker__error {
  background-image: url('../../../images/lktoepgu/signature/validity_error.png');
}
.file-attr-signature-marker__warn {
  background-image: url('../../../images/lktoepgu/signature/validity_alert.png');
}
.file-attr-signature-btn {
  display: inline-block;
  vertical-align: middle;
}
/*.ecp-dialog {
  left:0!important;
  top:auto!important;
  transform: none!important;
}  */
.bill-list-item-dates {
  font-weight: normal;
}
.bill-list-item-dates .fa {
  margin-right: 0.5em;
}
.hunter-resources {
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
}
.hunter-resources .hunter-info,
.hunter-resources__blocks {
  margin-top: 24px;
}
.hunter-resources .hunter-table {
  margin-top: 16px;
}
.hunter-resources__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hunter-resources__header-title label {
  margin: 0;
  padding: 0;
  line-height: inherit;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.hunter-resources__header-title input {
  margin: 0;
  padding: 0;
}
.hunter-resources__sum {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
}
.hunter-resources__sum-value {
  font-weight: bold;
}
.hunter-resources__block-item:not(:first-child) {
  margin-top: 24px;
}
.hunter-resources__block-item .hunter-resources__table-container {
  padding-left: 24px;
}
.hunter-tabs {
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  display: flex;
}
.hunter-info + .hunter-tabs {
  margin-top: 0.5em;
}
.hunter-tabs .hunter-content {
  width: 100%;
}
.hunter-tabs__button {
  position: relative;
  flex-shrink: 0;
  padding: 16px;
  background: none;
  border: none;
  color: #777777;
}
.hunter-tabs__button:first-child:after {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.hunter-tabs__button:last-child:after {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.hunter-tabs__button:only-child:after {
  border-radius: 4px;
}
.hunter-tabs__button:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #eeeeee;
}
.hunter-tabs__button.active {
  color: #0063b0;
}
.hunter-tabs__button.active:after {
  background-color: #0063b0;
}
.hunter-close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: none;
  font-weight: bold;
  background: none;
  color: #0063b0;
}
.hunter-info {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding: 16px 24px;
  background-color: #E7F1F8;
  border-radius: 4px;
}
.hunter-info + .hunter-info {
  margin-top: 2px;
}
.hunter-info__left-side .hunter-info__item {
  padding: 0;
}
.hunter-info__right-side {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.hunter-info__item {
  padding: 6px 12px;
  border-radius: 4px;
}
.hunter-info__item--small {
  font-size: 12px;
}
.hunter-info__item--gray {
  color: #777777;
}
.hunter-info__item--bg-white {
  background-color: white;
}
.hunter-info__value {
  font-weight: bold;
}
.hunter-info__value.active {
  color: #0063b0;
}
.hunter-table {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  border-collapse: unset;
  border-spacing: 0;
  background-color: #eeeeee;
  overflow: hidden;
}
.hunter-table thead {
  background-color: #eeeeee;
}
.hunter-table tbody {
  background-color: white;
}
.hunter-table th {
  padding: 12px 24px;
}
.hunter-table td {
  padding: 8px 24px;
}
.b-section {
  position: relative;
}
.b-section__content {
  min-height: 370px;
  padding: 60px 0;
}
.b-section-common {
  margin-top: 60px;
}
.b-section-common--mid {
  margin-top: 30px;
}
.b-page-section {
  padding: 0 0 45px;
}
.b-page-section:before,
.b-page-section:after {
  content: " ";
  display: table;
}
.b-page-section:after {
  clear: both;
}
.b-page-section + .b-page-section {
  padding-top: 55px;
  border-top: #eeeeee;
}
.b-page-section-title {
  font-size: 25px;
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
  margin: 0 0 45px;
  padding: 0;
}
.b-page-section-item {
  margin-top: 25px;
}
.b-page-section-item-title {
  font-weight: bold;
  margin: 0 0 0.25em;
}
.b-page-section-item-data {
  margin: 0;
  padding: 0;
  line-height: 1.3333333;
}
.b-auth-warn {
  background-color: #fff;
  height: 510px;
}
.b-auth-warn-bg {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: block;
}
.b-auth-warn-slider {
  height: 100%;
}
.b-auth-warn-wrapper {
  padding: 0 20px;
  position: relative;
}
.b-auth-warn-slide {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 10;
  transition: opacity 1s;
  color: #333333;
  text-decoration: none;
}
.b-auth-warn-slide__visible {
  display: block;
  z-index: 11;
  opacity: 1;
}
.b-auth-warn-slide-link {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  text-decoration: none;
  color: #333333;
  outline: 0 none;
}
.b-auth-warn-slide-link:hover {
  text-decoration: none;
}
.b-auth-warn-inner {
  height: calc(100% - 110px);
  max-width: 655px;
  padding: 40px 65px 40px 65px;
  position: relative;
  display: table;
  z-index: 15;
}
.b-auth-warn-inner__actions {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: 25px;
  top: 50%;
  height: auto;
  padding: 30px;
  transform: translateY(-50%);
  margin-top: -55px;
  width: auto;
}
.b-auth-warn-text {
  display: table-cell;
  vertical-align: middle;
  color: #415862;
  text-shadow: 1px 1px 0 #fff;
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5em;
  font-size: 36px;
}
.b-auth-warn-text b,
.b-auth-warn-text strong {
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: bold;
}
.b-auth-warn-actions-text {
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  color: #555555;
  font-size: 25px;
  line-height: 24px;
  text-align: center;
  padding-top: 100px;
  background: url("../../../images/lktoepgu/gu.png") center top no-repeat transparent;
}
.b-auth-btn {
  width: 275px;
  text-align: center;
  word-break: break-all;
  display: block;
  margin-top: 15px;
}
.b-auth-btn__sign_up {
  color: #565656;
}
.b-auth-warn-slider-control {
  display: block;
  position: absolute;
  top: 20px;
  left: 14px;
  width: 30px;
  height: 39px;
  line-height: 39px;
  text-align: center;
  background: #fbfbfb;
  cursor: pointer;
  z-index: 15;
}
.b-auth-warn-slider-control--right {
  left: 46px;
}
.b-help {
  background-color: #fff;
}
.btn-fontmiddle {
  font-size: 16px;
}
.b-help-bg {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: block;
}
.b-help-wrapper {
  padding: 0;
  position: relative;
}
.b-help-inner {
  padding: 60px 60px 80px;
  position: relative;
  color: #fff;
  min-height: 450px;
  margin: 0 auto;
}
.b-help-inner h2 {
  font-size: 52px;
  margin-bottom: 40px;
  margin-top: 0;
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: 400;
}
.b-help-inner--text {
  font-size: 21px;
  font-weight: 400;
  padding-bottom: 60px;
}
.b-help-inner .btn-group .btn {
  margin-right: 28px;
}
.b-help-inner .b-help-secure {
  padding: 40px 50px 60px;
  background: url('../../../images/lktoepgu/help-secure_bg.png') repeat center center transparent;
  position: absolute;
  right: 0;
  top: 80px;
  width: 235px;
}
.b-help-inner .b-help-secure--text {
  background: url('../../../images/lktoepgu/help_secure.png') no-repeat top center transparent;
  padding-top: 100px;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  line-height: 26px;
  text-align: center;
  color: #565656;
  display: block;
}
.b-help-inner .b-help-secure--text :hover {
  color: #415862;
}
.modal-help {
  padding: 60px 0 40px;
}
.modal-page-content-wrapper-contacts--grey {
  margin: 0 auto;
  padding: 0;
}
.b-help-contacts {
  display: table;
  padding: 20px 0 0;
  margin: 0 auto;
}
.b-help-contacts-block {
  display: table-cell;
  width: 300px;
  text-align: center;
}
.b-help-contacts-block--inner {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding-top: 90px;
}
.b-help-contacts-block--inner a {
  text-decoration: none;
  color: #565656;
  display: block;
  text-align: center;
}
.b-help-contacts-block--inner a :hover {
  color: #415862;
}
.b-help-contacts-block--support {
  background: url('../../../images/lktoepgu/help-support.png') no-repeat top center transparent;
}
.b-help-contacts-block--phone {
  background: url('../../../images/lktoepgu/help-phone.png') no-repeat top center transparent;
}
.b-help-contacts-block--app {
  background: url('../../../images/lktoepgu/help-app.png') no-repeat top center transparent;
}
.b-help-contacts-block--btn {
  padding-top: 20px;
}
.b-help-contacts-block--btn span {
  display: block;
  width: 160px;
  margin: 0 auto;
}
.b-help-faq .btn-block {
  padding: 0 0 30px;
  text-align: center;
}
.b-help-faq--ul {
  display: table;
  padding: 45px 0 30px;
  margin: 0 auto;
}
.b-help-faq--ul li {
  display: block;
  width: 33%;
  float: left;
  padding-left: 50px;
}
.b-help-faq--ul li a:before {
  content: "\f105";
  color: #0063b0;
  border: 2px solid #0063b0;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  padding-top: 2px;
  text-align: center;
  font: normal normal normal 18px/16px FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  position: absolute;
  font-weight: 700;
  padding-left: 2px;
}
.b-help-faq--ul li a {
  display: block;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
}
.b-help-faq--ul li a span {
  padding: 0 0 30px 40px;
  display: block;
}
.b-help-helpful {
  padding-top: 0;
}
.b-help-helpful .btn-block {
  padding: 0 0 30px;
  text-align: center;
}
.b-help-helpful--ul {
  display: block;
  padding: 0 0 12px;
  margin: 0 auto;
  width: 1198px;
  overflow: hidden;
}
.b-help-helpful--ul li {
  display: block;
  width: 299px;
  float: left;
}
.b-help-helpful--ul li a {
  display: block;
  width: 281px;
  height: 281px;
  line-height: 281px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 10px;
  margin: 0 9px 18px;
}
.b-help-helpful--ul li a span {
  line-height: 24px;
  display: inline-block;
  padding: 10px;
}
.b-help-helpful--ul--centered {
  text-align: center;
  width: auto;
}
.b-help-helpful--ul--centered li {
  display: inline-block;
  float: none;
}
.b-help-helpful--ul--centered li + li {
  margin-left: -3px;
}
.modal-body-headcrumbs--help {
  border-bottom: 1px solid #e5e5e7;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.modal-body-headcrumbs--help .modal-body-headcrumbs-title {
  line-height: 46px;
}
.faq-inner {
  padding: 0;
  list-style: none;
}
.faq-inner--question {
  font-size: 17px;
  line-height: 26px;
  font-weight: 400;
  margin: 23px 0;
  text-decoration: none;
  border-bottom: 1px dashed #0173c1;
  display: inline-block;
  position: relative;
}
.faq-inner--question:hover,
.faq-inner--question:focus {
  text-decoration: none;
}
.faq-inner--question:before {
  content: "\f107";
  color: #565656;
  border: 1px solid #ccc;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  padding-top: 3px;
  text-align: center;
  font: normal normal normal 18px/16px FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  position: absolute;
  right: -32px;
  top: 4px;
  font-weight: bold;
}
.faq-inner--answer {
  display: none;
}
.faq-inner > li {
  list-style: none;
  display: block;
}
li.active .faq-inner--answer {
  display: block;
}
li.active .faq-inner--question:before {
  content: "\f106";
  padding-top: 1px;
}
.help-article {
  font-size: 16px;
  color: #535b63;
}
.help-article h3 {
  margin: 20px 0 40px 0;
  font: 600 18px 'ekibastuz', 'sans-serif';
}
.b-tech-message {
  background: #fffadf;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.b-tech-message strong,
.b-tech-message b {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.b-tech-message .wrapper {
  padding: 15px 45px 15px 65px;
  font-size: 15px;
  color: #000;
  max-height: 1000000px;
}
.b-tech-message-icon {
  width: 24px;
  height: 24px;
  color: #f4821f;
  border-radius: 100%;
  font-style: normal;
  border: 2px solid;
  position: absolute;
  top: 12px;
  left: 24px;
}
.b-tech-message-icon:after {
  content: "!";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: inherit;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 100;
  max-height: 1000000px;
}
.b-tech-message-close {
  font-size: 24px;
  color: #777777;
  position: absolute;
  right: 25px;
  top: 9.5px;
}
.b-tech-message-close:hover {
  text-decoration: none;
  color: #555555;
}
.b-panel {
  color: #fff;
  background: #213781;
  user-select: none;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
}
.b-panel__govbar_enabled {
  margin-top: -50px;
}
.b-panel__govbar_enabled .b-panel-wrapper {
  display: none;
}
.b-panel-drop {
  float: left;
  cursor: pointer;
  position: relative;
  padding: 0;
  height: 50px;
  line-height: 50px;
}
.b-panel-drop:hover {
  background: #0063b0;
}
.b-panel-drop.b-panel-drop--no-drop {
  cursor: default;
}
.b-panel-drop.b-panel-drop--no-drop:hover {
  background: transparent;
}
.b-panel-drop__logo {
  width: 76px;
}
.b-panel-drop__lang {
  float: right;
  text-transform: uppercase;
}
.b-panel-drop__lang .b-panel-drop-btn .g-flag {
  top: 17px;
}
.b-panel-drop__lang .g-flag {
  vertical-align: top;
  position: relative;
  margin-right: 12px;
}
.b-panel-drop__serverTime {
  float: right;
  pointer-events: none;
  text-transform: uppercase;
}
.b-panel-drop-inner {
  padding-top: 0;
  padding-bottom: 0;
}
.b-panel-drop:first-child .b-panel-drop-inner {
  padding-left: 9px;
  left: -9px;
}
.b-panel-drop-inner:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f107";
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 9px;
  font-size: 16px;
}
.b-panel-drop-btn {
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  position: relative;
  display: block;
  box-shadow: none;
  outline: none;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0 18px;
}
.b-panel-drop-btn__logo {
  background: url('../../../images/lktoepgu/rus-logo.png') 50% 50% no-repeat;
  background-size: 26px 29px;
}
.b-panel-drop-btn.dropdown-value {
  padding-right: 35px;
}
.b-panel-drop-btn.dropdown-value:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f107";
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 9px;
}
.b-header-wrapper {
  padding-top: 15px;
  padding-bottom: 15px;
  transition: padding 0.35s ease;
}
.b-header-wrapper:before,
.b-header-wrapper:after {
  content: " ";
  display: table;
}
.b-header-wrapper:after {
  clear: both;
}
.b-header-logo {
  float: left;
  width: 280px;
  height: 50px;
  font-size: 26.85px;
  line-height: 1em;
  white-space: nowrap;
  margin: 0;
  padding: 0;
}
.b-header-logo-inner {
  display: inline-block;
}
.b-header-logo-glob-outer {
  position: relative;
  overflow: hidden;
}
.b-header-logo-glob {
  display: block;
  height: 50px;
  transition: height 0.35s ease;
}
.b-header-logo-img-outer {
  width: 28px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
}
.b-header-logo-img {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  top: 0;
}
.b-header-logo-caption {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 26.84px;
  margin-left: 8px;
}
.section-fixate__scrolled .b-header-logo {
  height: 32px;
}
.section-fixate__scrolled .b-header-logo-glob {
  display: block;
  height: 32px;
}
.section-fixate__scrolled .b-header {
  border-bottom: 0;
}
.section-fixate__scrolled .b-header-wrapper {
  padding-top: 9px;
  padding-bottom: 10px;
}
.section-fixate__scrolled .b-header-profile {
  top: 0;
}
.section-fixate__scrolled .b-header-profile__anonymous {
  top: 13px;
}
.section-fixate__scrolled .b-header-profile-drop-btn {
  padding-top: 15px;
  padding-bottom: 15px;
}
.section-fixate__scrolled .b-header-nav {
  margin: 0;
  padding-top: 4px;
}
.section-fixate__scrolled .b-search-form-col--input .b-search-input-wrapper .form-control {
  height: 40px;
  line-height: 40px;
}
.section-fixate__scrolled .b-search-wrapper {
  padding: 4px 11px;
}
.section-fixate__scrolled .b-search-form-submit-btn {
  height: 40px;
  line-height: 40px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 15px;
}
.section-fixate__scrolled .b-search-form-close-btn {
  font-size: 38px;
  line-height: 38px;
  margin-top: 2px;
}
.section-fixate__scrolled .b-search--header .b-search-form-col--close {
  padding-left: 30px;
}
.section-fixate__scrolled .section-fixate-inner {
  border-bottom: 1px solid rgba(168, 168, 168, 0.15);
}
.b-header-profile {
  position: absolute;
  right: 25px;
  font-size: 18px;
  color: #0063b0;
  top: 21px;
  transition: opacity 0.65s ease, top 0.35s ease;
}
.b-header-profile__anonymous {
  top: 35px;
}
.b-header-profile__hidden {
  opacity: 0;
  pointer-events: none;
}
.section-fixate__banner_passed .b-header-profile__hidden {
  opacity: 1;
  pointer-events: auto;
}
.b-header-profile-drop {
  margin-right: -12px;
}
.b-header-profile-drop-btn {
  display: block;
  position: relative;
  width: 100%;
  text-align: right;
  text-decoration: none !important;
  background: none;
  padding: 15px 14px 15px 17px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid transparent;
  border-bottom: 0;
  transition: padding 0.35s ease;
}
.open .b-header-profile-drop-btn {
  border-color: rgba(0, 0, 0, 0.15);
}
.b-header-profile-drop-btn .g-user-name {
  display: inline-block;
  font-family: "als_ekibastuz_light", Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
}
.b-header-profile-drop-btn:after {
  content: "";
  position: relative;
  vertical-align: top;
  line-height: inherit;
  margin-top: 0.5em;
  margin-left: 0.25em;
  display: inline-block;
  overflow: hidden;
  height: 0;
  width: 0;
  border: 4px solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.open .b-header-profile-drop-btn:after {
  border-top-color: transparent;
  border-bottom-color: initial;
  margin-top: calc(0.5em - 4px);
}
.b-header-profile-drop-menu {
  box-shadow: none;
  position: relative;
  width: 100%;
  background: #fff;
  padding: 5px 0 0;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 0 6px 6px;
  border-top: 0;
}
.b-header-profile-drop-menu:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 17px;
  right: 17px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  height: 0;
}
.b-header-profile-drop-menu li {
  display: block;
  list-style: none;
}
.b-header-profile-drop-menu li a {
  width: 100%;
  height: 100%;
  display: block;
  color: inherit;
  font-weight: normal;
  text-decoration: none !important;
  padding: 13px 14px 13px 17px;
}
.b-header-profile-drop-menu li.changeauth span {
  display: block;
  color: #000;
  font-size: 12px;
  font-family: als_ekibastuz_light, Arial, sans-serif;
  font-weight: 400;
}
.b-header-profile-drop-menu li.changeauth > span {
  padding-left: 17px;
}
.b-header-profile-drop-menu li.changeauth:hover a span {
  color: #333;
}
.b-header-profile-drop-menu li.changeauth > a {
  padding: 7px 14px 7px 35px;
  font-size: 12px;
  max-width: 275px;
  white-space: normal;
}
.b-header-profile-drop-menu .changeauthBlock {
  overflow: auto;
}
.b-header-profile-drop.dropdown.open {
  background: #fff;
}
.b-header-title {
  text-transform: lowercase;
  text-decoration: none !important;
  display: block;
  float: left;
  height: 49px;
  line-height: 49px;
  padding-left: 37px;
}
.b-header-title,
.b-header-title:hover,
.b-header-title:active {
  color: #0065b1;
}
.b-header-title-main {
  color: #ee3f58;
}
.b-header-profile-link {
  font-family: "als_ekibastuz_light", Arial, sans-serif;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  background: url("../../../images/lktoepgu/key.png") left center no-repeat transparent;
  padding-left: 24px;
  font-weight: normal;
}
.b-header-profile-link__signup {
  margin-left: 24px;
}
.b-header-profile-btn {
  display: inline-block;
  vertical-align: middle;
  padding: 6px 26px;
  font-weight: 700;
  border: 1px solid #0063b0;
  color: #0063b0;
  margin-left: 24px;
}
.b-header-nav {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  margin: 0;
  padding-top: 20px;
}
.b-header-nav li {
  display: inline-block;
  text-align: center;
  margin-left: 45px;
}
.b-header-nav li.active a {
  color: #000;
}
.b-header-nav li a {
  text-decoration: none !important;
}
.b-header-catwrapper {
  padding: 0!important;
  position: relative;
  display: none;
}
.b-header-catwrapper .b-header-catwrapper-block {
  position: absolute;
  top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  width: 100%;
  background: #fff;
  padding-bottom: 40px;
}
.b-header-catwrapper .b-header-catwrapper-block .btn-block {
  text-align: center;
}
.b-header-catwrapper .b-header-catwrapper-block .btn-block .btn + .btn {
  margin-left: 15px;
}
.b-header-catwrapper .b-header-catwrapper-block .service__list {
  list-style: none;
  padding: 0;
  margin: 10px 0 30px;
}
.b-header-catwrapper .b-header-catwrapper-block .service__list:after {
  display: block;
  clear: both;
  content: '';
}
.b-header-catwrapper .b-header-catwrapper-block .service__list li {
  width: 24%;
  padding: 0px 10px;
  margin: 5px 0;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.b-header-catwrapper .b-header-catwrapper-block .service__list li:hover {
  background: #d9edf7;
}
.b-header-catwrapper .b-header-catwrapper-block .service__list li img {
  width: 44px;
  height: 44px;
}
.b-header-catwrapper .b-header-catwrapper-block .service__list li .service__ext_block {
  display: none;
}
.b-header-catwrapper .b-header-catwrapper-block .service__list li a {
  text-decoration: none !important;
  color: #000;
  font-size: 16px;
  display: table;
  min-height: 70px;
}
.b-header-catwrapper .b-header-catwrapper-block .service__list li a span {
  display: table-cell;
  vertical-align: middle;
}
.b-header-catwrapper .b-header-catwrapper-block .service__list li a span.service__name {
  padding-left: 14px;
}
.b-header-catwrapper-arrow {
  display: block;
  position: absolute;
  bottom: 100%;
  background: url("../../../images/lktoepgu/hcc.png") center bottom no-repeat transparent;
  z-index: 100;
  width: 34px;
  height: 16px;
  margin-left: -17px;
  margin-bottom: -1px;
}
.b-header-catalog-toggler {
  position: relative;
}
.b-header-catalog-toggler a {
  display: inline-block;
  position: relative;
}
.b-header-catalog-toggler-bridge {
  position: absolute;
  top: 60%;
  left: -30px;
  right: -30px;
  height: 50px;
  display: none;
}
.b-panel-drop__category:hover {
  background: #2344a4;
}
.b-panel-drop__category .dropdown-menu {
  border: 0 none;
  margin: 0;
  border-radius: 0;
  padding: 0;
}
.b-panel-drop__category .dropdown-menu > li > a {
  padding: 0 20px;
  line-height: 50px;
  height: 50px;
  white-space: nowrap;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.b-panel-drop__category .dropdown-menu > li > a:hover {
  background-color: #2a4fb7;
  border-top: 1px solid #1e3685;
  border-bottom: 1px solid #1e3685;
}
.b-panel-drop__category .open .b-panel-drop-btn {
  background: #2344a4;
}
.b-panel-drop__category .open .dropdown-menu {
  background: #2344a4;
}
.nav-primary {
  padding: 0 0 10px;
}
.nav-primary-wrapper {
  text-align: center;
}
.nav-primary-title {
  font-size: 36px;
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: normal;
  padding: 0;
  margin: 0 0 65px;
}
.nav-primary-title--no-margin {
  margin: 0;
}
.nav-primary-list {
  line-height: 3em;
}
.nav-primary-link {
  white-space: nowrap;
  font-size: 25px;
  text-decoration: none !important;
  border-bottom: 1px dashed;
  margin: 0 4%;
}
.nav-primary-link:first-child {
  margin-left: 2%;
}
.nav-primary-link:last-child {
  margin-left: 2%;
}
.nav .nav-vertical-level1 {
  font-size: 16px;
  padding-bottom: 10px;
}
.nav .nav-vertical-level1 a {
  border-bottom: 1px dashed #0173c1;
}
.nav .nav-vertical-level1 a:hover {
  border-bottom: 1px dashed #1a88d7;
  text-decoration: none;
}
.nav .nav-vertical-level1.active a {
  border-bottom: 0;
  color: #333;
}
.nav .nav-vertical-level2 {
  font-size: 14px;
  padding-bottom: 10px;
  padding-left: 20px;
  position: relative;
  /*&:before {
        content: "";
        position: absolute;
        vertical-align: top;
        line-height: inherit;
        margin-top: 5px;
        left: 0;
        display: block;
        overflow: hidden;
        height: 0;
        width: 0;
        border: 4px solid #0173c1;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
    }*/
}
.nav .nav-vertical-level2.active:before {
  border-left-color: #333;
}
.nav .nav-vertical-level2.active a {
  color: #333;
  text-decoration: none;
}
@media only screen and (min-width: 960px) and (max-width: 1340px) {
  .nav-primary-link {
    margin-left: 2%;
    margin-right: 2%;
    font-size: 23px;
  }
}
@media only screen and (max-width: 960px) {
  .nav-primary-link {
    margin-left: 1.5%;
    margin-right: 1.5%;
    font-size: 22px;
  }
}
.b-search-wrapper {
  padding: 11px;
}
.b-search-wrapper__tips__common {
  padding-top: 0;
}
.b-search {
  background: #bfe5ff;
  box-shadow: inset 0 10px 40px rgba(0, 0, 0, 0.05);
  position: relative;
}
.b-search__auth_warn {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.b-search__auth_warn .b-search-wrapper {
  padding: 20px 24px;
}
.b-search__auth_warn .b-search-tip {
  color: #fff;
}
.b-search__auth_warn .b-search-tip a {
  color: inherit;
  border-bottom: 1px dashed;
  text-decoration: none !important;
}
.b-search__auth_warn .b-search-form-submit-btn {
  font-size: 27px;
  line-height: 27px;
  padding-left: 20px;
  padding-right: 20px;
}
.b-header-search-wrapper {
  transition: opacity 0.2s;
  opacity: 0;
  position: absolute;
  left: -9999px;
  right: 9999px;
  z-index: 22;
  top: 0;
  bottom: 0;
}
.b-header-search-wrapper.pos {
  left: 0;
  right: 0;
}
.b-header-search-wrapper.in {
  opacity: 1;
}
.b-search--header {
  background: #ffffff;
  box-shadow: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
}
.b-search--header .b-search-area,
.b-search--header .b-search-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all linear 0.2s;
}
.b-search--header .b-search-form {
  height: 100%;
}
.b-search--header .b-search-form-submit-btn {
  transition: all linear 0.2s;
}
.b-search--header .b-search-form-submit-btn-text {
  font-size: 16px;
  vertical-align: middle;
  margin-top: -4px;
  display: inline-block;
  padding-left: 5px;
}
.b-search--header .b-search-form-col--submit {
  padding-left: 30px;
  transition: all linear 0.2s;
}
.b-search--header .b-search-form-col--close {
  padding-left: 40px;
  transition: all linear 0.2s;
}
.b-search--header .b-search-input-wrapper .form-control {
  box-shadow: none;
  padding: 0 45px 0 15px;
  height: 52px;
  line-height: 52px;
}
.b-search--header .b-search-input-wrapper .form-control:focus {
  box-shadow: none;
  border: 1px solid #cdd1d4;
}
.b-search-form-close-btn {
  font-size: 40px;
  line-height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-top: 8px;
  transition: all linear 0.2s;
}
.b-search-form-close-btn:hover {
  text-decoration: none;
}
.b-header-search-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: -9999px;
  right: 9999px;
  bottom: 0;
  z-index: 1020;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.2s;
}
.b-header-search-overlay.pos {
  left: 0;
  right: 0;
}
.b-header-search-overlay.in {
  opacity: 1;
}
.b-panel .b-header-search-overlay {
  bottom: auto;
  height: 50px;
}
.b-search__focused .b-search-wrapper__tips {
  z-index: 1041;
}
.b-search__focused .b-search-wrapper__tips .b-search-tip {
  color: #fff;
}
.b-search__focused .b-search-wrapper__tips .b-search-tip a {
  color: inherit;
  border-bottom: 1px dashed;
  text-decoration: none !important;
}
.b-search-area {
  position: relative;
}
.b-bc-search-dropdown-menu .b-search-area {
  background: #e9f2f8;
}
.b-search-input-wrapper {
  position: relative;
}
.b-search-input-wrapper .form-control {
  height: 54px;
  line-height: 54px;
  padding: 0 45px 0 25px;
  font-size: 17px;
  text-align: left;
  border-radius: 4px;
}
.b-search-input-wrapper .form-control ::placeholder {
  color: #ccc;
}
.b-search-input-wrapper .form-control.empty-state {
  border-color: #fcd;
}
.b-search-input-wrapper .form-control.empty-state:focus {
  outline: 0;
  box-shadow: none;
}
.b-search-form {
  width: 100%;
  display: table;
  position: relative;
  z-index: +4;
}
.b-search-form__focused {
  z-index: 1050;
}
.b-section__header .b-search .tt-empty {
  display: none;
}
.b-search-form-col {
  display: table-cell;
  vertical-align: middle;
  padding-left: 12px;
}
.b-search-form-col:first-child {
  padding-left: 0;
  width: 100%;
}
.b-search-form-clear {
  display: block;
  position: absolute;
  top: 50%;
  right: 25px;
  font-size: 24px;
  line-height: 24px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: +1;
  color: inherit;
  text-decoration: none !important;
  opacity: 0;
}
.b-search-form-clear.vis {
  opacity: 0.5;
}
.b-search-form-clear:hover {
  opacity: 0.85;
}
.b-search-form-submit-btn {
  font-size: 27px;
  line-height: 27px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  height: 54px;
}
.b-search-form-submit-btn span {
  padding-left: 10px;
}
.b-search-tip {
  display: block;
}
.b-search-tip a {
  cursor: pointer;
}
.search-results-empty-container {
  padding-top: 45px;
}
.search-results-empty-icon {
  display: block;
  text-align: center;
}
.search-results-empty-icon__found {
  margin-top: 90px;
}
.search-results-empty-icon-img {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  z-index: 5;
}
.search-results-empty-icon__not_found .search-results-empty-icon-img {
  height: 249px;
  max-width: 232px;
  background: url('../../../images/lktoepgu/pelmen.png') 50% 0 no-repeat;
  background-size: 100% auto;
}
.search-results-empty-icon__found .search-results-empty-icon-img {
  height: 185px;
  max-width: 172px;
  background: url('../../../images/lktoepgu/pelmen-kindly.png') 50% 0 no-repeat;
  background-size: 100% auto;
}
.search-results-empty-icon-bubble {
  position: absolute;
  left: 100%;
  bottom: 100%;
  margin-bottom: -75px;
  font-size: 12px;
  display: block;
  width: 265px;
  color: #535b63;
  background: #fff;
  border: 3px solid #BBDDF7;
  border-radius: 18px;
  padding: 18px;
  text-align: center;
}
.search-results-empty-icon-bubble a {
  text-decoration: none;
}
.search-results-empty-icon-bubble a:hover {
  text-decoration: underline;
}
.search-results-empty-icon-bubble:after {
  width: 45px;
  height: 50px;
  content: "";
  display: block;
  position: absolute;
  background: url('../../../images/lktoepgu/pelmen-bubble-tail.png') 0 0 no-repeat;
  background-size: 45px 50px;
  right: 100%;
  margin-right: 0;
  bottom: 0;
  z-index: +1;
}
.search-results-empty-icon__not_found .search-results-empty-icon-bubble:after {
  bottom: 0;
}
.search-results-empty-icon__found .search-results-empty-icon-bubble:after {
  bottom: 37px;
}
.search-results-empty-message {
  padding-top: 50px;
}
.search-results-empty-message-title {
  display: block;
  text-align: center;
  font-weight: normal;
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 34.2px;
}
.search-results-empty-message-text {
  display: block;
  max-width: 420px;
  margin: 1em auto 0;
  text-align: center;
  font-size: 18px;
  color: #535b63;
}
.search-results-empty-actions {
  display: block;
  text-align: center;
  padding-top: 40px;
}
.search-results-empty-actions .btn .fa {
  margin-right: 1em;
}
.search-results-empty-actions .btn + .btn {
  margin-left: 20px;
}
.search-results-form {
  padding-left: 0;
  padding-right: 0;
}
.search-results-filter {
  margin-top: 40px;
}
.b-search-input-wrapper .sq-result-cell--spacer,
.search-results-container .sq-result-cell--spacer {
  width: 0 !important;
}
.search-results-container {
  margin-top: 40px;
  background: #e9f2f8;
}
.search-results-container .sq-result-cell {
  border-left: 0 !important;
  border-right: 0 !important;
}
.search-results-container .sq-result-cell--res {
  border-color: #eeeeee !important;
}
.search-results-container .search-result-group:first-child:last-child .sq-result-cell {
  border-bottom: 0 !important;
}
.b-no-search-results {
  display: block;
}
.b-no-search-results:before,
.b-no-search-results:after {
  content: " ";
  display: table;
}
.b-no-search-results:after {
  clear: both;
}
.b-no-search-results-info {
  display: block;
  float: left;
  width: 65%;
}
.b-no-search-results-title {
  display: block;
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 40px;
  line-height: 44px;
  padding-right: 20px;
}
.b-no-search-results-image-wrapper {
  display: block;
  float: right;
  width: 35%;
}
.b-no-search-results-image {
  display: inline-block;
  width: 334px;
  height: 264px;
  background: url("../../../images/lktoepgu/pelmen.gif") 50% 50% no-repeat transparent;
  margin: -30px 0 0;
}
.b-no-search-results-buttons {
  display: block;
  padding-top: 30px;
  padding-right: 20px;
}
.b-no-search-results-buttons .btn + .btn {
  margin-left: 20px;
}
.search__word {
  display: inline-block;
  padding: 0 2px;
  font-weight: bold;
  color: #fff;
  background-color: #0063b0;
}
.b-footer {
  background-color: #e6ebec;
  color: #7b878f;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.app-inner > .b-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.b-footer-wrapper {
  padding-top: 30px;
  padding-bottom: 6px;
}
.b-footer-inner:before,
.b-footer-inner:after {
  content: " ";
  display: table;
}
.b-footer-inner:after {
  clear: both;
}
.b-footer-copy {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 15px 0 15px 85px;
  background: url('../../../images/lktoepgu/logo-grey.png') left center no-repeat transparent;
}
.b-footer-copy a {
  color: #555555;
}
.b-footer-nav ul,
.b-footer-nav li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.b-footer-nav > ul {
  display: table;
  width: 100%;
  margin-bottom: 20px;
}
.b-footer-nav > ul > li {
  display: table-cell;
  width: 25%;
}
.b-footer-nav p {
  padding: 0;
  margin: 0;
}
.b-footer-nav a {
  color: #555555;
}
.b-footer-nav .phone {
  text-decoration: none;
  font-size: 18px;
  color: #000;
  padding-bottom: 12px;
  font-weight: 500;
}
.b-footer-nav .elink {
  display: inline-block;
}
.b-footer-nav h1 {
  margin: 0 0 12px;
  padding: 0;
  font-weight: 500;
  font-size: 18px;
  color: #000;
}
.b-footer-logo {
  text-decoration: none !important;
  color: inherit !important;
  white-space: nowrap;
  float: left;
  max-width: 50%;
  font-size: 14px;
  line-height: 20px;
}
.b-footer-logo-href {
  position: absolute;
  left: -9999px;
}
.b-footer-logo-cont {
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  width: 44px;
  height: 44px;
  margin: 0 13px;
}
.b-footer-logo-img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.b-footer-logo-caption {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  max-width: 380px;
  padding: 3px 14px;
}
.b-footer-contacts {
  float: right;
  max-width: 50%;
  padding: 6px 0;
  font-size: 16px;
  line-height: 20px;
}
.b-footer-sector {
  display: inline-block;
  vertical-align: top;
}
.b-footer-sector + .b-footer-sector {
  margin-left: 42px;
}
.b-footer-phone-list {
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: bold;
  text-align: right;
  display: table;
  height: 2.5em;
  float: left;
}
.b-footer-phone-list .b-footer-sector-inner {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}
.b-footer-phone {
  display: block;
}
.b-footer-link {
  display: block;
  color: inherit;
  text-decoration: underline;
}
.b-pnf-error {
  text-align: center;
}
.b-pnf-action {
  margin-top: 30px;
}
.b-pnf-action .btn + .btn {
  margin-left: 20px;
}
.b-pnf-text {
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  color: #535b63;
}
.b-pnf-text h1,
.b-pnf-text h2,
.b-pnf-text h3,
.b-pnf-text h4,
.b-pnf-text h5,
.b-pnf-text h6 {
  font-family: "EtelkaLightPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: normal;
  font-size: 36px;
  line-height: 40px;
  color: #000;
}
.b-pnf-title {
  padding-bottom: 35px;
  border-bottom: 1px solid #cdd1d4;
  margin-bottom: 70px;
  text-align: left;
}
.b-pnf-title h1 {
  display: block;
  text-align: left;
  margin: 0;
  line-height: 46px;
  font-size: 40px;
  font-family: "als_ekibastuz_light", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: normal;
  display: inline-block;
  vertical-align: middle;
}
.b-pnf-title h1 .modal-subtitle {
  margin-top: 7px;
}
.b-pnf-title h1 ._placeholder + ._placeholder {
  margin-top: 10px;
}
.b-pnf-title .b-pnf-pelmen {
  margin-right: 20px;
}
.b-pnf-title .b-pnf-pelmen + * {
  max-width: 80%;
}
.b-pnf-pelmen {
  width: 79px;
  height: 86px;
  display: inline-block;
  vertical-align: middle;
  background-image: url("../../../images/lktoepgu/pelmens.png");
  background-repeat: no-repeat;
  background-position: 0 0;
}
.b-pnf-pelmen.b-pnf-pelmen--big {
  width: 232px;
  height: 249px;
  background-position: -79px 0;
}
.section__description {
  font-size: 18px;
  line-height: 26px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  padding: 10px 0;
}
.document_signer_check .loader {
  visibility: hidden;
  margin-right: 15px;
}
.document_signer_check .loader span {
  display: inline-block;
  padding-left: 40px;
  background: url("../../../images/lktoepgu/loader-small.gif") no-repeat left center transparent;
  line-height: 46px;
  height: 46px;
  color: #0063b0;
  font-weight: bold;
  font-size: 18px;
}
.document_signer_check .loader.show {
  visibility: visible;
}
.document_signer_check .form:before,
.document_signer_check .form:after {
  content: " ";
  display: table;
}
.document_signer_check .form:after {
  clear: both;
}
.document_signer_check .form .form__item_tr:first-child {
  margin: 24px 0 15px;
}
.document_signer_check .form .form__item_tr {
  margin: 30px 0;
  padding: 30px;
  border: 1px solid #e4eef5;
  width: 50%;
}
.document_signer_check .form .form__item_tr.uploaded {
  background: #f6f9fb;
}
.document_signer_check .form .form__item_tr.uploaded .jq-file {
  width: 65%!important;
}
.document_signer_check .form .form__item_tr.static {
  float: left;
  width: 25%;
  border: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.document_signer_check .form .form__item_tr.static .jq-file {
  width: 100%!important;
}
.document_signer_check .form .form__item_tr.static .fileBlock {
  padding-left: 0;
  padding-right: 0;
}
.document_signer_check .form .form__item_tr.static .fileBlock .jq-file__name span {
  font-size: 14px;
  color: #a0aeae;
  display: block;
}
.document_signer_check .form .form__item_tr.static .form__lbl {
  display: none;
}
.document_signer_check .form .form__item_tr.static .upload-box-el-actions {
  display: none;
}
.document_signer_check .form .form__item_tr.static .upload-box-progress-wrapper {
  display: none;
}
.document_signer_check .form .form__item_tr.static .jq-file {
  padding-right: 0;
  width: auto;
}
.document_signer_check .form .form__item_tr .fileBlock {
  padding: 20px 30px 20px 48px;
}
.document_signer_check .form .form__item_tr .fileBlock:before,
.document_signer_check .form .form__item_tr .fileBlock:after {
  content: " ";
  display: table;
}
.document_signer_check .form .form__item_tr .fileBlock:after {
  clear: both;
}
.document_signer_check .form .form__item_tr .fileAttr_icon {
  display: table-cell;
  width: 55px;
}
.document_signer_check .form .form__item_tr .fileAttr_icon span {
  width: 55px;
  display: block;
  margin-right: 30px;
  margin-bottom: 5px;
}
.document_signer_check .form .form__item_tr .fileAttr_icon .file_icon {
  background: url('../../../images/lktoepgu/signer_check/file_icon.png') no-repeat center center transparent;
  height: 77px;
}
.document_signer_check .form .form__item_tr .fileAttr_icon .sign_icon {
  background: url('../../../images/lktoepgu/signer_check/sign_icon.png') no-repeat center center transparent;
  height: 42px;
}
.document_signer_check .form .form__item_tr .upload-box {
  display: table-cell;
  width: 100%;
}
.document_signer_check .form .form__item_tr .upload-box .upload-box-action.upload-box-action__cancel {
  background: url('../../../images/lktoepgu/signer_check/remove.png') no-repeat center center transparent;
  width: 11px;
  height: 11px;
  padding: 0;
  line-height: normal;
  border: 0;
  display: block;
  border-radius: unset;
  color: transparent;
}
.document_signer_check .form .form__item_tr .upload-box .upload-box-el-container {
  position: static;
  z-index: 900;
}
.document_signer_check .form .form__item_tr .upload-box .upload-box-progress-text {
  top: 4px;
  right: 125px;
  display: none;
}
.document_signer_check .form .form__item_tr .upload-box .upload-box-progress-wrapper {
  top: 40px;
  z-index: 800;
}
.document_signer_check .form .form__item_tr .upload-box .jq-file {
  cursor: pointer;
  padding-right: 0;
  width: 94px;
}
.document_signer_check .form .form__item_tr .upload-box .jq-file input {
  width: 92px;
  left: 0;
  transform: scale(20);
}
.document_signer_check .form .form__item_tr .upload-box .jq-file__name {
  display: none;
}
.document_signer_check .form .form__item_tr .upload-box .jq-file__browse {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  font-size: 14px;
  display: inline-block;
  left: 0;
  right: auto;
  padding: 10px 25px;
}
.document_signer_check .form .form__item_tr .upload-box .jq-file:hover .jq-file__browse {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
  cursor: pointer;
}
.document_signer_check .form .form__item_tr .upload-box .jq-file.disabled {
  position: absolute!important;
  margin-top: 5px;
  top: 0;
}
.document_signer_check .form .form__item_tr .upload-box .jq-file.disabled .jq-file__name {
  display: block;
  border: 0;
  font-size: 15px;
  color: #000;
  padding: 0;
  position: relative;
  height: auto;
  background: transparent;
}
.document_signer_check .form .form__item_tr .upload-box .jq-file.disabled .jq-file__browse {
  display: none;
}
.document_signer_check .form .form__item_tr .form__lbl {
  font-size: 21px;
  font-weight: bold;
}
.document_signer_check .form .form__item_tr .form__lbl .counter {
  border: 2px solid #0667b2;
  border-radius: 100%;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  color: #0667b2;
  width: 26px;
  line-height: 22px;
  text-align: center;
  margin-right: 15px;
}
.document_signer_check .form .form__item_tr .form__lbl .note {
  margin-left: 48px;
}
.document_signer_check .sign_result_block {
  padding: 30px 30px 30px 138px;
  margin: 30px 0;
  position: relative;
  width: 50%;
}
.document_signer_check .sign_result_block h2 {
  margin-top: 0;
}
.document_signer_check .sign_result_block b {
  font-weight: bold;
  font-family: als_ekibastuz_bold, Arial, sans-serif;
}
.document_signer_check .sign_result_block span {
  color: #999999;
}
.document_signer_check .sign_result_block .certInfo table tr td {
  vertical-align: top;
  padding: 4px 0 4px 20px;
  color: #999999;
  font-weight: bold;
  font-family: als_ekibastuz_bold, Arial, sans-serif;
}
.document_signer_check .sign_result_block .certInfo table tr td:first-child {
  color: #000000;
}
.document_signer_check .sign_result_block:before {
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 30px;
  top: 30px;
  font: normal normal normal 50px/60px FontAwesome;
  text-align: center;
}
.document_signer_check .sign_result_block.green {
  background: #f1f7ea;
}
.document_signer_check .sign_result_block.green:before {
  content: "\f00c";
  border: 0;
  color: #7fb441;
  border-radius: 100%;
}
.document_signer_check .sign_result_block.red {
  background: #fbeaec;
}
.document_signer_check .sign_result_block.red:before {
  content: "\f00d";
  border: 0;
  color: #dc3f55;
  border-radius: 100%;
}
.document_signer_check .sign_result_block.blue_marker {
  background: #c3d9ff;
}
.document_signer_check .sign_result_block.yellow {
  background: #F9F3D9;
}
.document_signer_check .sign_result_block .trafficMessageFull {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 2px solid #000;
  color: #999999;
  font-weight: bold;
  font-family: als_ekibastuz_bold, Arial, sans-serif;
}
.document_signer_check .sign_result_block .trafficMessageFull.green {
  border-top: 2px solid #7fb441;
}
.document_signer_check .sign_result_block .trafficMessageFull.red {
  border-top: 2px solid #dc3f55;
}
.document_signer_check .sign_result_block .trafficMessageFull.blue_marker {
  border-top: 2px solid #c3d9ff;
}
.document_signer_check .sign_result_block .trafficMessageFull.yellow {
  border-top: 2px solid #F9F3D9;
}
.document_signer_check .sign_result_block .trafficMessageFull .green {
  color: #7fb441;
}
.document_signer_check .sign_result_block .trafficMessageFull .red {
  color: #dc3f55;
}
.document_signer_check .sign_result_block .trafficMessageFull .blue_marker {
  color: #c3d9ff;
}
.document_signer_check .sign_result_block .trafficMessageFull .yellow {
  color: #F9F3D9;
}
.b-search-filters-categories {
  border-top: 1px solid #cdd1d4;
  padding: 0;
}
.b-search-filters-categories-list {
  padding: 20px 0 0;
}
.b-search-filters-categories-list:before,
.b-search-filters-categories-list:after {
  content: " ";
  display: table;
}
.b-search-filters-categories-list:after {
  clear: both;
}
.b-search-filters-category-item {
  position: relative;
  padding: 12px 0 12px 125px;
  width: 50%;
  float: left;
}
.b-search-filters-category-link {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  font-size: 16px;
}
.b-search-filters-category-link-icon {
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  left: 85px;
  top: 11px;
  transform: rotate(90deg);
}
.b-search-filters-category-link-icon:before,
.b-search-filters-category-link-icon:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.b-search-filters-category-link-icon:before {
  content: "\f1db";
  font-size: 200%;
}
.b-search-filters-category-link-icon:after {
  content: "\f105";
}
.b-search-filters-categories-title {
  font-weight: bold;
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  margin: 0;
  padding: 20px 0 0;
}
.b-search-filters-categories-title + .b-search-filters-categories-list .b-search-filters-category-item {
  padding-left: 40px;
}
.b-search-filters-categories-title + .b-search-filters-categories-list .b-search-filters-category-link-icon {
  left: 0;
}
/*
Typehead
*/
.twitter-typeahead {
  /*display: block !important;*/
  width: 100%;
}
.typeahead,
.tt-query,
.tt-hint {
  width: 100%;
  height: 52px;
  /*padding: 8px 12px;*/
  /*line-height: 30px;*/
  outline: none;
}
.typeahead,
.tt-query,
.tt-hint,
.search-query {
  /*outline: none;*/
  z-index: +1;
}
.tt-query {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.tt-hint {
  color: #999;
}
.tt-dropdown-menu {
  text-align: left;
}
.tt-dropdown-menu {
  max-width: 100%;
  min-width: 300px;
  width: 100%;
  /*margin-top: -5px;*/
  /* .search-query has margin-bottom:5px style */
  /*padding: 8px 0;*/
  background-color: #fff;
  white-space: normal;
  z-index: 9999 !important;
}
.bxSearch .tt-dropdown-menu {
  margin-top: -5px;
}
.tt-dropdown-menu.scroll {
  /* has custom scroll */
  background-color: transparent;
}
.tt-dropdown-menu.scroll.mCS_destroyed,
.tt-dropdown-menu.scroll .mCustomScrollBox {
  background-color: #fff;
}
.tt-suggestion {
  display: table;
  width: 100%;
  /*padding: 3px 20px;*/
  /*font-size: 18px;*/
  /*line-height: 24px;*/
}
.sq-result-cell--res {
  background: #ffffff;
  border: 1px solid #bfe5ff;
  border-top: 0;
  padding-top: 15px;
  margin-top: 5px;
}
.tt-suggestion:first-child .sq-result-cell--res {
  border-top: 0;
  margin-top: 0;
}
.sq-result-row:last-child .sq-result-cell--res {
  border-bottom: 1px solid #bfe5ff;
}
.sq-result-cell--spacer {
  display: table-cell;
  width: 136px;
}
.sq-result-row:hover .sq-result-cell--res {
  background: #f7fafc;
}
.tt-dropdown-menu b {
  font-weight: bold;
  color: #000;
}
.tt-suggestion.tt-cursor .sq-result-item,
.tt-suggestion.tt-cursor .sq-result-item:hover {
  /*color: #fff;*/
  /*background-color: #fde6e3;*/
  /*background-color: rgba(0,0,0,.05);*/
  text-decoration: none !important;
}
.tt-suggestion.tt-cursor .sq-result-item .sq-result-item-text,
.tt-suggestion.tt-cursor .sq-result-item .sq-result-item-text,
.tt-suggestion.tt-cursor .sq-result-item .sq-result-item-text b,
.tt-suggestion.tt-cursor .sq-result-item .sq-result-item-text b strong {
  color: #0063b0;
}
.tt-suggestion p {
  margin: 0;
}
.sq-result-more-wrap {
  text-align: left;
  padding: 0 0 0 20px;
}
.sq-result-more {
  display: none;
  color: #0063b0;
}
.sq-result-more :hover {
  color: #003864;
  text-decoration: underline;
}
.sq-result-more-wrap.show-true .sq-result-more,
.tt-suggestion:last-child .sq-result-more {
  display: inline-block;
  margin: 0 0 10px;
}
/**
Res
*/
.tt-dataset {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background: #fff;
  z-index: +2;
  display: none;
}
.tt-open .tt-dataset {
  display: block;
}
/**
* search result shadow
* @see lktoepgu-1155
*/
.tt-dataset-search {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
}
.tt-empty .tt-dataset-search {
  box-shadow: none;
}
/**
Search query
*/
/*.sq-result-item,*/
.sq-empty-message {
  padding: 25px 20px 10px;
  font-size: 16px;
  position: relative;
  display: block;
}
.tt-suggestions {
  /*padding: 12px 0 3px 0;*/
}
.tt-dropdown-menu {
  height: auto !important;
  max-height: 500px;
  /*min-height:300px;*/
  /*overflow: auto;*/
}
.tt-dropdown-menu > .scroll {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.tt-dropdown-menu div[class^="tt-dataset-"] {
  display: table !important;
  width: 100% !important;
}
.sq-result-group {
  display: table-cell !important;
  padding-right: 25px;
  padding-top: 28px;
  width: 16.5% !important;
  min-width: 150px;
  height: 100px;
  vertical-align: top;
  text-align: right;
}
.sq-result-group-title {
  display: inline-block;
  position: relative;
  line-height: 1.3;
  font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 17px;
  font-weight: normal;
  text-align: right;
  max-width: 110px;
  color: #0063b0;
}
.sq-result-group-title:hover {
  cursor: pointer;
}
.sq-result-group-title:hover .caption {
  color: #003864;
  text-decoration: underline;
}
.sq-result-group-title .length {
  display: block;
  position: absolute;
  left: 100%;
  bottom: 100%;
  margin-left: -0.5em;
  margin-bottom: -0.5em;
  z-index: +1;
  cursor: default;
  pointer-events: none;
  border-radius: 12px;
  padding: 5px 8px;
  color: #fff;
  font-family: "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 14px;
  line-height: 1em;
  white-space: nowrap;
  background: #0063b0;
}
.tt-suggestions {
  display: table-cell !important;
  border-bottom: 1px solid #dedede;
}
.sq-result-more,
.sq-result-item {
  font-size: 14px;
}
.sq-result-more-cut {
  text-transform: lowercase;
}
.sq-result-item {
  display: block;
  padding: 0 85px 30px 20px;
  text-decoration: none !important;
  margin-top: 0;
}
.sq-result-item b,
.sq-result-item strong {
  font-weight: bold;
}
.sq-result-item-text {
  display: block;
  font-weight: normal;
  font-family: "EtelkaTextPro", "als_ekibastuz_bold", Arial, sans-serif;
  font-size: 16px;
  color: #000;
}
.sq-result-item-descr {
  display: block;
  font-weight: normal;
  font-size: 12px;
  line-height: 1em;
  margin-top: 1em;
  color: #a8a8a8;
}
.sq-result-item-snippet {
  line-height: 20px;
  margin-top: 1em;
  color: #828282;
}
.sq-result-item-snippet b,
.sq-result-item-snippet strong {
  color: #dd191d;
  font-weight: normal;
}
.sq-result-item-icon {
  display: none;
}
.tt-dataset-service .sq-empty-message {
  display: block;
  font-size: 14px;
}
.tt-dataset-situation .sq-empty-message,
.tt-dataset-category .sq-empty-message,
.tt-dataset-org .sq-empty-message,
.tt-dataset-news .sq-empty-message {
  display: none;
}
/*
Simple autocomplete based on typeahead
 */
.behavior-autocomplete ~ .tt-dropdown-menu .tt-suggestion {
  padding: 3px 20px;
}
.behavior-autocomplete ~ .tt-dropdown-menu .tt-suggestion:hover {
  cursor: pointer;
  background-color: #dd191d;
}
.b-tiles-empty {
  display: block;
  padding: 120px 60px 60px;
  text-align: center;
}
.b-tiles-empty-message {
  display: block;
  margin-bottom: 1em;
  font-weight: 400;
  font-size: 18px;
}
.b-tiles-empty-message-sub {
  font-size: 14px;
  opacity: 0.75;
}
.b-tiles-list {
  font-size: 0.01px;
}
.b-tiles-list:before,
.b-tiles-list:after {
  content: " ";
  display: table;
}
.b-tiles-list:after {
  clear: both;
}
.b-tiles-actions {
  padding: 70px 0 0;
  text-align: center;
  clear: both;
}
.b-tiles-actions .btn {
  padding: 20px 23px;
  line-height: 0.75em;
}
.g-tile {
  /* common */
  position: relative;
  display: inline-block;
  vertical-align: top;
  /* base */
  /* responsive values */
  height: 240px;
  width: 33.33%;
  /* animation */
}
.g-tile__default {
  color: #0063b0;
}
.g-tile__primary {
  color: #0063b0;
}
.g-tile__secondary {
  color: #56c5cc;
}
.g-tile__danger {
  color: #b2234b;
}
.g-tile__info {
  color: #2e729e;
}
.g-tile__warning {
  color: #f4821f;
}
.g-tile__critical {
  color: #dd191d;
}
.g-tile__success {
  color: #6ec256;
}
.g-tile__disabled {
  color: #777777;
}
.b-tiles-container .g-tile {
  padding: 50px 15px 10px;
  float: left;
}
.g-tile--2 {
  width: 50%;
  height: auto;
}
.g-tile--wrapper {
  float: left;
  margin-right: 0;
}
.g-tile--loading:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  background: center center no-repeat;
  background-image: url('../../../images/lktoepgu/loader-small.gif');
  background-color: rgba(0, 0, 0, 0.5);
}
.g-tile-box {
  display: block;
  cursor: pointer;
  position: relative;
  height: 100%;
}
.g-tile-box,
.g-tile-box:hover {
  color: inherit;
  text-decoration: none;
}
.g-tile-front {
  position: relative;
  z-index: +1;
}
.g-tile-inner {
  position: relative;
  margin: 0;
}
.g-tile .formErMes {
  display: none;
}
.g-tile input[error] {
  border: 1px solid #b2234b;
}
.g-tile-title {
  display: block;
  padding: 0 0 15px;
  margin: 0;
  line-height: 1.4em;
  font-size: 22px;
  font-family: "als_ekibastuz_bold", "als_ekibastuz_bold", Arial, sans-serif;
  font-weight: 400;
  word-break: break-word;
}
.g-tile-inner {
  display: block;
  padding-left: 106px;
}
.g-tile-img-outer {
  display: block;
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
}
.g-tile-img {
  display: block;
  position: relative;
  width: 78px;
  height: 78px;
}
.g-tile-img-svg {
  width: 100%;
  height: 100%;
}
.g-tile-content {
  margin: 0;
  font-size: 16px;
  font-family: 'HelveticaNeueCyr', Helvetica, Sans-Serif;
  line-height: 18px;
  padding: 10px 0 0;
  position: relative;
  display: block;
  width: 100%;
  color: #535b63;
}
.g-tile-content-inner {
  display: inline;
  position: relative;
}
.g-tile-content-inner:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  font-size: 16px;
  vertical-align: top;
  font-weight: bold;
  position: absolute;
  right: -0.75em;
  bottom: 0.05em;
}
.responsive__screen_small body {
  width: 100%;
  min-width: 100%;
}
.responsive__screen_small .section-fixate__fixed .section-fixate-inner {
  min-width: 100%;
  max-width: 100%;
}
.responsive__screen_small .wrapper {
  min-width: 100%;
  max-width: 100%;
}
.responsive__screen_small .b-header-logo {
  width: 220px;
}
.responsive__screen_small .g-tile-title {
  font-size: 16px;
}
.responsive__screen_small .g-tile-content {
  font-size: 14px;
  line-height: 16px;
}
.responsive__tablet_sm .t-modal-layout .t-modal-layout-item {
  width: 50%;
}
.responsive__tablet_sm .app-inner {
  padding-bottom: 150px;
}
.responsive__tablet_sm .b-footer-wrapper {
  padding-bottom: 15px;
}
.responsive__tablet_sm .b-footer-phone-list {
  float: none;
  height: 0;
  margin-bottom: 0;
  width: 100%;
}
.responsive__tablet_sm .b-footer-logo-cont {
  width: 61px;
  height: 61px;
}
.responsive__tablet_sm .b-footer-contacts,
.responsive__tablet_sm .b-footer-logo-caption {
  font-size: 14px;
  line-height: 1.3em;
}
.responsive__tablet_sm .b-footer-contacts {
  text-align: right;
}
.responsive__tablet_sm .b-footer-logo-caption {
  max-width: 280px;
  padding-left: 7px;
}
.responsive__tablet_sm .b-tech-message .b-tech-message-body > * {
  font-size: 14px !important;
}
.responsive__tablet_sm .b-petition-in-sign-check-wrap {
  margin-left: 0;
  position: relative;
  left: 0;
  top: 0;
}
.responsive__tablet_sm .b-petition-in-sign-check.fixed {
  position: static;
}
.responsive__tablet_sm .attr-field--layout .attr-label--inline {
  width: 35%;
}
.responsive__tablet_sm .attr-field--layout .attr-label--inline + .attr-value {
  width: 65%;
}
.responsive__tablet_sm .g-tile {
  width: 50%;
}
.responsive__tablet_sm .petition-row-info-actions {
  margin-top: -20px;
  margin-bottom: 20px;
}
.responsive__tablet_sm .petition-row-info-actions .btn {
  float: left !important;
  width: 30%;
  margin-top: 20px;
  padding: 20px 32px;
  font-size: 16px;
}
.responsive__tablet_sm .petition-row-info-actions .btn:nth-child(1n) {
  margin-left: 5%;
}
.responsive__tablet_sm .petition-row-info-actions .btn:nth-child(3n-2) {
  margin-left: 0;
}
.responsive__tablet_sm .b-auth-warn-slide__visible .b-auth-warn-bg {
  opacity: 0.35;
}
.responsive__tablet_sm .b-auth-warn-inner {
  max-width: 65%;
}
.responsive__tablet_sm .b-auth-warn-inner__actions {
  background: transparent;
  padding-left: 20px;
  padding-right: 20px;
}
.responsive__tablet_sm body {
  min-width: auto;
}
.responsive__tablet_sm .section-fixate__fixed .section-fixate-inner {
  min-width: auto;
}
.responsive__tablet_sm .b-header-profile {
  position: relative;
}
.responsive__tablet_sm .b-section__fixate.section-fixate {
  height: 185px!important;
}
.responsive__mobile .modal-body textarea,
.responsive__mobile .modal-body input {
  font-size: 16px;
}
.responsive__mobile .t-modal-layout .t-modal-layout-item {
  width: 100%;
}
.responsive__mobile .app-inner {
  padding-bottom: 150px;
}
.responsive__mobile .b-tech-message .b-tech-message-body > * {
  font-size: 14px !important;
}
.responsive__mobile .b-petition-in-sign-check-wrap {
  position: relative;
  margin-left: 0;
  left: 0;
  top: 0;
}
.responsive__mobile .b-petition-in-sign-check.fixed {
  position: static;
}
.responsive__mobile .attr-field--layout .attr-label--inline {
  width: 35%;
}
.responsive__mobile .attr-field--layout .attr-label--inline + .attr-value {
  width: 65%;
}
.responsive__mobile .g-tile {
  width: 50%;
}
.responsive__mobile .petition-row-info-actions {
  margin-top: -20px;
  margin-bottom: 20px;
}
.responsive__mobile .petition-row-info-actions .btn {
  float: left !important;
  width: 45%;
  margin-top: 20px;
  padding: 20px 32px;
  font-size: 16px;
}
.responsive__mobile .petition-row-info-actions .btn:nth-child(1n) {
  margin-left: 10%;
}
.responsive__mobile .petition-row-info-actions .btn:nth-child(2n-1) {
  margin-left: 0;
}
.responsive__mobile .avDates-datesCol {
  width: 24%;
  margin-bottom: 10px;
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  body {
    width: 100%;
    min-width: 100%;
  }
  .section-fixate__fixed .section-fixate-inner {
    min-width: 100%;
    max-width: 100%;
  }
  .wrapper {
    min-width: 100%;
    max-width: 100%;
  }
  .b-header-logo {
    width: 220px;
  }
  .g-tile-title {
    font-size: 16px;
  }
  .g-tile-content {
    font-size: 14px;
    line-height: 16px;
  }
}
@media only screen and (max-width: 1023px) {
  .t-modal-layout .t-modal-layout-item {
    width: 50%;
  }
  .app-inner {
    padding-bottom: 150px;
  }
  .b-footer-wrapper {
    padding-bottom: 15px;
  }
  .b-footer-phone-list {
    float: none;
    height: 0;
    margin-bottom: 0;
    width: 100%;
  }
  .b-footer-logo-cont {
    width: 61px;
    height: 61px;
  }
  .b-footer-contacts,
  .b-footer-logo-caption {
    font-size: 14px;
    line-height: 1.3em;
  }
  .b-footer-contacts {
    text-align: right;
  }
  .b-footer-logo-caption {
    max-width: 280px;
    padding-left: 7px;
  }
  .b-tech-message .b-tech-message-body > * {
    font-size: 14px !important;
  }
  .b-petition-in-sign-check-wrap {
    margin-left: 0;
    position: relative;
    left: 0;
    top: 0;
  }
  .b-petition-in-sign-check.fixed {
    position: static;
  }
  .attr-field--layout .attr-label--inline {
    width: 35%;
  }
  .attr-field--layout .attr-label--inline + .attr-value {
    width: 65%;
  }
  .g-tile {
    width: 50%;
  }
  .petition-row-info-actions {
    margin-top: -20px;
    margin-bottom: 20px;
  }
  .petition-row-info-actions .btn {
    float: left !important;
    width: 30%;
    margin-top: 20px;
    padding: 20px 32px;
    font-size: 16px;
  }
  .petition-row-info-actions .btn:nth-child(1n) {
    margin-left: 5%;
  }
  .petition-row-info-actions .btn:nth-child(3n-2) {
    margin-left: 0;
  }
  .b-auth-warn-slide__visible .b-auth-warn-bg {
    opacity: 0.35;
  }
  .b-auth-warn-inner {
    max-width: 65%;
  }
  .b-auth-warn-inner__actions {
    background: transparent;
    padding-left: 20px;
    padding-right: 20px;
  }
  body {
    min-width: auto;
  }
  .section-fixate__fixed .section-fixate-inner {
    min-width: auto;
  }
  .b-header-profile {
    position: relative;
  }
  .b-section__fixate.section-fixate {
    height: 185px!important;
  }
}
@media screen and (max-width: 780px) {
  .modal-body textarea,
  .modal-body input {
    font-size: 16px;
  }
  .t-modal-layout .t-modal-layout-item {
    width: 100%;
  }
  .app-inner {
    padding-bottom: 150px;
  }
  .b-tech-message .b-tech-message-body > * {
    font-size: 14px !important;
  }
  .b-petition-in-sign-check-wrap {
    position: relative;
    margin-left: 0;
    left: 0;
    top: 0;
  }
  .b-petition-in-sign-check.fixed {
    position: static;
  }
  .attr-field--layout .attr-label--inline {
    width: 35%;
  }
  .attr-field--layout .attr-label--inline + .attr-value {
    width: 65%;
  }
  .g-tile {
    width: 50%;
  }
  .petition-row-info-actions {
    margin-top: -20px;
    margin-bottom: 20px;
  }
  .petition-row-info-actions .btn {
    float: left !important;
    width: 45%;
    margin-top: 20px;
    padding: 20px 32px;
    font-size: 16px;
  }
  .petition-row-info-actions .btn:nth-child(1n) {
    margin-left: 10%;
  }
  .petition-row-info-actions .btn:nth-child(2n-1) {
    margin-left: 0;
  }
  .avDates-datesCol {
    width: 24%;
    margin-bottom: 10px;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  /* Retina-specific stuff here */
}
.isNew:after {
  content: 'Новое';
  display: block;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 35px;
  height: 35px;
  background: #ff0000;
  border-radius: 100%;
  color: #fff;
  font-size: 11px;
  text-align: center;
  padding: 10px 0;
  line-height: 15px;
}
