.b-pnf-error {
  text-align: center;
}

.b-pnf-action {
  margin-top: 30px;

  .btn + .btn {
    margin-left: 20px;
  }
}

.b-pnf-text {
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  color: #535b63;

  h1, h2, h3, h4, h5, h6 {
    font-family: @font-family-bolder;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    color: #000;
  }
}

.b-pnf-title {
  padding-bottom: 35px;
  border-bottom: 1px solid #cdd1d4;
  margin-bottom: 70px;
  text-align: left;

  h1 {
    .modal-title;
    display: inline-block;
    vertical-align: middle;
  }

  .b-pnf-pelmen {
    margin-right: 20px;

    & + * {
      max-width: 80%;
    }
  }
}

.b-pnf-pelmen {
  width: 79px;
  height: 86px;
  display: inline-block;
  vertical-align: middle;
  background-image: url("@{path-images}pelmens.png");
  background-repeat: no-repeat;
  background-position: 0 0;

  &.b-pnf-pelmen--big {
    width: 232px;
    height: 249px;
    background-position: -79px 0;
  }
}