@fonts-path : '../../../fonts';

@font-face {
  @font-family-bolder-fname : 'etelka-light-pro_16165-webfont';
  @font-family-bolder-path : '@{fonts-path}/EtelkaLightPro/@{font-family-bolder-fname}' ;
  font-family: 'EtelkaLightPro';
  src: url('@{font-family-bolder-path}.eot');
  src: url('@{font-family-bolder-path}.eot?#iefix') format('embedded-opentype'),
  url('@{font-family-bolder-path}.woff2') format('woff2'),
  url('@{font-family-bolder-path}.woff') format('woff'),
  url('@{font-family-bolder-path}.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  @font-family-bold-fname : 'web-etelka-text-pro-webfont';
  @font-family-bold-path : '@{fonts-path}/EtelkaTextPro/@{font-family-bold-fname}' ;
  font-family: 'EtelkaTextPro';
  src: url('@{font-family-bold-path}.eot');
  src: url('@{font-family-bold-path}.eot?#iefix') format('embedded-opentype'),
  url('@{font-family-bold-path}.woff2') format('woff2'),
  url('@{font-family-bold-path}.woff') format('woff'),
  url('@{font-family-bold-path}.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  @font-family-bold-fname : 'etelka-text-pro-bold-webfont';
  @font-family-bold-path : '@{fonts-path}/EtelkaTextPro/@{font-family-bold-fname}' ;
  font-family: 'EtelkaTextPro';
  src: url('@{font-family-bold-path}.eot');
  src: url('@{font-family-bold-path}.eot?#iefix') format('embedded-opentype'),
  url('@{font-family-bold-path}.woff2') format('woff2'),
  url('@{font-family-bold-path}.woff') format('woff'),
  url('@{font-family-bold-path}.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  @font-family-medium-fname : 'EtelkaMediumProRegular';
  @font-family-medium-path : '@{fonts-path}/EtelkaMediumPro/@{font-family-medium-fname}' ;
  font-family: 'EtelkaMediumPro';
  src: url('@{font-family-medium-path}.eot');
  src: /*local('☺'), /**/url('@{font-family-medium-path}.eot?#iefix') format('embedded-opentype'),
  //url('@{font-family-medium-path}.woff2') format('woff2'),
  url('@{font-family-medium-path}.woff') format('woff'),
  url('@{font-family-medium-path}.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'als_ekibastuz_bold';
  src: url('@{fonts-path}/als_ekibastuz_bold.eot');
  src: url('@{fonts-path}/als_ekibastuz_bold.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}/als_ekibastuz_bold.woff2') format('woff2'),
  url('@{fonts-path}/als_ekibastuz_bold.woff') format('woff'),
  url('@{fonts-path}/als_ekibastuz_bold.ttf') format('truetype'),
  url('@{fonts-path}/als_ekibastuz_bold.svg#als_ekibastuz_bold') format('svg');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'als_ekibastuz_light';
  src: url('@{fonts-path}/als_ekibastuz_light.eot');
  src: url('@{fonts-path}/als_ekibastuz_light.eot?#iefix') format('embedded-opentype'),
  url('@{fonts-path}/als_ekibastuz_light.woff2') format('woff2'),
  url('@{fonts-path}/als_ekibastuz_light.woff') format('woff'),
  url('@{fonts-path}/als_ekibastuz_light.ttf') format('truetype'),
  url('@{fonts-path}/als_ekibastuz_light.svg#als_ekibastuz_light') format('svg');
  font-weight: normal;
  font-style: normal;

}