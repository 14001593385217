.b-service-baseinfo {
  font-size: @font-size-base;
  line-height: 24px;//@font-size-large;

  > p:first-child{
    margin-top: 0;
  }
}
.service-back-link {
  white-space: normal;
  padding-left:20px;
  padding-right:20px;
  .fa {
    font-size: 120%;
    margin-right: .5em;
    font-weight: bold;
    vertical-align: top;
    margin-top: .05em;
  }
}
.service-target-recipient-cat-comment {
  display: block;
  margin-top:.25em;
  &:first-letter {
    text-transform:lowercase;
  }
}

.service-agreement-list {
  margin: 2em 0;
  .sal-item {
    margin: 1em 0 0;
  }
}