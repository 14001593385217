@tiles-img-size : 78px;
@tiles-img-margin: 10px;

.b-tiles-empty {
  display: block;
  padding: 120px 60px 60px;
  text-align: center;
}
.b-tiles-empty-message {
  display: block;
  margin-bottom: 1em;
  font-weight: 400;
  font-size: @font-size-h4;
  &-sub {
    font-size: @font-size-h5;
    opacity: .75;
  }
}
.b-tiles-container {
}
.b-tiles-list {
  .clearfix();
  font-size: 0.01px;
}
.b-tiles-actions {
  padding: 70px 0 0;
  text-align: center;
  clear: both;
  .btn {
    padding: 20px 23px;
    line-height: .75em;
  }
}

.g-tile {
  .color();

  .b-tiles-container & {
    padding: 50px 15px 10px;// space between tiles
    float:left; // float one by one
  }

  & {
    /* common */
    position: relative;
    display: inline-block;
    vertical-align: top;

    /* base */
    //cursor:pointer;

    /* responsive values */
    height: 240px;//325px;
    //&--1    {width:75px}
    &--2    {width:50%; height: auto;}
    //&--3, & {width:300px} // default width
    //&--4    {width:450px}
    //&--6    {width:620px}
    //&--9    {width:960px}

    //width: 25%;

    //.modal--department & {
      width: 33.33%;
    //}

    &--wrapper {
      float:left;
      margin-right:0;
    }

    /* animation */
    //transition: transform , opacity;

    //animation-duration: .75s;
    //animation-timing-function: ease-out;
    //animation-iteration-count: 1;
  }

  &--loading {
    &:after {

      // TODO

      display: block;
      content: "";
      position: absolute;
      left:0;
      right:0;
      bottom:0;
      top:0;
      z-index: +1;
      background: center center no-repeat;
      background-image : url('@{path-images}loader-small.gif');
      background-color: rgba(0,0,0,.5);
    }
  }

  &-box {
    display: block;
    cursor: pointer;
    position: relative;
    height: 100%;

    &, &:hover {
      color: inherit;
      text-decoration: none;
    }

    //box-shadow: 0 1px 1px 0 rgba(0,0,0,.10), 0 0 7px 0 rgba(0,0,0,.07);
    //
    //// transform
    //transform-style: preserve-3d;
    //backface-visibility: hidden;
    //
    //transition: all .35s ease-out;
  }

  &-front {
    //background-size: 100% 100%;
    //background-size: cover;
    //background-position: center center;
    //background-repeat: no-repeat;
    //background-color: #fff;
    position: relative;
    z-index: ~"+1";
  }

  &-inner {
    position: relative;
    margin: 0;// 20px;
  }

  //&-separator {
  //  margin-top:0;
  //}

  //&-anchor {
  //  &-arrow {
  //    &--default {
  //      background-image: url('../../../../images/sunshine/index/icons/navigation/sm/ico-anchor-arrow-black.png');
  //    }
  //  }
  //}

  // Color types
  //&--t {
  //
  //  .color(@type, @color) {
  //    &-@{type} {
  //      .anchor-arrow, .statement, .line {background-color: @color}
  //      .anchor {color: @color}
  //    }
  //  }
  //
  //  .color(1, #ff5145);
  //  .color(2, #ff9c1c);
  //  .color(3, #f7c53e);
  //  .color(4, #bbd400);
  //  .color(4, #bbd400);
  //  .color(6, #00c3c4);
  //  .color(7, #657ae0);
  //  .color(8, #9369c7);
  //  .color(9, #e35383);
  //  .color(10,#a37139);
  //  .color(11,#2d9c68);
  //  .color(12,#178ec7);
  //  .color(13,#4a54b0);
  //  .color(14,#9369c7);
  //  .color(15,#e35383);
  //
  //}

  .formErMes{
    display:none;
  }

  input[error]{
    border: 1px solid @brand-danger;
  }

}

.g-tile-title {
  display: block;
  padding: 0 0 15px;
  margin:0;

  // more words in a row
  //width: 105%;

  line-height: 1.4em;
  font-size: 22px;
  font-family: @font-family-ekb-bold;
  font-weight: 400;
  word-break: break-word;

  //min-height: 4em;// hmm..
}
.g-tile-inner {
  display: block;
  padding-left: 106px;
}
.g-tile-img-outer {
  display: block;
  text-align: left;
  //position: relative;
  //width:100%;

  position: absolute;
  left:0;
  top: 0;
}
.g-tile-img {

  & {
    display: block;
    position: relative;
    width: @tiles-img-size;
    height:@tiles-img-size;
  }

  &-svg {
    width:100%;
    height:100%;
  }
}
.g-tile-content {
  margin: 0;
  font-size: 16px;
  font-family: 'HelveticaNeueCyr',Helvetica,Sans-Serif;
  line-height: 18px;//@line-height-base;
  padding:10px 0 0;
  position: relative;
  display: block;
  width: 100%;
  color: #535b63;

  //padding-right:1em; // space for `angle arrow`
}
.g-tile-content-inner{
  display: inline;
  position: relative;
  &:after {
    .fa-icon();
    content: @fa-var-angle-right;
    font-size: @font-size-middle;
    vertical-align: top;
    font-weight: bold;
    //position: relative;
    //margin-top: .05em;
    //margin-left:-1em;
    //left: 1.25em;

    position: absolute;
    right:-.75em;
    bottom:.05em;
  }
}