@equeue-calendar-cell-padding : 5px;

.equeue-calendar {
  color: @datepicker-text-color;
}
.equeue-calendar-wrapper {
  display: table;
}
.equeue-calendar-legend,
.equeue-calendar-placeholder {
  display: table-cell;
  vertical-align: top;
}
.equeue-calendar-placeholder {
}
.equeue-calendar-legend {
  padding: 0 15px;
}
.equeue-calendar-legend-footer,
.equeue-calendar-legend-info,
.equeue-calendar-legend-header {
  padding: 15px 25px;
}
.equeue-calendar-legend-header {
  background: #f7f9f9;
  font-weight: bold;
}
.equeue-calendar-legend-info {

}
.equeue-calendar-legend-footer {
  border-top:1px solid #e3e3e5;
}
.equeue-calendar-legend-timezone-notify {
  display: block;
  position: relative;
  padding-left:45px;
  color: @lead-text-color-dark;
  line-height: 1.3em;
}
.equeue-calendar-legend-timezone-notify-icon {
  width: 29px;
  height:29px;
  position: absolute;
  left:0;
  top:0;
  border-radius: 100%;
  border:2px solid;
  &:after {
    @border-width : 1px;
    @size : 10px;
    content:"";
    display: block;
    position: absolute;
    top:50%;
    left:50%;
    width:@size;
    height:@size;
    margin-top:-@size + @border-width;
    margin-left:-@border-width;
    border:@border-width solid;
    border-right:0;
    border-top:0;
  }
}

.equeue-calendar-legend-helper {
  white-space: nowrap;
  margin-top: 15px;
  display: block;
}

.equeue-calendar-cell {
  display: inline-block;
  border: 1px solid @datepicker-border-color;
  padding: @equeue-calendar-cell-padding;
  font-size: inherit;
  width: 2em;
  height: 2em;
  line-height: 1em;
  text-align:center;
  background: @datepicker-active-bg;
  color: @brand-primary;

  &.disabled{
    background: @datepicker-disabled-bg;
    color: inherit;;
  }
}

.equeue-calendar-legend-helper-label,
.equeue-calendar-legend-helper-cell {
  display: inline-block;
  vertical-align: top;
}
.equeue-calendar-legend-helper-label {
  white-space: normal;
  word-break: break-word;
  padding: @equeue-calendar-cell-padding;
  margin-left: 10px;
}