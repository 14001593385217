@breadcrumbs-splitter-width : 13px;
@breadcrumbs-line-height : 19px;
@breadcrumbs-padding-vertical : 12px;
@breadcrumbs-padding-horizontal : 20px;

.b-breadcrumbs {
  .clearfix();
  background: #f6f6f6;
  border-top: 5px solid @brand-info;
  border-bottom: 1px solid #e1e1e1;
  font-size:13px;
  line-height: @breadcrumbs-line-height;
  color: #a5aeb3;

  &-list {
    overflow: hidden;
  }
}
.b-breadcrumbs-item {
  display: block;
  position: relative;
  padding: @breadcrumbs-padding-vertical @breadcrumbs-padding-horizontal;
  color: inherit;
  float: left;
  max-width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &-text {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__link {
    color: #4c4c4c;
    text-decoration: none !important;
  }
  &:last-child {
    max-width: 75%;
  }
  & + & + &:last-child {
    max-width: 50%;
  }
  &:first-child {
    padding-left: 0;
  }
  .fa {
    margin-right: 10px;
    margin-top: -.5em;
    position: relative;
    top: -.05em;
  }

  & + & {
    padding-left: (@breadcrumbs-padding-horizontal + @breadcrumbs-splitter-width);

    &:before {
      position: absolute;
      left: 0;
      top:0;
      bottom:0;
      content: "&raquo;";
      display:block;
      text-indent: -9999px;
      overflow: hidden;
      width: @breadcrumbs-splitter-width;
      background: url('@{path-images}breadcrumbs-splitter.png') 0 0 no-repeat;
      background-size: 100% 100%;
    }
  }
}

.b-bc-search-drop {
  float:right;
  position: relative;
  height: @breadcrumbs-padding-vertical*2 + @breadcrumbs-line-height;
  margin-left: -(@breadcrumbs-padding-horizontal*2 + 2em);
  top: 0;
}

.b-bc-search-dropdown-btn {
  .dropdown-arrow-after(4px);
  color: @gray;

  padding: 0 .5em;
  margin-right: -.5em;
  height: 100%;
  border: 0;
  background: none;

  .fa {
    font-size: 22px;
    line-height: inherit;
  }
}

.b-bc-search-dropdown-menu {
  box-shadow: none;
  position: absolute;
  background: @dropdown-bg;
  border:0;
  border-bottom: 1px solid @blockquote-border-color;
  padding: 13px 0 20px;
  margin: 0;

  right: 0;
  left:0;
  top: 100%;
  z-index: 100;

  display: none;
  &.open {
    display: block;
  }
}

.b-cat-breadcrumbs {
  padding: 0 0 30px;
  font-family: @font-family-bolder;
  font-weight: 100;

  border-bottom: 1px solid @blockquote-border-color;
}

.b-cat-breadcrumbs-navigator {
  padding: 0;
  font-size: 0.01px; // remove spacing between childrens
}
.b-cat-breadcrumbs-actions {
  padding: 20px 0 0;
}

.b-cat-breadcrumbs-item {
  display: inline-block;
  vertical-align: bottom;
  font-size: @font-size-large*2;

  &, &:hover {
    color: inherit;
    text-decoration: none;
  }

  & + & {
    margin-left: .25em;
    &:before {
      content: "/";
      color: inherit;
      display: inline-block;
      vertical-align: bottom;
      margin: 0 0 0 .25em;
    }
  }

  &__parent {
    font-size: @font-size-large*1.75;
  }
  &__current {
    font-size: @font-size-large*1.5;
  }

  &-text {
    border-bottom: 1px solid transparent;
  }
  &__link:hover &-text {
    border-bottom: 1px solid;
  }

  &-text__color {
    .color();
  }
}

.b-cat-breadcrumbs-actions-btn__back {
  .fa {
    margin-right: .5em;
    font-weight: bold;
  }
}