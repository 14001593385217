.g-download {
  display: block;
  position: relative;
  margin: 20px 0 0;

  & + .g-download {
    margin-left: 25px;
  }

  &.g-download--inline {
    display: inline-block;

    .g-download-inner {
      table-layout: auto;
    }
  }
}
.g-download-link {
  display: table-cell;
  vertical-align: middle;
  height: @download-icon-min-height;
  padding-left: 45px;
  font-size: 15px;

  text-decoration: none !important;

  &:hover {
    .g-download-link-col__title {
      text-decoration: underline;
    }
  }
}
.g-download-inner {
  display: table;
  width: 100%;
  height:100%;
  table-layout: fixed;
}
.g-download-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  display: block;
  width:95%;
}
.g-download-comment {
  font-size: @font-size-small;
}
.g-download-icon {
  left:0;
  top:0;
  position: absolute;
  background: url('@{path-images}svg/etc/download-sheet.svg') right bottom no-repeat;
  width: @download-icon-min-width;
  height: @download-icon-min-height;
  &:after {
    content: attr(data-extension);
    position: absolute;
    bottom: 7px;
    right: 1px;
    left: 1px;
    text-align: center;
    font-style: normal;
    font-size: 9px;
    font-family: @font-family-sans-serif;
    text-transform: uppercase;
    color: #80888A;
  }
}
.g-download-attach-btn {
  //color: inherit;
  //width: 1em;
  //height:1em;
  //display: block;
  //position: absolute;
  //background: none;
  //border: none;
  //top:.5em;
  //right:0;
  //&:after {
  //  .fa-icon();
  //  //content: @fa-var-arrow-right;
  //  content: @fa-var-angle-right;
  //  color: inherit;
  //  font-size: 22px;
  //  position: absolute;
  //  top:50%;
  //  left:50%;
  //  transform: translate(-50%,-50%);
  //}

  .fa {
    margin-right: .5em;
    font-size: 120%;
  }
}
.g-download-preview {
  display: block;
  cursor: zoom-in;
  overflow: hidden;

  left:0;
  top:0;
  position: absolute;
  width: @download-icon-min-width;
  height: @download-icon-min-height;
  border: 1px solid @block-border-color;

  &-img,
  img {
    min-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
}

.g-download-link-layout {
  display: table;
  table-layout: fixed;
  width:100%;
  text-decoration: none !important;
}
.g-download-link-col {
  display: table-cell;
  vertical-align: top;

  &__actions {
    white-space: nowrap;

    .btn {
      //opacity: 0;
      opacity:.25;
      transition: opacity .35s ease;
    }

    .g-download-link:hover & {
      .btn {opacity: 1;}
    }

    .btn + .btn {
      margin-left: 12px;
    }
  }


}