@vis-ticket-header-icon-size : 40px;

.user-ntf-second-applicant-open {
  width: 99%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  position: relative;
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    content: '';
    width: 50px;
    background: #fffadf; /* Old browsers */
    background: -moz-linear-gradient(left, transparent, #fffadf 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, transparent,#fffadf 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, transparent,#fffadf 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='transparent', endColorstr='#fffadf',GradientType=1 ); /* IE6-9 */
  }
}

.vis-results {
  padding-left: 0;
  padding-right: 0;
}

.vis-ticket {
  max-width: 800px;
  margin: 0 auto;
}

.vis-ticket-header {

}
.vis-ticket-header-inner {
  position: relative;
  padding-bottom: 45px;
}
.vis-ticket-header-layout {
  display: table;
  height: @vis-ticket-header-icon-size; // min-height
  margin-right: 250px;
}
.vis-ticket-header-layout-inner {
  display: table-cell;
  vertical-align: middle;
}

.vis-ticket-header-status {
  display: block;
  font-size: @font-size-large;
  font-family: @font-family-bold;
  font-weight: normal;
}
.vis-ticket-header-info {
  display: block;
  font-size: @font-size-base;
  font-family: @font-family-base;
}
.vis-ticket-header-actions {
  position: absolute;
  right:0;
  top:0;

  .btn {
    padding-top:9px;
    padding-bottom:9px;
  }
}

//
// SIGN RESULTS
//

.vis-result {
  //color: #4d4d4d;

  .modal-body--custom-attrs{
    padding:35px 20px 0;
  }
}

.vis-result-map {
  position: relative;
  /*margin: -35px -50px 40px;*/
  margin-bottom: 40px;
}

.vis-result-map-img {
  width: 100%;
}

.vis-result-layout {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.vis-result-row {
  display: table-row;
}

.vis-result-col {
  @side-padd : 50px;
  vertical-align: top;
  display: table-cell;
  padding: 0 @side-padd;


  &:first-child {
    padding-left: 0;
    width: 30%;
  }
  &:last-child {
    padding-right: 0;
    width: 70%;
  }
}

.vis-result-date-item + .vis-result-date-item,
.vis-result-row--bordered .vis-result-col + .vis-result-col {
  border-left-width: 2px;
  border-left-style: solid;
}

.vis-result-block {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0);

  & + & {
    margin-top: 25px;
  }
}

.vis-result-block-email {
  word-break: break-word;
}

.vis-result-date-item,
.vis-result-block--date,
.vis-result-row--bordered .vis-result-col + .vis-result-col {
  border-color: #e9f2f8;//rgba(0, 0, 0, .15);
}

.vis-result-block--pin,
.vis-result-block--date,
.vis-result-block--greetings {
  font-family: @font-family-medium;
  font-size: @font-size-large;
  //font-family: 'Open Sans Bold';
  //font-size: 125%;
}

.vis-result-block--pin-helper,
.vis-result-block--greetings {
  //max-width: 90%;
}

.vis-result-block--office {
}

.vis-result-block--pin,
.vis-result-block--date {
  padding: 15px;
  text-align: center;
}

.vis-result-block--date {
  padding-left: 0;
  padding-right: 0;

  background: #eef8fd;
  border: 1px solid #e4eef5;
  color: @lead-text-color-dark;
}

.vis-result-date {
  display: table;
  width: 100%;
}

.vis-result-date-item {
  display: table-cell;
  text-align: center;

  &--time {
    width: 35%;
  }
}

.vis-result-block--pin {
  text-transform: uppercase;
  border: 1px solid;
  //color: @brand-critical;
  color: @brand-danger;
}

.vis-result-actions {
  .clearfix();
  text-align: right;
  width: 100%;
  display: block;

  .btn-action--remove-ticket {
    float: left;
  }

  .btn {
    margin-bottom: 20px;
  }

  .btn + .btn {
    margin-left: @padding-small-horizontal/2;
  }
  .btn-link + .btn-link {
    margin-left: -@padding-small-horizontal;
  }

  .vis-result-col {
    vertical-align: middle;
    text-align: center;
  }

  .btn-action--accept-ticket {
    width: 100%;
  }
}

.vis-result-actions--additional {
  .vis-result-action-link,
  .btn {
    min-width: 35%;
    max-width: 47.5%;
    color: @text-color;
  }
}

.vis-result-action-link {
  background-position: 10px 50%;
  background-repeat: no-repeat;
  border: 0;

  &:hover {
    background-color: transparent;

    .vis-result-action-link-text {
      border-bottom: 1px solid;
    }
  }

  &--print {
    /*background-image: url('../../images/sunshine/ico/ticket-print.png');*/
    padding-left: 44px;
  }
  &--download {
    /*background-image: url('../../images/sunshine/ico/ticket-download.png');*/
    padding-left: 0;
  }
}