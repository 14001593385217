@linked-list-action-icon-size : 18px;

.linked-list {
  display: block;
  margin: 0;
  padding: 0;

  .linked-list-outer & {
    padding-bottom: 30px;
  }
}
.linked-list-item {
  display: block;
  padding: 0;
  margin:0;

  & + & {
    margin-top: 10px;
  }
}
.linked-list-item-inner {
  position: relative;
  display: inline-block;
  width:auto;
}
.linked-list-actions {
  display: block;
  padding: 0;
  margin:0;
}
.linked-list-link {
  display: block;
  font-size: @font-size-middle;
}
.linked-list-item-actions {
  left:100%;
  top: 50%;
  font-size: 0.01px;
  margin-top:-@linked-list-action-icon-size/2;
  padding: 0 0 0 10px;
  position: absolute;
  white-space: nowrap;
  display: none;

  .linked-list-item:hover & {
    display: block;
  }
}

.linked-list-item-action {
  margin-left: 10px;

  @size : @linked-list-action-icon-size;
  width: @size;
  height:@size;
  overflow: hidden;
  position: relative;
  color: transparent;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  &:after {
    .fa-icon();
    font-size: @size;
    line-height: 1em;
    display: inline-block;
    text-decoration: none !important;
    position: absolute;
    top:0;
    left:0;
  }

  &--edit {
    &:after {
      content:@fa-var-pencil;
      color: @link-color;
    }
    &:hover:after {
      color: lighten(@link-hover-color, 20%);
    }
  }
  &--remove {
    @c : @brand-critical;
    &:after {
      content: "\00d7";
      color: @c;
    }
    &:hover:after {
      color: lighten(@c, 20%);
    }
  }
}