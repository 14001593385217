.featherlight-loading .featherlight-content {
  background-color:transparent;
}

//.featherlight-loading .featherlight-close-icon,
.featherlight-loading .featherlight-previous,
.featherlight-loading .featherlight-next {
  display:none;
}

.featherlight:last-of-type {
  background: fade(#fff, 65%);
}