.b-petition-in-sign {
  padding: 54px 0;
  border-bottom: 1px solid #c8c7cc;
  position: relative;
  color: #839199;
  display: block;

  b {
    color: #000;
  }

  p {
    &:first-child {
      margin-top: 0;
    }
  }

  &:hover {
    color: inherit;
    background: #e9f2f8;
  }
}

.b-petition-in-sign-ico {
  display: block;
  position: absolute;
  left: 5px;
  width: 102px;
  top: 50px;
  height: 102px;
}

.b-petition-in-sign-error {
  margin-bottom: 20px;

  h3 {
    font-size: 32px;

    .modal-subtitle {
      color: @brand-critical;
    }
  }

  .modal-header {
    margin-bottom: 0;
  }
}

.b-petition-in-sign-content {
  display: block;
  padding-left: 130px;
}

.b-petition-in-sign-link {
  font-size: 18px;
  line-height: 22px;
  color: @link-color;
}

.b-petition-in-sign-check-wrap {
  display: block;
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 40px;
  width: 280px;
  padding: 0;
}

.b-petition-in-sign-check {
  display: block;
  position: relative;
  width: 280px;
  padding: 40px 0 0;
  top: 0;

  &:first-child {
    padding-top:0;
  }

  &.fixed {
    position: fixed;
  }

  .btn {
    width: 182px;
  }
}

.b-petition-states {
  padding-bottom: 30px;
  border-bottom: 1px solid #cdd1d4;
  color: #839199;
  position: relative;

  b {
    color: #000;
  }

  .petition-states-ok {
    color: @brand-success;
  }

  .petition-states-wrong {
    color: @brand-critical;
  }

  .petition-states-wait {
    color: @brand-warning;
  }
}

.b-petition-state-item {
  display: block;
  margin: 1em 0;
}

.b-sign-type-ico {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: @status-icon-size;
  height:@status-icon-size;
  margin: 0 10px;

  border-radius: 100%;
  border: 0 solid;

  &[data-signature] {
    cursor: pointer;
  }

  &:before,
  &:after {
    .fa-icon();
    width: 1em;
    text-align: center;
    font-size: 100%;//16px;
    line-height: 1em;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }

  &:before {
    content: @fa-var-circle-thin;
    font-size: 200%;//28px;
  }

  &,
  &--unknown {  color: @brand-disabled; &:after {
    content: @fa-var-question;
  }}
  &--valid {color: @brand-success; &:after {
    content: @fa-var-check;
  }}
  &--disabled {color: @brand-disabled; &:after {
    content: @fa-var-cog;
  }}
  &--wrong {color: @brand-critical; &:after {
    margin-top:-1px;
    content: @fa-var-times;
  }}
  &--wait {color: @brand-warning; &:after {
    content: @fa-var-ellipsis-h;
    font-size: 100%;
  }}
}

.p-modal--petition-in-sign-view {
  min-height: 350px;
}