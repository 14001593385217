.upload-box {
  display: block;
  vertical-align: baseline;
  position: relative;

  .jq-file__name {
    padding-right: 64px;
  }
}
.upload-box__amazon-s3 {

}
.upload-box-button {
  margin: 1px;
}

.upload-box-el-container {
  position: relative;
}
.upload-box-el-actions {
  position: absolute;
  left:100%;
  margin-left: 5px;
  top: 0;

  &__compact {
    left:auto;
    right: 100px;
    margin-left: 5px;
    top: 100%;
    margin-top: 5px;

    .upload-box-action {
      padding-top: 2px;
      padding-bottom: 2px;
      font-size: 85%;
      line-height: 1.33em;
    }
  }
}

.upload-box-progress-wrapper {
  display: block;
  width: ~"calc(100% - 100px)";

  margin-top: 0;
  position: absolute;
  left: 0;
  top: 100%;
}

.upload-box-progress-el {
  position: absolute;
  left:0;
  bottom:100%;
  background: @brand-primary;
  display: block;
  width: 0;
  height:7px;
  border:0;
  overflow: hidden;

  -webkit-transition: width .35s ease;
  -moz-transition: width .35s ease;
  transition: width .35s ease;

  &__static {
    -webkit-transition: initial;
    -moz-transition: initial;
    transition: initial;
  }

  opacity: .5;
}
.upload-box-progress-el.error {
  border-top-color: #f33;
  border-bottom-color: #a33;
}

.upload-box-progress-text {
  /*font-size: 12px;*/
  font-size: 80%;
  line-height: 1em;
  text-align: left;
  padding: .75em 0 0;

  position: absolute;
  right: 120px;
  top: 10px;
}
.upload-box-progress-status {
  font-size: inherit;
  text-align: right;
  padding: 5px 0 0;
}
.upload-box-progress-status__success {
  color: #00aa00;
}
.upload-box-progress-status__error {
  color: #ee0000;
}
/*.upload-box-progress-text + .upload-box-progress-status {*/
/*float: right;*/
/*}*/