.responsive__mobile {

  .modal-body {
    textarea,
    input {
      // disable auto zoom on text inputs
      font-size:16px;
    }
  }

  .t-modal-layout .t-modal-layout-item {
    width:100%;
  }

  .app-inner {
    padding-bottom: 150px;
  }

  .b-tech-message {
    .b-tech-message-body {

      & > * {
        font-size: 14px !important;
      }
    }
  }

  .b-petition-in-sign-check-wrap {
    position: relative;
    margin-left:0;
    left: 0;
    top: 0;
  }

  .b-petition-in-sign-check {

    &.fixed {
      position: static;
    }
  }

  .attr-field--layout {
    .attr-label--inline {
      width: 35%;

      &+.attr-value {
        width: 65%;
      }
    }
  }

  //.b-auth-warn-inner {
  //  display: table;
  //  max-width:100%;
  //  min-height: 400px;
  //  height:100%;
  //}
  //.b-auth-warn-inner__actions {
  //  width:100%;
  //  right:0;
  //  left:0;
  //  bottom: 20px;
  //  top: auto;
  //  height:auto;
  //  min-height: initial;
  //  padding:0;
  //  transform: none;
  //}
  //.b-auth-warn-actions {
  //  display: block;
  //}
  //.b-auth-warn-text {
  //  display: table-cell;
  //}
  //.b-auth-warn-actions,
  //.b-auth-warn-text {
  //  text-align: center;
  //}

  .g-tile {
    width: 50%;
  }

  .petition-row-info-actions {
    @btn-margin-top : 20px;
    margin-top: -@btn-margin-top;
    margin-bottom: @btn-margin-top;
    .btn {
      float:left !important;
      width: 45%;
      margin-top: @btn-margin-top;
      padding: @padding-large-vertical @padding-large-horizontal;
      font-size: @font-size-middle;
      &:nth-child(1n) {
        margin-left: 10%;
      }
      &:nth-child(2n-1) {
        margin-left: 0;
      }
    }
  }

  .avDates-datesCol {
    width: 24%;
    margin-bottom: 10px;
  }
}