@cookieBar-color : #2e729e;
@cookieBar-color-hover: #00467d;

.cookie-accept-message {
  position: fixed;
  background: #FFFFFF;
  z-index: 10000;
  box-shadow: 0 0 3px #ccc;
  padding: 0 0 0 20px;
  border-top: 2px solid @cookieBar-color;
  &.at-bottom {
    bottom: 0;
    left: 0;
    right: 0;
  }

  & p {
    margin: 1em;
    padding-right:100px;
  }

  & .cookieBar-button {
    position: absolute;
    bottom:50%;
    margin-bottom:-18px;
    right: 20px;
    text-decoration: none;
    padding: 5px 10px;
  }
}