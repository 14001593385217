.throbber {
  display: block;
}
.throbber-title {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 11px;
  font-weight: 400;
  font-size: @font-size-h3;

  .content-body.hidden + .throbber & {
    padding-top:0;
  }
}
.throbber-sub-title {
  font-size: @font-size-h4;
  margin-bottom: 30px;
  text-align: center;
  opacity: .75;
}
.throbber-img {
  display: block;
  margin: 20px auto;

  &__big {
    @size : 100px;
    width: @size;
    height: @size;
    background: url('@{path-images}loader-big.gif') center center no-repeat;
  }
  &__small {
    @size : 24px;
    width: @size;
    height: @size;
    background: url('@{path-images}loader-small.gif') center center no-repeat;
  }
}

.holder-loader {
  position: relative;
  cursor: not-allowed;
}
.holder-loader:after {
  content: "" !important;
  display: block !important;
  background: #fff url('@{path-images}loader-small.gif') 50% no-repeat !important;
  position: absolute !important;
  top: -1px !important;
  left: -1px !important;
  width: calc(100% + 2px) !important;
  height: calc(100% + 2px) !important;
  right: 0 !important;
  bottom: 0 !important;
  cursor: progress !important;
  z-index: 5 !important;
  opacity: .85 !important;
}
.holder-loader:before {
  content: none !important;
}