.b-tech-message {
  background: #fffadf;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;

  strong, b {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  }

  .wrapper {
    @padd-vertical : 15px;
    padding: @padd-vertical 45px @padd-vertical 65px;
    font-size: 15px;
    color: @gray-base;
    .mobile-txt-fix;
  }
}
.b-tech-message-body {

}
.b-tech-message-icon {
  @icon-color : @brand-warning;
  @icon-size : 24px;

  width: @icon-size;
  height:@icon-size;
  color: @icon-color;
  border-radius: 100%;
  font-style: normal;
  border: 2px solid;
  position: absolute;

  top: 12px;
  left: 24px;

  &:after {
    content:"!";
    display: block;
    position: absolute;
    top:50%;
    left:50%;
    color: inherit;
    transform: translate(-50%,-50%);
    font-size: 20px;
    font-weight: 100;
    .mobile-txt-fix;
  }
}
.b-tech-message-close {
  font-size: 24px;
  color: @gray-light;
  position: absolute;
  right: 25px;
  top: 9.5px;
  &:hover {
    text-decoration: none;
    color: @gray;
  }
}