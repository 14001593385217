.b-panel {
  color: #fff;
  background: @panel-color-bg;
  user-select: none;
  position: relative;
  top:0;
  left:0;
  right:0;
  height: @panel-height;

  &__govbar_enabled {
    //display: none;
    margin-top:-50px;
    .b-panel-wrapper {
      display: none;
    }
  }
}
.b-panel-drop {
  float:left;
  cursor: pointer;
  position: relative;
  padding: 0;
  height: @panel-height;
  line-height: @panel-height;

  &:hover {
    background: @panel-color-hover-bg;
  }

  &.b-panel-drop--no-drop {
    cursor: default;

    &:hover {
      background: transparent;
    }
  }

  &__logo {
    width:76px;
  }
  &__lang {
    float:right;
    text-transform: uppercase;

    .b-panel-drop-btn {
      .g-flag {
        top: 17px;
      }
    }

    .g-flag {
      vertical-align: top;
      position: relative;
      margin-right:12px;
    }
  }

  &__serverTime {float:right; pointer-events:none; text-transform:uppercase;  }
}

.b-panel-drop-inner {
  padding-top: 0;
  padding-bottom: 0;

  .b-panel-drop:first-child & {
    padding-left:9px;
    left: -9px;
  }

  &:after {
    .fa-icon();
    content: @fa-var-angle-down;

    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right:9px;
    font-size: 16px;
  }
}

.b-panel-drop-btn {
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  position: relative;
  display: block;
  box-shadow: none;
  outline:none;
  margin:0;

  width: 100%;
  height: 100%;

  padding: 0 18px;

  &__logo {
    background: url('@{path-images}rus-logo.png') 50% 50% no-repeat;
    background-size:26px 29px;
  }

  &.dropdown-value {
    padding-right: 5px + @panel-arr-width;

    &:after {
      .fa-icon();
      content: @fa-var-angle-down;

      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right:9px;
    }
  }
}
