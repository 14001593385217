.service-dep {
  .modal-header {
    padding: 0 0 0 100px;
  }
  #leftnav.sticky .dep-leftnav-container{
    position: fixed;
  }
  .dep-leftnav-title {
    font-size: 18px;
    font-weight: bold;
    font-family: "EtelkaMediumPro", "als_ekibastuz_bold", Arial, sans-serif;
    margin-bottom: 15px;
  }
  .dep-leftnav-container ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .dep-leftnav-container li {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 30px;
  }
  .dep-leftnav-container li a{
    font-size: 14px;
    color: #0173c1;
    border-bottom: 1px dashed #0173c1;
    display: inline-block;
    line-height: 14px;
    text-decoration: none;
  }
  .dep-leftnav-container li a:hover {
    text-decoration: none;
    border-bottom: 1px transparent;
  }
}