.btn {
  &.loading {
    color: transparent !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #fff;
    border-color: #fff;
    background-image: url('@{path-images}loader-small.gif');
    pointer-events: none;
    transition: background-color .15s ease;
  }
}

.btn-back {
  i {
    margin-right: 10px;
    font-weight: bold;
  }
}

.btn-group-wrapper {
  display: block;
  margin: 25px 0;
}

.btn-group-label {
  display: inline-block;
  vertical-align: middle;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  color: #000;
  margin: 0 20px 0 0;
}

.scroll-top-button {
  width: 65px;
  height: 65px;
  border-radius: 5%;
  border: 1px solid #cdd1d4;
  background: #fff;
  color: #4c4c4c;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 25px;
  z-index: 100;
  text-align: center;

  &:after {
    display: inline-block;
    content: " ";
    border: 1px solid #cdd1d4;
    width: 25px;
    height: 25px;
    border-width: 2px 0 0 2px;
    transform: rotate(45deg);
    margin-top: 25px;
  }

  &:hover {
    background-color: #efefef!important;
  }

}

.btn-fgisdo {color:#A6AFC2}
.btn-more {
  .my-small-loader {position:absolute; margin-top:-10px !important}
}