.sidebar-steps {
  margin: 0;
  padding: 0;
  width:100%;

  counter-reset: sidebar-step-counter;
}

.sidebar(@color: @brand-primary) {

  .sidebar-step {
    padding: (@group-bullet-size*0.2) 0 95px (@group-bullet-size+11px);
    font-family: @font-family-bold;
    font-size: @font-size-middle;
    line-height: 1.5em;

    word-break: break-word;
    position: relative;
    display: block;

    &, a {
      cursor: default;
      /*cursor: not-allowed;*/
    }

    &--prev,
    &--active {
      &, a {
        cursor: pointer;
      }
    }

    &--active {
      font-weight: bold;
    }

    a, a:hover {
      color: inherit;
      text-decoration: none !important;
    }

    &:before {
      content:"";
      display: block;
      position: absolute;

      .gradient(@gray-lighter, @color);

      @s : 5px;
      @w : 3px;
      width: 3px;
      left: @group-bullet-size/2 - @w/2;
      top: @group-bullet-size + @s;
      bottom: @s;
    }
    &[data-target="__services"].sidebar-step--active:before,
    &:last-child:before {
      display: none;
    }
  }

  .sidebar-step-arrow {
    fill: @color;
    width:19px;
    height:80px;
    position: relative;
    .sidebar-steps & {
      left: (@group-bullet-size*0.2);
    }
  }

  .sidebar-step-marker {
    width: @group-bullet-size;
    height:@group-bullet-size;
    background: #fff;
    border-radius: 100%;
    box-shadow: inset 0 0 0 2px @color;
    position: absolute;
    top:0;
    left:-1px;

    //&__main {
    //  background: @color;
    //  box-shadow : none;
    //}

    &:after {
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);

      content: counter(sidebar-step-counter);
      counter-increment: sidebar-step-counter;

      color: @color;
      //font-size: @font-size-small;
      font-size: @font-size-base;
      font-family: @font-family-medium;
      font-weight: normal;
      font-style: normal;

      height: 1em;
      line-height: 1.25em;
      width: 100%;
      text-align: center;
    }

    .sidebar-step--active & {
      box-shadow: inset 0 0 0 8px @color;
      &:after {
        content:"";
      }
    }
  }

  .sidebar-step--prev.sidebar-step--valid:not(.sidebar-step--active) .sidebar-step-marker {
    background-color: @color;
    box-shadow: none;
    &:after {
      .fa-icon();
      content: @fa-var-check;
      font-size: 18px;
      color: #fff;
    }
  }

  .sidebar-step--prev.sidebar-step--valid {
    color: @text-color;
  }

  //.sidebar-step--active,
  //.sidebar-step--prev.sidebar-step--active,
  //.sidebar-step--prev:hover,
  //.sidebar-step--prev.sidebar-step--valid:hover {
  //  color: @link-hover-color !important;
  //}
  .sidebar-step--disabled {
    /*opacity: .5;*/
    display: none;
  }
}

// global // TODO : color by egService type
.sidebar(@brand-primary);

// equeue
.modal--equeue {
  .sidebar(@brand-primary);
}