.linkobj-header {
  display: block;
  padding: 0 0 40px;
}
.linkobj-title {
  position: relative;
  font-family: @font-family-bold;
  font-weight: bold;
  font-size: @font-size-large;
  line-height: 1.5em;
}
.linked-obj-area {
  cursor: pointer;
  &:hover {
    border-color: @input-border-focus;
  }
}